import "./CardFrozenSuccessfullyModal.css"

const CardFrozenSuccessfullyModal= () => {
    return(
        <div id="card-frozen-success-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <div className="freeze-card-success">
                    <div>
                        <img src={require("../../../../images/card-frozen.svg").default} /> 
                    </div>
                </div>
                
            
                <div className="ep-pin">
                    <h4>Card frozen successfully</h4>
                </div>

                <div className="freeze-card-modal-text">
                    <span>Your Payrail business debit card has been frozen.</span> <br></br>
                    <span>You can unfreeze anytime you want</span>
                </div>

                <div className="freeze-card-modal-btn">
                    <button className="pry-btn modal-trigger modal-close ">
                        Okay
                    </button>
                </div>
            </div>

        </div>
    )
}

export default CardFrozenSuccessfullyModal;
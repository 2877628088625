import React, { useEffect, useState }  from "react";
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../../../components/InputField";
import { setLoading } from "../../../../../redux/reducers/auth";
import { addInvoice, fetchInvoice } from "../../../../../redux/reducers/invoice";
import { TextArea } from "../../../../../components/TextArea";
import M from "materialize-css";
import { REQUEST_PAYMENT_MODAL_ID } from "../../../../../utility/modalIDs";


/**
 * Constructs form validation object for user old signup using Yup library
 * @param password : User password.
 */
 const formSchema = Yup.object().shape({
    amount: Yup.string().required("Required"),
    email_address: Yup.string().required("Required").email(),
    // description: Yup.string().required("Required"),
    
})
const SimpleInvoiceTab = () => {
    const dispatch = useDispatch()
    const  {   invoice, auth } = useSelector(state=> state)
    const  {   actionType, invoiceAdded } = invoice
    const {loading} = auth
    const [error, setError] = useState({})
    

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
    resolver: yupResolver(formSchema),
    })

    useEffect(()=>{
        setError(errors)
    },[errors])

    /**
     * onSubmit function to trigger form submission for phone number registration.
     * @param email : User email address.
     * @param phone : User phone number.
     * @param password : Account password.
     */
     const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){

            dispatch(setLoading(true))
            dispatch(addInvoice(data))
            
            }
        }

        useEffect(()=>{
            if (invoiceAdded && actionType === "invoice/invoiceAdded" ) {
                console.log("___ROLR")
                dispatch(fetchInvoice())
                dispatch(setLoading(false))
                const elemsModal2 = document.querySelector('#customer-success-modal');
                M.Modal.getInstance(elemsModal2).open();

                const elemsModal1 = document.querySelector(`#${REQUEST_PAYMENT_MODAL_ID}`);
                M.Modal.getInstance(elemsModal1).close();

            }

        },[invoiceAdded,actionType])

    return (
        <div>
            <p className="rpmsit-description">Set amount, description, and request payment from a customer.</p>
            <form  className="dashboard-form modal-form" onSubmit={handleSubmit(onSubmit)}>
               
                <InputField 
                    type="email" 
                    label="Email Address" 
                    {...register('email_address',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['email_address']? error['email_address'].message: ""} 
                    id={{input: "email_address"}} 
                    classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                />
                
                <InputField 
                    type="text" 
                    label="Amount" 
                    {...register('amount',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['amount']? error['amount'].message: ""} 
                    id={{input: "amount"}} 
                    classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                >
                    <small className="rpmsit-min-depo-text">Minimum request amount is NGN 100</small>
                    </InputField>
                         
                <TextArea 
                    type="text" 
                    label="Note" 
                    {...register('description',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['description']? error['description'].message: ""} 
                    id={{input: "description"}} 
                    classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"materialize-textarea form-inp-field", }} 
                    />

                <button type="submit" className="pry-btn rpmsit-btn" >
                    Send
                </button>
                {/* <button type="submit" className="pry-btn modal-trigger modal-close rpmsit-btn" data-target="bill-payment-schedule-success-modal">
                    Send
                </button> */}
            </form>
        </div>
    )
};

export default SimpleInvoiceTab;
import React from "react";
import { useDispatch } from "react-redux";
// import M from 'materialize-css';

import ModalWrapper from "../../../components/UI/ModalWrapper";
import { setLoading } from "../../../redux/reducers/auth";
import { approveTransaction, declineTransaction } from "../../../redux/reducers/wallet";

import { REQUEST_TRANSACTION_ITEM_MODAL_ID } from "../../../utility/modalIDs";

const RequestItemModal = ({item}) => {
    const dispatch = useDispatch()
    const {channel="",status="",reference="",title="",amount="", date="", trx_status=""} = item
    const handleApproval = ()=>{
        dispatch(setLoading(true))
        dispatch(approveTransaction(item.id))
    }
    const handleDecline = ()=>{
        dispatch(setLoading(true))
        dispatch(declineTransaction(item.id))
    }
    return (
        <ModalWrapper modalID={REQUEST_TRANSACTION_ITEM_MODAL_ID}>
            <div className="flex-div qt-modal-header">
                <img src="" alt="" className="qt-modal-icon" />
                <div>
                    <h4 className="qt-modal-title">{title}</h4>
                    <span className="qt-modal-desc">
                        {date}
                    </span>
                </div>
            </div>
            <div className="qt-modal-content-wrapper">
                <div className="qt-modal-content-row flex-div justify-content-btw">
                    <div className="qt-modal-content-block">
                        <div className="qt-content-title">Amount</div>
                        <div className="qt-content-desc">₦{amount}</div>
                    </div>
                    <div className="qt-modal-content-block">
                        <div className="qt-content-title">Reference</div>
                        <div className="qt-content-desc">{reference}</div>
                    </div>
                </div>
                <div className="qt-modal-content-row flex-div justify-content-btw">
                    <div className="qt-modal-content-block">
                        <div className="qt-content-title">Origin</div>
                        <div className="qt-content-desc">Tunde Ajayoi </div>
                    </div>
                    <div className="qt-modal-content-block">
                        <div className="qt-content-title">Debit Account</div>
                        <div className="qt-content-desc">Main Account: ₦3,500,000</div>
                    </div>
                </div>
            </div>

            <div className="ivvrm-btns qt-btns-wrapper">
               
                <div onClick={handleApproval} className="ivvrm-btn full-width">Approve</div>
                <div onClick={handleDecline} className="ivvrm-btn full-width">Decline</div>
                <div className="ivvrm-btn2 full-width modal-close" >Cancel</div>
            </div>
        </ModalWrapper>
    );
};

export default RequestItemModal;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  referrals:[],
  reward_earned:{
    current_reward:0,
    total_reward: 0
  },
  referral_detail:{
    referral_code:"OLADJOOYE",
    referral_link: "https://use.payrail.com/r/oladjooye"
  },
  performance:{
    merchant_per_referral: 0.1,
    merchant_referred: 0,
    current_signup: 0
  }
 
}

export const referralSlice = createSlice({
  name: 'referral',
  initialState,

  reducers: {
    addReferral: ()=>{},
    searchReferral: ()=>{},
    getPerformance: ()=>{},
    Performance: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
    filterReferral: ()=>{},
    fetchReferral: ()=>{},
    Referrals: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
    getRewardEarn: ()=>{},
    RewardEarn: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
    getReferralDetail: ()=>{},
    ReferralDetail: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Referrals,
  Performance,
  ReferralDetail,
  RewardEarn,
  
  addReferral,
  searchReferral,
  filterReferral,
  fetchReferral,
  getPerformance,
  getRewardEarn,
  getReferralDetail
   
    } = referralSlice.actions

export default referralSlice.reducer
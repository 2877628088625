import { useEffect } from "react";
import M from "materialize-css";

import AddMoney from "./AddMoney";
import SendMoney from "./SendMoney";
import RecentTranstions from "./RecentTransactions";
import { useSelector } from "react-redux";

const TransferPageMain = ({goToBulkTransfer}) => {
    const {wallet={}} = useSelector(state=>state);
    const {recent_transaction = []} = wallet
    useEffect(()=> {
        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);
    }, []);
    return (
            <>
                <div className="page-title-wrapper">
                    <h4 className="page-content-title">
                        <span>Transfer</span>
                    </h4>
                </div>

                <div className="dashboard-form mtp-account-select">
                    <div className="select-field input-field single-field">
                        <select id="role">
                            <option value="Business Owner">Business Owner</option>
                            <option value="Business Owner">Business Owner</option>
                            <option value="Business Owner">Business Owner</option>
                        </select>
                        <img src={require("../../images/caret-icon.svg").default} alt="caret" className="mtp-inp-caret-icon" />
                    </div>

                    <div className="mtp-account-select-balance">
                        <span>₦</span>12,000,000.09
                    </div>
                </div>

                <div className="mtp-section">
                    <div className="row tab-wrapper mtp-tab-container">
                        <div className="col s12 pad0">
                            <ul className="tabs">
                                <li className="tab">
                                    <a className="active flex-div" href="#profile-block">
                                        <span>Add money</span> 
                                    </a>
                                </li>
                                <li className="tab">
                                    <a className="flex-div" href="#security-block">
                                        <span>Send Money</span> 
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div id="profile-block" className="col s12 pad0">
                            <AddMoney />
                        </div>
                        <div id="security-block" className="col s12 pad0">
                            <SendMoney goToBulkTransfer={goToBulkTransfer} />
                        </div>
                    </div>
                    <RecentTranstions recent_transaction={recent_transaction} />
                </div>
            </>
    );
};

export default TransferPageMain;
import ColoredWrapper from "../../components/UI/coloredWrapper/coloredWrapper";

import M from "materialize-css";
import $ from "jquery";
import { useEffect } from "react";

// Components: Modals
import NewPaymentDateModal from "./components/Modals/NewPaymentDate";
import LoanConfirmationModal from "./components/Modals/LoanConfirmation";
import ChooseRepaymentOptionsModal from "./components/Modals/ChooseRepaymentOptions";

//modalIDs
import { CHOOSE_REPAYMENT_OPTION_MODAL } from "../../utility/modalIDs";

const LoanPreference = () => {
    useEffect (()=> {
        const elemsRange = document.querySelectorAll("input[type=range]");
        M.Range.init(elemsRange);

        $("#borrow-range").change(function(){
            let num = $(this).val().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            $("#borrow-amount > span.e-t").text(num);
        })
        $("#borrow-tenure-range").change(function(){
            $("#loan-tenure > span.e-t").text($(this).val());
        })
    }, []);

    return (
        <div className="page-content-wrapper">
            <div className="container">  
                <div className="page-title-wrapper loan-overview-header-container">
                    <h4 className="page-content-title flex-div">
                        <span>Loan</span>
                    </h4>
                </div>
                <div className="loan-overview-content">
                    <div className="borrow-box-wrapper">
                        <ColoredWrapper outlined fullHeight>
                            <h4>Payrail Loan</h4>
                            <p>Drag the pointers to select loan amount and repayment duration.</p>
                            <div className="loan-range-container">
                                <div className="borrow-box">
                                    <div className="borrow-txts-wrapper flex-div justify-content-btw">
                                        <span className="borrow-txt">I want to borrow</span>
                                        <span id="borrow-amount" className="borrow-amt-label">
                                            <small>₦</small><span className="e-t">2,500,000</span>
                                        </span>
                                    </div>
                                    <div className="range-field-wrapper">
                                        <p className="range-field">
                                            <input type="range" id="borrow-range" defaultValue="2500000" min="1000" max="5000000" />
                                        </p>
                                        <div className="flex-div justify-content-btw range-field-range">
                                            <span>₦1,000</span>
                                            <span>₦5,000,000</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="borrow-tenure-box">
                                    <div className="borrow-txts-wrapper flex-div justify-content-btw">
                                        <span className="borrow-txt">To repay in</span>
                                        <span id="loan-tenure" className="borrow-amt-label"> 
                                            <span className="e-t">6</span> <span> months</span>
                                        </span>
                                    </div>
                                    <form action="#" className="range-field-wrapper">
                                        <p className="range-field">
                                            <input type="range" id="borrow-tenure-range" defaultValue="6" min="3" max="12" />
                                        </p>
                                        <div className="flex-div justify-content-btw range-field-range">
                                            <span>3</span>
                                            <span>12</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="payment-info-row flex-div">
                                <img src={require("../../images/info-circle.svg").default} alt="info-circle" className="info-circle" />
                                <span>
                                    If you receive the loan today, your first repayment date is <br /> <b id="next-payment-date">Januray 13, 2022</b>
                                </span>
                            </div>
                        </ColoredWrapper>
                    </div>

                    <div>
                        <ColoredWrapper outlined classes="">
                            Below shows the outcome of the Payrail loan you've applied for, the amount and duration of payment. <a href="/#" className="loan-learn-more">Learn more</a>
                        </ColoredWrapper>

                        <ColoredWrapper outlined classes="loan-preference-right-bottom">
                            <p>In total, you will pay</p>
                            <div className="loan-order-amount">
                                <span className="small-currency-symbol">₦</span>2,500,000.00
                                <div className="loan-order-rate-box">+2.5%pm</div>
                            </div>
                            <div className="loan-order-text-top">That is <span>₦2,500,000</span> plus <span>₦62,500</span> in interest</div>
                            <div className="loan-order-text-bottom">
                                You'll pay <b className="black-txt">₦427,083.33</b> in <b className="black-txt">06 months </b> 
                            </div>
                            <hr></hr>
                            <small className="loan-order-disclaimer">
                                By clicking "Apply for ₦2,500,000 loan" you agree to Payrail loan <a href="/#" className="loan-TandC">terms and conditions</a>
                            </small>
                            <div className="loan-order-apply">
                                <button className="pry-btn modal-trigger" data-target={CHOOSE_REPAYMENT_OPTION_MODAL}>
                                    Apply for ₦2,500,000.00
                                </button>
                            </div>
                        </ColoredWrapper>
                    </div>
                </div>
                <LoanConfirmationModal />
                <NewPaymentDateModal />
                <ChooseRepaymentOptionsModal />
            </div>
        </div>
    )
}

export default LoanPreference;
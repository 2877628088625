import React from "react";
import M from "materialize-css";
import { VIEW_REQUEST_MODAL_ID } from "../../utility/modalIDs";

const InvoiceItem = ({item={}, onClick=()=>{}}) =>{

    const {customer="",amount="",requested="",invoice_number="",status=""} = item

    return(
        <tr className="ubip-cursor-p modal-trigger" onClick={()=>onClick(item)} >
        <td>{customer}</td>
        <td className="gray-txt">NGN {amount}</td>
        <td>{requested}</td>
        <td>{invoice_number}</td>
        <td className="ubip-success">{status}</td>
    </tr>
    )
}

export default InvoiceItem
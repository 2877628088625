import React, { useEffect } from "react";
import M from "materialize-css";
import ModalWrapper from "../../../components/UI/ModalWrapper";
import { OTP_MODAL, WITHDRAWAL_TO_BANK_MODAL } from "../../../utility/modalIDs";
import { InputField } from "../../../components/InputField";

const WithdrawToBankModal = () => {
    useEffect (()=> {
        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);

    return (
        <ModalWrapper modalID={WITHDRAWAL_TO_BANK_MODAL}>
            <h4 className="ivvrm-header center">Withdraw to Bank</h4>

            <img src={require("../../../images/dome-bank.svg").default} alt="icon" className="amvbtm-icon" />

            <div className="amvbtm-description">
                Send money from your Payrail business to your registered business account
            </div>

            <form className="dashboard-form modal-form amvutm-mt">
                <InputField 
                    type="text" 
                    label="Amount"
                    defaultValue={"NGN 10,000"}
                    id={{input: "amount"}} 
                    classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field", label:"active"}} 
                    readonly=""
                />

                <InputField 
                    type="text" 
                    label="Account Number" 
                    defaultValue={"0126789124 - Ogbous and Sons "}
                    id={{input: "account_number"}} 
                    classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field", label:"active"}} 
                    readonly=""
                />

                <InputField 
                    type="text" 
                    label="Bank Name" 
                    defaultValue={"GT Bank"}
                    id={{input: "bank_name"}} 
                    classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field", label:"active"}} 
                    readonly=""
                />

                <InputField 
                    type="password" 
                    label="Password" 
                    id={{input: "password"}} 
                    classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field"}} 
                />

                <button type="button" className="pry-btn modal-trigger modal-close amvutm-btn-fullwidth" data-target={OTP_MODAL}>
                    Continue
                </button>
            </form>
        </ModalWrapper>
    );
};

export default WithdrawToBankModal;
import React from "react";
import M from "materialize-css";

const PlanItem = ({item={}, onClick=()=>{}}) =>{

    const {information="",subscription="",amount="",interval="",create_on="",status=""} = item

    return(
        <tr>
            <td>{information}</td>
            <td>{subscription}</td>
            <td className="gray-txt">NGN {amount}</td>
            <td>{interval}</td>
            <td>{create_on}</td>
            <td>{status}</td>
        </tr>
    )
}

export default PlanItem
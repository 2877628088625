// import React, { useState } from "react";

// css
import "./ClosedTicket.css";

// Components
import Wrapper from "../../../components/UI/Wrapper";


// Modal ID
import { INVITE_A_TEAM_MATE_MODAL_ID  } from "../../../utility/modalIDs";
import { NavLink, useNavigate } from "react-router-dom";
import TicketItem from "../../../components/TicketItem";
import { useState } from "react";

const ClosedTicket = ({disputes = []}) => {
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([
        {}
    ])
    return (
        <>
           
            <div className="page-bar-wrapper flex-div justify-content-btw">
                    <div className="page-bar-left flex-div">
                        <div className="filter-block flex-div">
                            <div className="filter-dropdown-trigger">
                                <img src={require('../../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                                <span>Filters</span>
                            </div>


                            <ul id="filter-dropdown" className="filter-dropdown-content">

                                <div className="arrow-up"></div>

                                <div className="select-field input-field single-field rel">
                                    <select id="status">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="status" className="active">Status</label>
                                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="category">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="category" className="active">Category </label>
                                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="resolution">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="resolution" className="active">Resolution </label>
                                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="date-period">
                                        <option value="All Time">All Time</option>
                                        <option value="This Week">This Week</option>
                                        <option value="This Month">This Month</option>
                                    </select>
                                    <label htmlFor="date-period" className="active">Date Period</label>
                                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="filter-btns-wrapper">
                                    <button className="filter-btn pry-btn">Filter</button>
                                    <button className="filter-reset-btn">
                                        <span>Reset to default</span>
                                    </button>
                                </div>
                            </ul>
                        </div>
                        <div className="vertical-divider">
                            <hr />
                        </div>
                        <div className="bar-search-block rel">
                            <img src={require('../../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                            <input onChange={(handleInput)=>{}} type="search" className="bar-search" placeholder="Search Customers" />
                        </div>
                    </div>
                   
                    <div className="page-bar-right flex-div">
                    <button className="tt-invite-btn" onClick={()=>{
                        navigate("/dashboard/tickets/create-new-ticket")
                    }}>+ Create Ticket</button>
                        <div className="export-trigger-block flex-div">
                            <span>Export CSV</span>
                            <img src={require('../../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                        </div>
                    </div>
                </div>

            <div className="table-wrapper">
                    <table id="customers-table">
                        <thead>
                            <tr>
                                <th>DATE</th>
                                <th>CATEGORY</th>
                                <th>UNIQUE ID</th>
                                <th>CLIENT</th>
                                <th>CLIENT NAME</th>
                                <th>SUBJECT</th>
                                <th>STATUS</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                        {disputes.map(((item)=>{
                                        
                                        return(
                                            <TicketItem item={item} onClick={()=>{}} />
                                        )
                                    })
                                )
                            }
                           
                        </tbody>
                    </table>
                </div>

           
        </>
    );
}

export default ClosedTicket;
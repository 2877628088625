import { createSlice } from '@reduxjs/toolkit'

const initialState = {

  transaction_history:[],
  ongoing_transaction:[{
    title: "Airtime Purchase",
    status: "Processed",
    reference: "PT08148620099",
    type: "Bill Payment",
    amount: "10,000",
    date: "01 Sept, 04:25 AM",
    trx_status: "success"
  
  },{
    title: "Airtime Purchase",
    status: "Processed",
    reference: "PT08148620099",
    type: "Bill Payment",
    amount: "10,000",
    date: "01 Sept, 04:25 AM",
    trx_status: "failed"
  
  },{
  title: "Airtime Purchase",
  status: "Processed",
  reference: "PT08148620099",
  type: "Bill Payment",
  amount: "10,000",
  date: "01 Sept, 04:25 AM",
  trx_status: "pending"
  
  }],
  recent_transaction:[{
    title: "Airtime Purchase",
    status: "Processed",
    reference: "PT08148620099",
    type: "Bill Payment",
    amount: "10,000",
    date: "01 Sept, 04:25 AM",
    trx_status: "success"

},{
    title: "Airtime Purchase",
    status: "Processed",
    reference: "PT08148620099",
    type: "Bill Payment",
    amount: "10,000",
    date: "01 Sept, 04:25 AM",
    trx_status: "failed"

},{
  title: "Airtime Purchase",
  status: "Processed",
  reference: "PT08148620099",
  type: "Bill Payment",
  amount: "10,000",
  date: "01 Sept, 04:25 AM",
  trx_status: "pending"

}],
request_transaction:[{
  title: "Airtime Purchase",
  status: "Processed",
  reference: "PT08148620099",
  type: "Bill Payment",
  amount: "10,000",
  date: "01 Sept, 04:25 AM",
  trx_status: "success"

},{
  title: "Airtime Purchase",
  status: "Processed",
  reference: "PT08148620099",
  type: "Bill Payment",
  amount: "10,000",
  date: "01 Sept, 04:25 AM",
  trx_status: "failed"

},{
title: "Airtime Purchase",
status: "Processed",
reference: "PT08148620099",
type: "Bill Payment",
amount: "10,000",
date: "01 Sept, 04:25 AM",
trx_status: "pending"

}],
  wallet_balance: {
    balance: "200,000",
    monthlyBudget: 2000,
    spentSoFar: 500,
    burn:0.00,
    scheduledPayment: 0,
    scheduledAmount: 0.00
    
  },
  bank_detail:{
    bankName: "Angala MFB",
    accountName: "Ogbous Venture Lspan",
    accountNumber: "1812788912"
  }
  

}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,

  reducers: {
    approveTransaction: ()=>{},
    declineTransaction: ()=>{},
    addMoney: ()=>{},
    bankTransfer: ()=>{},
    ussdTransfer: ()=>{},
    sendMoney: ()=>{},
    searchOngoingTransaction: ()=>{},
    searchRequestTransaction: ()=>{},
    getBankDetail: ()=>{},
    bankDetail: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
    getBalance: ()=>{},
    balance: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
    getTransaction:()=>{},
    transactionHistory: (state, action) => {
     
      state.user = action.payload;
      state.server_error = null;
      state.signupSuccessful = true;
      state.actionType = action.type
    },
    getOngoingTransaction:()=>{},
    ongoingTransaction: (state, action) => {
     
      state.user = action.payload;
      state.server_error = null;
      state.signupSuccessful = true;
      state.actionType = action.type
    },
    getRecentTransaction:()=>{},
    recentTransaction: (state, action) => {
     
      state.user = action.payload;
      state.server_error = null;
      state.signupSuccessful = true;
      state.actionType = action.type
    },
    getRequestTransaction:()=>{},
    requestTransaction: (state, action) => {
     
      state.user = action.payload;
      state.server_error = null;
      state.signupSuccessful = true;
      state.actionType = action.type
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  balance,
  transactionHistory,
  bankDetail,
  ongoingTransaction,
  recentTransaction,
  requestTransaction,

  sendMoney,
  getBalance,
  getTransaction,
  addMoney,
  bankTransfer,
  ussdTransfer,
  getBankDetail,
  getRecentTransaction,
  getOngoingTransaction,
  searchOngoingTransaction,
  searchRequestTransaction,
  getRequestTransaction,
  approveTransaction,
  declineTransaction
   
    } = walletSlice.actions

export default walletSlice.reducer
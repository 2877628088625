import React from "react";
import ModalWrapper from "../../../components/UI/ModalWrapper";
import { WITHDRAW_SUCCESS_MODAL } from "../../../utility/modalIDs";
import congrats from "../../../images/congrats.png";

const WithdrawSuccessModal = () => {
    return (
        <ModalWrapper modalID={WITHDRAW_SUCCESS_MODAL}>
            <div className="cong-icon-wrapper">
                <img src={congrats} alt="congrats" className="congrats-img" />
            </div>
            <h2 className="amsm-header">Request completed</h2>
            <p className="amsm-grey-text">
                Your withdrawal will be sent to your bank account shortly
            </p>
        </ModalWrapper>
    );
};

export default WithdrawSuccessModal;
// import React, {useEffect, useState} from 'react'

export const TextArea = ({isloading, type,   id ="", classname, label, onChange, error = "", children, ...otherprop}) =>{
    return(
        <div className={`${classname.div} ${error? "status-error": ""}`} id={id.div}>
            <textarea 
                id={id.textarea} 
                onChange={onChange} 
                 type={type} 
                className={`materialize-textarea ${classname.textarea}`} 
                name={id.textarea}
                {...otherprop}
            />
            <label for={id.textarea}>{label}</label>
            <small className={classname.error}>{error}</small>
        </div>
    )

}

import logo from '../images/payrail-logo.svg';
import 'materialize-css/dist/css/materialize.min.css';
import 'material-design-icons/iconfont/material-icons.css';
import M from 'materialize-css';
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import analyticsIcon from '../icons/agent-metrics-inactive.svg';
import userManagementIcon from '../icons/user-management-inactive.svg';
import configurationsIcon from '../icons/configurations-inactive.svg';
import institutionsIcon from '../icons/institutions-inactive.svg';
import approvalsIcon from '../icons/approvals-inactive.svg';
import agentManagementIcon from '../icons/agent-management-inactive.svg';
import customerManagementIcon from '../icons/customer-management-inactive.svg';
import valueAddedServicesIcon from '../icons/value-added-services-inactive.svg';
import transactionsIcon from '../icons/transactions-inactive.svg';
import settlementIcon from '../icons/settlement-inactive.svg';
import reconciliationIcon from '../icons/reconciliation-inactive.svg';
import posTerminalsIcon from '../icons/pos-terminals-inactive.svg';
import reportsIcon from '../icons/reports-inactive.svg';
import ticketManagementIcon from '../icons/ticket-management-inactive.svg';
import bulkNotification from '../icons/bulk-notification-inactive.svg';
import auditIcon from '../icons/audits-inactive.svg';


const NavigationItem = ({ item }) => {
  useEffect(() => {
    var elemsCollapsible = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elemsCollapsible);
  }, []);

  if (item.children) {
    return (
      <li className="link-wrapper">
        <div className="collapsible-header pg-link">
          <div className="flex-div">
            <img src={item.icon} alt="icon" className="link-img" />
            <span className="link-txt">{item.label}</span>
          </div>
          <img src={require("../images/caret-down.svg").default} alt="caret" className="caret-icon" />
        </div>
        <div className="collapsible-body">
          <ul>
            {item.children.map(child => (
              <NavigationItem key={child.label} item={child} />
            ))}
          </ul>
        </div>
      </li>
    );
  }

  return (
    <li className="link-wrapper">
      <NavLink exact to={item.path} className="pg-link">
        <div className="flex-div">
          <img src={item.icon} alt="icon" className="link-img" />
          <span className="link-txt">{item.label}</span>
        </div>
      </NavLink>
    </li>
  );
};

const NavigationMenu = ({ data }) => {
  return (
    <div className="nav-list-wrapper">
        <div className="nav-list-1">

        <ul className="collapsible">
            {data.map(item => (
                <NavigationItem key={item.label} item={item} />
            ))}
            </ul>

        </div>
    </div>
    
  );
};

const SideBar = () => {
  const menuData = [
    {
        label: "Analytics",
        icon: analyticsIcon,
        children: [
            {
                label: "Merchant Metrics",
                active:'/icons/agent-metrics.svg',
                icon: analyticsIcon,
                path: "/dashboard/home",
            }
        ],
        
    },
    {
        label: "System",
        icon: userManagementIcon,
        children: [
            {
                label: "User Management",
                active:'/icons/user-management.svg',
                icon: userManagementIcon,
                path: "/dashboard/administration",
                permission:"USER_MANAGEMENT",
            },
            // {
            //     label: "Configurations",
            //     active:'/icons/configurations.svg',
            //     icon: configurationsIcon,
            //     path: "/dashboard/system/configuration",
            //     permission:"CONFIGURATIONS",
            // },
            // {
            //     permission:"INSTITUTIONS",
            //     label: "Institutions",
            //     active:'/icons/institutions.svg',
            //     icon: institutionsIcon,
            //     data: "1",
            //     path: "/dashboard/system/institutions",
            // },
            // {
            //     permission:"APPROVALS",
            //     label: "Approvals",
            //     active:'/icons/approvals.svg',
            //     icon: approvalsIcon,
            //     data: "approvals",
            //     path: "/dashboard/system/approvals"
            // },
        ],
        
    },
    {
        label: "Merchants",
        icon: agentManagementIcon,
        children: [
            {
                label: "Merchant Management",
                active:'/icons/agent-management.svg',
                icon: agentManagementIcon,
                data: "2",
                path: "/dashboard/merchants",
                permission: ["VIEW_AGENT_MANAGEMENT", "EDIT_AGENT_MANAGEMENT"]
            },
            // {
            //     label: "Customer Management",
            //     active:'/icons/customer-management.svg',
            //     icon: customerManagementIcon,
            //     path: "/dashboard/merchants/customers",
            //     permission: ["VIEW_CUSTOMER_MANAGEMENT", "EDIT_CUSTOMER_MANAGEMENT"]
            // },
            {
                label: "Bill Payment",
                active:'/icons/value-added-services.svg',
                icon: valueAddedServicesIcon,
                path: "/dashboard/bill-payments",
                permission: ["VIEW_VALUE_ADDED_SERVICES", "EDIT_VALUE_ADDED_SERVICES"]
            },
            {
                label: "Transactions",
                active:'/icons/transactions.svg',
                icon: transactionsIcon,
                path: "/dashboard/transactions",
                permission: ["VIEW_TRANSACTIONS", "EDIT_TRANSACTIONS"]
            },
            // {
            //     label: "Settlement",
            //     active:'/icons/settlement.svg',
            //     icon: settlementIcon,
            //     path: "/dashboard/settlement",
            //     permission: ["VIEW_SETTLEMENTS", "EDIT_SETTLEMENTS"]
            // },
            {
                label: "Reconciliation",
                active:'/icons/reconciliation.svg',
                icon: reconciliationIcon,
                path: "/dashboard/reconciliation",
                permission: ["VIEW_RECONCILATION", "EDIT_RECONCILATION"]
            },
            {
                label: "Revenue",
                active:'/icons/reconciliation.svg',
                icon: reconciliationIcon,
                path: "/dashboard/revenue",
                permission: ["VIEW_RECONCILATION", "EDIT_RECONCILATION"]
            },
            {
                label: "POS Terminals",
                active:'/icons/pos-terminals.svg',
                icon: posTerminalsIcon,
                path: "/dashboard/pos",
                permission: ["VIEW_POS_TERMINAL", "EDIT_POS_TERMINAL"]
            },
        ]
    },
    {
        label: "Insights and Reports",
        icon: reportsIcon,
        children: [
            {
                label: "Reports",
                active:'/icons/report.svg',
                icon: reportsIcon,
                path: "reports",
                permission: ["VIEW_REPORTS", "EDIT_REPORTS"]
            },
        ]
    },
    {
        label: "Support",
        icon: ticketManagementIcon,
        children: [
            {
                label: "Ticket Management",
                active:'/icons/ticket-management.svg',
                icon: ticketManagementIcon,
                path: "/dashboard/tickets",
                permission: ["EDIT_TICKET_MANAGEMENT"]
            },
            {
                label: "Bulk Notification",
                active:'/icons/bulk-notification.svg',
                icon: bulkNotification,
                path: "/dashboard/support/bulk-notification",
                permission: ["EDIT_BULK_NOTIFICATION"]
            },
            {
                label: "Audits",
                active:'/icons/audits.svg',
                icon: auditIcon,
                path: "/dashboard/support/audits",
                permission: ["EDIT_AUDITS"]
            },
        ]
    },
   
]

  return (
    <section id="side-bar">
      <div className="container">
        <div className="brand-logo">
          <NavLink to="/dashboard/home">
            <img src={logo} alt="Payrail logo" className="logo" />
          </NavLink>
        </div>
        <NavigationMenu data={menuData} />
      </div>
    </section>
  );
};

export default SideBar;
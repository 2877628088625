// css
import { NavLink } from "react-router-dom";
import "./index.css";
import { SelectField } from "../../../components/selectField";
import { InputField } from "../../../components/InputField";
import M from "materialize-css";
import { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // add the Quill stylesheet

const CreateNewTicket = ({ goback }) => {
  const [content, setContent] = useState('');
  
  function handleContentChange(value) {
    setContent(value);
  }
  const styles = {
    fontSize: '16px',
    color: '#333',
    
    width: '100%',
    height: '200px',
    resize: 'none',
    marginBottom: '10px'
  }

    return (
        <div className="container admin-page-cont">
            <NavLink to="/dashboard/administration" >
              <div className="manage-cards-goback" onClick={goback}>
                  <div className="manage-cards-goback-icon">
                      <img src={require("../../../images/caret-down.svg").default} alt="caret"  />
                  </div>
                <span>Back</span>
              </div>
            </NavLink>

            <div className="header">
                <h5 className="iatmm-header">Create a new ticket</h5>
                <p className="iatmm-sub-header">Create ticket for merchant issue</p>
            </div>

            <form action="#" id={`create-ticket-form`} onSubmit={e => e.preventDefault()} className="dashboard-form">
                <div className="iatmm-d-flex iatmm-mb iatmm-pb-8">
                    <div className="input-field single-field iatmm-no-mb grid-field">
                        <InputField 
                            type="text" 
                            label="Name" 
                            id={{input: "first_name"}} 
                            classname={{div:"input-field iatmm-no-mb", error:"error-text", input:"form-inp-field"}} 
                        />
                    </div>

                    <div className="input-field single-field iatmm-no-mb grid-field">
                    <InputField 
                        type="email" 
                        label="Email Address" 
                        id={{input: "email"}} 
                        classname={{div:"input-field", error:"error-text", input:"form-inp-field"}} 
                    />
                       
                    </div>
                </div>

                <div className="iatmm-d-flex iatmm-mb iatmm-pb-8">
                    <div className="input-field single-field iatmm-no-mb grid-field ">
                        <InputField 
                        type="text" 
                        label="Subject" 
                        id={{input: "last_name"}} 
                        classname={{div:"input-field iatmm-no-mb", error:"error-text", input:"form-inp-field"}} 
                    />
                    </div>

                 <div className="input-field single-field iatmm-no-mb grid-field">
                    <SelectField 
                    items={[
                        {key: "Business Owner", value: "Business Owner"},
                        {key: "Administrator", value: "Administrator"},
                    ]}
                    onChange={e =>{}}
                    label="Category" 
                    id={{select: "role"}} 
                    classname={{div:"select-field input-field single-field rel", label:"active", img: "inp-caret-icon" }} 
                />
                       
                    </div>
                </div>
               
                <div className="input-field">
                    <div className="input-title">
                        <b>
                            Tikect message <span className="red-text">*</span>
                        </b>
                        <br />
                    </div>
                    <ReactQuill
                    style={styles}
                      value={content}
                      onChange={handleContentChange}
                    />
                    {/* <textarea id="chargeback-reason" className="materialize-textarea form-inp-field"></textarea> */}
                </div>
                <div className="input-field upload-text">
                    <div className="input-title">
                        <b>
                        File type: .jpg, .gif, .jpeg, .png, .pdf, zip, .doc, .docx (Max file size: 128MB) <span className="red-text">*</span>
                        </b>
                        <p>
                            Upload all relevant document for this ticket. Make sure they are legible and 
                            contain specific details about the issue. 
                        </p>
                    </div>
                    <div className="file-field input-field">
                        <input type="file" id="accept-chargeback-files" multiple />
                        <div className="file-path-wrapper">
                            <input className="file-path form-inp-field" type="text" placeholder="+ Choose File" />
                        </div>
                    </div>
                </div>

                <div className="iatmm-d-flex btn-wrapper">
                    <button className="iatmm-btn iatmm-cancel-btn modal-close">
                        Cancel
                    </button>

                    <button className="iatmm-btn iatmm-invite-btn modal-close">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CreateNewTicket;
import { useState } from "react";

import InvoiceBuilderReview from "./Review";
import InvoiceBuilderDraft from "./Draft";

const InvoiceBuilderRight = () => {
    const [state, setState] = useState("review");

    const changeStateHandler = (val) => {
        setState(val)
    };

    if (state === "review") {
        return <InvoiceBuilderReview draftHandler={changeStateHandler} />;
    } else {
        return <InvoiceBuilderDraft editHandler={changeStateHandler} />;
    }
};

export default InvoiceBuilderRight;
import { useEffect } from "react";
import M from "materialize-css";

import "./InviteATeamMate.css";

// components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";

//modal ID
import { INVITE_A_TEAM_MATE_MODAL_ID } from "../../../../../utility/modalIDs";

import { SelectField } from "../../../../../components/selectField";
import { InputField } from "../../../../../components/InputField";

const InviteATeamMateModal = () => {
    useEffect(()=> {
        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);
    return(
        <ModalWrapper modalID={INVITE_A_TEAM_MATE_MODAL_ID }>
            <div>
                <h5 className="iatmm-header">Invite a team mate</h5>
                <p className="iatmm-sub-header">Send an invitation to join your company’s Payrail account</p>
            </div>

            <form action="#" id={`${INVITE_A_TEAM_MATE_MODAL_ID }-form`} onSubmit={e => e.preventDefault()} className="dashboard-form">
                <div className="iatmm-d-flex iatmm-mb iatmm-pb-8">
                    <div className="input-field single-field iatmm-no-mb">
                        <InputField 
                            type="text" 
                            label="First Name" 
                            id={{input: "first_name"}} 
                            classname={{div:"input-field iatmm-no-mb", error:"error-text", input:"form-inp-field"}} 
                        />
                    </div>

                    <div className="input-field single-field iatmm-no-mb">
                        <InputField 
                            type="text" 
                            label="Last Name" 
                            id={{input: "last_name"}} 
                            classname={{div:"input-field iatmm-no-mb", error:"error-text", input:"form-inp-field"}} 
                        />
                    </div>
                </div>
                <div className="input-field single-field">
                    <InputField 
                        type="email" 
                        label="Email Address" 
                        id={{input: "email"}} 
                        classname={{div:"input-field", error:"error-text", input:"form-inp-field"}} 
                    />
                </div>
                <SelectField 
                    items={[
                        {key: "Business Owner", value: "Business Owner"},
                        {key: "Administrator", value: "Administrator"},
                    ]}
                    label="Assign Role" 
                    id={{select: "role"}} 
                    classname={{div:"select-field input-field single-field rel", label:"active", img: "inp-caret-icon" }} 
                />

                <div className="iatmm-d-flex btn-wrapper">
                    <button className="iatmm-btn iatmm-cancel-btn modal-close">
                        Cancel
                    </button>

                    <button className="iatmm-btn iatmm-invite-btn modal-close">
                        Send invitations
                    </button>
                </div>
            </form>

        </ModalWrapper>
    )
};

export default InviteATeamMateModal;
import React from "react";
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { COPY_USSD_CODE_MODAL_ID, ADD_MONEY_VIA_USSD_TRANSFER_MODAL_ID, ADD_MONEY_SUCCESS_MODAl_ID } from "../../../../utility/modalIDs";

const CopyUSSDCodeModal = () => {
    return (
        <ModalWrapper modalID={COPY_USSD_CODE_MODAL_ID} showBackBtn backTo="USSD" modalToOpenID={ADD_MONEY_VIA_USSD_TRANSFER_MODAL_ID}>
            <div className="cucm-amount">
                <span>₦</span>1000
            </div>

            <p className="cucm-grey-text">Tap to dail the code below to fund your Payrail Wallet</p>

            <div className="cucm-ussd-text">
                <span>*737*2*10000*00912345686#</span>
                <img src={require("../../../../images/red-copy-icon.svg").default} alt="copy" className="copy-icon" />
            </div>

            <button className="pry-btn modal-trigger modal-close amvutm-btn-fullwidth" data-target={ADD_MONEY_SUCCESS_MODAl_ID}>
                Done
            </button>
        </ModalWrapper>
    );
};

export default CopyUSSDCodeModal;
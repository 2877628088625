import React from "react";
import M from "materialize-css";

const ReportItem = ({item={}, onClick=()=>{}}) =>{

    const {phone="+2348060110110",username="Moh Salah",amount="140" } = item

    return(
        <tr>
        <td>{phone}</td>
        <td >{username}</td>
        <td>{amount}</td>
       
        <td>
            <button onClick={onClick} className="pry-btn resolve-btn modal-trigger" data-target="resolve-dispute-modal">
               View →
            </button>
        </td>
      
    </tr>
    )
}

export default ReportItem
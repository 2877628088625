import React, { useEffect, useState, useRef } from 'react';
import PhoneInput from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import PropTypes from 'prop-types';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en';
import $ from 'jquery';
import M from 'materialize-css';

const PhoneNumber = ({
  isloading,
  value,
  type,
  id = '',
  classname,
  label,
  trimPlus = true,
  onChange,
  error = '',
  children,
  placeholder,
  ...otherprop
}) => {
  const [country, setCountry] = useState('NG');
  const inputRef = useRef(null);

  useEffect(() => {
    const handleInputFocus = () => {
      const targetLabel = $(inputRef.current).parent().find('label');
      targetLabel.addClass('active');
    };

    const handleInputBlur = () => {
      const targetLabel = $(inputRef.current).parent().find('label');
      if ($(inputRef.current).val()) {
        targetLabel.addClass('active');
      } else {
        targetLabel.removeClass('active');
      }
    };

    $(inputRef.current).on('input', handleInputFocus);
    $(inputRef.current).on('blur', handleInputBlur);

    if (value) {
      const targetLabel = $(inputRef.current).parent().find('label');
      targetLabel.addClass('active');
    }

    return () => {
      $(inputRef.current).off('input', handleInputFocus);
      $(inputRef.current).off('blur', handleInputBlur);
    };
  });

  return (
    <>
      <CountrySelect
        label="Country"
        value={country}
        onChange={setCountry}
        error={error && error['countries'] ? error['countries'].message : ''}
        id={{ select: 'countries' }}
        classname={{ div: 'input-field col s12 mag-d-10 pad0 country-list-wrapper', error: 'error-text', label: 'active' }}
      />
      <div className={`${classname.div} ${error ? 'status-error' : ''}`} id={id.div}>
        <Input
         
          country={country}
          international
          withCountryCallingCode
          value={value}
          onChange={(v) => {
            onChange(v);
          }}
          className={classname.input}
          name={id.input}
          placeholder={placeholder}
          ref={inputRef}
          {...otherprop}
        />
        <label htmlFor={id.input} className={classname.label}>
          {label}
        </label>
        <small className={classname.error}>{error}</small>
        {children}
      </div>
    </>
  );
};

export default PhoneNumber;

const CountrySelect = ({ value, onChange, labels = en, id = '', children, classname = {}, label, items = [], ...rest }) => {
  useEffect(() => {
    var elemSelect = document.getElementById(`${id.select}`);
    M.FormSelect.init(elemSelect);
  }, []);

  return (
    <div className={`${classname.div}`} id={id.div}>
      <select {...rest} value={value} onChange={(event) => onChange(event.target.value || undefined)}>
        <option value="">{labels['ZZ']}</option>
        {getCountries().map((country) => (
          <option key={country} value={country}>
            {labels[country]} +{getCountryCallingCode(country)}
          </option>
        ))}
      </select>
      <label htmlFor={id.select} className={classname.label}>
        {label}
      </label>
      <img src={require('../../images/caret-down.svg').default} alt="caret" className={`${classname.img ? classname.img : 'caret-icon'}`} />
    </div>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // labels: PropTypes.objectOf(PropTypes.string).isRequired
};

export { CountrySelect };

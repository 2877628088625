import logo from '../images/payrail-logo.svg';

import { BrowserRouter as Router, Routes, Route, NavLink  } from "react-router-dom";
import { useEffect, useState } from 'react';

import Lottie from 'react-lottie';
import animationData1 from '../lotties/lf30_editor_3mq89uw0.json';
import animationData2 from '../lotties/lf30_editor_aa8esn7u.json';
import animationData3 from '../lotties/lf30_editor_7x8qtm6u.json';

import LoginOld from '../auth-pages/login-OldUser';
import LoginNew from '../auth-pages/login-NewUser';

import EmailSignup from '../auth-pages/signup-WithEmail';
import PhoneSignup from '../auth-pages/signup-WithPhone';

import VerifyEmailSignup from '../auth-pages/verification-WithEmail';
import VerifyPhoneSignup from '../auth-pages/verification-WithPhone';

import EmailIdentity from '../auth-pages/identity-WithEmail';
import PhoneIdentity from '../auth-pages/identity-WithPhone';

import ForgotPassword from '../auth-pages/forgotPassword';

import BvnVerification from '../auth-pages/verifyBVN';
import SignupSuccess from '../auth-pages/SignupSuccess';
import EmailVerified from '../auth-pages/email-verified';
import PhoneVerified from '../auth-pages/phone-verified';
import ResetPassword from '../auth-pages/resetPassword';
import Mfa from '../auth-pages/mfa-screen';

const AuthView = () => {
   

    const animationOptions1 = {
        loop: true,
        autoplay: true,
        animationData: animationData1,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
      const animationOptions2 = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
      const animationOptions3 = {
        loop: true,
        autoplay: true,
        animationData: animationData3,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

      useEffect(() => {
        // Key-Points Section --- Title Carousel
        var lottieIndex = 0;
        var timeoutId = null; // add a variable to store the timeout ID
        lottieCarousel();
    
        function lottieCarousel() {
            var i;
            var x = document.getElementsByClassName("lottie-block");
            for (i = 0; i < x.length; i++) {
                x[i].style.display = "none";  
            }
            lottieIndex++;
            if (lottieIndex > x.length) {lottieIndex = 1}    
            x[lottieIndex-1].style.display = "block";
            clearTimeout(timeoutId); // clear the previous timeout
            timeoutId = setTimeout(lottieCarousel, 13000); // assign a new timeout ID
        }

        console.log(window.location.pathname);
    
        // return a cleanup function to clear the timeout when the component unmounts
        return () => {
            clearTimeout(timeoutId);
        }
    }, []);
    

    return (
      
            <div>
                <div id="auth-main-wrapper">
                <div className="auth-nav-header">
                    <div className="container">
                        <a href="/login">
                            <img src={logo} alt="Payrail" className="brand-img" />
                        </a>
                    </div>
                </div>

                <section className="auth-wrapper container">
                    <div className="auth-block-wrapper">
                       <div className="center">
                            <ul className="form-nav" >
                            
                                <li>
                                    <NavLink to={window.location.pathname === '/'?"/":"/login"} className="route-link" activeClassName="active-link">
                                    <span>Log in</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>


                        <Routes>
                          
                             <Route  path="/"  element={ <LoginNew />}/>
                           
                            <Route path="/login/verify/mfa" element={ <Mfa />}/>
                              
                            <Route path="/forgot-password" element={ <ForgotPassword />}/>
                               
                            <Route path="/reset-password" element={ <ResetPassword />}/>
                               

                            <Route exact path="/login" element={ <LoginNew />}/>
                                
                            <Route path="/login/old" element={ <LoginOld />}/>
                               
                        </Routes>
                    </div>
                    <div className="support-and-privacy flex-div justify-content-btw">
                        <div className="contact-side sm-black-txt">
                            Trouble signing in? <a href="/#" className="black-text">Contact support</a>
                        </div>
                        <div className="privacy-terms-side sm-black-txt">
                            <a href="/#" className="p-link sm-black-txt">Privacy</a>
                            <span className="p-line">|</span>
                            <a href="/#" className="p-link sm-black-txt">Terms</a>
                        </div>
                    </div>
                </section>

                <section className="lotties-wrapper">
                    <div className="lottie-block" id="lottie-1">
                        <Lottie 
                            options={animationOptions1}
                            height={400}
                            width={400}
                        />
                    </div>
                    <div className="lottie-block" id="lottie-2">
                        <Lottie 
                            options={animationOptions2}
                            height={400}
                            width={400}
                        />
                    </div>
                    <div className="lottie-block" id="lottie-3">
                        <Lottie 
                            options={animationOptions3}
                            height={400}
                            width={400}
                        />
                    </div>
                    {/* <div className="lottie-block" id="lottie-4">
                        <Lottie 
                            options={animationOptions4}
                            height={400}
                            width={400}
                        />
                    </div> */}
                </section>

                <footer id="auth-footer">
                    <div className="flex-div container">
                        <div className="trusted-block flex-div">
                            <img src={require("../images/trust.svg").default} alt="shield-icon" className="footer-item-icon" />
                            <span>Trusted by over <span className="pry-color">100k Business</span> like you!</span>
                        </div>

                        <div className="copyright-div">
                            © Angala Financial Technologies Limited. All Rights Reserved.
                        </div>
                    </div>
                </footer>
                </div>

                {/* <!-- Modal Section --> */}
                <div id="many-failed-attempts" className="modal">
                    <div className="modal-content">
                        <div className="mpodal-content-block">
                            <div className="user-pix-div center mag-bottom-0">
                                <img src={require("../images/secure-lock.svg").default} alt="secure-lock" className="user-pix" />
                            </div>
                            <div className="user-detected-notice center">
                                <h1>Please try again later</h1>
                                <p>
                                    You have been locked out because you signed with wrong details multiple times. 
                                    Please wait for 30 minutes and try again correct details
                                </p>
                            </div>
                            <div className="flex-btn-wrapper flex-div">
                                <button type="button" className="btn pry-btn continue-btn modal-close">Okay</button>
                            </div>
                        </div>
                    </div>
                </div>

                
                {/* <!-- Modal Section --> */}
                <div id="detected-olduser-modal" className="modal">
                    <div className="modal-content">
                        <div className="mpodal-content-block">
                            <div className="user-pix-div center">
                                <img src={require("../images/user-pix.png").default} alt="user" className="user-pix" />
                            </div>
                            <div className="user-detected-notice center">
                                <h1>Olamide, we notice you exist within our ecosystem</h1>
                                <p>
                                    Click <b>continue</b> to use the same account details, <b>cancel</b> to create a fresh account.
                                </p>
                            </div>
                            <div className="flex-btn-wrapper flex-div">
                                <button type="button" className="btn pry-btn continue-btn">Continue</button>
                                <button type="button" className="btn cancel-btn modal-close">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    )
}

export default AuthView;
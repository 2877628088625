// css
import { NavLink } from "react-router-dom";
import "./index.css";
import { SelectField } from "../../components/selectField";
import { InputField } from "../../components/InputField";
import M from "materialize-css";
import { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // add the Quill stylesheet
import Messages from "./Messages";

const BulkNotification = ({ goback }) => {
  const [content, setContent] = useState('');
  
  function handleContentChange(value) {
    setContent(value);
  }
  const styles = {
    fontSize: '16px',
    color: '#333',
    
    width: '100%',
    height: '200px',
    resize: 'none',
    marginBottom: '10px'
  }

    return (
        <div className="container admin-page-cont">

            <div className="header">
                <h5 className="iatmm-header">Compose new message</h5>
              
            </div>

            <form action="#" id={`create-ticket-form`} onSubmit={e => e.preventDefault()} className="dashboard-form">
               

                <div className="iatmm-d-flex iatmm-mb iatmm-pb-8">
                    <div className="input-field single-field iatmm-no-mb grid-field ">
                        <InputField 
                        type="text" 
                        label="Title" 
                        id={{input: "title"}} 
                        classname={{div:"input-field iatmm-no-mb", error:"error-text", input:"form-inp-field"}} 
                    />
                    </div>

                 
                </div>
               
                <div className="input-field">
                    <div className="input-title">
                        <b>
                        Message <span className="red-text">*</span>
                        </b>
                        <br />
                    </div>
                    <ReactQuill
                    style={styles}
                      value={content}
                      onChange={handleContentChange}
                    />
                    {/* <textarea id="chargeback-reason" className="materialize-textarea form-inp-field"></textarea> */}
                </div>
                <br />
                <div className="notification-wrapper">

                <label>
                      <input name="card" type="checkbox" className="filled-in" defaultChecked />
                      <span className="spptpt-checkbox-text">Push Notification</span>
                    </label>
                    <label>
                      <input name="bank" type="checkbox" className="filled-in" defaultChecked />
                      <span className="spptpt-checkbox-text">SMS</span>
                    </label>
                   
                </div>

                <div className="iatmm-d-flex btn-wrapper">
                    <button className="iatmm-btn iatmm-cancel-btn modal-close">
                        Cancel
                    </button>

                    <button className="iatmm-btn iatmm-invite-btn modal-close">
                        Submit
                    </button>
                </div>
            </form>
            <Messages />
        </div>
    );
}

export default BulkNotification;
import M from "materialize-css";

import ColoredWrapper from "../../components/UI/coloredWrapper/coloredWrapper.js";

import FreezeDebitCardModal from "../../components/CardsPageComponents/Modals/FreezeDebitCardModal";
import CardFrozenSuccessfullyModal from "../../components/CardsPageComponents/Modals/CardFrozenSuccessfullyModal";

import CardDefreezeModal from "../../components/CardsPageComponents/Modals/CardDefreezeModal";
import CardDefreezeSuccessModal from "../../components/CardsPageComponents/Modals/CardDefreezeSuccessModal";

import { useEffect } from "react";
import CreateCardPinModal from "../../components/CardsPageComponents/Modals/CreateCardPinModal.js";
import CreateCardPinSuccess from "../../components/CardsPageComponents/Modals/CreateCardPinSuccess.js";
import ChangeCardPinModal from "../../components/CardsPageComponents/Modals/ChangeCardPinModal.js";
import ChangeCardPinSuccess from "../../components/CardsPageComponents/Modals/ChangeCardPinSuccess.js";
// import { NavLink } from "react-router-dom";

const ManageCardContent = () => {
    useEffect (()=> {
        const elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);
    }, []);

    function freezeCardTrigger() {
        // Get the checkbox
        const checkBox = document.getElementById("freeze-card-trigger");

        const freezeModal = document.querySelector('#card-freeze-modal');
        const unfreezeModal = document.querySelector('#card-defreeze-modal');
      
        if (checkBox.checked === true){
            M.Modal.getInstance(freezeModal).open();
        } else {
            M.Modal.getInstance(unfreezeModal).open();
        }
    }

    const CopyToClipboard = (selectedId) => {
        /* Get the text field */
        var copyText = document.getElementById(selectedId);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        
        /* Alert the copied text */
        M.toast({html: 'Copied'})
    }

    return (
        <div className="manage-cards-grid">
            <ColoredWrapper outlined>
                <div className="manage-cards-grid-div manage-cards-bb">
                    <span>Card Type</span>
                    <span>Virtual Card</span>
                </div>

                <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                    <span>Card Number</span>
                    <span id="card-pan">**** **** 0091</span>

                    <input type="text" id="card-num-1" defaultValue="1929393-0303" hidden />

                    <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("card-num-1")}>
                        <span>Click to copy</span>
                    </div>
                </div>

                <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                    <span>CVV</span>
                    <span id="card-id">***</span>

                    <input type="text" id="card-cvv-1" defaultValue="0303" hidden />

                    <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("card-cvv-1")}>
                        <span>Click to copy</span>
                    </div>
                </div>

                <div className="manage-cards-grid-div">
                    <span>Expiration date</span>
                    <span>June, 2023</span>
                </div>
            </ColoredWrapper>
            <ColoredWrapper outlined>
                <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                    <span>Billing Address</span>
                    <span>Block 32, 2C  Kunle Crescent</span>

                    <input type="text" id="billing-address" defaultValue="Block 32, 2C  Kunle Crescent" hidden />

                    <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("billing-address")}>
                        <span>Click to copy</span>
                    </div>
                </div>

                <div className="manage-cards-grid-div manage-cards-bb">
                    <span>ZipCode</span>
                    <span>100001</span>

                    <input type="text" id="zipcode" defaultValue="100001" hidden />

                    <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("zipcode")}>
                        <span>Click to copy</span>
                    </div>
                </div>

                <div className="manage-cards-grid-div manage-cards-bb modal-trigger pointer" data-target="change-card-pin-modal">
                    <span>Card PIN</span>
                    <img className="manage-cards-pin-icon" src={require("../../images/caret-down.svg").default} alt="caret"  />
                </div>


                <div className="manage-cards-grid-div">
                    <span>Freeze Card</span>
                    <div className="switch">
                        <label>
                            <input type="checkbox" id="freeze-card-trigger" onChange={() => freezeCardTrigger()} /> 
                            <span className="lever"></span>
                        </label>
                    </div>
                </div>
            </ColoredWrapper>

            <FreezeDebitCardModal />
            <CardFrozenSuccessfullyModal />

            <CardDefreezeModal />
            <CardDefreezeSuccessModal />

            <CreateCardPinModal />
            <CreateCardPinSuccess />

            <ChangeCardPinModal />
            <ChangeCardPinSuccess />
        </div>
    )
}
export default ManageCardContent
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from './privateRoute';
import GuestRoutes from './guestRoute';
import AuthView from '../layouts/AuthView';
import DashboardView from '../layouts/DashboardView';
import { useDispatch, useSelector } from 'react-redux';
import ErrorStatus from '../components/errorStatus';
import SuccessStatus from '../components/successStatus';
import Toast from '../components/Toast';
import {setToast as makeToast} from '../redux/reducers/toast';
import StatusPageView from '../layouts/StatusPageView';
const RouteHooks = () => {
    const dispatch = useDispatch()
    const { message} = useSelector(state => state.toast)
    const [toast, setToast] = useState({})
   
    useEffect(() => {
      
        if (message && message != null) {
           
            if (message) {
                setToast({message:message.message, type: message.type})
            }

          const timer = setTimeout(function(){
                dispatch(makeToast(null))
                setToast({})
              }, 3000);

              return ()=> timer
        }

       
    }, [ message]);

    return(
        <>
            <Routes>
                
                <Route  path="/*" element={
                    <GuestRoutes>
                        <AuthView/>
                    </GuestRoutes>
                }/>
            
                <Route path="dashboard/*" element={
                    <PrivateRoutes>
                        <DashboardView/>
                    </PrivateRoutes>
                } />

                <Route path="status/*" element={
                    <PrivateRoutes>
                        <StatusPageView/>
                    </PrivateRoutes>
                }/>
            
            
            </Routes>
          {toast.message && <Toast type={toast.type} message={toast.message} />}
            
        </>
       

    )
}

export default RouteHooks;
import Wrapper from "../../../../../../components/UI/Wrapper";

const InvoiceBuilderDraft = ({ editHandler }) => {
    return (
            <Wrapper outlined grey className="ipib-container-item ipib-container-item-draft">
                <div className="ipib-review-title">
                    Draft Mode
                </div>

                <div className="ipib-draft-description">
                    Your request to jumbleadio@gmail.com  is still in Draft Mode. Use the button below to send when you're ready.
                </div>

                <button className="pry-btn modal-trigger modal-close">
                    Send Now
                </button>

                <hr className="ipib-draft-hr-mt" />

                <div className="ipib-draft-btns">
                    <button className="pry-btn ipib-cancel-btn" onClick={() => editHandler("review")}>
                        Edit
                    </button>

                    <button className="pry-btn ipib-cancel-btn" onClick={() => editHandler("review")}>
                        Duplicate
                    </button>

                    <button className="pry-btn ipib-cancel-btn" onClick={() => editHandler("review")}>
                        Delete
                    </button>
                </div>
            </Wrapper>
    )
};

export default InvoiceBuilderDraft;
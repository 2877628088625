import React, { useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import { NavLink } from "react-router-dom";
import Wrapper from "../../../components/UI/Wrapper";
import { BULK_TRANSFER_SUCCESS_MODAl_ID } from "../../../utility/modalIDs";

import $ from "jquery"

const EnterPin = () => {

    useEffect(()=> {
        $("#bulk-transfer-success-modal-id .modal-close").click(function(){
            window.location="/dashboard/transfer"
        })
    }, []);

    const [otp, setOTP] = useState('');

    return (
        <Wrapper white outlined className="tip-flex-item rp-pin-wrapper">
            <div className="ep-pin">
                <h4>Enter transaction PIN</h4>
                <small>Enter your 4-digit Payrail PIN to approve this transfer</small>
            </div>

            <div className="ep-pin-box-container">
                <div>
                    <div id="verification-codes">
                        <div className="input-row row">
                            <OtpInput
                                value={otp}
                                onChange={(val)=>{
                                    setOTP(val)
                                }}
                                numInputs={4}
                                separator={<span> </span>}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="ep-pin-reset">
                <small>
                    Get trouble? Reset your PIN in 
                    <NavLink to="/dashboard/settings" className="pry-color"> account security settings</NavLink>
                </small>
            </div>

            <button className="pending-btn pry-btn modal-trigger ep-pin-okay-btn modal-close " data-target={BULK_TRANSFER_SUCCESS_MODAl_ID}>
                Okay
            </button>
        </Wrapper>
    );
};

export default EnterPin;
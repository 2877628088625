import { NavLink } from "react-router-dom";
import { useEffect }  from "react";

import M from "materialize-css";
const FundToActivateCard = () => {
    useEffect (()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);
    }, []);

    return (
        <div className="section-block wallet-card empty-card">
            <div className="wallet-card-logo-div flex-div justify-content-btw">
                <span className="balance-wrapper-txt">Virtual Card</span>
                <img src={require("../../images/payrail-text-logo.svg").default} alt="payrail" className="wallet-card-logo" />
            </div>
            <div className="balance-wrapper">
                <h4 className="wallet-balance">
                    <small >₦</small>0.00
                </h4>
            </div>
            <div className="wallet-card-bottom flex-div">
                <div className="flex-div add-send-money">
                    <NavLink to="/#" className="hover-scale">
                        <img src={require("../../images/fund-card.svg").default} alt="icon" className="asm-img" />
                    </NavLink> 
                    <NavLink to="/#" className="hover-scale">
                        <img src={require("../../images/withdraw.svg").default} alt="icon" className="asm-img" />
                    </NavLink>
                </div>
                <div className="wallet-bizness-name">
                    <img src={require("../../images/biz-logo.svg").default} alt="icon" className="wallet-biz-logo" /> <br />
                    <span>Ogbous and Sons </span>
                </div>
            </div>

            <div className="fund-to-activate">
                <div className="modal-trigger" data-target="fund-card-modal">Fund to Activate Card</div>
            </div>
        </div>
    )
}

export default FundToActivateCard;
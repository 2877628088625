import React from "react";
import M from "materialize-css";

const SubscriberItem = ({item={}, onClick=()=>{}}) =>{

    const {customer="",plan="",amount="",subscribed_on="",unit="",status=""} = item

    return(
        <tr>
        <td>{customer}</td>
        <td>{plan}</td>
        <td className="gray-txt">NGN {amount}</td>
        <td>{subscribed_on}</td>
        <td>{unit}</td>
        <td>{status}</td>
    </tr>
    )
}

export default SubscriberItem
// import M from "materialize-css";
// import React, { useEffect } from "react";

const AddVirtualCard = () => {
    // useEffect(() => {
    //     var elems = document.querySelectorAll('.modal');
    //     M.Modal.init(elems);
    // }, [])
    return(
        <div className="section-block wallet-card add-virtual-card modal-trigger" data-target="virtual-card-heads-up-modal">
            <div className="add-vc-txt-block center">
                <span>+</span>
                <div>Get a Virtual Card</div>
            </div>
        </div>
    )
}

export default AddVirtualCard
import "./CreateNewRole.css";

// components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";

//modal ID
import { VIEW_ROLE_AND_PRIVILEGES_MODAL_ID } from "../../../../../utility/modalIDs";
import { NavLink } from "react-router-dom";
import KYCApproval from "../../../Merchants/KYCApproval";

const CreateNewRoleModal = () => {
    return(
        <ModalWrapper modalID={VIEW_ROLE_AND_PRIVILEGES_MODAL_ID }>
            <div>
                <h5 className="iatmm-header">Merchant KYC Approval</h5>
                <p className="iatmm-sub-header">Admins have the following privileges:</p>
            </div>

           

         
           <KYCApproval />
        </ModalWrapper>
    )
};

export default CreateNewRoleModal;
// css
import { NavLink } from "react-router-dom";
import "./Administration.css";

const RoleCreator = ({ goback }) => {
    return (
        <div className="container admin-page-cont">
            <NavLink to="/dashboard/administration">
              <div className="manage-cards-goback" onClick={goback}>
                  <div className="manage-cards-goback-icon">
                      <img src={require("../../images/caret-down.svg").default} alt="caret"  />
                  </div>
                <span>Back</span>
              </div>
            </NavLink>

            <div className="ap-role-name">
                <h6>Role name</h6>
                <p>What do you want to call this role?</p>
            </div>

            <form action="#" id="role-creator-form" onSubmit={e => e.preventDefault()} className="dashboard-form">
                <div className="input-field single-field ap-half">
                    <div className="input-field">
                        <input id="role_name" type="text" className="form-inp-field" />
                        <label htmlFor="role_name">Role name</label>
                    </div>
                </div>
                <div className="ap-privileges-box">
                    <h6>Payments and invoices</h6>
                    <ul>
                        <li className="ap-privileges-list-item">
                            <span>Prepare and manage payments and invoices</span>
                            <div>
                                <label className="ap-mr">
                                  <input name="group1" type="checkbox" className="filled-in" defaultChecked />
                                  <span>Yes</span>
                                </label>
                                <label>
                                  <input name="group1" type="checkbox" className="filled-in" />
                                  <span>No</span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="ap-privileges-box">
                    <h6>Account privileges</h6>
                    <ul>
                        <li className="ap-privileges-list-item ap-mb">
                            <span>Can view accounts</span>
                            <div>
                                <label className="ap-mr">
                                  <input name="group2" type="checkbox" className="filled-in" defaultChecked />
                                  <span >Yes</span>
                                </label>
                                <label>
                                  <input name="group2" type="checkbox" className="filled-in" />
                                  <span >No</span>
                                </label>
                            </div>
                        </li>
                        <li className="ap-privileges-list-item ap-mb">
                            <span>Can download statements</span>
                            <div>
                                <label className="ap-mr">
                                  <input name="group3" type="checkbox" className="filled-in" defaultChecked />
                                  <span >Yes</span>
                                </label>
                                <label>
                                  <input name="group3" type="checkbox" className="filled-in" />
                                  <span>No</span>
                                </label>
                            </div>
                        </li>
                        <li className="ap-privileges-list-item ap-mb">
                            <span>Can create accounts</span>
                            <div>
                                <label className="ap-mr">
                                  <input name="group4" type="checkbox" className="filled-in" defaultChecked />
                                  <span>Yes</span>
                                </label>
                                <label>
                                  <input name="group4" type="checkbox" className="filled-in" />
                                  <span>No</span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="ap-privileges-box">
                    <h6>Administration privileges</h6>
                    <ul>
                        <li className="ap-privileges-list-item2 ap-mb">
                            <span>Team mates</span>

                            <div>
                                <label className="ap-mr2">
                                  <input name="group5" type="checkbox" className="filled-in" defaultChecked />
                                  <span>View</span>
                                </label>
                                <label className="ap-mr2">
                                  <input name="group5" type="checkbox" className="filled-in" />
                                  <span>Edit</span>
                                </label>
                                <label>
                                  <input name="group5" type="checkbox" className="filled-in" />
                                  <span>Hide</span>
                                </label>
                            </div>
                        </li>
                        <li className="ap-privileges-list-item2">
                            <span>Roles and privileges</span>

                            <div>
                                <label className="ap-mr2">
                                  <input name="group6" type="checkbox" className="filled-in" defaultChecked />
                                  <span>View</span>
                                </label>
                                <label className="ap-mr2">
                                  <input name="group6" type="checkbox" className="filled-in" />
                                  <span>Edit</span>
                                </label>
                                <label>
                                  <input name="group6" type="checkbox" className="filled-in" />
                                  <span>Hide</span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="ap-privileges-box">
                    <h6>Settings privileges</h6>
                    <ul>
                        <li className="ap-privileges-list-item3 ap-mb">
                            <span>Company settings</span>

                            <div>
                                <label className="ap-mr3">
                                  <input name="group7" type="checkbox" className="filled-in" />
                                  <span>Edit</span>
                                </label>
                                <label>
                                  <input name="group7" type="checkbox" className="filled-in" defaultChecked />
                                  <span>Hide</span>
                                </label>
                            </div>
                        </li>
                        <li className="ap-privileges-list-item3 ap-mb">
                            <span>Compliance check</span>
                            <div>
                                <label className="ap-mr3">
                                  <input name="group8" type="checkbox" className="filled-in" />
                                  <span>Edit</span>
                                </label>
                                <label>
                                  <input name="group8" type="checkbox" className="filled-in" defaultChecked />
                                  <span>Hide</span>
                                </label>
                            </div>
                        </li>
                        <li className="ap-privileges-list-item3 ap-mb">
                            <span>Approval rules</span>
                            <div>
                                <label className="ap-mr3">
                                  <input name="group9" type="checkbox" className="filled-in" />
                                  <span>Edit</span>
                                </label>
                                <label>
                                  <input name="group9" type="checkbox" className="filled-in" defaultChecked />
                                  <span>Hide</span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="ap-btn-wrapper">
                  <NavLink to="/dashboard/administration">
                    <button className="ap-btn">Save Role</button>
                  </NavLink>
                </div>
            </form>
        </div>
    );
}

export default RoleCreator;
import React from "react";
import M from "materialize-css";
import "./index.css";

const MessageItem = ({item={}, onClick=()=>{}}) =>{

    const {email="",status="",due_when="",created_date="",tx_date="",tx_amount="", category, client=""} = item

    return(
        <tr>
        <td>{'27-12-2021  06:48 PM'}</td>
        <td>{'Welcome to your best week yet! A new week is here with opportunities for you to give your best to the world! Send and Receive money with payrail to get started!'}</td>
        <td>
        <div className="mode-switch-wrapper">
            <div className="switch">
                <label>
                    <input type="checkbox" defaultChecked />
                    <span className="lever"></span>
                    <span className="test-mode mode-status">False</span>
                    <span className="live-mode mode-status">True</span>
                </label>
            </div>
        </div>
        </td>
        <td>
        <div className="mode-switch-wrapper">
            <div className="switch">
                <label>
                    <input type="checkbox" />
                    <span className="lever"></span>
                    <span className="test-mode mode-status">False</span>
                    <span className="live-mode mode-status">True</span>
                </label>
            </div>
        </div>
        </td>

        
    </tr>
    )
}

export default MessageItem
import React, {useState, useEffect} from "react";
import { InputField } from "../../../components/InputField";
import { SelectField } from '../../../components/selectField';
import { TextArea } from "../../../components/TextArea";
import ModalWrapper from "../../../components/UI/ModalWrapper";
import 'materialize-css/dist/css/materialize.min.css';
import M from "materialize-css";
import { EDIT_POS_TERMINAL, POS_REQUEST_SUCCESS, POS_TERMINAL_REQUEST } from "../../../utility/modalIDs";
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import classnames from 'classnames';
import $ from "jquery";


const formSchema = Yup.object().shape({
    terminalType: Yup.string().required("Required"),
    numberOfTerminals: Yup.string().required("Required"),
    deliveryAddress: Yup.string().required("Required"),
    terminalAlias: Yup.string().required("Required"),
    moreInformation: Yup.string(),
});

const EditInventory = ({editTerminal = ()=>{}, inventory}) => {
    const [error, setError] = useState({});

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
        })
    
    useEffect(()=>{
        setError(errors)
    },[errors])

    const onSubmit = (data) => {
        trigger()
        console.log('_____DATA___', data)
        if (Object.keys(errors).length === 0){
            // const terminalBtnModal = document.querySelector(`#${POS_REQUEST_SUCCESS}`);
            // M.Modal.getInstance(terminalBtnModal).open();
        }
    }
    return (
        <ModalWrapper modalID={EDIT_POS_TERMINAL} className="very-big-modal">
            <div className="modal-block-header">
                <h4 className="modal-block-title">Edit POS Terminal Inventory</h4>
                <div className="modal-block-desc">Note that all changes made are effected immediately.</div>
            </div>
            <div className="grid-row-2">
            <form onSubmit={handleSubmit(onSubmit)} id="pos-request-form">
                <InputField 
                        type="text" 
                        label="Terminal ID"
                        placeholder={"POS989108u2i01PAY"}
                        id={{input: "deliveryAddress"}} 
                        onChange={(e) => {
                            console.log("___VL___",e)
                            setValue(e.target.id, e.target.value)
                        }}
                        {...register('deliveryAddress',{ required: true })}
                        error={error && error['deliveryAddress']? error['deliveryAddress'].message: ""}
                        classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field", label:"active", error:"error-text", error:"error-text"}} 
                    />
                   
                    <InputField 
                            type="text" 
                            label="Serial Number"
                            placeholder={"00023230116065816005044700062"}
                            id={{input: "numberOfTerminals"}} 
                            onChange={e =>{
                                console.log("___VL___", e)
                                setValue(e.target.id, e.target.value)}}
                            {...register('numberOfTerminals',{ required: true })}
                            error={error && error['numberOfTerminals']? error['numberOfTerminals'].message: ""}
                            classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field", label:"active", error:"error-text"}} 
                        />

                    <SelectField 
                        items={[
                            {key: "Android Terminal", value: "Android Terminal"}
                        ]}
                        label="POS Terminal Type" 
                        id={{select: "terminalType"}} 
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        {...register('terminalType', { required: true })}
                        error={error && error['terminalType']? error['terminalType'].message: ""}
                        classname={{div:"input-field col s12 mag-d-10 pad0 country-list-wrapper", label:"active", error:"error-text"}} 
                    />
                        
                    <div className="submit-btn-wrapper flex-div justify-content-btw">
                        <div className="cancel-btn modal-close">
                            <u>Cancel</u>
                        </div>
                        <button type="submit" className="submit-btn ">Save</button>
                    </div>
                </form>

                <div className="pos-img-wrapper">
                    <img src={require("../../../images/pos-terminal.png")} alt="pos terminal" className="pos-img" />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default EditInventory;
import React, { useState, useEffect } from "react";
import M from "materialize-css";
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { QUICK_TRANSFER_MODAL_ID, VERIFICATION_CODE_MODAL_ID } from "../../../../utility/modalIDs";
import { SelectField } from "../../../../components/selectField";
import { InputField } from "../../../../components/InputField";

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';


const QuickTransferModal = () => {
    const [ transferType, setTransferType ] = useState("subaccount");
    useEffect (()=> {
            var elemSelect = document.querySelectorAll('select');
            M.FormSelect.init(elemSelect);
    }, [transferType]);

    let formSchema;

    if (transferType === "subaccount") {
        formSchema = Yup.object().shape({
            amount: Yup.string().required("Required"),
            payment_tag: Yup.string().required("Required"),
            subaccount: Yup.string().required("Required"),
            account_number: Yup.string().required("Required"),
        });
    } else {
        formSchema = Yup.object().shape({
            amount: Yup.string().required("Required"),
            payment_tag: Yup.string().required("Required"),
            bank_name: Yup.string().required("Required"),
        });
    }

    const [error, setError] = useState({});

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
    })
    
    useEffect(()=>{
        setError(errors)
    },[errors]);

    const onSubmit = (data) => {
        trigger()
        if (Object.keys(errors).length === 0){
           
        }
    }
    return (
        <ModalWrapper modalID={QUICK_TRANSFER_MODAL_ID}>
            <div className="qtsmm-modal-title">Quick Transfer</div>
            <form className="dashboard-form modal-form amvutm-mt"  onSubmit={handleSubmit(onSubmit)}>
                <InputField 
                    type="text" 
                    label="Amount" 
                    {...register('amount',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['amount']? error['amount'].message: ""} 
                    id={{input: "amount"}} 
                    classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field"}} 
                >
                    <span className="form-field-subscript text-underline grey-text">+25 Transaction Fee</span>
                </InputField>

                <InputField 
                    type="text" 
                    label="Payment Tag" 
                    {...register('payment_tag',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['payment_tag']? error['payment_tag'].message: ""} 
                    id={{input: "payment-tag"}} 
                    classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field"}} 
                />

                {
                    transferType === 'subaccount' ? (
                        <SelectField 
                            items={[
                                {value: "select", key: "Select a subaccount"},
                                {value: "groceries", key: "Groceries account"},
                            ]}
                            type="text" 
                            label="Select a Subaccount" 
                            {...register('subaccount',{ required: true })}
                            onChange={e => setValue(e.target.id, e.target.value)} 
                            id={{select: "subaccount"}} 
                            classname={{div:"select-field input-field single-field rel", label:"active", img: "inp-caret-icon" }} 
                        >
                            <span className="form-field-subscript text-underline cursor-pointer" onClick={() => setTransferType("Third-party")}>Third-party Transfer</span>
                        </SelectField>
                    ) : (
                        <>
                            <SelectField 
                                items={[
                                    {key: "GTbank", value: "GTbank"},
                                ]}
                                type="text" 
                                label="Bank" 
                                {...register('bank_name',{ required: true })}
                                onChange={e => setValue(e.target.id, e.target.value)} 
                                id={{select: "bank_name"}} 
                                classname={{div:"select-field input-field single-field rel", label:"active", img: "inp-caret-icon" }} 
                            >
                                <span className="form-field-subscript text-underline cursor-pointer" onClick={() => setTransferType("subaccount")}>Choose Subaccount</span>
                            </SelectField>


                            <InputField 
                                type="text" 
                                label="Amount" 
                                {...register('account_number',{ required: true })}
                                onChange={e =>{setValue(e.target.id, e.target.value)}}
                                error={error && error['account_number']? error['account_number'].message: ""} 
                                id={{input: "account-number"}} 
                                classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field"}} 
                            >
                                <div className="qtsmm-confirm-beneficiary">Akinyode Ologunmeru</div>
                            </InputField>

                            <div className="inner-checkbox-field qtsmm-checkbox-margin">
                                <label>
                                    <input type="checkbox" className="filled-in" id="use-general-email" />
                                    <span>Allow payments when monthly budget is exceeded</span>
                                </label>
                            </div>
                        </>
                    )
                }

                <button type="submit" className="pry-btn modal-trigger modal-close amvutm-btn-fullwidth" data-target={VERIFICATION_CODE_MODAL_ID}>
                    Done
                </button>
            </form>
        </ModalWrapper>
    );
};

export default QuickTransferModal;
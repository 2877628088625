// css
import "./RolesAndPrivileges.css";
import M from "materialize-css";
// Components
import Wrapper from "../../../components/UI/Wrapper";
import CreateNewRoleModal from "./Modals/CreateNewRole";

// Modal ID
import { VIEW_ROLE_AND_PRIVILEGES_MODAL_ID  } from "../../../utility/modalIDs";
import { useNavigate } from "react-router-dom";

const RolesAndPrivileges = () => {
    const navigate = useNavigate();


    const selectRole = (role) => {
        const terminalBtnModal = document.querySelector(`#${VIEW_ROLE_AND_PRIVILEGES_MODAL_ID}`);
        M.Modal.getInstance(terminalBtnModal).open();
    }

    return (
        <>
            <Wrapper grey outlined className="tt-top">
                <div className="tt-top-content">
                    <button className="tt-invite-btn" onClick={()=> navigate("/dashboard/role-creator") }>+ Create new role</button>
                </div>
            </Wrapper>

            <Wrapper grey outlined  className="tt-table-container table-wrapper">
                <table id="customers-table">
                    <thead>
                        <tr>
                            <th>ROLE NAME</th>
                            <th>TYPE</th>
                            <th>CREATED BY</th>
                            <th>TEAM MATES</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>Admin</td>
                            <td>Default</td>
                            <td>Payrail</td>
                            <td className="gray-txt">1</td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>View</button></td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Edit</button></td>
                        </tr>

                        <tr>
                            <td>Member</td>
                            <td>Default</td>
                            <td>Payrail</td>
                            <td className="gray-txt">0</td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>View</button></td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Edit</button></td>
                        </tr>

                        <tr>
                            <td>Owner</td>
                            <td>Default</td>
                            <td>Payrail</td>
                            <td className="gray-txt">2</td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>View</button></td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Edit</button></td>
                        </tr>

                        <tr>
                            <td>Payable Admin</td>
                            <td>Default</td>
                            <td>Payrail</td>
                            <td className="gray-txt">0</td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>View</button></td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Edit</button></td>
                        </tr>

                        <tr>
                            <td>Receivable Admin</td>
                            <td>Default</td>
                            <td>Payrail</td>
                            <td className="gray-txt">0</td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>View</button></td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Edit</button></td>
                        </tr>
                    </tbody>
                </table>
            </Wrapper>

            <CreateNewRoleModal />
        </>
    );
}

export default RolesAndPrivileges;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accounts:[{name:"Salary",split:"10%",code:"ACCT_xsdgahte", id:""}],
 
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,

  reducers: {
    addAccount: ()=>{},
    searchAccount: ()=>{},
    sortAccount: ()=>{},
    fetchAccount: ()=>{},
    Accounts: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
    accountAdded: (state, action) => {
      state.accountAdded = true;
      state.actionType = action.type
    },
   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Accounts,
  accountAdded,
  
  addAccount,
  searchAccount,
  sortAccount,
  fetchAccount,
   
    } = accountSlice.actions

export default accountSlice.reducer
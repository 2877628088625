import React from "react";
import M from "materialize-css";

const TicketItem = ({item={}, onClick=()=>{}}) =>{

    const {email="omobolanle@gmail.com",status="Open",due_when="",date="27-12-2021 06:48 PM",uniqueId="#Technical - 9540",clientName="Omobolanle May", category = "Technical", subject="Please be fast about my KYC"} = item

    return(
        <tr>
        <td>{date}</td>
        <td >{category}</td>
        <td>{uniqueId}</td>
        <td>{email}</td>
        <td>{clientName}</td>
     
        <td>
            {subject}
        </td>
        <td>{status}</td>
        <td>
            <button onClick={onClick} className="primary-btn " >
                View →
            </button>
        </td>
    </tr>
    )
}

export default TicketItem
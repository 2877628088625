// css
import "../KYCApproval/KYCApproval.css";
import M from "materialize-css";
import $ from "jquery";
import { useEffect, useState } from "react";
// Components
import Wrapper from "../../../components/UI/Wrapper";

// Modal ID
import { VIEW_ROLE_AND_PRIVILEGES_MODAL_ID  } from "../../../utility/modalIDs";
import { useNavigate } from "react-router-dom";

const KYCApproval = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const handleInput = (e) =>{
        setSearch(e.target.value)
    }
    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);

        $(".filter-dropdown-trigger").click(function(){
            $(this).parent().find(".filter-dropdown-content").slideToggle();
        });
    }, []);
    
    const selectRole = (role) => {
        const terminalBtnModal = document.querySelector(`#${VIEW_ROLE_AND_PRIVILEGES_MODAL_ID}`);
        M.Modal.getInstance(terminalBtnModal).open();
    }

    return (
        <>
          

            <Wrapper grey outlined  className="tt-table-container table-wrapper">
                <table id="customers-table">
                    <thead>
                        <tr>
                            <th>KYC</th>
                            <th>DETAILS</th>
                            <th>UPLOADED ON</th>
                            <th>Status</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>BVN</td>
                            <td>12345678909</td>
                            <td>27/12/2021 06:48PM </td>
                            <td>Verified</td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Download</button></td>
                            <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Approve</button></td>
                        <td> <button className="primary-btn" onClick={()=>{
                            selectRole({});
                        }}>Decline</button></td>
                           
                        </tr>

                       
                    </tbody>
                </table>
            </Wrapper>

           
        </>
    );
}

export default KYCApproval;
import React, { useState, useEffect } from "react";
import $ from "jquery";
import M from "materialize-css"

import Wrapper from "../../../../../../components/UI/Wrapper";

const InvoiceBuilderReview = ({ draftHandler }) => {
    const [show, setShow] = useState(false);

    const showAllFieldsHandler = (e) => {
        setShow(!show);
    };

    useEffect(()=> {
        $(".rpmpit-underline.rpmsit-min-depo-text").click(function(){
            $(this).hide();
            $(".input-field").removeClass("rpmsit-rel")
        })

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    },[])
    
    return (
        <Wrapper outlined grey  className="ipib-container-item">
                <div className="ipib-review-title">
                    Review
                </div>
                <form action="#" className="dashboard-form modal-form" onSubmit={e => e.preventDefault()}>
                    <div className="input-field single-field">
                        <input id="invoice_no" type="text" className="form-inp-field" />
                        <label htmlFor="invoice_no">Invoice Number</label>
                    </div>

                    <div className="input-field single-field rpmsit-rel">
                        <input id="email_add" type="email" className="form-inp-field" />
                        <label htmlFor="email_add">Email Address</label>
                        <small className="rpmsit-min-depo-text rpmpit-underline rpmpit-cursor-p" onClick={() => showAllFieldsHandler()}>Update customer details?</small>
                    </div>

                    {
                        show ?
                        <>
                            <div className="input-field single-field">
                                <input id="first_name" type="text" className="form-inp-field" />
                                <label htmlFor="first_name">First Name</label>
                            </div>

                            <div className="input-field single-field">
                                <input id="last_name" type="text" className="form-inp-field" />
                                <label htmlFor="last_name">Last Name</label>
                            </div>
                        </>
                        : null
                    }

                    <div className="input-field single-field">
                        <input id="duedate" type="text" className="form-inp-field" />
                        <label htmlFor="duedate">Due Date</label>
                    </div>
                    <div className="input-field single-field">
                        <input id="tax" type="text" className="form-inp-field" />
                        <label htmlFor="tax">Tax</label>
                    </div>
                    <div className="input-field single-field">
                        <input id="discount" type="text" className="form-inp-field" />
                        <label htmlFor="discount">Discount</label>
                    </div>
                    <div className="select-field input-field single-field rel">
                        <select id="payment_account">
                            <option value="Show all">Main Account</option>
                            <option value="Show New">Show New</option>
                            <option value="Show Returning">Show Returning</option>
                        </select>
                        <label htmlFor="payment_account" className="active">Payment Account </label>
                        <img src={require("../../../../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                    </div>
                    <div className="input-field single-field">
                        <textarea id="description" className="materialize-textarea form-inp-field"></textarea>
                        <label htmlFor="description">Note</label>
                    </div>

                    <hr/>

                    <div className="ipib-review-btns">
                        <button className="pry-btn ipib-cancel-btn" onClick={() => draftHandler("draft")}>
                            Save Draft
                        </button>

                        <button className="pry-btn modal-trigger modal-close" data-target="bill-payment-schedule-success-modal">
                            Send Now
                        </button>
                    </div>
                </form>
            </Wrapper>
    )
};

export default InvoiceBuilderReview;
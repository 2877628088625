import M from "materialize-css";
import $ from "jquery";

import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../redux/reducers/auth";
import debounce from "debounce";
import ViewTicketModal from "./viewTicketModal";
import TicketItem from "../../components/TicketItem";
import { fetchDispute, searchDispute } from "../../redux/reducers/dispute";
import OpenTicket from "./OpenTicket";
import AnsweredTicket from "./AnsweredTicket";
import ClosedTicket from "./ClosedTicket";
import TicketMetrics from "./TicketMetrics";

const Tickets = () => {
    const dispatch = useDispatch()
    const {auth = {}, dispute={}, customer} = useSelector(state=>state);
    const { disputes=[]} = dispute
    const [search, setSearch] = useState("");

    const handleSearch = (text) =>{
       dispatch(searchDispute(text))

    }

    const getCustomer = () =>{
        dispatch(setLoading(true));
        dispatch(fetchDispute())
        dispatch(setLoading(false));
    }
    const searchHandler = useCallback(debounce((text)=>handleSearch(text), 2000), []);

    useEffect(()=>{
        if (search !== "") {
            searchHandler(search)
        }else{
            if (search !==null) {
                getCustomer()
            }
        }
    },[search])

    const handleInput = (e) =>{
        setSearch(e.target.value)
    }
    useEffect (()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $(".filter-dropdown-trigger").click(function(){
            $(".filter-dropdown-content").slideToggle();
        });
    }, []);

    return(
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Tickets </span>
                        <small className="page-label-tag">4</small>
                    </h4>
                </div>
                <div className="row tab-wrapper ap-fullHeight2">
                <div className="col s12 pad0">
                    <ul className="tabs">
                        <li className="tab">
                            <a className="active flex-div" href="#open-ticket">
                                <span>Open Tickets</span> 
                            </a>
                        </li>
                        <li className="tab">
                            <a className="flex-div" href="#answered-ticket">
                                <span>Answered Tickets</span> 
                            </a>
                        </li>
                        <li className="tab">
                            <a className="flex-div" href="#close-ticket">
                                <span>Closed Ticket</span> 
                            </a>
                        </li>
                        <li className="tab">
                            <a className="flex-div" href="#ticket-metrics">
                                <span>Ticket Metrics</span> 
                            </a>
                        </li>
                    </ul>
                </div>
                <div id="open-ticket" className="col s12 pad0 ap-fullHeight">
                    <OpenTicket />
                </div>
                <div id="answered-ticket" className="col s12 pad0">
                    <AnsweredTicket />
                </div>
                <div id="close-ticket" className="col s12 pad0">
                    <ClosedTicket />
                </div>
                <div id="ticket-metrics" className="col s12 pad0">
                    <TicketMetrics />
                </div>

                
                

                </div>

               

                
            </div>

            <ViewTicketModal />
        </div>
    )
}

export default Tickets;
import "./VirtualCardHeadsUp.css"

const VirtualCardHeadsUp = () => {
    return(
        <div id="virtual-card-heads-up-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <div className="virtual-card-heads-up-modal">
                    <div>
                        <img src={require("../../../../images/orange-card.svg").default} alt="orange-card-icon" /> 
                    </div>
                </div>

                <div className="ep-pin">
                    <h4>Heads Up!</h4>
                </div>

                <div className="virtual-card-heads-up-modal-text">
                    <span>A new virtual card will cost</span> <br></br>
                    <span><strong className="black-txt">₦850.00</strong> Card fee + VAT</span>
                </div>

                <div className="virtual-card-heads-up-modal-btn">
                    <button className="cancel-btn modal-close">
                        Cancel
                    </button>

                    <button className="pry-btn modal-trigger modal-close" data-target="virtual-card-created-modal">
                        Okay
                    </button>
                </div>
            </div>

        </div>
    )
}

export default VirtualCardHeadsUp;
const { terminalStatus } = require(".")
const { default: Wrapper } = require("../../components/UI/Wrapper")
const { Loader } = require("../../components/loader")

const EmptyInventry= ({AddInventory = ()=>{}}) => {

    return(
        <div className="empty-content-container center">
                                <img src={require("../../images/empty-placeholder-1.svg").default} alt="empty" className="empty-content-img" />
                                <div>
                                    <b>You have not requested a terminal</b>
                                    <p>
                                        If you’d like a terminal for your business, kindly request using the button below
                                    </p>
                                    <div className="empty-container-cta">
                                        <button className="request-terminal-btn " onClick={()=>{
                                    AddInventory();
                                }}>
                                            + Request for Terminal
                                        </button>
                                    </div>
                                </div>
                            </div>
    )

}

export default EmptyInventry
import "./pos.css"
import { useEffect, useState } from "react";
import 'materialize-css/dist/css/materialize.min.css';
import M from "materialize-css";

import Wrapper from "../../components/UI/Wrapper";

// Link to DaterangePicker:  https://rsuitejs.com/components/date-range-picker/
import 'rsuite/dist/rsuite.min.css';
import DateRangePicker from 'rsuite/DateRangePicker';

// Link to Charts: https://github.com/hustcc/echarts-for-react
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from "react-redux";
import { Terminals, fetchTerminals } from "../../redux/reducers/pos";
import { Loader } from "rsuite";
import { POS_TERMINAL_REQUEST } from "../../utility/modalIDs";
import PosTerminalRequest from "./Modals/PosTerminalRequest";
import PosRequestSuccess from "./Modals/PosRequestSuccess";
import { fetchTerminalApi } from "../../redux/sagas/requests/pos";
import POSRequest from "./POSRequest";
import EmptyInventry from "./EmptyInventry";
import POSInventory from "./POSInventory";
import AddInventory from "./Modals/AddInventory";
import EditInventory from "./Modals/EditInventory";
import RetreiveInventory from "./Modals/RetrieveInventory";
import AssignInventory from "./Modals/AssignInventory";

const barOption = {
    xAxis: {
        type: 'category',
        data: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov']
    },
    yAxis: {
        type: 'value',
        show: false
    },
    series: [
        {
          data: [120, 200, 150, 80, 70, 110, 130, 70],
          type: 'bar',
          roundCap: true,
          color: "#EA6212",
          barGap: '100%',
          barWidth: 10,
          itemStyle: {
            borderRadius: [8, 8, 0, 0]
          }
        }
    ],
    grid: {
        left: '0%',
        right: '0%',
        top: '0',
        bottom: '0',
        containLabel: true
    },
};



const PosPage = () => {

    const dispatch = useDispatch()
    const {auth = {}, pos={}, customer} = useSelector(state=>state);
    const [inventory, setInventory] = useState();
    const { terminals=[], fetchingTerminal} = pos
   
    const fetchPOSTerminal = () =>{
        fetchTerminalApi().then(res=>{
            dispatch(Terminals(res))
        }).catch(err=>{

        })
       
       
    }
    
    useEffect(()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemsSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemsSelect);

        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        fetchPOSTerminal()
    }, []);

    const addTerminal = () => {
        const terminalBtnModal = document.querySelector('#add-pos-terminal');
        M.Modal.getInstance(terminalBtnModal).open();
    }

    const editTerminal = () => {
        const terminalBtnModal = document.querySelector('#edit-pos-terminal');
        M.Modal.getInstance(terminalBtnModal).open();
    }

    const retreiveTerminal = () => {
        const terminalBtnModal = document.querySelector('#retreive-pos-terminal');
        M.Modal.getInstance(terminalBtnModal).open();
    }

    const assignTerminal = () => {
        const terminalBtnModal = document.querySelector('#assign-pos-termial');
        M.Modal.getInstance(terminalBtnModal).open();
    }

    const viewRequest = () => {
        const terminalBtnModal = document.querySelector('#pos-terminal-request');
        M.Modal.getInstance(terminalBtnModal).open();
    }

   
    return (
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title">
                        <span>POS Terminal</span>
                    </h4>
                </div>

                <div className="section-row-wrapper grid-row-2">
                    <div className="request-terminal-block">
                        <div className="rtb-sub-wrapper">
                            <p>Hello! Request for a new Payrail POS terminal</p>
                            <p className="gray-txt">
                                Receive payments safely and securely while getting quick and easy settlements
                            </p>

                            <div className="request-terminal-btn-wrapper">
                                <button className="request-terminal-btn " onClick={()=>{
                                    addTerminal();
                                }} >
                                    + Add POS Terminal
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="section-block expense-overivew-block">
                        <div className="expense-block-header section-block-header-2 flex-div">
                            <div className="expense-income-div flex-div">
                                <div className="income-div flex-div">
                                    <span className="disc"></span>
                                    <span className="eid-txt">Income</span>
                                    <b className="eid-amount" title="₦120,000,000.00">₦120M</b>
                                </div>
                            </div>
                            <div className="flex-div daterange-filter">
                                <DateRangePicker placeholder="1 Sept 21 - 30 Sept 21" format="dd-MMM-yy" placement="bottomEnd" />
                            </div>
                        </div>
                        <div className="expense-income-chart-wrapper">
                            <ReactECharts height="200" width="100%" option={barOption} />
                        </div>
                    </div>
                </div>

                <div className="section-row-wrapper">
                    <div className="row tab-wrapper">
                        <div className="full-width">
                            <ul className="tabs">
                                <li className="tab">
                                    <a className="active flex-div" href="#all-terminals">
                                        <span>Request</span> 
                                    </a>
                                </li>
                                <li className="tab">
                                    <a className="flex-div" href="#terminal-requests">
                                        <span>Inventry</span> 
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div id="all-terminals" className="full-width terminals-list-block">
                        <POSRequest posRequests={terminals} viewRequest={viewRequest} fetchingTerminal={fetchingTerminal} />
                        </div>
                        <div id="terminal-requests" className="full-width terminals-list-block">
                            {
                                terminals.length ? (
                                    <POSInventory assignTerminal={assignTerminal} retreiveTerminal={retreiveTerminal} editTerminal={editTerminal} addTerminal={addTerminal} POSInventories={terminals} fetchingPOSInventory={fetchingTerminal} />
                                ):(
                               <EmptyInventry AddInventory={addTerminal} />
                                )
                            }
                       
                            
                        </div>
                    </div>
                </div>
            </div>
            <AddInventory />
            <EditInventory inventory={inventory} />
            <RetreiveInventory inventory={inventory}  />
            <AssignInventory inventory={inventory}  />
            <PosTerminalRequest />
            <PosRequestSuccess />
        </div>
    )
}

export default PosPage;
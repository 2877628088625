// import React, {useEffect, useState} from 'react'

export const Loader = () =>{

    return(
        <div className="progress load-progress">
            <div className="indeterminate"></div>
        </div>
    )

}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  plans:[{information:"Newsletter",subscription:"1/1 Active",amount:"3,000.00",interval:"Monthly",create_on:"Feb 23, 2021",status:"Active"}],
  subscribers:[{customer:"Jide Apete",plan:"Newsletter",amount:"100.00",subscribed_on:"Jun 3, 2021",unit:"2",status:"Active"}]
 
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,

  reducers: {
    addPlan: ()=>{},
    searchPlan: ()=>{},
    filterPlan: ()=>{},
    fetchPlan: ()=>{},
    Plans: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },

    searchSubscribers: ()=>{},
    filterSubscribers: ()=>{},
    fetchSubscribers: ()=>{},
    Subscribers: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Plans,
  Subscribers,
  
  addPlan,
  searchPlan,
  filterPlan,
  fetchPlan,
  searchSubscribers,
  filterSubscribers,
  fetchSubscribers
   
    } = subscriptionSlice.actions

export default subscriptionSlice.reducer
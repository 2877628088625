import { NavLink, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
// import $ from 'jquery'
import M from 'materialize-css';

import '../../../css/business.css';
import { useSelector } from "react-redux";
import Transaction from "../../../components/transaction";
import KYCApproval from "./KYCApproval";
import Administrations from "./Administrations";
import ProfileDetails from "./ProfileDetails";

const MerchantView = () => {
   const navigate = useNavigate();
    const {auth = {}, wallet={}} = useSelector(state=>state);
    const {recent_transaction=[], wallet_balance={}, bank_detail={}} = wallet

    const CopyToClipboard = (selectedId) => {
        /* Get the text field */
        var copyText = document.getElementById(selectedId);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        
        /* Alert the copied text */
        M.toast({html: 'Account number copied'})
    }
    
    return ( 
        <div className="page-content-wrapper">
            <div className="container">
                <div className="section-row-wrapper grid-row-2">
                    <div className="section-block wallet-card">
                        {/* <div className="wallet-card-logo-div">
                            <img src={require("../../images/payrail-text-logo.svg").default} alt="payrail" className="wallet-card-logo" />
                        </div> */}
                        <div className="balance-wrapper">
                            <span className="balance-wrapper-txt">Trading Balance</span>
                            <h4 className="wallet-balance">
                                <small >₦</small>{wallet_balance.balance || "35,000,000.09"}
                            </h4>
                        </div>
                        <div className="wallet-card-bottom flex-div">
                            <div className="flex-div add-send-money">
                                <NavLink to="/dashboard/merchants/customers" className="view-customer">
                                <img src={require("../../../icons/customer-management-inactive.svg").default} alt="icon" className="view-customer-icon" />  
                                <p className="view-customer-text">Customers</p>
                                </NavLink> 
                                <NavLink to="/dashboard/merchants/invoice" className="view-customer">
                                <img src={require("../../../icons/transactions-inactive.svg").default} alt="icon" className="view-customer-icon" />  
                                <p className="view-customer-text">Invoices</p>
                                </NavLink> 
                                {/* <NavLink to="/dashboard/merchants/invoice" className="view-customer">
                                <img src={require("../../../icons/transactions-inactive.svg").default} alt="icon" className="view-customer-icon" />  
                                <p className="view-customer-text">Revenue</p>
                                </NavLink>  */}
                                {/* <NavLink to="/#" className="hover-scale">
                                    <img src={require("../../../images/send-money.svg").default} alt="icon" className="asm-img" />
                                </NavLink> */}
                            </div>
                            <div className="wallet-bizness-name">
                                <img src={require("../../../images/biz-logo.svg").default} alt="icon" className="wallet-biz-logo" /> <br />
                                <span>{bank_detail.accountName}</span>
                            </div>
                        </div>
                    </div>

                    <div className="section-block bank-details-block">
                        <div className="bank-details-header section-block-header-2 flex-div">
                            <div className="text-uppercase">Bank Details</div>
                            <div className="copy-div pointer flex-div" onClick={() => CopyToClipboard("acct-num")}>
                                <img src={require("../../../images/red-copy-icon.svg").default} alt="icon" className="copy-icon" />
                                <span>Copy</span>
                            </div>
                        </div>
                        <div className="bank-details">
                            <div id="bank-details-table">
                                <ul>
                                    <li>
                                        <span>Bank name:</span>
                                        <span>{bank_detail.bankName}</span>
                                    </li>
                                    <li>
                                        <span>Account name:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Account number:</span>
                                        <span id="acct-num-txt">{bank_detail.accountNumber}</span>
                                        <input type="text" id="acct-num" defaultValue="1812788912" hidden />
                                    </li>
                                </ul>
                            </div>

                            <div className="bd-block-footer">
                                {/* <div className="flex-div pointer">
                                    <img src={require("../../images/statement.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Statement</span>
                                </div> */}
                                <div onClick={()=>navigate('/dashboard/settings')} className="flex-div pointer">
                                    <img src={require("../../../images/settings-2.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Settings</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-row-wrapper grid-row-2">
                <div className="section-block bank-details-block">
                        <div className="bank-details-header section-block-header-2 flex-div">
                            <div className="text-uppercase">Merchant  Information</div>
                            <div className="copy-div pointer flex-div" onClick={() => CopyToClipboard("acct-num")}>
                                <img src={require("../../../images/red-copy-icon.svg").default} alt="icon" className="copy-icon" />
                                <span>Copy</span>
                            </div>
                        </div>
                        <div className="bank-details">
                            <div id="bank-details-table">
                                <ul>
                                    <li>
                                        <span>Merchant  ID:</span>
                                        <span>{bank_detail.bankName}</span>
                                    </li>
                                    <li>
                                        <span>Merchant  Type:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Username:</span>
                                        <span id="acct-num-txt">{bank_detail.accountNumber}</span>
                                        <input type="text" id="acct-num" defaultValue="1812788912" hidden />
                                    </li>
                                    <li>
                                        <span>Name:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Email:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Phone:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Address:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Nationality:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>LGA:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>State:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Referrer:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>KYC:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>


                                </ul>
                            </div>

                            <div className="bd-block-footer">
                                {/* <div className="flex-div pointer">
                                    <img src={require("../../images/statement.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Statement</span>
                                </div> */}
                                <div onClick={()=>navigate('/dashboard/settings')} className="flex-div pointer">
                                    <img src={require("../../../images/settings-2.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Settings</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-block bank-details-block">
                        <div className="bank-details-header section-block-header-2 flex-div">
                            <div className="text-uppercase">Business/Company Information</div>
                            <div className="copy-div pointer flex-div" onClick={() => CopyToClipboard("acct-num")}>
                                <img src={require("../../../images/red-copy-icon.svg").default} alt="icon" className="copy-icon" />
                                <span>Copy</span>
                            </div>
                        </div>
                        <div className="bank-details">
                            <div id="bank-details-table">
                                <ul>
                                    <li>
                                        <span>Business name:</span>
                                        <span>{bank_detail.bankName}</span>
                                    </li>
                                    <li>
                                        <span>Trading name:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Business Description:</span>
                                        <span id="acct-num-txt">{bank_detail.accountNumber}</span>
                                        <input type="text" id="acct-num" defaultValue="1812788912" hidden />
                                    </li>
                                    <li>
                                        <span>Staff number:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Industry:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Category:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Currency:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>

                                </ul>
                            </div>

                            <div className="bd-block-footer">
                                {/* <div className="flex-div pointer">
                                    <img src={require("../../images/statement.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Statement</span>
                                </div> */}
                                <div onClick={()=>navigate('/dashboard/settings')} className="flex-div pointer">
                                    <img src={require("../../../images/settings-2.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Settings</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="section-row-wrapper grid-row-2">
                    <div className="section-block bank-details-block">
                        <div className="bank-details-header section-block-header-2 flex-div">
                            <div className="text-uppercase">Business Contact</div>
                            <div className="copy-div pointer flex-div" onClick={() => CopyToClipboard("acct-num")}>
                                <img src={require("../../../images/red-copy-icon.svg").default} alt="icon" className="copy-icon" />
                                <span>Copy</span>
                            </div>
                        </div>
                        <div className="bank-details">
                            <div id="bank-details-table">
                                <ul>
                                    <li>
                                        <span>Tax ID no:</span>
                                        <span>{bank_detail.bankName}</span>
                                    </li>
                                    <li>
                                        <span>Business Address:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Business Email:</span>
                                        <span id="acct-num-txt">{bank_detail.accountNumber}</span>
                                        <input type="text" id="acct-num" defaultValue="1812788912" hidden />
                                    </li>
                                    <li>
                                        <span>Support Email:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Support No:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Business Social Media Account:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>
                                    <li>
                                        <span>Website Url:</span>
                                        <span>{bank_detail.accountName}</span>
                                    </li>

                                </ul>
                            </div>

                            <div className="bd-block-footer">
                                {/* <div className="flex-div pointer">
                                    <img src={require("../../images/statement.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Statement</span>
                                </div> */}
                                <div onClick={()=>navigate('/dashboard/settings')} className="flex-div pointer">
                                    <img src={require("../../../images/settings-2.svg").default} alt="icon" className="bd-txt-icon" />
                                    <span>Settings</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-block bank-details-block">
                        <div className="bank-details-header section-block-header-2 flex-div">
                            <div className="text-uppercase">KYC details</div>
                            <div className="copy-div pointer flex-div" onClick={() => CopyToClipboard("acct-num")}>
                                <img src={require("../../../images/red-copy-icon.svg").default} alt="icon" className="copy-icon" />
                                <span>Copy</span>
                            </div>
                        </div>
                        <div className="bank-details">
                            <KYCApproval/>
                        </div>
                    </div>
                </div>

                <div className="section-row-wrapper grid-row-2">
                    <div className="section-block bank-details-block">
                        <div className="bank-details-header section-block-header-2 flex-div">
                            <div className="text-uppercase">Mike Ola profile details</div>
                           
                        </div>
                        <div className="bank-details">
                            <ProfileDetails />
                        </div>
                       
                    </div>

                    <div className="section-block bank-details-block">
                        <div className="bank-details-header section-block-header-2 flex-div">
                            <div className="text-uppercase">Administrations</div>
                           
                        </div>
                        <div className="bank-details">
                            <Administrations/>
                        </div>
                    </div>
                </div>

                <div className="section-row-wrapper grid-row-1">
                    <div className="section-block recent-tranx-wrapper">
                        <div className="section-block-header flex-div">
                            <span>RECENT TRANSACTION</span>
                            <NavLink to="/#" className="flex-div view-all-link">
                                <span>VIEW ALL</span>
                                <img src={require("../../../images/right-arr.svg").default} alt="icon" className="right-arr1" />
                            </NavLink>
                        </div>

                        <div className="recent-tranx-table-wrapper">
                            <table className="brief-tranx-table responsive-table">
                                <tbody>

                                {recent_transaction.map(((item)=>{
                                       
                                        return(
                                            <Transaction {...item} />
                                        )
                                    }))}


                                    {/* <tr>
                                        <td>
                                            <div className="flex-div">
                                                <img src={require("../../images/airtime-tranx.svg").default} alt="transaction icon" className="tranx-activity-icon" />
                                                <div className="tranx-activity-details">
                                                    <div className="tranx-activity">Airtime Purchase</div>
                                                    <small className="tranx-activity-status">Processed</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span>08148620099</span>
                                        </td>
                                        <td>
                                            <span>Bill Payment </span>
                                        </td>
                                        <td>
                                            <div className="flex-div">
                                                <div className="tranx-activity-details">
                                                    <div className="tranx-activity tranx-amount failed-transaction">- ₦10,000</div>
                                                    <small className="tranx-activity-status">01 Sept, 04:25 AM</small>
                                                </div>
                                                <img src={require("../../images/menu-circle.svg").default} alt="icon" className="table-menu-circle" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="flex-div">
                                                <img src={require("../../images/recieved-tranx.svg").default} alt="transaction icon" className="tranx-activity-icon" />
                                                <div className="tranx-activity-details">
                                                    <div className="tranx-activity">Adebola sent you Money</div>
                                                    <small className="tranx-activity-status">Received</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span>1078234098</span>
                                        </td>
                                        <td>
                                            <span>Income </span>
                                        </td>
                                        <td>
                                            <div className="flex-div">
                                                <div className="tranx-activity-details">
                                                    <div className="tranx-activity tranx-amount success-transaction">+ ₦10,000</div>
                                                    <small className="tranx-activity-status">28 Aug, 01:50 PM</small>
                                                </div>
                                                <img src={require("../../images/menu-circle.svg").default} alt="icon" className="table-menu-circle" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="flex-div">
                                                <img src={require("../../images/airtime-tranx.svg").default} alt="transaction icon" className="tranx-activity-icon" />
                                                <div className="tranx-activity-details">
                                                    <div className="tranx-activity">Sent Money to Oladele</div>
                                                    <small className="tranx-activity-status">Processed</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span>18902789012</span>
                                        </td>
                                        <td>
                                            <span>Transfer</span>
                                        </td>
                                        <td>
                                            <div className="flex-div">
                                                <div className="tranx-activity-details">
                                                    <div className="tranx-activity tranx-amount pending-transaction">- ₦10,000</div>
                                                    <small className="tranx-activity-status">20 Aug, 02:30 PM</small>
                                                </div>
                                                <img src={require("../../images/menu-circle.svg").default} alt="icon" className="table-menu-circle" />
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default MerchantView;
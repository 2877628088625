import React from "react";
import '../../css/modals.css';
import 'rsuite/dist/rsuite.min.css';

const BillTransactionItem = ({item, onClick=()=>{}}) =>{
    const {channel="",status="",reference="",title="",amount="", date="", trx_status="",new_balance, old_balance, merchant='Japtini',charge, service, transaction_type, phone} = item
    return(
        <tr className="thp-transaction-item" onClick={onClick}>
       <td>{date}</td>
       <td>{reference}</td>
       <td>{transaction_type}</td>
       <td>{phone}</td>
       <td>{service}</td>
       <td>{charge}</td>
       <td className="gray-txt">
            <span className="pending-transaction">NGN {amount}</span>
        </td>
       <td>{old_balance}</td>
       <td>{new_balance}</td>
        <td>
            <span className="status-pill pending-pill">{status}</span>
        </td>
       
        <td> <button className="primary-btn" onClick={()=>{
                        //  navigate('view')
                        }}>View</button></td>
        {/* <img src={require("../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  /> */}
    </tr>
    )
}

export default BillTransactionItem
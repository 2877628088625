import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loans:[],
  loan_balance:{}
 
}

export const loanSlice = createSlice({
  name: 'loan',
  initialState,

  reducers: {
    addLoan: ()=>{},
    payBackLoan: ()=>{},
    searchLoan: ()=>{},
    filterLoan: ()=>{},
    fetchLoan: ()=>{},
    Loans: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Loans,
  
  addLoan,
  payBackLoan,
  searchLoan,
  filterLoan,
  fetchLoan,
   
    } = loanSlice.actions

export default loanSlice.reducer
import "../../Invoice.css";

import InvoiceBuilderLeft from "./Left";
import InvoiceBuilderRight from "./Right";

const InvoiceBuilder = ({data}) => {
    return (
        <div className="ipib-container">
            <InvoiceBuilderLeft data={data} />
            <InvoiceBuilderRight data={data} />
        </div>
    );
};

export default InvoiceBuilder;
import "../css/modals.css"

import congratsImg from "../images/congrats.png"

import { useEffect } from "react";
import $ from "jquery";
// import M from "materialize-css"

const AddSuccessModal = ({title="Customer added successfully", desc="A new customer has been added. You can add more."}) => {
    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);

    return(
        <div id="customer-success-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <img src={require("../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />
                <div className="success-img-wrapper center">
                    <img src={congratsImg} alt="successful" className="success-img" />
                    <div className="success-modal-txt-content">
                        <h4 className="success-modal-title">{title}</h4>
                        <p className="success-modal-desc">
                            {desc}
                        </p>
                    </div>
                    <div className="pry-btn-wrapper">
                        <button className="pry-btn modal-close">Got it</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddSuccessModal;
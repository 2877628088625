//modal ID
import { NEW_PAYMENT_DATE_MODAL } from "../../../../../utility/modalIDs";

// Components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";

//CSS
import "./NewPaymentDate.css";

const NewPaymentDateModal = () => {
  
    return (
        <ModalWrapper modalID={NEW_PAYMENT_DATE_MODAL}>
            <h4>New Payment Date</h4>
            <small>When do you want this payment to occur?</small>

            <form action="#" id="loan-new-payment-date-form" onSubmit={e => e.preventDefault()} className="dashboard-form">
                <div className="input-field single-field">
                    <div className="input-field">
                        <input id="repayment_date_reminder" type="text" className="form-inp-field" />
                        <label htmlFor="repayment_date_reminder">Remind Me</label>
                    </div>
                </div>
            </form>

            <div className='npdm-warning'>
                <img src={require("../../../../../images/warning.svg").default} alt="close"/>
                <small>Note: The new date selected affects all other subsiquent paymemt dates</small>
            </div>

            <hr></hr>

            <div className="npdm-btns-container">
                <button className="pry-btn npdm-cancel-btn modal-close">
                    Cancel
                </button>
                <button className="pry-btn modal-close">
                    Okay
                </button>
            </div>
        </ModalWrapper>
    )
};

export default NewPaymentDateModal;
// import "../css/modals.css"
import "../css/dispute-modal.css"

import { useEffect } from "react";
import $ from "jquery";
import M from "materialize-css"

const ResolveDisputeModal = () => {

    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        const commentBox = document.querySelector("#comment-box");
        commentBox.addEventListener("keyup", function(event) {
            var commentBoxText = document.querySelector("#comment-box").value;
            // Number 13 is the "Enter" key on the keyboard
            if(event.keyCode === 13) {
                if(commentBoxText !== "") {
                    // Cancel the default action, if needed
                    event.preventDefault();
                    // Trigger the button element with a click
                    //   document.getElementById("myBtn").click();
                    $(".chat-box").prepend('<li class="chat-box-row myself"> <div> <div class="chat-row-title"> <b class="chat-sender">Me </b> <span class="chat-time"> · a second ago</span> </div> <div class="chat-row-message">' + commentBoxText + '</div> </li> </div>');
                }
            }
        });
    }, []);

    return(
        <div id="resolve-dispute-modal" className="modal dashboard-modal dispute-modal">
            <div className="modal-content">
                <h4 className="modal-block-title">Resolve transaction dispute</h4>
                <img src={require("../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />
                <div className="dispute-grid">
                    <div className="resolve-dispute-block">
                        <div className="disputes-details-wrapper">
                            <div className="disputes-details-block flex-div justify-content-btw">
                                <span>Created</span>
                                <b>Jun 3, 2021 03:02 PM </b>
                            </div>
                            <div className="disputes-details-block flex-div justify-content-btw">
                                <span>Category</span>
                                <b>Charge Back</b>
                            </div>
                            <div className="disputes-details-block flex-div justify-content-btw">
                                <span>Refund Requested</span>
                                <b>NGN 100.00 </b>
                            </div>
                        </div>
                        <div className="disputes-tab-wrapper">
                            <div className="row">
                                <div className="col s12 pad0">
                                    <ul className="tabs">
                                        <li className="tab">
                                            <a className="active" href="#accept-chargeback">Accept Chargeback</a>
                                        </li>
                                        <li className="tab">
                                            <a href="#decline-chargeback">Decline Chargeback</a>
                                        </li>
                                    </ul>
                                </div>
                                <form action="#" id="accept-chargeback" className="col s12">
                                    <div className="input-field">
                                        <div className="input-title">
                                            <b>
                                                Reason for accepting chargeback <span className="red-text">*</span>
                                            </b>
                                        </div>
                                        <textarea id="chargeback-reason" className="materialize-textarea form-inp-field"></textarea>
                                    </div>
                                    <div className="input-field">
                                        <div className="input-title">
                                            <b>
                                                Please upload receipts that show that you delivered value to this customer <span className="red-text">*</span>
                                            </b>
                                            <p>
                                                Upload all relevant document for this chargeback. Make sure they are legible and 
                                                contain specific details about the transaction to avoid losses. 
                                            </p>
                                        </div>
                                        <div className="file-field input-field">
                                            <input type="file" id="accept-chargeback-files" multiple />
                                            <div className="file-path-wrapper">
                                                <input className="file-path form-inp-field" type="text" placeholder="+ Choose File" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="submit-btn-wrapper flex-div justify-content-end">
                                        <button className="pry-btn">Accept Chargeback</button>
                                    </div>
                                </form>
                                <form action="#" id="decline-chargeback" className="col s12">
                                    <div className="input-field">
                                        <div className="input-title">
                                            <b>
                                                Reason for declining chargeback <span className="red-text">*</span>
                                            </b>
                                        </div>
                                        <textarea id="chargeback-reason" className="materialize-textarea form-inp-field"></textarea>
                                    </div>
                                    <div className="input-field">
                                        <div className="input-title">
                                            <b>
                                                Please upload receipts that show that you delivered value to this customer <span className="red-text">*</span>
                                            </b>
                                            <p>
                                                Upload all relevant document for this chargeback. Make sure they are legible and 
                                                contain specific details about the transaction to avoid losses. 
                                            </p>
                                        </div>
                                        <div className="file-field input-field">
                                            <input type="file" id="decline-chargeback-files" multiple />
                                            <div className="file-path-wrapper">
                                                <input className="file-path form-inp-field" type="text" placeholder="+ Choose File" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="submit-btn-wrapper flex-div justify-content-end">
                                        <button className="pry-btn decline-btn">Decline Chargeback</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="resolve-dispute-chat-block">
                        <div className="flex-div chat-block-header">
                            <img src={require("../images/chat.svg").default} alt="chat" className="chat-icon" />
                            <span>Conversation</span>
                        </div>
                        <div className="input-field">
                            <input type="text" id="comment-box" placeholder="Add a comment" className="form-inp-field" />
                        </div>
                        <ul className="chat-box">
                            <li className="chat-box-row myself">
                                <div>
                                    <div className="chat-row-title">
                                        <b className="chat-sender">Me </b>
                                        <span className="chat-time"> · 31 mins ago</span>
                                    </div>
                                    <div className="chat-row-message">
                                        Okay... We are working on it.
                                    </div>
                                </div>
                            </li>
                            <li className="chat-box-row admin-guys">
                                <div>
                                    <div className="chat-row-title">
                                        <b className="chat-sender">Payrail </b>
                                        <span className="chat-time"> · an hour ago</span>
                                    </div>
                                    <div className="chat-row-message">
                                        Please kindly respond to the dispute
                                    </div>
                                </div>
                            </li>
                            <li className="chat-box-row customer-guys">
                                <div>
                                    <div className="chat-row-title">
                                        <b className="chat-sender">Customer </b>
                                        <span className="chat-time"> · 1 day ago</span>
                                    </div>
                                    <div className="chat-row-message">
                                        I was debited twice for the same transaction. I will like to be refunded.
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ResolveDisputeModal;
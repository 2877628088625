import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customers:[{email:"aundearisekola@gmail.com",status:"Active",phone_number:"+2348159109387",first_name:"Toyeeb",last_name:"Atunde"}],
 
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,

  reducers: {
    addCustomer: ()=>{},
    searchCustomer: ()=>{},
    filterCustomer: ()=>{},
    fetchCustomer: ()=>{},
    Customers: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
      state.actionType = action.type
    },
    customersAdded: (state, action) => {
      state.customersAdded = true;
      state.actionType = action.type
    },
   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Customers,
  customersAdded,
  
  addCustomer,
  searchCustomer,
  filterCustomer,
  fetchCustomer,
   
    } = customerSlice.actions

export default customerSlice.reducer
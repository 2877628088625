import "./VirtualCardCreated.css";

import M from "materialize-css";

const VirtualCardCreated = () => {
    const CopyToClipboard = (selectedId) => {
        /* Get the text field */
        var copyText = document.getElementById(selectedId);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        
        /* Alert the copied text */
        M.toast({html: 'Copied'})
    }

    return(
        <div id="virtual-card-created-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <div className="virtual-card-heads-up-modal">
                    <div>
                        <img src={require("../../../../images/orange-card.svg").default} alt="orange-card-icon" /> 
                    </div>
                </div>

                <div className="ep-pin">
                    <h4>Awesome!</h4>
                </div>

                <div className="virtual-card-heads-up-modal-text">
                    See below your payrail virtual card details 
                </div>

                <div className="v-card-details-wrapper">
                    <div className="manage-cards-grid-div manage-cards-bb">
                        <span>Card Type</span>
                        <span>Virtual Card</span>
                    </div>

                    <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                        <span>Card Number</span>
                        <span id="card-pan">**** **** 0091</span>

                        <input type="text" id="card-num-2" defaultValue="1929393-0303" hidden />

                        <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("card-num-2")}>
                            <span>Click to copy</span>
                        </div>
                    </div>

                    <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                        <span>CVV</span>
                        <span id="card-id">***</span>

                        <input type="text" id="card-cvv-2" defaultValue="0303" hidden />

                        <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("card-cvv-2")}>
                            <span>Click to copy</span>
                        </div>
                    </div>

                    <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                        <span>Expiration date</span>
                        <span>June, 2023</span>
                    </div>

                    <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                        <span>Billing Address</span>
                        <span className="truncate">Block 32, 2C  Kunle Crescent</span>

                        <input type="text" id="billing-address-2" defaultValue="Block 32, 2C  Kunle Crescent" hidden />

                        <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("billing-address-2")}>
                            <span>Click to copy</span>
                        </div>
                    </div>

                    <div className="manage-cards-grid-div manage-cards-bb manage-cards-dr">
                        <span>ZipCode</span>
                        <span>100001</span>

                        <input type="text" id="zipcode-2" defaultValue="100001" hidden />

                        <div className="manage-cards-copy-div" onClick={() => CopyToClipboard("zipcode-2")}>
                            <span>Click to copy</span>
                        </div>
                    </div>
                </div>

                <div className="virtual-card-heads-up-modal-btn">
                    <button className="pry-btn modal-close">
                        Okay
                    </button>
                </div>
            </div>

        </div>
    )
}

export default VirtualCardCreated;
import React from "react";
import M from 'materialize-css';

import ModalWrapper from "../../../../components/UI/ModalWrapper";

import { VIEW_REQUEST_MODAL_ID } from "../../../../utility/modalIDs";
import { useDispatch } from "react-redux";
import { markAsPay, sendReminder } from "../../../../redux/reducers/invoice";
import { setLoading } from "../../../../redux/reducers/auth";

const ViewRequestModal = ({item={}}) => {
    const dispatch = useDispatch()
    const {customer="",amount="",requested="",invoice_number="",status=""} = item
    const sendInvoiceReminder = ()=>{
        dispatch(setLoading(true))
        dispatch(sendReminder(item.id))
    }
    const markInvoiceAsPaid = ()=>{
        dispatch(setLoading(true))
        dispatch(markAsPay(item.id))
    }
    const CopyToClipboard = (selectedId) => {
        /* Get the text field */
        var copyText = document.getElementById(selectedId);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        
        /* Alert the copied text */
        M.toast({html: 'API key copied'})
    }

    return (
        <ModalWrapper modalID={VIEW_REQUEST_MODAL_ID}>
            <h4 className="ivvrm-header">View Request</h4>
            <p className="ivvrm-sub-header">You sent a request of <span>NGN {amount}</span> to <span>{customer} </span></p>
            <div className="api-key-block">
                <div className="api-key-field flex-div">
                    <input className="api-key" id="test-key" readOnly value={`https://payrail.co/pay/${invoice_number}`} />
                    <img src={require("../../../../images/red-copy-icon.svg").default} alt="copy" className="copy-icon" onClick={() => CopyToClipboard("test-key")} />
                </div>
            </div>
            <div className="ivvrm-requests-status-box">
                <div className="ivvrm-request-status">
                    <span className="ivvrm-title">Status</span>
                    <span className="ivvrm-status">{status}</span>
                </div>
                <div className="ivvrm-request-status">
                    <span className="ivvrm-title">Sent</span>
                    <span className="ivvrm-status">{requested}</span>
                </div>
                <div className="ivvrm-request-status">
                    <span className="ivvrm-title">Code</span>
                    <span className="ivvrm-status">{invoice_number}</span>
                </div>
                <div className="ivvrm-request-status">
                    <span className="ivvrm-title">Offline Reference</span>
                    <span className="ivvrm-status">156277199273</span>
                </div>
            </div>

            <div className="ivvrm-btns">
                <button className="ivvrm-btn">Edit Amount</button>
                <button onClick={sendInvoiceReminder} className="ivvrm-btn">Send Reminder</button>
                <button onClick={markInvoiceAsPaid} className="ivvrm-btn">Mark As Paid</button>
                <div className="ivvrm-btn2 modal-close" >Cancel</div>
            </div>
        </ModalWrapper>
    );
};

export default ViewRequestModal;
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { ADD_MONEY_VIA_BANK_TRANSFER_MODAL_ID } from "../../../../utility/modalIDs";

import M from 'materialize-css';

const AddMoneyViaBankTransferModal = () => {
    const CopyToClipboard = (selectedId) => {
        /* Get the text field */
        var copyText = document.getElementById(selectedId);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        
        /* Alert the copied text */
        M.toast({html: 'Account number copied'})
    }
    return (
        <ModalWrapper modalID={ADD_MONEY_VIA_BANK_TRANSFER_MODAL_ID}>
            <h4 className="ivvrm-header">Bank Transfer</h4>

            <img src={require("../../../../images/dome-bank.svg").default} alt="icon" className="amvbtm-icon" />

            <div className="amvbtm-description">
                Transfer money to the account details below to fund your Payrail Wallet
            </div>

            <div className="amvbtm-info">
                <div className="amvbtm-info-title">Bank</div>
                <div className="amvbtm-info-value">Angala MFB</div>
            </div>

            <div className="amvbtm-info">
                <div className="amvbtm-info-title">Account Name</div>
                <div className="amvbtm-info-value">Ogbous Venture LTD</div>
            </div>

            <div className="amvbtm-info">
                <div className="amvbtm-info-title">Acount Number</div>
                <div className="amvbtm-info-value">
                    <span className="acct-num-txt">00912345686</span>
                    <input id="amvbtm-acct-num" readOnly defaultValue="00912345686" hidden />
                    <img src={require("../../../../images/red-copy-icon.svg").default} alt="copy" className="copy-icon" onClick={() => CopyToClipboard("amvbtm-acct-num")} />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AddMoneyViaBankTransferModal;
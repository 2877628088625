import M from "materialize-css";
import { useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import { PasswordField } from "../../passwordField";

const ChangeCardPinModal = () => {
    const [error] = useState({})
    const [value, setValue] = useState({})
    const handleInput = (e) =>{
        setValue({...value, [e.target.id]: e.target.value})
    }
    const [otp, setOTP] = useState('');
    useEffect (()=> {
        const elemsTabs = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTabs);
    }, []);
    return (
        <div id="change-card-pin-modal" className="modal dashboard-modal card-form-modal">
            <div className="modal-content">
                <img src={require("../../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />  

                <div className="card-pins-tabs-wrapper">
                    <ul class="tabs">
                        <li class="tab">
                            <a class="active" href="#reset-pin">Reset</a>
                        </li>
                        <li class="tab">
                            <a href="#change-pin">Change</a>
                        </li>
                    </ul>
                </div>

                <section id="reset-pin">
                    <h4 className="modal-block-title center">Enter the verification code</h4>  
                    <p className="modal-block-desc center">
                        Please enter the reset code sent to <br /> ******20099 and ****ola@gmail.com.
                    </p>                    
                    <form action="#" className="card-modal-form">
                        <div className="codes-wrapper">
                            <div id="verification-codes">
                                <div className="input-row row">
                                    <OtpInput
                                        value={otp}
                                        onChange={(val)=>{
                                            setOTP(val)
                                        }}
                                        numInputs={6}
                                        separator={<span> </span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="submit-btn-wrapper flex-div justify-content-btw">
                            <div className="resend-otp-wrapper">
                                Did’nt get OTP? <span className="resend-trigger pry-color">Resend</span>
                            </div>
                            <div className="btn-side">
                                <button type="button" className="submit-btn pointer pry-btn modal-close modal-trigger" data-target="create-card-pin-modal">Create PIN</button>
                            </div>
                        </div>
                    </form>
                </section>

                <section id="change-pin">
                    <h4 className="modal-block-title center">
                        Change Card PIN, <span> Olamide</span>
                    </h4>  
                
                    <form action="#" className="card-modal-form">
                        <div className="row">
                            <PasswordField 
                                type="password"
                                value={value.old_password} 
                                label="Old Password" 
                                onChange={handleInput} 
                                error={error['old_password']}
                                id={{input: "old_password", div: "old-password-row"}} 
                                classname={{div:"input-field col s12 mag-d-10 pad0" , error:"error-text", input:"inp-field", }} 
                            />
                            <PasswordField 
                                type="password"
                                value={value.new_password} 
                                label="New Password" 
                                onChange={handleInput} 
                                error={error['new_password']}
                                id={{input: "new_password"}} 
                                classname={{div:"input-field col s12 mag-d-10 pad0" , error:"error-text", input:"inp-field", }} 
                            />
                            <PasswordField 
                                type="password"
                                value={value.confirm_password} 
                                label="Confirm New Password" 
                                onChange={handleInput} 
                                error={error['confirm_password']}
                                id={{input: "confirm_password"}} 
                                classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0" , error:"error-text", input:"inp-field", }} 
                            />
                        </div>
                        <div className="submit-btn-wrapper flex-div justify-content-end">
                            <div className="btn-side">
                                <button type="button" className="submit-btn pointer pry-btn modal-close modal-trigger" data-target="change-card-pin-success">Create new password</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default ChangeCardPinModal

import $ from "jquery";
import "../Transactions.css";
import  React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../../redux/reducers/auth";
import debounce from "debounce";

import Wrapper from "../../../components/UI/Wrapper";
import { getRecentTransaction, searchOngoingTransaction } from "../../../redux/reducers/wallet";
import Transaction from "../../../components/OngoingTransaction";
import { useNavigate, useNavigation } from "react-router-dom";

const Ongoing = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {auth = {}, wallet={}, customer} = useSelector(state=>state);
    
    const { ongoing_transaction=[]} = wallet
    const [search, setSearch] = useState("");

    const handleSearch = (text) =>{
       dispatch(searchOngoingTransaction(text))

    }

    const fetchRecent = () =>{
        dispatch(setLoading(true));
        dispatch(getRecentTransaction())
        dispatch(setLoading(false));
    }
    
    const searchHandler = useCallback(debounce((text)=>handleSearch(text), 2000), []);

    useEffect(()=>{
        if (search !== "") {
            searchHandler(search)
        }else{
            if (search !==null) {
                fetchRecent()
            }
        }
    },[search])

    const handleInput = (e) =>{
        setSearch(e.target.value)
    }

    

    const changeRoute = () => {
        window.location.href = "/dashboard/transaction-single-view"
    }
    return (
        <>
            <div className="page-bar-wrapper flex-div justify-content-btw thp-no-mt">
                <div className="page-bar-left flex-div" >
                    <div className="thp-account-select">
                        <div className="select-field input-field single-field">
                            <select id="total-revenue">
                                <option value="Show all">All accounts</option>
                                <option value="Show New">All accounts</option>
                                <option value="Show Returning">All accounts</option>
                            </select>
                            <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="thp-caret-icon" />
                        </div>
                    </div>
                    <div className="filter-block flex-div">
                        <div className="filter-dropdown-trigger">
                            <img src={require('../../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                            <span>Filters</span>
                        </div>


                        <ul id="filter-dropdown" className="filter-dropdown-content">

                            <div className="arrow-up"></div>

                            <div className="select-field input-field single-field rel">
                                <select id="status-1">
                                    <option value="Show all">Successful</option>
                                    <option value="Show New">Failed</option>
                                    <option value="Show Returning">Pending</option>
                                </select>
                                <label htmlFor="status-1" className="active">Status </label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="select-field input-field single-field rel">
                                <select id="interval">
                                    <option value="Show all">Show all</option>
                                    <option value="Show New">Show New</option>
                                    <option value="Show Returning">Show Returning</option>
                                </select>
                                <label htmlFor="interval" className="active">Channel</label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="select-field input-field single-field rel">
                                <select id="total-revenue">
                                    <option value="Show all">Show all</option>
                                    <option value="Show New">Show New</option>
                                    <option value="Show Returning">Show Returning</option>
                                </select>
                                <label htmlFor="total-revenue" className="active">Date Period</label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="input-field single-field">
                                <input id="first_name" type="text" className="form-inp-field" />
                                <label htmlFor="first_name">Customer ID/Email</label>
                            </div>

                            <div className="input-field single-field">
                                <input id="first_name" type="text" className="form-inp-field" />
                                <label htmlFor="first_name">Payment Page ID</label>
                            </div>

                            <p>
                                <label>
                                <input type="checkbox"  class="filled-in" checked="checked" />
                                <span>Save as default filter</span>
                                </label>
                            </p>

                            <div className="filter-btns-wrapper">
                                <button className="filter-btn pry-btn">Filter</button>
                                <button className="filter-reset-btn">
                                    <span>Reset to default</span>
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div className="vertical-divider">
                        <hr />
                    </div>
                    <div className="bar-search-block rel">
                        <img src={require('../../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                        <input onChange={handleInput} type="search" className="bar-search" placeholder="Search Customers" />
                    </div>
                </div>
                <div className="page-bar-right flex-div">
                    <div className="export-trigger-block flex-div">
                        <span>Export CSV</span>
                        <img src={require('../../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                    </div>
                </div>
            </div>

            <Wrapper className="sakam full-height" outlined grey fullHeight>
                <table id="customers-table">
                    <thead>
                        <tr>
                            <th>CHANNEL</th>
                            <th>STATUS</th>
                            <th>REFERENCE</th>
                            <th>TRANSACTION TYPE</th>
                            <th>TRANSACTION AMOUNT</th>
                            <th>MERCHANT</th>
                            <th>DATE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                    {ongoing_transaction.map(((item)=>{
                                        
                                        return(
                                            <Transaction item={item} onClick={()=>{
                                                navigate('/dashboard/transaction-view')
                                            }} />
                                        )
                                    })
                                )
                            }
                        {/* <tr className="thp-transaction-item" onClick={changeRoute}>
                            <td>
                                <img src={require('../../../images/phone-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>Pending</td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="pending-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/wallet-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>Successful</td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="success-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/building-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>Failed</td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="failed-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/wallet-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>Pending</td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="pending-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/wallet-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>Failed</td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="failed-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/building-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>Successful</td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="success-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr> */}
                    </tbody>
                </table>
            </Wrapper>
        </>
    );
};

export default Ongoing;
import { NavLink } from "react-router-dom";
import congratsIcon from "../../images/congrats.png";
const FundSuccessModal = () => {
    return(
        <div id="fund-success-modal" className="modal dashboard-modal card-success-modal">
            <div className="modal-content">
                <img src={require("../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />
                <div className="success-img-wrapper center">
                    <img src={congratsIcon} alt="successful" className="success-img" />
                    <div className="success-modal-txt-content">
                        <h4 className="success-modal-title">Awesome!</h4>
                        <p className="success-modal-desc">
                            <span>Your payrail debit card has been funded successfuly</span> <br />
                            <NavLink to="/dashboard/transactions" className="pry-color">
                                <u>Transaction Page</u>
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundSuccessModal;
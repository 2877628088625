import "../../css/settings.css";
import "./Settings.css";

import { useEffect } from "react";
// import $ from "jquery";
import M from "materialize-css";

import ProfileBlock from "./ProfileTab";
import SecurityTab from "./SecurityTab";
import PreferenceTab from "./PreferenceTab";
import ComplianceTab from "./ComplianceTab";
import APIKeysAndWebHooks from "./APIKeys";

const Settings = () => {
    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);
    return(
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Settings </span>
                        {/* <small className="page-label-tag">3</small> */}
                    </h4>
                </div>

                <div className="row tab-wrapper">
                    <div className="col s12 pad0">
                        <ul className="tabs">
                            <li className="tab">
                                <a className="active flex-div" href="#profile-block">
                                    <span>Profile</span> 
                                </a>
                            </li>
                            <li className="tab">
                                <a className="flex-div" href="#security-block">
                                    <span>Security</span> 
                                </a>
                            </li>
                            {/* <li className="tab">
                                <a className="flex-div" href="#preference-block">
                                    <span>Preference</span> 
                                </a>
                            </li> */}
                            {/* <li className="tab">
                                <a className="flex-div" href="#compliance-block">
                                    <span>Compliance</span> 
                                </a>
                            </li> */}
                            {/* <li className="tab">
                                <a className="flex-div" href="#api-keys-block">
                                    <span>API Keys & Webhooks</span> 
                                </a>
                            </li> */}
                        </ul>
                    </div>

                    <div id="profile-block" className="col s12 pad0">
                        <ProfileBlock />
                    </div>

                    <div id="security-block" className="col s12 pad0">
                        <SecurityTab />
                    </div>

                    {/* <div id="preference-block" className="col s12 pad0 sppt-width">
                        <PreferenceTab />
                    </div> */}

                    {/* <div id="compliance-block" className="col s12 pad0">
                        <ComplianceTab />
                    </div>

                    <div id="api-keys-block" className="col s12 pad0">
                        <APIKeysAndWebHooks />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Settings;
import ColoredWrapper from "../../components/UI/coloredWrapper/coloredWrapper";
import ReactECharts from 'echarts-for-react';
import { NavLink } from "react-router-dom";

const LoansOverview = () => {
    const pieOption_1 = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          right: 0,
          bottom: 0,
          orient: 'vertical'
        },
        grid: {
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
        },
        series: [
          {
            name: 'loan status',
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '10'
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 2, name: 'Total repayment days' },
              { value: 1, name: 'Elapsed days' }
            ],
            color: ['#777777', '#DA6A2F']
          }
        ]
    };

    const repayments = [
        {
            date: "August 12, 2021",
            time: "01: 50 PM",
            amount: "₦416,666.67",
            installmentNumber: "5th"
        },
        {
            date: "July 12, 2021",
            time: "01: 50 PM",
            amount: "₦416,666.67",
            installmentNumber: "4th"
        },
        {
            date: "June 15, 2021",
            time: "04: 50 AM",
            amount: "₦416,666.67",
            installmentNumber: "3rd"
        },
        {
            date: "May 12, 2021",
            time: "10: 10 AM",
            amount: "₦416,666.67",
            installmentNumber: "2nd"
        },
        {
            date: "April 12, 2021",
            time: "12: 50 PM",
            amount: "₦416,666.67",
            installmentNumber: "1st"
        },
    ]

    return (
        <div className="page-content-wrapper">
            <div className="container">  
                <div className="page-title-wrapper loan-overview-header-container">
                    <h4 className="page-content-title flex-div">
                        <span>Loan</span>
                    </h4>
                    <div className="lp-trigger"> 
                        <NavLink to="/dashboard/loan-preference" className="flex-div">
                            Loan Preference
                            <div className="">
                                <img src={require("../../images/caret-down.svg").default} alt="caret" />
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className="loan-overview-content">
                    <div>
                        <ColoredWrapper outlined>
                            <div className="loan-status-header">
                                <span>My Loan status</span>
                                <span>Active</span>
                            </div>
                            <div className="loan-pie-chart">
                                    <ReactECharts option={pieOption_1} />
                            </div>

                            <div className="loan-chart-hr"></div>

                            <div className="loan-status-bottom">
                                <div>
                                    <small>Loan Left</small>
                                    <span>- ₦2,353,333.33</span>
                                </div>

                                <div className="pointer">
                                    <img src={require("../../images/plus-icon.svg").default} alt="caret" className="hover-scale"  />
                                    <span>Payback loan</span>
                                </div>
                            </div>
                        </ColoredWrapper>

                        <ColoredWrapper outlined classes="repayment-alert-container">
                            <div className="repayment-alert">
                                <div className="flex-div">
                                    <img src={require("../../images/loan-bell.svg").default} alt="loan-bell" className="loan-bell"  />

                                    <div>
                                        <div className="repayment-alert-bold-text"><span>Payment coming up</span></div>
                                        <div><small>₦416,666.67 is due in 5 days time</small></div>
                                    </div>
                                </div>

                                <div className="on-track">
                                    <img src={require("../../images/star.svg").default} alt="caret"  />
                                    <span>ON TRACK</span>
                                </div>

                                <div className="on-track-close">
                                    <img src={require("../../images/+.svg").default} alt="close icon" className="track-status-close-icon"  />
                                </div>
                            </div>
                        </ColoredWrapper>
                    </div>

                    <div>
                        <ColoredWrapper outlined classes="loan-stat-unpaid-top">
                            <div>
                                <span><small>LOAN STATUS</small></span>
                                <span><small>out of ₦2,770,000</small></span>
                            </div>

                            <div>
                                <span>Total paid so far</span>
                                <span>₦800,333.67</span>
                            </div>
                        </ColoredWrapper>

                        <ColoredWrapper outlined classes="loan-stat-unpaid-bottom">
                            <div>
                                <span><small>REPAYMENT</small></span>
                                <span><small>5 LEFT (OUT OF 8)</small></span>
                            </div>

                            <div>
                                <ul>
                                    {
                                        repayments.map((payment, index) => (
                                            <li key={index} className="loan-repayment-list-item">
                                                <div>
                                                    <img src={require("../../images/arrow-diagonal-icon.svg").default} alt="caret"  />
                                                    <div>
                                                        <div>
                                                            <span>{payment.date}</span>
                                                            <span>{payment.amount}</span>
                                                        </div>
                                                        <div>
                                                            <small>{payment.time}</small>
                                                            <small>{payment.installmentNumber} payment</small>
                                                        </div>
                                                    </div>
                                                    <img src={require("../../images/2-circles.svg").default} alt="caret"  />
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </ColoredWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoansOverview;
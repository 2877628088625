import { useState } from "react";
import OtpInput from 'react-otp-input';

import { NavLink } from "react-router-dom";

const FundTransactionPinModal = () => {
    const [otp, setOTP] = useState('');

    return(
        <div id="fund-transaction-pin-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <div className="ep-back-btn-container">
                    <img src={require("../../images/caret-down.svg").default} alt="caret"/>
                    <span>Quick Transfer</span>
                </div>
                <img src={require("../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />    
            
                <div className="ep-pin">
                    <h4>Enter transaction PIN</h4>
                    <small>Enter your 4-digit Payrail PIN to approve this transfer</small>
                </div>

                <div className="ep-pin-box-container">
                    <div>
                        <div id="debit-card-codes">
                            <div className="input-row row">
                                <OtpInput
                                    value={otp}
                                    onChange={(val)=>{
                                        setOTP(val)
                                    }}
                                    numInputs={4}
                                    separator={<span> </span>}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ep-pin-reset">
                    <small>
                        Get trouble? Reset your PIN in 
                        <NavLink to="/dashboard/settings" className="pry-color"> account security settings</NavLink>
                    </small>
                </div>

                <button className="pending-btn pry-btn ep-pin-okay-btn modal-close modal-trigger" data-target="fund-success-modal">
                    Okay
                </button>
            </div>

        </div>
    )
}

export default FundTransactionPinModal;
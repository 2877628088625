import logo from '../images/payrail-logo.svg';

import "../css/status-page.css"

import { BrowserRouter as Routes, Route  } from "react-router-dom";

import SuccessfulPage from '../status-pages/successful-page';
import FailurePage from '../status-pages/failure-page';
import CancelPage from '../status-pages/cancel-page';

const StatusPageView = () => {

    return (
      
            <div>
                <div id="status-main-wrapper">
                    <div className="auth-nav-header">
                        <div className="container">
                            <a href="/login">
                                <img src={logo} alt="Payrail" className="brand-img" />
                            </a>
                        </div>
                    </div>

                    <section className="container">
                        <Routes>
                            <Route  path="/success" element={ <SuccessfulPage />}/>  
                            <Route  path="/failure" element={ <FailurePage />}/>   
                            <Route  path="/cancel" element={ <CancelPage />}/>
                        </Routes>
                    </section>


                    <footer id="auth-footer">
                        <div className="flex-div container">
                            <div className="trusted-block flex-div">
                                <img src={require("../images/trust.svg").default} alt="shield-icon" className="footer-item-icon" />
                                <span>Trusted by over <span className="pry-color">100k Business</span> like you!</span>
                            </div>

                            <div className="copyright-div">
                                © Angala Financial Technologies Limited. All Rights Reserved.
                            </div>
                        </div>
                    </footer>
                </div>

            </div>
       
    )
}

export default StatusPageView;
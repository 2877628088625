//modal ID
import { LOAN_APPLICATION_CONFIRMATION_MODAL } from "../../../../../utility/modalIDs";

// Components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";

//CSS
import "./LoanConfirmation.css";

const LoanConfirmationModal = () => {
    return (
        <ModalWrapper modalID={LOAN_APPLICATION_CONFIRMATION_MODAL}>
            <div className="lcm-icon-container">
                <div className="lcm-icon-wrapper">
                    <img src={require("../../../../../images/bag-2.svg").default} /> 
                </div>
            </div>

            <h4 className="lcm-prompt-title">Please confirm</h4>

            <div className="lcm-prompt-text">
                <span>You are about to apply for Payrail Loan of</span> <br></br>
                <span>₦2,500,000.00</span>
            </div>
            <div className="lcm-btns">
                <button className="pry-btn lcm-cancel-btn modal-close">
                    Cancel
                </button>
                <button className="pry-btn modal-close">
                    Okay
                </button>
            </div>
        </ModalWrapper>
    )
};

export default LoanConfirmationModal;
import React from "react";

import "./coloredWrapper.css";

const coloredWrapper = (props) => {
    const styles = {
        background: "#F9F9F9",
        borderRadius: "16px"
    }
    
    if (props.outlined) {
        styles["border"] = "1px solid rgba(119, 119, 119, 0.2)";
    }

    if (props.fullHeight) {
        styles["height"] = "100%";
    }

    
    return (
        <div className={`coloredWrapper ${props.classes|| ""}`} style={styles} >
            {props.children}
        </div>
    );
}

export default coloredWrapper;
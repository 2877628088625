import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import M from 'materialize-css';

export const InputField = ({
  isloading,
  type,
  id = '',
  classname,
  label,
  onChange,
  error = '',
  children,
  placeholder,
  ...otherprop
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const handleInputFocus = () => {
      const targetLabel = $(inputRef.current).parent().find('label');
      targetLabel.addClass('active');
    };

    const handleInputBlur = () => {
      const targetLabel = $(inputRef.current).parent().find('label');
      if ($(inputRef.current).val()) {
        targetLabel.addClass('active');
      } else {
        targetLabel.removeClass('active');
      }
    };

    $(inputRef.current).on('input', handleInputFocus);
    $(inputRef.current).on('blur', handleInputBlur);
   
    if (otherprop.value) {
       
        const targetLabel = $(inputRef.current).parent().find('label');
        targetLabel.addClass('active');
      }
    return () => {
      $(inputRef.current).off('input', handleInputFocus);
      $(inputRef.current).off('blur', handleInputBlur);
    };
  });

  return (
    <div className={`${classname.div} ${error ? 'status-error' : ''}`} id={id.div}>
      <input
        id={id.input}
        onChange={onChange}
        type={type}
        
        className={classname.input}
        name={id.input}
        placeholder={placeholder}
        
        ref={inputRef}
        {...otherprop}
      />
      <label htmlFor={id.input} className={classname.label}>
        {label}
      </label>
      <small className={classname.error}>{error}</small>
      {children}
    </div>
  );
};

import "../Invoice.css";

import InvoiceBuilder from "./InvoiceBuilder";

const PaymentRequestDetails = ({ goback, data }) => {
    return (
        <div>
            <div className="manage-cards-goback" onClick={() => goback("main")}>
                <div className="manage-cards-goback-icon">
                    <img src={require("../../../../images/caret-down.svg").default} alt="caret"  />
                </div>
                <span>Back</span>
            </div>

            <InvoiceBuilder data={data} />
        </div>
    );
};

export default PaymentRequestDetails;
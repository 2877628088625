// import {
//    configureStore,
//    combineReducers,
//    getDefaultMiddleware 
//   } from '@reduxjs/toolkit'
// import createSagaMiddleware from "redux-saga"
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import { rootSaga } from '../sagas/rootSaga'
// import rootReducer from '../reducers'

// const sagaMiddleware = createSagaMiddleware();
// const persistConfig = {
//    key: 'root',
//    storage,
//    whitelist: ['auth'],
//  };
//  const persistedReducer = persistReducer(persistConfig, rootReducer);

//  const store = configureStore({
//     reducer: persistedReducer,
//     middleware: [...getDefaultMiddleware({thunk:false}), sagaMiddleware]
    
// });

// sagaMiddleware.run(rootSaga);

// export default store;

import {
   configureStore,
   combineReducers,
   getDefaultMiddleware,
 } from "@reduxjs/toolkit";
 import { persistReducer } from "redux-persist";
 import storage from "redux-persist/lib/storage";
 import rootReducer from "../reducers";
 import thunk from "redux-thunk";
 
 const persistConfig = {
   key: "root",
   storage,
   whitelist: ["auth"],
 };
 const persistedReducer = persistReducer(persistConfig, rootReducer);
 
 const store = configureStore({
   reducer: persistedReducer,
   middleware: [...getDefaultMiddleware({ thunk: false }), thunk],
 });
 
 export default store;
 
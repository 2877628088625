// import React, { useState } from "react";

// css
import "./Merchants.css";

// Components
import Wrapper from "../../../components/UI/Wrapper";
import InviteATeamMateModal from "./Modals/InviteATeamMate"
import $ from "jquery";

import M from "materialize-css";
// Modal ID
import { INVITE_A_TEAM_MATE_MODAL_ID  } from "../../../utility/modalIDs";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Merchants = ({goToAdminCreator}) => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const handleInput = (e) =>{
        setSearch(e.target.value)
    }
   

    return (
        <>
           
            <div className="page-bar-wrapper flex-div justify-content-btw thp-no-mt">
                <div className="page-bar-left flex-div" >
                    <div className="thp-account-select">
                        <div className="select-field input-field single-field">
                            <select id="total-revenue">
                                <option value="Show all">All accounts</option>
                                <option value="Show New">All accounts</option>
                                <option value="Show Returning">All accounts</option>
                            </select>
                            <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="thp-caret-icon" />
                        </div>
                    </div>
                    <div className="filter-block flex-div">
                        <div className="filter-dropdown-trigger">
                            <img src={require('../../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                            <span>Filters</span>
                        </div>


                        <ul id="filter-dropdown" className="filter-dropdown-content">

                            <div className="arrow-up"></div>

                            <div className="select-field input-field single-field rel">
                                <select id="status-1">
                                    <option value="Show all">Successful</option>
                                    <option value="Show New">Failed</option>
                                    <option value="Show Returning">Pending</option>
                                </select>
                                <label htmlFor="status-1" className="active">Status </label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="select-field input-field single-field rel">
                                <select id="interval">
                                    <option value="Show all">Show all</option>
                                    <option value="Show New">Show New</option>
                                    <option value="Show Returning">Show Returning</option>
                                </select>
                                <label htmlFor="interval" className="active">Channel</label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="select-field input-field single-field rel">
                                <select id="total-revenue">
                                    <option value="Show all">Show all</option>
                                    <option value="Show New">Show New</option>
                                    <option value="Show Returning">Show Returning</option>
                                </select>
                                <label htmlFor="total-revenue" className="active">Date Period</label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="input-field single-field">
                                <input id="first_name" type="text" className="form-inp-field" />
                                <label htmlFor="first_name">Customer ID/Email</label>
                            </div>

                            <div className="input-field single-field">
                                <input id="first_name" type="text" className="form-inp-field" />
                                <label htmlFor="first_name">Payment Page ID</label>
                            </div>

                            <p>
                                <label>
                                <input type="checkbox"  class="filled-in" checked="checked" />
                                <span>Save as default filter</span>
                                </label>
                            </p>

                            <div className="filter-btns-wrapper">
                                <button className="filter-btn pry-btn">Filter</button>
                                <button className="filter-reset-btn">
                                    <span>Reset to default</span>
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div className="vertical-divider">
                        <hr />
                    </div>
                    <div className="bar-search-block rel">
                        <img src={require('../../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                        <input onChange={handleInput} type="search" className="bar-search" placeholder="Search Merchant" />
                    </div>
                </div>
                <div className="page-bar-right flex-div">
                    <div className="export-trigger-block flex-div">
                        <span>Export CSV</span>
                        <img src={require('../../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                    </div>
                </div>
            </div>
           

            <Wrapper grey outlined  className="tt-table-container table-wrapper">
                <table id="customers-table">
                    <thead>
                        <tr>
                            <th>MERCHANT ID</th>
                            <th>USERNAME</th>
                            <th>STATUS</th>
                            <th>LAST LOGIN</th>
                            <th>STATE/LGA</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>AGL0000002</td>
                            <td>Jolly Mensa</td>
                           
                            <td>Activated</td>
                           
                            <td>27-12-2021 06:48 PM</td>
                            <td>Adamawa Damesa</td>
                            <td> <button className="primary-btn" onClick={()=>{
                         navigate('view')
                        }}>View</button></td>
                            <td> <button className="primary-btn" onClick={()=>{
                           
                        }}>Edit</button></td>
                        </tr>

                       
                    </tbody>
                </table>
            </Wrapper>

            <InviteATeamMateModal />
        </>
    );
}

export default Merchants;
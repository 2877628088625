import { useState } from "react";

//modal ID
import { CHOOSE_REPAYMENT_OPTION_MODAL, REPAYMENT_OPTION_MODAL } from "../../../../../utility/modalIDs";

// Components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";
import RepaymentOptionModal from '../RepaymentOption';
import LoanRepaymentSuccessModal from "../SuccessModal";

//CSS
import "./ChooseRepaymentOptions.css";

// consts
import { EXTRA, EARLY, FULL } from "../../../utils/repaymentOptions";

const ChooseRepaymentOptionsModal = () => {
    const [ currentOption, setCurrentOption ] = useState(EXTRA);

    const setOption = (option) => {
        setCurrentOption(option);
    }
    return (
        <>
            <ModalWrapper modalID={CHOOSE_REPAYMENT_OPTION_MODAL}>
                <h4>Choose a loan repayment option </h4>
                <h5 className='crom-grey-text crom-option-med-text'>3 payback options to ease your repayment</h5>
                <div className="crom-container">
                    <div
                        className='crom-option-item crom-option-item-bb modal-trigger modal-close'
                        data-target={REPAYMENT_OPTION_MODAL}
                        onClick={() => setOption(EARLY)}
                    >
                        <img src={require("../../../../../images/orange-clock.svg").default} alt="icon" className="repayment-modal-icon" />
                        <div className='crom-option-right'>
                            <h4 className='crom-option-text'>Pay before the due date</h4>
                            <small className='crom-grey-text'>Pay ₦416,666.667 before April 13, 2022, with no fee</small>
                            <img src={require("../../../../../images/caret-down.svg").default} alt="caret" className="crom-caret-right-icon" />
                        </div>
                    </div>
                    <div
                        className='crom-option-item crom-option-item-bb modal-trigger modal-close'
                        data-target={REPAYMENT_OPTION_MODAL}
                        onClick={() => setOption(EXTRA)}
                    >
                        <img src={require("../../../../../images/orange-plus.svg").default} alt="icon" className="repayment-modal-icon" />
                        <div className='crom-option-right'>
                            <h4 className='crom-option-text'>Pay off a little extra</h4>
                            <small className='crom-grey-text'>You will still pay your monthly amount as usual</small>
                            <img src={require("../../../../../images/caret-down.svg").default} alt="caret" className="crom-caret-right-icon" />
                        </div>
                    </div>
                    <div
                        className='crom-option-item modal-trigger modal-close'
                        data-target={REPAYMENT_OPTION_MODAL}
                        onClick={() => setOption(FULL)}
                    >
                        <img src={require("../../../../../images/orange-mark.svg").default} alt="icon" className="repayment-modal-icon" />
                        <div className='crom-option-right'>
                            <h4 className='crom-option-text'>Pay your loan in full</h4>
                            <small className='crom-grey-text'>Pay back ₦1,677,083.33</small>
                            <img src={require("../../../../../images/caret-down.svg").default} alt="caret" className="crom-caret-right-icon" />
                        </div>
                    </div>
                </div>
            </ModalWrapper>
            <RepaymentOptionModal option={currentOption}/> 
            <LoanRepaymentSuccessModal option={currentOption} />
        </>
    )
};

export default ChooseRepaymentOptionsModal;
const BulkTransfer = ({getStarted}) => {
    return (
        <>
            <div className="page-title-wrapper btp-mb">
                <h4 className="page-content-title">
                    <span>Introducing: Bulk transfer by Payrail</span>
                </h4>
            </div>

            <div className="btp-csv">
                <div className="btp-width">
                    <div className="btp-text">Create and arrange your CSV/Excel file in the format below and follow other instructions.</div>
                    <img src={require("../../images/create-csv.svg").default} alt="icon" />

                    <div className="btp-align-right">
                        <h6 className="btp-header">Load your CSV file</h6>
                        <p className="btp-text"><span>Upload your payment list</span> file  by clicking “Upload CSV” then ensure the list are validated and there is also
                            an option to remove invalid details
                        </p>
                        <button className="pry-btn" onClick={()=> getStarted("transfer information")} >
                            Get started
                        </button>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 className="btp-header2">Create your CSV file</h6>
                        <p className="btp-text2">The list of account you want to make 
                            payment to should follow that <span>format</span> for easy validation of account details.</p>
                    </div>
                    <img src={require("../../images/upload-csv.svg").default} alt="icon" />
                </div>
            </div>
        </>
    );
};

export default BulkTransfer;
import React, { useEffect }  from "react";
import M from "materialize-css";

import ModalWrapper from "../../../../../components/UI/ModalWrapper";
import SimpleInvoiceTab from "./SimpleInvoiceTab";
import ProfessionalInvoiceTab from "./ProfessionalInvoice";

import { REQUEST_PAYMENT_MODAL_ID } from "../../../../../utility/modalIDs";

const RequestPaymentModal = ({ goToDetails }) => {
    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);
    }, []);
    return (
        <ModalWrapper modalID={REQUEST_PAYMENT_MODAL_ID}>
            <h4 className="ivvrm-header">Request Payment</h4>
            <div className="row tab-wrapper ap-fullHeight2">
                <div className="col s12 pad0">
                    <ul className="tabs">
                        <li className="tab">
                            <a className="active flex-div" href="#profile-block">
                                <span>Professional Invoice</span> 
                            </a>
                        </li>
                        <li className="tab">
                            <a className="flex-div" href="#security-block">
                                <span>Simple Invoice</span> 
                            </a>
                        </li>
                    </ul>
                </div>
                <div id="profile-block" className="col s12 pad0 ap-fullHeight">
                    <ProfessionalInvoiceTab goToDetails={goToDetails} />
                </div>
                <div id="security-block" className="col s12 pad0">
                    <SimpleInvoiceTab/>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RequestPaymentModal;
// import { useEffect } from 'react';

export const PasswordField = ({type, value = "", id ="", classname, label, onChange, error = ""}) =>{

    function passwordToggle() {
        var x = document.getElementById(id.input);
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
    }

    return(
        <div className={`${classname.div} ${error? "status-error": ""}`} id={id.div}>
            <input 
                type={type}
                id={id.input} 
                onChange={onChange} 
                className={classname.input} 
                name={id.input}
            />
            <label for={id.input}>{label}</label>
            <small className={classname.error}>{error}</small>
            <span className="show-password" onClick={passwordToggle}>Show</span>
        </div>
    )

}

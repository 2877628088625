import { useEffect } from "react";
import PropTypes from "prop-types";
import M from "materialize-css";
// Components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";
import PinModal from "../../../../../components/PinModal";
import { InputField } from "../../../../../components/InputField";
import { SelectField } from "../../../../../components/selectField";
//CSS
import "./RepaymentOption.css";
// consts
import { EXTRA, EARLY, FULL } from "../../../utils/repaymentOptions";
// modal IDs
import { PIN_MODAL, CHOOSE_REPAYMENT_OPTION_MODAL, REPAYMENT_OPTION_MODAL, LOAN_REPAYMENT_SUCCESS_MODAL } from "../../../../../utility/modalIDs";

const EarlyPaybackModal = ({ option  }) => {
    useEffect (()=> {
        var elemSelect = document.getElementById('account_select');
        M.FormSelect.init(elemSelect);

    }, []);

    const OptionModalIcon = {
        [EARLY]: "orange-clock",
        [EXTRA]: "orange-plus",
        [FULL]: "orange-mark"
    }

    const OptionModalText = {
        [EARLY]: {
            top: "Pay your loan before the due date",
            bottom: "Pay ₦416,666.667 before April 13, 2022, with no fee"
        },
        [EXTRA]: {
            top: "Pay off a little extra",
            bottom: "You will still pay your monthly amount as usual"
        },
        [FULL]: {
            top: "Pay your loan in full",
            bottom: "Pay back ₦1,677,083.33"
        }
    }

    return (
        <>
            <ModalWrapper modalID={REPAYMENT_OPTION_MODAL}>
                {/* <img src={require(`../../../../../images/${OptionModalIcon[option]}.svg`).default} alt="icon" className="rom-top-icon" /> */}
                <img src={require(`../../../../../images/orange-clock.svg`).default} alt="icon" className="rom-top-icon" />
                <h4 className='rom-text-center'>{ OptionModalText[option].top }</h4>
                <h5 className='rom-text-width rom-grey-text rom-option-med-text rom-text-center'>{ OptionModalText[option].bottom }</h5>
                <form action="#" id="loan-early-payback-form" onSubmit={e => e.preventDefault()} className="dashboard-form">
                    <SelectField 
                        items={[
                            {key: "Main Account", value: "Main Account"},
                        ]}
                        type="text" 
                        label="Select Account" 
                        id={{select: "account_select"}} 
                        classname={{div:"select-field input-field single-field rel", label:"active", img: "inp-caret-icon" }} 
                    />

                    <div className="input-field single-field">
                        <InputField 
                            type="text" 
                            label="Amount" 
                            defaultValue={"₦416,666.667"}
                            id={{input: "amount"}}
                            readOnly
                            classname={{div:"input-field read-only-field", error:"error-text", input:"form-inp-field"}} 
                        />
                    </div>
                    <button className="pry-btn rom-btn modal-trigger modal-close" data-target={PIN_MODAL}>
                        Continue
                    </button>
                </form>
            </ModalWrapper>
            <PinModal modalToOpenID={CHOOSE_REPAYMENT_OPTION_MODAL} successModalID={LOAN_REPAYMENT_SUCCESS_MODAL} backTo="Repayment Options" />
        </>
    )
};

EarlyPaybackModal.propTypes = {
    option: PropTypes.oneOf([ EXTRA, FULL, EARLY])
};

export default EarlyPaybackModal;
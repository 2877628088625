import { useState } from "react";
import OtpInput from 'react-otp-input';
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { RECEIVE_OTP_VIA_AUTHENTICATOR_APP_MODAL_ID } from "../../../../utility/modalIDs";

const OTPViaAuthenticatorApp = () => {
    const [otp, setOTP] = useState("");

    return (
        <ModalWrapper modalID={RECEIVE_OTP_VIA_AUTHENTICATOR_APP_MODAL_ID} >
            <h4 className="ivvrm-header">Enable two-step authentication</h4>

            <div className="otpvem-text">
                Whenever you sign in to your Payrail account, you will need to enter both
                your password and a security code sent to your device.
            </div>

            <div className="otpvaam-box">
                <span className="otpvaam-instruction">
                    1. Download either of these apps and add your payrail account
                </span>
                <div className="otpvaam-d-flex">
                    <div className="otpvaam-flex-item">
                        <img src={require("../../../../images/google-auth.svg").default} alt="icon"/>
                        <span>Google Authenticator</span>
                    </div>
                    <div className="otpvaam-flex-item">
                        <img src={require("../../../../images/authy-app.svg").default} alt="icon"/>
                        <span>Authy App</span>
                    </div>
                </div>
            </div>

            <div className="otpvaam-box">
                <span className="otpvaam-instruction">
                2. Scan this QR code and enter the 6 digit OTP displayed on the app
                </span>
                <div className="otpvaam-d-grid">
                    <div className="otpvaam-flex-item">
                        <img src={require("../../../../images/qr-code.svg").default} alt="icon" className="otpvaam-qr-code-img" />
                    </div>
                    <form id="verification-form" className="otpvaam-flex-item otpvaam-flex-col">
                        <span className="otpvaam-otp-text">Enter the 6 digit security code</span>
                        <div id="verification-codes" className="otp-codes">
                            <OtpInput
                                value={otp}
                                onChange={(val)=>{
                                    setOTP(val)
                                }}
                                numInputs={6}
                                spmarator={<span> </span>}
                            />
                        </div>        
                    </form>
                </div>
            </div>

            <hr />

            <div className="ipib-draft-btns otpvaam-btn">
                <button className="pry-btn">
                    Complete Setup
                </button>
            </div>
        </ModalWrapper>
    )
};

export default OTPViaAuthenticatorApp;
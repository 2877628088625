import M from "materialize-css";
import $ from "jquery";

import { useEffect,useCallback, useState } from "react";

import AddNewCustomerModal from "../../components/addNewCustomerModal";
import AddSuccessModal from "../../components/sucessfulModal";
import CustomerItem from "../../components/CustomerItem";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../redux/reducers/auth";
import debounce from "debounce";
import { fetchCustomer, searchCustomer } from "../../redux/reducers/customer";

const Customers = () => {
    const dispatch = useDispatch()
    const {auth = {}, wallet={}, customer} = useSelector(state=>state);
    const { customers={}} = customer
    const [search, setSearch] = useState("");

    const handleSearch = (text) =>{
       dispatch(searchCustomer(text))

    }

    const getCustomer = () =>{
        dispatch(setLoading(true));
        dispatch(fetchCustomer())
        dispatch(setLoading(false));
    }
    const searchHandler = useCallback(debounce((text)=>handleSearch(text), 2000), []);

    useEffect(()=>{
        if (search !== "") {
            searchHandler(search)
        }else{
            if (search !==null) {
                getCustomer()
            }
        }
    },[search])

    const handleInput = (e) =>{
        setSearch(e.target.value)
    }

    useEffect (()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $(".filter-dropdown-trigger").click(function(){
            $(".filter-dropdown-content").slideToggle();
        });
    }, []);

    return(
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Customers </span>
                        <small className="page-label-tag">3</small>
                    </h4>
                </div>

                <div className="page-bar-wrapper flex-div justify-content-btw">
                    <div className="page-bar-left flex-div">
                        <div className="filter-block flex-div">
                            <div className="filter-dropdown-trigger">
                                <img src={require('../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                                <span>Filters</span>
                            </div>


                            <ul id="filter-dropdown" className="filter-dropdown-content">

                                <div className="arrow-up"></div>

                                <div className="input-field multiple-fields rel">
                                    <span className="multiple-fields-label">Display customers with </span>

                                    <div className="rel grid-row-2">
                                        <div className="rel">
                                            <select id="range-type">
                                                <option value="At least">At least</option>
                                                <option value="At Most">At Most</option>
                                            </select>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>
                                        <hr className="abs-vertical-line" />
                                        <div className="rel">
                                            <select id="range-num">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">6</option>
                                            </select>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>
                                    </div>
                                    <hr className="filter-horizontal-line" />
                                    <div className="rel">
                                        <select id="range-category">
                                            <option value="Transaction">Transaction</option>
                                            <option value="Transfers">Transfers</option>
                                        </select>
                                        <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                    </div>
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="date-period">
                                        <option value="All Time">All Time</option>
                                        <option value="This Week">This Week</option>
                                        <option value="This Month">This Month</option>
                                    </select>
                                    <label htmlFor="date-period" className="active">Date Period</label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="new-returning">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="new-returning" className="active">New or returning </label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="filter-btns-wrapper">
                                    <button className="filter-btn pry-btn">Filter</button>
                                    <button className="filter-reset-btn">
                                        <span>Reset to default</span>
                                    </button>
                                </div>
                            </ul>
                        </div>
                        <div className="vertical-divider">
                            <hr />
                        </div>
                        <div className="bar-search-block rel">
                            <img src={require('../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                            <input onChange={handleInput} type="search" className="bar-search" placeholder="Search Customers" />
                        </div>
                    </div>
                    <div className="page-bar-right flex-div">
                        <div className="add-btn-wrapper">
                            {/* <button className="add-trigger-btn modal-trigger" data-target="add-new-customer-modal">
                                &#43; Add Customers
                            </button> */}
                        </div>
                        <div className="export-trigger-block flex-div">
                            <span>Export CSV</span>
                            <img src={require('../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                        </div>
                    </div>
                </div>

                <div className="table-wrapper">
                    <table id="customers-table">
                        <thead>
                            <tr>
                                <th>STATUS</th>
                                <th>CUSTOMER EMAIL</th>
                                <th>FIRST NAME</th>
                                <th>LAST NAME</th>
                                <th>PHONE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.map(((item)=>{
                                        
                                        return(
                                            <CustomerItem {...item} />
                                        )
                                    })
                                )
                            }
                           
                            {/* <tr>
                                <td>Pending</td>
                                <td className="gray-txt">ambroseali@gmail.com</td>
                                <td>Ambrose</td>
                                <td>Isreal</td>
                                <td>+23408178998723</td>
                            </tr>
                            <tr>
                                <td>Successful</td>
                                <td className="gray-txt">lialia@yahoo.co.uk</td>
                                <td>Lilian</td>
                                <td>Leveticus</td>
                                <td>+23408178998723</td>
                            </tr>
                            <tr>
                                <td>Successful</td>
                                <td className="gray-txt">yandesef@gmail.com</td>
                                <td>Yewande</td>
                                <td>Yusouf</td>
                                <td>No Phone Number</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>

            <AddNewCustomerModal />
            <AddSuccessModal />
        </div>
    )
}

export default Customers;
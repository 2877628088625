import React, { useState } from "react";
import OtpInput from 'react-otp-input';

const FundVerificationCodeModal = () => {
    const [otp, setOTP] = useState('');

    return(
        <div id="fund-verification-code-modal" className="modal dashboard-modal card-form-modal">
            <div className="modal-content">
                <img src={require("../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />  

                <h4 className="modal-block-title">Enter the verification code</h4>  
            
                <form action="#" className="card-modal-form">
                    <div className="codes-wrapper">
                            <div id="verification-codes">
                                <div className="input-row row">
                                    <OtpInput
                                        value={otp}
                                        onChange={(val)=>{
                                            setOTP(val)
                                        }}
                                        numInputs={6}
                                        separator={<span> </span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="submit-btn-wrapper flex-div justify-content-btw">
                            <div className="resend-otp-wrapper">
                                Did'nt get OTP? <span className="resend-trigger pry-color">Resend</span>
                            </div>
                            <div className="btn-side">
                                <button type="button" className="submit-btn pointer pry-btn modal-close modal-trigger" data-target="fund-transaction-pin-modal">Verify</button>
                            </div>
                        </div>
                </form>
            </div>
        </div>
    )
}

export default FundVerificationCodeModal
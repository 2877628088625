import { createSlice } from '@reduxjs/toolkit'

const initialState = {

  user:{},
  loading: false,
  server_error: null,
  message:null,
  device_account: null,
  token: true

}

export const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    setLoading: (state, action) => {
      state.actionType = action.type
      state.loading = action.payload
    },
    logout: (state, action) => {
      state._authorizationCredential = null;
      state.user = {}

    },
    handleError: (state, action) => {
     
      state.server_error = action.payload
      state.actionType = action.type
      state.loading = false
      state.loginCredential = null;
      
    },
    handleMessage: (state, action) => {
     
      state.message = action.payload
    },
    loginAction: ()=>{},
    login: (state, action) => {
      state._authorizationCredential = action.payload._authorizationCredential;
      state.user = {...state.user, ...action.payload};
      state.server_error = null;
      state.loginSuccessful = true;
      state.loginCredential = null;
    },
    loginCredential: (state, action) => {
      state.loginCredential = action.payload;
     
    },
    signupAction:(state)=>{
     state.loading = true
    },
    signup: (state, action) => {
      state.user =  {...state.user, ...action.payload};
      state.server_error = null;
      state.signupSuccessful = true;
      state.actionType = action.type
    },
    verifyAction:()=>{},
    verifyOTP: (state, action) => {
     
      state.optVerificationSuccessful = true
      state.server_error = null;
      state.actionType = action.type
    },
    identityAction:()=>{},
    identitySignup: (state, action) => {
      state.user = {...state.user, ...action.payload}
      state.identitySignupSuccessful = true
      state.server_error = null;
      state.actionType = action.type
    },
    bvnAction:()=>{},
    bvnVerification: (state, action) => {
      console.log("__bvnVerificationSuccessful__", action)
      state.bvnVerificationSuccessful = true
      state.server_error = null;
      state.actionType = action.type

    },
    forgetPasswordAction:()=>{},
    forgetPassword: (state, action) => {
     
      state.forgetPasswordSuccessful = true
      state.server_error = null;
      state.actionType = action.type

    },
  },
})

// Action creators are generated for each case reducer function
export const { 
      setLoading,
      handleError, 
      handleMessage,
      login, 
      signup, 
      verifyOTP,
      identitySignup,
      bvnVerification,
      forgetPassword,
      logout,
      loginCredential,

        bvnAction,
        identityAction,
        verifyAction,
        signupAction,
        loginAction,
        forgetPasswordAction
    } = authSlice.actions

export default authSlice.reducer
import "../css/modals.css"
import M from 'materialize-css';
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { useEffect, useState } from "react";
import $ from "jquery";
import { setLoading } from "../redux/reducers/auth"
import { InputField } from './InputField';
import { useDispatch, useSelector } from "react-redux"
import { SelectField } from "./selectField"
import { addCustomer, fetchCustomer } from "../redux/reducers/customer";
// import M from "materialize-css"

/**
 * Constructs form validation object for user old signup using Yup library
 * @param password : User password.
 */
 const formSchema = Yup.object().shape({
    email: Yup.string().required("Required").email(),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
})
const AddNewCustomerModal = () => {
    const dispatch = useDispatch()
    const  {   customer, auth } = useSelector(state=> state)
    const  {   actionType, customersAdded } = customer
    const {loading} = auth
    const [error, setError] = useState({})
    

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
    resolver: yupResolver(formSchema),
    })

    useEffect(()=>{
        setError(errors)
    },[errors])

    /**
     * onSubmit function to trigger form submission for phone number registration.
     * @param email : User email address.
     * @param phone : User phone number.
     * @param password : Account password.
     */
     const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){

            dispatch(setLoading(true))
            dispatch(addCustomer(data))
            
            }
        }

        useEffect(()=>{
            if (customersAdded && actionType === "customer/customersAdded" && loading) {
                
                dispatch(fetchCustomer())
                dispatch(setLoading(false))
                const elemsModal2 = document.querySelector('#customer-success-modal');
                M.Modal.getInstance(elemsModal2).open();

                const elemsModal1 = document.querySelector('#add-new-customer-modal');
                M.Modal.getInstance(elemsModal1).close();

            }

        },[customersAdded,actionType])

    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
        var elemsModal = document.querySelectorAll('.customer-success-modal');
        M.Modal.init(elemsModal);
    }, []);

    return(
        <div id="add-new-customer-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <h4 className="modal-block-title">Add New Customer</h4>
                <img src={require("../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />
                <form className="dashboard-form " onSubmit={handleSubmit(onSubmit)}>

                 <InputField 
                        type="text" 
                        label="First Name" 
                        {...register('first_name',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['first_name']? error['first_name'].message: ""} 
                        id={{input: "first_name"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                    />

                    <InputField 
                        type="text" 
                        label="Last Name" 
                        {...register('last_name',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['last_name']? error['last_name'].message: ""} 
                        id={{input: "last_name"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                    />

                    <InputField 
                        type="text" 
                        label="Email Address" 
                        {...register('email',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['email']? error['email'].message: ""} 
                        id={{input: "email"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                    />

                    <SelectField 
                        items={[
                            {key: "Nigeria (+234)", value: "Nigeria"},
                            {key: "Ghana (+233)", value: "Ghana"},
                            {key: "USA (+1)", value: "USA"}
                        ]}
                        type="text" 
                        label="Country" 
                        {...register('countries',{ required: true })}
                        onChange={e => setValue(e.target.id, e.target.value)} 
                        id={{select: "countries"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0 country-list-wrapper", label:"active", }} 
                    />
                    <InputField 
                        type="tel" 
                        label="Phone number" 
                        {...register('phone',{ required: true })}
                        onChange={e => setValue(e.target.id, e.target.value)} 
                        error={error && error['phone']? error['phone'].message: ""}
                        id={{input: "phone"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    
                    

                    <div className="submit-btn-wrapper flex-div justify-content-btw">
                        <span className="cancel-modal modal-close">Cancel</span>
                        <button type="submit"  className="pry-btn submit-btn pointer" >Save</button>
                        {/* <button className="pry-btn submit-btn modal-close modal-trigger" data-target="customer-success-modal">Save</button> */}
                    </div>
                </form>
            </div>
        </div>

    )
}

export default AddNewCustomerModal;
import React, { useState } from "react";
import UploadBulkBeneficiariesModal from "./Modals/UploadBulkBeneficiary";
import ValidateBeneficiaries from "./Modals/ValidateBeneficiaries.js";
import TransferInformationForm from "./TransferInformationForm";
import EnterPin from "./EnterPin";
import SchedulePayment from "./Modals/SchedulePayment";
import RecurringPayment from "./Modals/RecurringPyament";
import BulkTransferSuccess from "./Modals/TransferSuccess";

const TransferInformation = ({goBack}) => {
    const [show, setShow ] = useState("form-content");

    let content = null;

    if (show === "form-content") {
        content = <TransferInformationForm goToPin={setShow} />
    } else {
        content = <EnterPin />
    }

    const handleGoBack = () => {
        if (show === "enter-pin-content") {
            setShow("form-content");
        } else {
            goBack("main")
        }
    }

    return (
        <>
            <div className="manage-cards-goback" onClick={() => handleGoBack()}>
                <div className="manage-cards-goback-icon">
                    <img src={require("../../../images/caret-down.svg").default} alt="caret"  />
                </div>
                <span>Back</span>
            </div>

            <div className="tip-d-flex justify-content-center">
                {/* <div>PROGRESS BAR SHOULD COME HERE</div> */}

                { content }
            </div>

            <UploadBulkBeneficiariesModal />
            <ValidateBeneficiaries />
            <SchedulePayment />
            <RecurringPayment />
            <BulkTransferSuccess />
        </>
    );
};

export default TransferInformation;
import React, { useEffect } from "react";
import { InputField } from "../../../../../components/InputField";
import "./index.css"

import M from "materialize-css";

const CustomTimeAndDatePicker = () => {
    useEffect(() => {
        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);
        var elemsTimepicker = document.querySelectorAll('.timepicker');
        M.Timepicker.init(elemsTimepicker);
    }, []);
    return (
        <div className="time-date-wrapper flex-div justify-content-btw">
            <InputField 
                type="text" 
                id={{input: "tdw-date"}} 
                classname={{div:"input-field datepicker-field", input:"inp-field center datepicker", }} 
                defaultValue="May 1,  2022"
            />
            <InputField 
                type="text" 
                id={{input: "tdw-time"}} 
                classname={{div:"input-field timepicker-field", input:"inp-field center timepicker", }} 
                defaultValue="12:00pm"
            />
        </div>
    );
};

export default CustomTimeAndDatePicker;;
import { useEffect } from "react";
import M from "materialize-css";

import PropTypes from 'prop-types';

const ModalWrapper = ({ modalID, backTo = "", modalToOpenID = "", showBackBtn = false , children, className}) => {
    useEffect (()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);
    }, [modalID]);

    return(
        <div id={modalID} className={`modal dashboard-modal ${className ? className: ""}`}>
            <div className="modal-content">
                {/* back button  */}
                { showBackBtn ? 
                    <div className="ep-back-btn-container modal-trigger modal-close" data-target={modalToOpenID}>
                        <img src={require("../../../images/caret-down.svg").default} alt="caret"/>
                        <span>{backTo}</span>
                    </div>
                    : null
                }

                {/* close icon  */}
                <img src={require("../../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />

                {/* modal content */}
                { children }
            </div>
        </div>
    )
};

ModalWrapper.propTypes = {
    modalID: PropTypes.string,
    showBackBtn: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element),
};

export default ModalWrapper;
import { useState } from "react";

import Wrapper from "../../../../../../components/UI/Wrapper";

const InvoiceBuilderLeft = () => {
    const [ itemAdded, SetItemAdded] = useState(false);
    const [ noOfItemsAdded, SetNoOfItemsAdded] = useState(0);


    const AddItemHandler = () => {
        SetNoOfItemsAdded(noOfItemsAdded + 1);

        if (!itemAdded) {
            SetItemAdded(true);
        }
    };

    const AddInvoiceInput = () => {
        return (
            <>
                <div className="input-field single-field">
                    <input id="invoice_item" type="text" className="form-inp-field" />
                    <label htmlFor="invoice_item">Invoice Item</label>
                </div>

                <div className="ipib-item-added-bottom">
                    <div className="input-field single-field">
                        <input id="quantity" type="text" className="form-inp-field" />
                        <label htmlFor="quantity">Quantity</label>
                    </div>

                    <div className="input-field single-field">
                        <input id="unit_cost" type="text" className="form-inp-field" />
                        <label htmlFor="unit_cost">Unit Cost</label>
                    </div>
                </div>
            </>
        )
    };
    return (
        <Wrapper className="ipib-container-item">
                <div className="ipib-invoice-no">
                    INVOO1
                </div>
                <div className="ipib-item">
                    <div>
                        FROM: <span className="ipib-item-bold">Ogbous and Sons</span> 
                    </div>

                    <div>
                        SENT ON -
                    </div>
                </div>

                <div className="ipib-item">
                    <div>
                        TO: <span className="ipib-item-bold">jumbleadio@gmail.com </span> 
                    </div>

                    <div>
                        DUE DATE -
                    </div>
                </div>

                {
                    itemAdded ? 
                        <form action="#" className="dashboard-form modal-form ipib-item-added-box" onSubmit={e => e.preventDefault()}>
                            {AddInvoiceInput()}
                        </form>
                        : null
                }
                

                <div className="ipib-add-btn" onClick={() => AddItemHandler()}>
                    + Add Item
                </div>

                <div className="ipib-sub-total">
                    <div>Sub Total</div>
                    <div>-</div>
                </div>
                <div className="ipib-total">
                    <div>Total</div>
                    <div>-</div>
                </div>
            </Wrapper>
    )
};

export default InvoiceBuilderLeft;
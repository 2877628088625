
const { default: Wrapper } = require("../../components/UI/Wrapper")
const { Loader } = require("../../components/loader")
const terminalStatus = {
    'active': "green-text",
    'suspended': "amber-text",
    'damaged': "red-text",
};
const POSRequest= ({posRequests, fetchingTerminal, viewRequest}) => {

    return(
        <Wrapper grey outlined  className="tt-table-container table-wrapper">
                                    <table id="customers-table">
                                        <thead>
                                            <tr>
                                                <th>REQUEST DATE</th>
                                                <th>NAME</th>
                                                <th>PHONE</th>
                                                <th>POS TYPE</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
    
    
                                            {
                                                fetchingTerminal? <Loader/> :
                                                posRequests.length? posRequests.map(item=>{
                                                    const {terminalId, terminalAlias, bankAccount, status = ''} = item
                                                    return(
                                                        <tr>
                                                            <td>{terminalId}</td>
                                                            <td>{terminalAlias}</td>
                                                            <td>{bankAccount}</td>
                                                            <td>
                                                                {status == ''}
                                                                <span className={terminalStatus[status]}>{status}</span>
                                                            </td>
                                                            <td><button className="primary-btn" onClick={()=>{
                                                                viewRequest()
                                                            }}>View</button></td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                           
                                        </tbody>
                                    </table>
                                </Wrapper>
    )

}

export default POSRequest
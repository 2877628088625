import congratsIcon from "../../../images/congrats.png";
const ChangeCardPinSuccess = () => {
    return(
        <div id="change-card-pin-success" className="modal dashboard-modal card-success-modal">
            <div className="modal-content">
                <img src={require("../../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />
                <div className="success-img-wrapper center">
                    <img src={congratsIcon} alt="successful" className="success-img" />
                    <div className="success-modal-txt-content">
                        <h4 className="success-modal-title">Successful!</h4>
                        <p className="success-modal-desc">
                            Your card PIN has been changed successfuly, you can now transact with the new PIN
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeCardPinSuccess;
import "./FreezeDebitCardModal.css"

import { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';

import $ from "jquery";
import { NavLink } from "react-router-dom";

const FreezeDebitCardModal = () => {
    const [otp, setOTP] = useState('');

    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);

    return(
        <div id="card-freeze-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <div className="ep-back-btn-container">
                    <img src={require("../../../../images/caret-down.svg").default} alt="caret"/>
                    <span>Quick Transfer</span>
                </div>
                <img src={require("../../../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />    
            
                <div className="ep-pin">
                    <h4>Freeze Debit Card</h4>
                    <small>Enter your 4-digit Payrail PIN to Freeze Debit Card</small>
                </div>

                <div className="ep-pin-box-container">
                    <div>
                        <div id="debit-card-codes">
                            <div className="input-row row">
                                <OtpInput
                                    value={otp}
                                    onChange={(val)=>{
                                        setOTP(val)
                                    }}
                                    numInputs={4}
                                    separator={<span> </span>}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ep-pin-reset">
                    <small>
                        Get trouble? Reset your PIN in 
                        <NavLink to="/dashboard/settings" className="pry-color"> account security settings</NavLink>
                    </small>
                </div>

                <button className="pending-btn pry-btn modal-trigger ep-pin-okay-btn modal-close " data-target="card-frozen-success-modal">
                    Okay
                </button>
            </div>

        </div>
    )
}

export default FreezeDebitCardModal;
import '../css/styles.css';
import '../css/fonts.css';
import 'materialize-css/dist/css/materialize.min.css';
import 'material-design-icons/iconfont/material-icons.css';

import '../css/dashboard.css';

import { BrowserRouter as Router, Routes , Route } from "react-router-dom";

import $ from 'jquery';

// import AuthView from './layouts/AuthView';
// import DashboardView from './layouts/DashboardView';

import Home from '../dashboard-pages/Home/Index';
import GettingStarted from '../dashboard-pages/Home/GettingStarted';

import SideBar from '../components/sideBar';
import TopNav from '../components/topNav';
import PendingTask from '../dashboard-pages/Home/PendingTask';

import Business from '../dashboard-pages/Business/Index';
// import Customers from '../dashboard-pages/Business/customers';
// import EmptyCustomers from '../dashboard-pages/Business/emptyCustomers';
import Disputes from '../dashboard-pages/Business/disputes';
// import Subscriptions from '../dashboard-pages/Business/subscriptions';
// import SubAccounts from '../dashboard-pages/Business/SubAccount';
import Invoice from '../dashboard-pages/Business/Invoice';

import Settings from '../dashboard-pages/Settings';
import BillPayments from '../dashboard-pages/BillPayments';
import Card from '../dashboard-pages/Card';
import Loan from '../dashboard-pages/Loan';
import Referral from '../dashboard-pages/Referral';
import Administration from '../dashboard-pages/Administration';
import Transfer from '../dashboard-pages/Transfer';
import Transactions from '../dashboard-pages/Transactions';
import TransactionSingleView from '../dashboard-pages/Transactions/TransactionSingleView';

// import SuccessStatus from './components/successStatus';
// import ErrorStatus from './components/errorStatus';
import LoansOverview from '../dashboard-pages/Loan/Overview';
import LoanPreference from '../dashboard-pages/Loan/Preference';
import RoleCreator from '../dashboard-pages/Administration/RoleCreator';
import WithdrawalPage from '../dashboard-pages/Withdrawal';
import PosPage from '../dashboard-pages/Pos';
import Customers from '../dashboard-pages/Business/customers';
import EmptyCustomers from '../dashboard-pages/Business/emptyCustomers';
import Subscriptions from '../dashboard-pages/Business/subscriptions';
import Merchant from '../dashboard-pages/Merchant';
import MerchantView from '../dashboard-pages/Merchant/Merchants/merchant-view';
import Reconciliation from '../dashboard-pages/Reconcilation';
import BillTransactions from '../dashboard-pages/BillTransactions';
import BillTransactionView from '../dashboard-pages/BillTransactions/BillTransactionView';
import Revenue from '../dashboard-pages/Revenue';
import Tickets from '../dashboard-pages/Ticket';
import CreateNewTicket from '../dashboard-pages/Ticket/CreateNewTicket';
import ViewTicketModal from '../dashboard-pages/Ticket/viewTicketModal';
import BulkNotification from '../dashboard-pages/BulkNotification';
import Report from '../dashboard-pages/Report';
import Audits from '../dashboard-pages/Audits';

function App() {

    $(window).scroll(function () {
        $('.auth-nav-header').toggleClass('scrolled-nav', $(this).scrollTop() > 50);
    });
    

    return (
      
        <div className="App">
            {/*---==== Contents Sections  ====---*/}
                {/* <Routes >
                    <Route exact path="/">
                        <AuthView />
                    </Route>
                    <Route exact path="/dashboard">
                        <DashboardView />
                    </Route>
                </Routes > */}
            {/*---==== end of Contents Sections  ====---*/}

            <div id="body-wrapper">
                {/* <!--=== SideBar Section ===--> */}
                <SideBar/>
                {/* <!--=== end of SideBar Section ===--> */}

                {/* <!--=== Main Content ===--> */}
                <section id="content-body">
                    {/* <!--=== Top-Nav Section ===--> */}
                    <TopNav />
                    {/* <!--=== end of Top-Nav Section ===--> */}

                    <div className="page-content-block-wrapper">
                        <Routes >
                            {/*---==== Contents Sections  ====---*/}
                            <Route path="/home" element={<Home/>} />
                               
                           
                            <Route path="/getting-started"  element={<GettingStarted/>} />
                             
                            <Route path="/pending-tasks"  element={<PendingTask/>} />
                             
                            <Route path="/business"  element={<Business/>} />

                            <Route path="/merchants"  element={<Merchant/>} />
                            <Route path="/merchants/view"  element={<MerchantView/>} />

                              
                            <Route path="/merchants/customers"  element={<Customers/>} />
                                
                            {/* <Route path="/sub-accounts"  element={<SubAccounts/>} /> */}
                               
                            <Route path="/merchants/invoice"  element={<Invoice/>} />
                              
                            <Route path="/empty-customers"  element={<EmptyCustomers/>} />
                             
                            <Route path="/disputes"  element={<Disputes/>} />

                            <Route path="/reconciliation"  element={<Reconciliation/>} />
                              
                            <Route path="/subscriptions"  element={<Subscriptions/>} />
                               
                            <Route path="/settings"  element={<Settings/>} />
                              
                            <Route path="/referrals"  element={<Referral/>} />
                              
                            <Route path="/bill-payments"  element={<BillTransactions/>} />
                            <Route path="/bill-transaction-view"  element={<BillTransactionView />} />
                               
                            <Route path="/card"  element={<Card/>} />
                               
                            <Route path="/administration"  element={<Administration/>} />
                            <Route path="/role-creator"  element={<RoleCreator/>} />
                            
                              
                            <Route path="/transfer"  element={<Transfer/>} />
                               
                            <Route path="/transactions"  element={<Transactions/>} />
                            <Route path="/transaction-view"  element={<TransactionSingleView />} />
                            <Route path="/revenue"  element={<Revenue/>} />
                            <Route path="/reports"  element={<Report/>} />
                            <Route path="/support/audits"  element={<Audits/>} />
                            <Route path="/tickets"  element={<Tickets/>} />
                            <Route path="/tickets/create-new-ticket"  element={<CreateNewTicket/>} />
                            <Route path="/support/bulk-notification"  element={<BulkNotification/>} />
                            <Route path='/ticket/view' element={<ViewTicketModal />} />
                            <Route path="/loans"  element={<Loan/>} />
                            <Route path="/loans-overview"  element={<LoansOverview/>} />
                            <Route path="/loan-preference"  element={<LoanPreference/>} />

                            <Route path="/withdrawal"  element={<WithdrawalPage/>} />
                            <Route path="/pos"  element={<PosPage/>} />
                               
                            {/*---==== end of Contents Sections  ====---*/}
                        </Routes >
                    </div>
                </section>
                {/* <!--=== end of Main Content ===--> */}
            </div>
        </div>
       
    );
}

export default App;

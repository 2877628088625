import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  terminal:[],
 
}

export const cardSlice = createSlice({
  name: 'terminal',
  initialState,

  reducers: {
    addCard: ()=>{},
    requestCard: ()=>{},
    filterCard: ()=>{},
    fetchCard: ()=>{},
    Cards: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Cards,
  
  addCard,
  requestCard,
  filterCard,
  fetchCard,
   
    } = cardSlice.actions

export default cardSlice.reducer
import React, { useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import $ from "jquery";
import { NavLink } from "react-router-dom";
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { ENTER_PIN_MODAL_ID, QUICK_TRANSFER_MODAL_ID, SEND_MONEY_SUCCESS_MODAL_ID } from "../../../../utility/modalIDs";

const EnterPinModal = () => {
    const [otp, setOTP] = useState('');

    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);

    return (
        <ModalWrapper modalID={ENTER_PIN_MODAL_ID} showBackBtn backTo="Quick Transfer" modalToOpenID={QUICK_TRANSFER_MODAL_ID}>
            <div className="ep-pin">
                    <h4>Enter transaction PIN</h4>
                    <small>Enter your 4-digit Payrail PIN to approve this transfer</small>
                </div>

                <div className="ep-pin-box-container">
                    <div>
                        <div id="verification-codes">
                            <div className="input-row row">
                                <OtpInput
                                    value={otp}
                                    onChange={(val)=>{
                                        setOTP(val)
                                    }}
                                    numInputs={4}
                                    separator={<span> </span>}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ep-pin-reset">
                    <small>
                        Get trouble? Reset your PIN in 
                        <NavLink to="/dashboard/settings" className="pry-color"> account security settings</NavLink>
                    </small>
                </div>

                <button className="pending-btn pry-btn modal-trigger ep-pin-okay-btn modal-close " data-target={SEND_MONEY_SUCCESS_MODAL_ID}>
                    Okay
                </button>
        </ModalWrapper>
    );
};

export default EnterPinModal;
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import M from "materialize-css";
import PersonalSetingsForm from "./PersonalSettingsForm";
import CompanySetingsForm from "./CompanySettingsForm";
import BankSettingsForm from "./BankSettingsForm";

const ProfileBlock = () => {
    const {auth = {}} = useSelector(state=>state);
    const {_authorizationCredential, onboardingResponse, phone} = auth.user
    const {subscriberAccountName,kyc  } = _authorizationCredential._certificate
    const {firstname,lastname,email} = kyc || onboardingResponse
    useEffect(()=> {
        $("#user-pix-filepath").change(function(){
            var uploadedImageSrc1 = window.URL.createObjectURL(this.files[0]);
            $("#personal-profile-edit-form .upload-logo").hide();
            $("#personal-profile-edit-form .uploaded-img-div").show();
            $("#uploaded-img").attr("src", uploadedImageSrc1);
        });
        $("#business-logo-filepath").change(function(){
            var uploadedImageSrc2 = window.URL.createObjectURL(this.files[0]);
            $("#business-profile-edit-form .upload-logo").hide();
            $("#business-profile-edit-form .uploaded-img-div").show();
            $("#uploaded-img-2").attr("src", uploadedImageSrc2);
        });

        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    },[]);

    const toggleProfileBlock = (hiddenSection, displayedSection) => {
        document.getElementById(hiddenSection).style.display = "none";
        document.getElementById(displayedSection).style.display = "block";
    };

    return (
        <div className="profile-settings-wrapper">
            <div id="profile-block-wrapper">
                <div className="personal-profile-block psw-block">
                    <div className="profile-block-header flex-div justify-content-btw">
                        <div className="profile-block-title">Personal Settings</div>
                        <div className="edit-profile-block" onClick={() => toggleProfileBlock("profile-block-wrapper", "edit-personal-profile-block")}>
                            <span>Edit</span>
                        </div>
                    </div>
                    <div className="profile-details-row flex-div">
                        <div className="profile-avatar-wrapper">
                            <img src={require('../../../images/oo-avatar.svg').default} alt="oo-avatar" className="profile-block-avatar" />
                        </div>
                        <div className="profile-name-block">
                            <div className="profile-name">{`${firstname} ${lastname}`}</div>
                            <div className="profile-email">{email || phone || "mideola@gmail.com"}</div>
                            <div className="profile-status">Admin</div>
                        </div>
                    </div>
                </div>
                {/* <div className="business-profile-block psw-block">
                    <div className="profile-block-header flex-div justify-content-btw">
                        <div className="profile-block-title">Company Settings</div>
                        <div className="edit-profile-block" onClick={() => toggleProfileBlock("profile-block-wrapper", "edit-business-profile-block")}>
                            <span>Edit</span>
                        </div>
                    </div>
                    <div className="profile-details-row flex-div">
                        <div className="profile-avatar-wrapper">
                            <img src={require('../../../images/os-avatar.svg').default} alt="oo-avatar" className="profile-block-avatar" />
                        </div>
                        <div className="profile-name-block">
                            <div className="profile-name">Ogbous and Sons</div>
                            <div className="profile-email">ogbousventure@gmail.com</div>
                            <div className="profile-status">Start Up Business</div>
                        </div>
                    </div>
                </div>
                
                <div className="business-profile-block psw-block">
                    <div className="profile-block-header flex-div justify-content-btw">
                        <div className="profile-block-title">Bank Settings</div>
                        <div className="edit-profile-block" onClick={() => toggleProfileBlock("profile-block-wrapper", "edit-bank-profile-block")}>
                            <span>Edit</span>
                        </div>
                    </div>
                    <div className="profile-details-row flex-div">
                        <div className="profile-avatar-wrapper">
                            <img src={require('../../../images/os-avatar.svg').default} alt="oo-avatar" className="profile-block-avatar" />
                        </div>
                        <div className="profile-name-block">
                            <div className="profile-name">Ogbous and Sons</div>
                            <div className="profile-email">Wema Bank</div>
                            <div className="profile-status">Default Bank</div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div id="edit-personal-profile-block" className="hidden">
                <PersonalSetingsForm toggleProfileBlock={toggleProfileBlock}/>
            </div>

            {/* <div id="edit-business-profile-block" className="hidden">
                <CompanySetingsForm toggleProfileBlock={toggleProfileBlock}/>
            </div>

            <div id="edit-bank-profile-block" className="hidden">
                <BankSettingsForm toggleProfileBlock={toggleProfileBlock}/>
            </div> */}

        </div>
    )
}

export default ProfileBlock;
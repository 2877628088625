import '../css/auth.css';
import $ from 'jquery';
import M from 'materialize-css';
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputField } from '../components/InputField';
import { PasswordField } from '../components/passwordField';
import * as Yup from 'yup'
import { useNavigate, NavLink  } from 'react-router-dom';
import {signup, signupFailure, signupSuccess} from "../redux/actions/auth/registeration";
import { Loader } from '../components/loader';
import { SelectField } from '../components/selectField';
import { forgetPasswordAction, setLoading } from '../redux/reducers/auth';

/**
 * Constructs form validation object for user old signup using Yup library
 * @param email : User email address.
 * @param phone : User phone number.
 */
const formSchema = Yup.object().shape({
    email: Yup.string().email(),
    phone: Yup.number(),
})

/**
 * Forgot password componenet
 */
const ForgotPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const  { loading, user,  server_error, forgetPasswordSuccessful, actionType } = useSelector(state=> state.auth)
    const [error, setError] = useState({})
    const [value, setValues] = useState("") 

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
      })

      useEffect(()=>{
          setError(errors)
      },[errors])

      /**
     * onSubmit function to trigger form submission for forgot password.
     * @param email : User email address.
     * @param phone : User phone number.
     */
       const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){

            dispatch(forgetPasswordAction(data))
            dispatch(setLoading(true))
            
            }
        }

        useEffect(()=>{
            if (forgetPasswordSuccessful && actionType === "auth/forgetpassword") {
                dispatch(setLoading(false))
                navigate("/signup/success")
               
            }

        },[forgetPasswordSuccessful,actionType])
     

    useEffect(() => {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $("ul.form-nav").hide();

        $("#email-reset-trigger").click(function(){
            $("#reset-email-form").slideDown();
            $("#reset-phone-form").slideUp();
        });
        $("#phone-reset-trigger").click(function(){
            $("#reset-phone-form").slideDown();
            $("#reset-email-form").slideUp();
        })
    }, []);

    
    return(
        <div className="auth-block">
            <form id="reset-email-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Enter your email
                    </h1>
                    <p className="greet-desc">
                        Please, check your browser’s address bar to be sure you’re on <br />
                        <a href="https://app.payrail.com">https://app.payrail.com</a>
                    </p>
                </div>
                <div className="input-row row">
                    <InputField 
                        type="email" 
                        label="Email Address" 
                        {...register('email',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['email']? error['email'].message: ""} 
                        id={{input: "email"}} 
                        classname={{div:"input-field col s12 mag-d-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                </div>

                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <button type="button" className="txt-btn pry-color" id="phone-reset-trigger"><u>Reset with phone number instead</u></button>
                    <div className="btn-side">
                        <button type="submit" className="submit-btn pointer">Send Reset Request</button>
                    </div>
                </div>
            </form>

            <form id="reset-phone-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Enter your phone number
                    </h1>
                    <p className="greet-desc">
                        Please, check your browser’s address bar to be sure you’re on <br />
                        <a href="https://app.payrail.com">https://app.payrail.com</a>
                    </p>
                </div>
                <SelectField 
                    id={{select: "countries"}} 
                    {...register('countries',{ required: true })}
                    onChange={e => setValue(e.target.id, e.target.value)}  
                    classname={{div: "input-field col s12 pad0 country-list-wrapper", label: "active"}}
                    label="Country"
                />
                <InputField 
                    type="tel" 
                    label="Phone number" 
                    {...register('phone',{ required: true })}
                    onChange={e => setValue(e.target.id, e.target.value)} 
                    error={error && error['phone']? error['phone'].message: ""}
                    id={{input: "phone"}} 
                    classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                />

                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <button type="button" className="txt-btn pry-color" id="email-reset-trigger"><u>Reset with email instead</u></button>
                    <div className="btn-side">
                        <button type="submit" className="submit-btn pointer">Send Reset Request</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ForgotPassword;
import M from "materialize-css";
import $ from "jquery";

import { useEffect } from "react";

import AddNewCustomerModal from "../../components/addNewCustomerModal";
import AddSuccessModal from "../../components/sucessfulModal";

const EmptyCustomers = () => {
    useEffect (()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $(".filter-dropdown-trigger").click(function(){
            $(".filter-dropdown-content").slideToggle();
        });
    }, []);

    return(
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Customers </span>
                        <small className="page-label-tag">3</small>
                    </h4>
                </div>

                <div className="page-bar-wrapper flex-div justify-content-btw">
                    <div className="page-bar-left flex-div">
                        <div className="filter-block flex-div">
                            <div className="filter-dropdown-trigger">
                                <img src={require('../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                                <span>Filters</span>
                            </div>


                            <ul id="filter-dropdown" className="filter-dropdown-content">

                                <div className="arrow-up"></div>

                                <div className="input-field multiple-fields rel">
                                    <span className="multiple-fields-label">Display customers with </span>

                                    <div className="rel grid-row-2">
                                        <div className="rel">
                                            <select id="range-type">
                                                <option value="At least">At least</option>
                                                <option value="At Most">At Most</option>
                                            </select>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>
                                        <hr className="abs-vertical-line" />
                                        <div className="rel">
                                            <select id="range-num">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">6</option>
                                            </select>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>
                                    </div>
                                    <hr className="filter-horizontal-line" />
                                    <div className="rel">
                                        <select id="range-category">
                                            <option value="Transaction">Transaction</option>
                                            <option value="Transfers">Transfers</option>
                                        </select>
                                        <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                    </div>
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="date-period">
                                        <option value="All Time">All Time</option>
                                        <option value="This Week">This Week</option>
                                        <option value="This Month">This Month</option>
                                    </select>
                                    <label htmlFor="date-period" className="active">Date Period</label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="new-returning">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="new-returning" className="active">New or returning </label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="filter-btns-wrapper">
                                    <button className="filter-btn pry-btn">Filter</button>
                                    <button className="filter-reset-btn">
                                        <span>Reset to default</span>
                                    </button>
                                </div>
                            </ul>
                        </div>
                        <div className="vertical-divider">
                            <hr />
                        </div>
                        <div className="bar-search-block rel">
                            <img src={require('../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                            <input type="search" className="bar-search" placeholder="Search Customers" />
                        </div>
                    </div>
                    <div className="page-bar-right flex-div">
                        <div className="add-btn-wrapper">
                            <button className="add-trigger-btn modal-trigger" data-target="add-new-customer-modal">
                                &#43; Add Customers
                            </button>
                        </div>
                        <div className="export-trigger-block flex-div">
                            <span>Export CSV</span>
                            <img src={require('../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                        </div>
                    </div>
                </div>

                <div className="table-wrapper">
                    <table id="customers-table">
                        <thead>
                            <tr>
                                <th>STATUS</th>
                                <th>CUSTOMER EMAIL</th>
                                <th>FIRST NAME</th>
                                <th>LAST NAME</th>
                                <th>PHONE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img src={require("../../images/empty-cell-status.svg").default} alt="empty-cell" className="empty-cell" />
                                </td>
                                <td>
                                    <img src={require("../../images/empty-cell-email.svg").default} alt="empty-cell" className="empty-cell" />
                                </td>
                                <td>
                                    <img src={require("../../images/empty-cell-name.svg").default} alt="empty-cell" className="empty-cell" />
                                </td>
                                <td>
                                    <img src={require("../../images/empty-cell-name.svg").default} alt="empty-cell" className="empty-cell" />
                                </td>
                                <td>
                                    <img src={require("../../images/empty-cell-phone.svg").default} alt="empty-cell" className="empty-cell" />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="empty-content-container center">
                        <img src={require("../../images/customers-placeholder.svg").default} alt="customers" className="empty-content-img" />
                        <div>
                            <b>You are yet to add a customer</b>
                            <p>
                                Customers are added when they carry out transactions or subscribe to your plan. 
                                You can also add customers yourself. 
                            </p>
                            <div className="empty-container-cta">
                                <button className="empty-cta-btn modal-trigger" data-target="add-new-customer-modal">
                                    &#43; Add Customers
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddNewCustomerModal />
            <AddSuccessModal />
        </div>
    )
}

export default EmptyCustomers;
import { http } from '../../../../utility/http';

/**
 * Define function to make authentication request
 * @param {*email, password} payload 
 * @returns object that consist user payload
 */
export const fetchTerminalApi = (payload) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
    
      const data = [
        {terminalId: 'POS000234', terminalAlias: 'PL002', bankAccount: '0124568899', status: 'active'},
        {terminalId: 'POS000230', terminalAlias: 'PL001', bankAccount: '0124568899', status: 'suspended'},
        {terminalId: 'POS000110', terminalAlias: 'PL010', bankAccount: '0124568899', status: 'suspended'},
        {terminalId: 'POS000234', terminalAlias: 'PL002', bankAccount: '0124568899', status: 'damaged'},
      ];
      resolve(data);
    }, 3000);
  });
};


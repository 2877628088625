import M from "materialize-css";
import $ from "jquery";
import "./Invoice.css";

import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../../redux/reducers/auth";
import debounce from "debounce";

// components
import RequestPaymentModal from "./Modals/RequestPayment";
import TableWithContent from "./tableWithContent";
// import TableWithoutContent from "./tableWithoutContent";

//modal ID
import { REQUEST_PAYMENT_MODAL_ID } from "../../../utility/modalIDs";
import { fetchInvoice, searchInvoice, getInvoiceBalance } from "../../../redux/reducers/invoice";
import AddSuccessModal from "../../../components/sucessfulModal";

const Invoice = ({ goToDetails }) => {
    const dispatch = useDispatch()
    const {auth = {}, invoice={}, customer} = useSelector(state=>state);
    const { invoices=[], invoiceBalance={}} = invoice
    const [search, setSearch] = useState("");

    const handleSearch = (text) =>{
       dispatch(searchInvoice(text))

    }

    const getInvoice = () =>{
        dispatch(setLoading(true));
        dispatch(fetchInvoice())
        dispatch(getInvoiceBalance())
        dispatch(setLoading(false));
    }
    const searchHandler = useCallback(debounce((text)=>handleSearch(text), 2000), []);

    useEffect(()=>{
        if (search !== "") {
            searchHandler(search)
        }else{
            if (search !==null) {
                getInvoice()
            }
        }
    },[search])

    const handleInput = (e) =>{
        setSearch(e.target.value)
    }

    useEffect(()=>{
        getInvoice()
            
    },[])
    useEffect (()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $(".filter-dropdown-trigger").click(function(){
            $(".filter-dropdown-content").slideToggle();
        });
    }, []);

        return(
            <>
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Invoice</span>
                        <small className="page-label-tag">2</small>
                    </h4>
                </div>

                <div className="page-bar-wrapper flex-div justify-content-btw">
                    <div className="page-bar-left flex-div">
                    <div className="filter-block flex-div">
                            <div className="filter-dropdown-trigger">
                                <img src={require('../../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                                <span>Filters</span>
                            </div>


                            <ul id="filter-dropdown" className="filter-dropdown-content">

                                <div className="arrow-up"></div>

                                <div className="select-field input-field single-field rel">
                                    <select id="date-period">
                                        <option value="All Time">All Time</option>
                                        <option value="This Week">This Week</option>
                                        <option value="This Month">This Month</option>
                                    </select>
                                    <label htmlFor="date-period" className="active">Status</label>
                                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="new-returning">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Type</option>
                                    </select>
                                    <label htmlFor="new-returning" className="active">Type</label>
                                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="input-field multiple-fields rel">
                                    <span className="multiple-fields-label">Time Range</span>

                                    <div className="rel grid-row-2">
                                        <div className="rel">
                                            <select id="range-type">
                                                <option value="At least">Startdate</option>
                                                <option value="At Most">At Most</option>
                                            </select>
                                            <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>
                                        <hr className="abs-vertical-line" />
                                        <div className="rel">
                                            <select id="range-num">
                                                <option value="1">End date</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">6</option>
                                            </select>
                                            <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-btns-wrapper">
                                    <button className="filter-btn pry-btn">Filter</button>
                                    <button className="filter-reset-btn">
                                        <span>Reset to default</span>
                                    </button>
                                </div>
                            </ul>
                        </div>

                        <div className="vertical-divider">
                            <hr />
                        </div>

                        <div className="bar-search-block rel">
                            <img src={require('../../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                            <input onChange={handleInput} type="search" className="bar-search" placeholder="Search Subaccounts" />
                        </div>
                    </div>
                    <div className="page-bar-right flex-div">
                        <div className="add-btn-wrapper">
                            <button className="add-trigger-btn modal-trigger" data-target={REQUEST_PAYMENT_MODAL_ID}>
                                &#43; Request a Payment
                            </button>
                        </div>
                    </div>
                </div>

                <div className="ubip-overview-container">
                    <div className="ubip-overview rel">
                        <div className="ubip-overview-title">
                            Received
                        </div>
                        <div className="ubip-overview-amount ubip-success ">
                            <span className="ubip-overview-currency">₦</span>
                            {invoiceBalance.received}
                        </div>
                    </div>


                    <div className="ubip-overview rel">
                        <div className="ubip-overview-title">
                            Total
                        </div>
                        <div className="ubip-overview-amount ubip-normal">
                            <span className="ubip-overview-currency">₦</span>
                            {invoiceBalance.total}
                        </div>
                    </div>

                    <div className="ubip-overview rel">
                        <div className="ubip-overview-title ">
                            Pending
                        </div>
                        <div className="ubip-overview-amount ubip-pending ">
                            <span className="ubip-overview-currency">₦</span>
                            {invoiceBalance.pending}
                        </div>
                    </div>
                </div>

                {/* <TableWithoutContent /> */}
                <TableWithContent invoices={invoices} />
            <RequestPaymentModal goToDetails={goToDetails} />
            <AddSuccessModal title="Invoice added successfully" desc="A new invoice has been added. You can add more." />
        </>
    )
}

export default Invoice;
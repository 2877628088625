import ColoredWrapper from "../../components/UI/coloredWrapper/coloredWrapper";
import "./Loan.css";

import { NavLink } from "react-router-dom";

const Loan = () => {
    return (
        <div className="page-content-wrapper">
            <div className="container">
                <div className="loan-full-height">
                    <div className="page-title-wrapper">
                        <h4 className="page-content-title flex-div">
                            <span>Loan</span>
                        </h4>
                    </div>
                    <ColoredWrapper outlined fullHeight classes="center-content-xy">
                        <div className="center-content-xy">
                            <div className="bag-svg">
                                <img src={require("../../images/bag.svg").default} alt="caret"  />
                            </div>
                            <span>You are not eligible for loan</span>
                            <div>
                                <span>Make several transactions, request for your physical
                                card, update and verify your KYC  document and
                                informations</span>
                            </div>
                            <NavLink to="/dashboard/loans-overview" className="ccxy-link">
                                Go to Overview
                            </NavLink>
                        </div>
                    </ColoredWrapper>
                </div>
            </div>
        </div>
    );
}

export default Loan;
import React from "react";
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { ADD_MONEY_SUCCESS_MODAl_ID } from "../../../../utility/modalIDs";

const AddMoneySuccessModal = () => {
    return (
        <ModalWrapper modalID={ADD_MONEY_SUCCESS_MODAl_ID}>
            <div className="bps-icon">
                <img src={require("../../../../images/success-tick.svg").default} alt="success" className="" />  
            </div>
            <h2 className="amsm-header">Good Job!</h2>
            <p className="amsm-grey-text">You just made a top-up attempt you will be notified as soon as payment is confirmed</p>
        </ModalWrapper>
    );
};

export default AddMoneySuccessModal;
import { combineReducers } from 'redux';
import auth from './auth';
import wallet from './wallet';
import account from './account';
import card from './card';
import pos from './pos';
import customer from './customer';
import dispute from './dispute';
import invoice from './invoice';
import loan from './loan';
import referral from './referral';
import subscription from './subscription';
import administration from './administration';
import toast from './toast';

const rootReducer = combineReducers({
    auth,
    wallet,
    account,
    card,
    pos,
    customer,
    dispute,
    invoice,
    loan,
    referral,
    subscription,
    administration,
    toast
});

export default rootReducer;
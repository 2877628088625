import React, { useState } from "react";
import OtpInput from 'react-otp-input';

import ModalWrapper from "../../../components/UI/ModalWrapper";
import { OTP_MODAL, WITHDRAW_SUCCESS_MODAL } from "../../../utility/modalIDs";

const OtpModal = () => {
    const [otp, setOTP] = useState('');

    return (
        <ModalWrapper modalID={OTP_MODAL}>
            <div className="qtsmm-modal-title">Enter OTP</div>
            <div className="modal-block-desc">Enter OTP sent to your email; o****n@gmail.com</div>
        
            <div id="verification-codes">
                <div className="input-row row vcm-mt">
                    <OtpInput
                        value={otp}
                        onChange={(val)=>{
                            setOTP(val)
                        }}
                        numInputs={6}
                        separator={<span> </span>}
                    />
                </div>
            </div>

            <div className="vcm-flex">
                <div className="vcm-small-text">
                    Did’nt get OTP? <span>Resend</span>
                </div>

                <button className="pry-btn modal-trigger modal-close " data-target={WITHDRAW_SUCCESS_MODAL}>
                    Verify
                </button>
            </div>
        </ModalWrapper>
    );
};

export default OtpModal;
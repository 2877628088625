import AddMoneyViaBankTransferModal from "./Modals/BankTransfer";
import AddMoneyViaUSSDTransferModal from "./Modals/USSD";
import CopyUSSDCodeModal from "./Modals/CopyUSSDCode";
import AddMoneySuccessModal from "./Modals/TransferSuccess";

import { ADD_MONEY_VIA_BANK_TRANSFER_MODAL_ID, ADD_MONEY_VIA_USSD_TRANSFER_MODAL_ID } from "../../../utility/modalIDs";

const AddMoney = () => {
    
    return (
        <div>
            <div className="smt-option smt-mb modal-trigger" data-target={ADD_MONEY_VIA_BANK_TRANSFER_MODAL_ID}>
                <img className="smt-option-icon" src={require("../../../images/bank-circle.svg").default} alt="icon" />
                <span className="smt-option-title">Bank Transfer</span>
                <img className="smt-option-chev-icon" src={require("../../../images/chevron-right.svg").default} alt="icon" />
            </div>
            <div className="smt-option modal-trigger" data-target={ADD_MONEY_VIA_USSD_TRANSFER_MODAL_ID}>
                <img className="smt-option-icon" src={require("../../../images/ussd-circle.svg").default} alt="icon" />
                <span className="smt-option-title">USSD Transfer</span>
                <img className="smt-option-chev-icon" src={require("../../../images/chevron-right.svg").default} alt="icon" />
            </div>

            <AddMoneyViaBankTransferModal />
            <AddMoneyViaUSSDTransferModal />
            <CopyUSSDCodeModal />
            <AddMoneySuccessModal />
        </div>
    );
};

export default AddMoney;
import '../css/auth.css';
// import $ from 'jquery';
import M from 'materialize-css';
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordField } from '../components/passwordField';
import * as Yup from 'yup'
import { useNavigate, NavLink  } from 'react-router-dom';
import auth, { loginAction, setLoading } from '../redux/reducers/auth';

/**
 * Constructs form validation object for user old signup using Yup library
 * @param password : Refferal code.
 */
const formSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
})

/**
 * Old user login componenet
 */
const LoginOld = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const  { loading, user, device_account, loginSuccessful, server_error, actionType } = useSelector(state=> state.auth)
    const [error, setError] = useState({})
    const [value, setValues] = useState("") 

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
      })

      useEffect(()=>{
          setError(errors)
      },[errors])

    /**
     * onSubmit function to trigger form submission for phone number registration.
     * @param email : User email address.
     * @param phone : User phone number.
     * @param password : Account password.
     */
     const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){

            dispatch(loginAction(data))
            dispatch(setLoading(true))
            
            }
        }

        useEffect(()=>{
            if (loginSuccessful && actionType === "auth/login") {
                dispatch(setLoading(false))
                navigate("/dashboard")
               
            }

        },[loginSuccessful,actionType])

    // const addActive = () => {
    //     var targetLabel = document.querySelector('.input-field label');
    //     targetLabel.classList.add("active");
    // };
    
    // const removeActive = () => {
    //     var targetLabel = document.querySelector('.input-field label');
    //     var targetInput = document.querySelector('.input-field input');
    //     if(targetInput && targetInput.value ) {
    //         targetLabel.classList.add("active");
    //     } else {
    //         targetLabel.classList.remove("active");
    //     }
    // };

    
    document.addEventListener('DOMContentLoaded', function() {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);
    });

   
    
    return(
        <div className="auth-block">
            <form id="olduser-login-form"  onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Welcome back, <span>{device_account.username || "Olamide"}</span>
                    </h1>
                    <p className="greet-desc">
                        Please, check your browser’s address bar to be sure you’re on <br />
                        <a href="https://app.payrail.com">https://app.payrail.com</a>
                    </p>
                </div>
                <div className="input-row row">
                    <PasswordField 
                        type="password"
                        value={value.password} 
                        label="Password" 
                        {...register('password',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['password']? error['password'].message: ""}
                        id={{input: "password"}} 
                        classname={{div:"input-field col s12 mag-d-0 pad0" , error:"error-text", input:"inp-field", }} 
                    />
                    <div className="alt-link-div right-align">
                        <NavLink to="/forgot-password" className="pry-color">Forgot password?</NavLink>
                    </div>
                </div>

                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    {/* important!!! --- Text for reconised device */}
                    <div className="txt-link-side">
                        New user? <NavLink to="/signup" className="pry-color"><u>Create account</u></NavLink>
                    </div>
                    {/* important!!! --- Text for reconised user */}
                    {/* <div className="txt-link-side">
                        
                        Need to change Password? <a href="/#" className="pry-color"><u>Click here</u></a>
                    </div> */}
                    <div className="btn-side">
                        <button type="submit" className="submit-btn pointer modal-trigger" data-target="detected-olduser-modal">Sign In</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default LoginOld;
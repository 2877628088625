// import logo from './images/payrail-logo.svg';

import './css/styles.css';
import './css/fonts.css';
import 'materialize-css/dist/css/materialize.min.css';
import 'material-design-icons/iconfont/material-icons.css';
import { Provider } from 'react-redux';
// import giveStore from './redux/store';
import store from './redux/store';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import $ from 'jquery';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';


import AuthView from './layouts/AuthView';
import DashboardView from './App2';
import RouteHooks from './routes';
import ErrorStatus from './components/errorStatus';

let persistor = persistStore(store);

function App() {

    $(window).scroll(function () {
        $('.auth-nav-header').toggleClass('scrolled-nav', $(this).scrollTop() > 50);
    });
    

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
    <Router>
      <div className="App">
      <RouteHooks/>
    
          {/*---==== Contents Sections  ====---*/}
            {/* <Routes>
                <Route exact path="/">
                    <AuthView />
                </Route>
                <Route exact path="/dashboard">
                    <DashboardView />
                </Route>
            </Routes> */}
          {/*---==== end of Contents Sections  ====---*/}
        
      </div>
    </Router>
    </PersistGate>
    </Provider>
  );
}

export default App;

import "./card.css";
import "../../components/CardsPageComponents/Modals/card-modals.css"
import ColoredWrapper from "../../components/UI/coloredWrapper/coloredWrapper.js";
import CardsCarousel from "../../components/CardsCarousel";
import ATMCard from "../../components/ATMCard";

import { useState } from "react";
// import AvailableCard from "../../components/CardsPageComponents/AvailableCard";

import { NavLink } from "react-router-dom";
import ManageCardContent from "./manageCardContent";
import VirtualCardHeadsUp from "../../components/CardsPageComponents/Modals/VirtualCardHeadsUp";
import VirtualCardCreated from "../../components/CardsPageComponents/Modals/VirtualCardCreated";
import RequestCardForm from "./requestCardForm";
import RequestCardInProcess from "./requestCardInProcess";
import FundCardModal from "../../components/FundCardModal/FundCardModal";
import FundVerificationCodeModal from "../../components/FundCardModal/FundVerificationCode";
import FundTransactionPinModal from "../../components/FundCardModal/FundTransactionPinModal";
import FundSuccessModal from "../../components/FundCardModal/FundSuccessModal";

const Card = () => {
    const [mainContentShow, setMainContentShow] = useState(true);
    const [manageCardShow, setManageCardShow] = useState(false);
    const [limitsAndAllowances , setLimitsAndAllowances] = useState(false);
    const [requestPhysicalCard , setRequestPhysicalCard] = useState(false);

    const [availableCards] = useState([
        {
            type: "visa",
            bank: "Access Bank",
            pan: "6743",
            exp: "06/2022",
            isDefault: true,
        },
        {
            type: "mastercard",
            bank: "Guaranty Trust Bank",
            pan: "6743",
            exp: "06/2022",
            isDefault: false,
        },
        {
            type: "verve",
            bank: "Wema Bank",
            pan: "6743",
            exp: "06/2022",
            isDefault: false,
        },
        {
            type: "verve",
            bank: "Wema Bank",
            pan: "6743",
            exp: "06/2022",
            isDefault: false,
        },
    ])

    const goToRequestPhysicalCardHandler = () => {
        setRequestPhysicalCard(true);
        setMainContentShow(false);
    }

    const goToLimitsAndAllowancesHandler = () => {
        setLimitsAndAllowances(true);
        setMainContentShow(false);
    }

    const goToManageCardHandler = () => {
        setManageCardShow(true);
        setMainContentShow(false);
    }

    const goBack = () => {
        setMainContentShow(true);
        setManageCardShow(false);
        setLimitsAndAllowances(false);
        setRequestPhysicalCard(false)
    }

    const mainContent = (
        <div className="page-content-wrapper">
            {/* <span className="modal-trigger" data-target="card-defreeze-modal">Freeze Card</span> */}
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Cards</span>
                    </h4>

                    <span>Add and manage your payment methods using our secure payment system</span>
                </div>

                <div className="cards-page-container">
                    <div className="cards-page-left-wrapper">
                        <CardsCarousel>
                            {/* <ATMCard /> */}
                        </CardsCarousel>               

                        <ColoredWrapper outlined>
                            <div className="card-limit-left-bottom" onClick={goToLimitsAndAllowancesHandler}>
                                <div className="flex-div justify-content-btw">
                                    <h4 className="bills-sub-header">Limits and allowances</h4>  
                                    <img src={require("../../images/right-arr.svg").default} alt="caret" />                           
                                </div>
                                <p>Update and upload your details including your ID and document to enjoy your outbound limits </p>
                            </div>

                            <div className="ctm-hr"></div>
                            
                            <div className="limits-and-allowances-controls">
                                <div className="limits-and-allowances-controls-left">
                                    <div onClick={goToManageCardHandler}> 
                                        <img src={require("../../images/settings-2.svg").default} alt="caret" className="limits-and-allowances-controls-icons" />
                                        <span>Manage Card</span>
                                    </div>
                                </div>
                                <div className="limits-and-allowances-controls-right">
                                    <div onClick={goToRequestPhysicalCardHandler}>
                                        <img src={require("../../images/card-icon.svg").default} alt="caret" className="limits-and-allowances-controls-icons" />
                                        <span>Request Physical Card</span>
                                    </div>
                                </div>
                                {/* <div className="bp-cursor">
                                    <img src={require("../../images/airtime-tranx.svg").default} alt="caret" />
                                    <span>Airtime</span>
                                </div> */}
                           
                            </div>
                        </ColoredWrapper>

                        <VirtualCardHeadsUp />
                        <VirtualCardCreated />
                    </div>
                        <ColoredWrapper outlined fullHeight>
                            {/* <div className="cards-page-right-wrapper">
                                {availableCards.map((card, index) => (
                                    <AvailableCard
                                        key={index}
                                        cardType={card.type}
                                        bank={card.bank}
                                        pan={card.pan}
                                        expires={card.exp}
                                        isDefault={card.isDefault}
                                        borderBottom={availableCards.length - index !== 1 }
                                    />)
                                )}
                                <span className="add-new-card-bottom">+Add new Debit Card</span>
                            </div> */}

                           <div className="cards-page-right-wrapper cards-page-center-xy">
                               <div className="cards-page-add-to-pushpenny">
                                    <div>
                                     <img src={require("../../images/push-penny.svg").default} alt="phone-verified-" className="congrats-img" />
                                    </div>
                                    <small>You are yet to add a debit card to your<br></br>Pushpenny account</small>
                                    <div >Add new Debit Card</div>
                               </div>
                           </div>
                        </ColoredWrapper>
                </div>
            </div>
            <FundCardModal />
            <FundVerificationCodeModal />
            <FundTransactionPinModal />
            <FundSuccessModal />
        </div>
    )

    const manageCardContent = (
        // <ManageCardContent />
        <div className="page-content-wrapper">
            <div className="container">
                <div className="manage-cards-goback" onClick={goBack}>
                    <div className="manage-cards-goback-icon">
                        <img src={require("../../images/caret-down.svg").default} alt="caret"  />
                    </div>
                    <span>Back</span>
                </div>


                <div className="manage-cards-title">
                    Manage your payrail business card details
                </div>

                <ManageCardContent />
            </div>
        </div>
    )

    const limitsAndAllowancesContent = (
        <div className="page-content-wrapper">
            <div className="container">
                <div className="manage-cards-goback" onClick={goBack}>
                    <div className="manage-cards-goback-icon">
                        <img src={require("../../images/caret-down.svg").default} alt="caret"  />
                    </div>
                    <span>Back</span>
                </div>

                <div className="manage-cards-title">
                    Limits and Allowances
                </div>

                <div className="manage-cards-grid">
                    <ColoredWrapper outlined>
                        <div className="limits-and-allowances-mb">
                            <div className="limits-and-allowances-header">
                                TRANSFER LIMIT (₦10,000,000)
                            </div>

                            <div className="limits-and-allowances-content">
                                <div>
                                    <span>Daily Web transaction</span>
                                    <span>₦10,000,000</span>
                                </div>
                                <div>
                                    <span>Per transaction</span>
                                    <span>₦1,000,000</span>
                                </div>
                            </div>
                        </div>


                        <div className="limits-and-allowances-mb">
                            <div className="limits-and-allowances-header">
                                BILLS PAYMENT LIMIT (₦1,000,000)
                            </div>

                            <div className="limits-and-allowances-content">
                                <div>
                                    <span>Per day</span>
                                    <span>₦1,000,000</span>
                                </div>
                                <div>
                                    <span>Per bill</span>
                                    <span>₦200,000</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="limits-and-allowances-header">
                                AIRTIME/DATA LIMIT (₦200,000)
                            </div>

                            <div className="limits-and-allowances-content">
                                <div>
                                    <span>Per day</span>
                                    <span>₦1200,000</span>
                                </div>
                                <div>
                                    <span>Per transaction</span>
                                    <span>₦200,000</span>
                                </div>
                            </div>
                        </div>
                    </ColoredWrapper>
                </div>
               
            </div>
        </div>
    )

    const requestPhysicalCardContent = (
        <div className="page-content-wrapper">
            <div className="container">
                <div className="manage-cards-goback" onClick={goBack}>
                    <div className="manage-cards-goback-icon">
                        <img src={require("../../images/caret-down.svg").default} alt="caret"  />
                    </div>
                    <span>Back</span>
                </div>


                <div className="manage-cards-title">
                    Request Physical Card
                </div>

                <div className="request-cards-container">
                    <ATMCard />
                    <ColoredWrapper outlined>
                        {/* <RequestCardForm /> */}
                        <RequestCardInProcess />
                    </ColoredWrapper>
                </div>
               
            </div>
        </div>
    )

    let pageContent;

    if (mainContentShow) {
        pageContent = mainContent;
    } else if (manageCardShow) {
        pageContent = manageCardContent;
    } else if (limitsAndAllowances) {
        pageContent = limitsAndAllowancesContent;
    } else if (requestPhysicalCard) {
        pageContent = requestPhysicalCardContent;
    }

    return pageContent;
}

export default Card;
import React from "react";
import { NavLink } from "react-router-dom";

import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { SEND_MONEY_SUCCESS_MODAL_ID } from "../../../../utility/modalIDs";

const SendMoneySuccessModal = () => {

    return (
        <ModalWrapper modalID={SEND_MONEY_SUCCESS_MODAL_ID}>
            <div className="bps-icon">
                    <img src={require("../../../../images/success-tick.svg").default} alt="success" className="" />  
                    <h2>Awesome</h2>  
                </div>

                <div className="bps-success-message">
                    <p>
                        You just made a transfer to Olamide view details in the <br/>
                        <NavLink to="/dashboard/transactions">Transaction page</NavLink>
                    </p>
                </div>

                <div className="bps-actions-super-wrapper">
                    <div className="bps-actions-wrapper-container">
                    <div className="bps-actions-wrapper modal-close modal-trigger" href="#bill-payment-schedule-payment-modal">
                            <div className="bps-action-container bps-action-items-center">
                                <img src={require("../../../../images/add-contact-circle-icon.svg").default} alt="schedule" className=""/>
                            </div>
                            <div className="bps-action-items-center">
                                <small className="bps-action-items-center">Add Beneficiary</small>
                            </div>
                        </div>

                        <div className="bps-actions-wrapper modal-close modal-trigger" href="#bill-payment-schedule-payment-modal">
                            <div className="bps-action-container bps-action-items-center" data-target="bill-payment-schedule-payment-modal">
                                <img src={require("../../../../images/transaction-schedule-icon.svg").default} alt="schedule" className=""/>
                            </div>
                            <div className="bps-action-items-center">
                                <small className="bps-action-items-center">Schedule</small>
                            </div>
                        </div>

                        <div className="bps-actions-wrapper">
                            <div className="bps-action-container bps-action-items-center">
                                <img src={require("../../../../images/share-receipt.svg").default} alt="share receipt"/>
                            </div>
                            <div className="bps-action-items-center">
                                <small className="bps-action-items-center">Share Receipt</small>
                            </div>
                        </div>                   
                    </div>
                </div>
        </ModalWrapper>
    );
};

export default SendMoneySuccessModal;
import { useEffect } from "react";
import PropTypes from "prop-types";

import $ from "jquery";

import "./SuccessModal.css";

// components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";

//modal ID
import { LOAN_REPAYMENT_SUCCESS_MODAL } from "../../../../../utility/modalIDs";

// consts
import { EXTRA, EARLY, FULL } from "../../../utils/repaymentOptions";
import { NavLink } from "react-router-dom";

const LoanRepaymentSuccessModal = ({ option }) => {
    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);

    const successModalMessages = {
        [EARLY]: "Your Payrail Loan monthly repayment has been paid successfully.",
        [EXTRA]: "Your paid off litte extra from your Payrail loan.",
        [FULL]: "Your Payrail Loan full repayment was successful."
    };

    return(
        <ModalWrapper modalID={LOAN_REPAYMENT_SUCCESS_MODAL}>
                <div className="lrsm-icon">
                    <img src={require("../../../../../images/success-tick.svg").default} alt="success" />   
                </div>

                <h2 className="lsrm-success center">Successful</h2> 

                <div className="lrsm-success-message">
                    <p>{successModalMessages[option]}</p>
                    <p>View details in the <NavLink to="/dashboard/transactions">Transaction Page</NavLink></p>
                </div>

                <div className="lrm-share">
                    <img src={require("../../../../../images/share-receipt.svg").default} alt="share receipt" className="pointer"/>
                    <small className="bps-action-items-center">Share Receipt</small>
                </div>  
        </ModalWrapper>
    )
};

LoanRepaymentSuccessModal.propTypes = {
    option: PropTypes.oneOf([ EXTRA, FULL, EARLY])
};

export default LoanRepaymentSuccessModal;
import React, { useState } from "react";
import TransferPageMain from "./Main";
import BulkTransfer from "./BulkTransfer";
import TransferInformation from "./TransferInformation/index.js";
import "./Transfer.css";

const TransferPage = () => {
    const [ show, setShow ] = useState("main");

    let content = null;

    if (show === "main") {
        content = <TransferPageMain goToBulkTransfer={setShow} />;
    } else if (show === "bulk transfer landing") {
        content = <BulkTransfer getStarted={setShow} />;
    } else if (show === "transfer information") {
        content = <TransferInformation goBack={setShow}/>;
    }
    return (
        <div className="page-content-wrapper">
            <div className="container">
                { content }
            </div>
        </div>
    );
};

export default TransferPage;
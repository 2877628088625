import "./Referral.css";

// Components: Modals
import Wrapper from "../../components/UI/Wrapper";

import { AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import { TiSocialFacebook } from 'react-icons/ti';
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/reducers/auth";
import { getReferralDetail, getRewardEarn } from "../../redux/reducers/referral";
import { useEffect } from "react";

const Referral = () => {
    const dispatch = useDispatch();
    const {auth = {}, referral={}} = useSelector(state=>state);
    const {reward_earned={}, referral_detail={}, performance={}} = referral

    useEffect(()=> {
        dispatch(setLoading(true))
        dispatch(getReferralDetail());
        dispatch(getRewardEarn())
        dispatch(setLoading(false))

    },[])

    return (
        <div className="page-content-wrapper">
            <div className="container">
                <h3 className="rp-header">Track your referrals</h3>

                <div className="rp-sub-header">
                    Refer new merchants with your referral code and earn 0.1% on the revenue Payrail makes from each transaction.
                </div>

                <div className="rp-referral-overview">
                    <div>
                        <div className="rp-overview-header">Current Rewards Earned</div>
                        <div className="rp-overview-details">
                            <div>₦</div>
                            <div className="rp-br">{reward_earned.current_reward}</div>
                        </div>
                    </div>

                    <div>
                        <div className="rp-overview-header">Total Rewards Earned</div>
                        <div className="rp-overview-details">
                            <div>₦</div>
                            <div>{reward_earned.total_reward}</div>
                        </div>
                    </div>

                    <div>
                        <button className="rp-share-btn">
                            Share referral link
                            <AiOutlineArrowRight />
                        </button>
                    </div>
                </div>

                <div className="rp-bottom">
                    <Wrapper outlined grey className="rp-bottom-left">
                        <div className="rp-bb">
                            <h6 className="rp-bottom-header">Your Referral Code</h6>

                            <div className="rp-bottom-icons-box">
                                <div className="rp-bottom-icons">
                                    <TiSocialFacebook/>
                                </div>
                                <div className="rp-bottom-icons">
                                    <AiOutlineTwitter />
                                </div>
                            </div>
                        </div>
                        <div className="rp-code">
                           {referral_detail.referral_code} 
                        </div>
                        <div className="rp-link">
                            {referral_detail.referral_link}
                            
                        </div>
                    </Wrapper>

                    <Wrapper outlined white fullHeight className="rp-bottom-right">
                        <div className="rp-bb">
                            <h6 className="rp-bottom-header">Performance</h6>
                        </div>
                        <div className="rp-performance-box">
                            <div className="rp-performance-detail">
                                <div className="rp-performance">Percentage Per Referral</div>
                                <div>
                                    <span className="rp-performance-detail-big">{performance.merchant_per_referral}</span>
                                    <span className="rp-percentage">%</span>
                                </div>
                            </div>

                            <div className="rp-performance-detail">
                                <div className="rp-performance">Merchants Referred</div>
                                <div>
                                    <span className="rp-performance-detail-big">{performance.merchant_referred}</span>
                                    <span className="rp-performance-detail-small">/500</span>
                                </div>
                            </div>

                            <div className="rp-performance-detail">
                                <div className="rp-performance">Current Signups</div>
                                <div>
                                    <span className="rp-performance-detail-big">{performance.current_signup}</span>
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                </div>
            </div>
        </div>
    );
}

export default Referral;
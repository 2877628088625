import React from 'react';
import { Navigate , Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoutes = ({ children }) =>  {
  let location = useLocation();
  const {auth} = useSelector(state=>state)
    const {_authorizationCredential = "", token = true} = auth
  
    if (_authorizationCredential) {
      return  children;
    }
   
    return  <Navigate to="/login" state={{ from: location }} replace/>

  };

export default PrivateRoutes;
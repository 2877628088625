import React from "react";

import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { UPLOAD_BULK_BENEFICIARIES_MODAL_ID, VALIDATE_BENEFICIARIES_MODAL_ID } from "../../../../utility/modalIDs";

const UploadBulkBeneficiariesModal = () => {
    return (
        <ModalWrapper modalID={UPLOAD_BULK_BENEFICIARIES_MODAL_ID}>
            <div className="upbm-header-wrapper">
                <h4 className="upbm-header-mb">Upload bulk beneficiaries</h4>
                <div className="modal-desc">Attach a CSV file of the list if the beneficiaries.</div>
            </div>
            <form action="#" className="dashboard-form modal-form" onSubmit={e => e.preventDefault()}>
                <div className="input-field single-field">
                    <div className="input-field">
                        <input id="list_name" type="text" defaultValue={""} className="form-inp-field" />
                        <label htmlFor="list_name">List Name</label>
                    </div>
                </div>

                <div className="file-field input-field">
                    {/* <div className="btn">
                        <span>File</span>
                        <input type="file" />
                    </div> */}
                    <div className="upbm-uploadbtn">
                        <div className="upbm-uploadbtn-label">File Attachment</div>
                        <img src={require("../../../../images/cloud-icon.svg").default} alt="caret" />
                        <div className="upbm-btn-text file-path-wrapper">
                            {/* <div>Drag and drop document here or Browse</div> */}
                            <input className="file-path" type="text" defaultValue={"Drag and drop document here or Browse"} readOnly />
                            <span>Supported file types: CSV</span>
                            <input type="file" id="csv-upload" accept=".csv" />
                        </div>
                    </div>
                    {/* <div className="file-path-wrapper">
                        <input className="file-path" type="text" />
                    </div> */}
                </div>

                <button className="pry-btn modal-trigger modal-close amvutm-btn-fullwidth" data-target={VALIDATE_BENEFICIARIES_MODAL_ID}>
                    Upload
                </button>
            </form>
        </ModalWrapper>
    );
};

export default UploadBulkBeneficiariesModal;
import { useEffect } from "react";

// css
import "./Administration.css";

import $ from "jquery";

import M from "materialize-css";

// Components
import Merchants from "./Merchants";
import KYCApproval from "./KYCApproval";

const Merchant = () => {
    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);
    }, []);
    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);

        $(".filter-dropdown-trigger").click(function(){
            $(this).parent().find(".filter-dropdown-content").slideToggle();
        });
    }, []);
    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);

    return (
        <div className="container admin-page-cont">
            <div className="page-title-wrapper">
                <h4 className="page-content-title">
                    <span>Merchant</span>
                </h4>
            </div>

            <div className="row tab-wrapper ap-fullHeight2">
                <div className="col s12 pad0">
                    <ul className="tabs">
                        <li className="tab">
                            <a className="active flex-div" href="#profile-block">
                                <span>Merchants</span> 
                            </a>
                        </li>
                        <li className="tab">
                            <a className="flex-div" href="#security-block">
                                <span>KYC Pending approvals</span> 
                            </a>
                        </li>
                    </ul>
                </div>
                <div id="profile-block" className="col s12 pad0 ap-fullHeight">
                    <Merchants />
                </div>
                <div id="security-block" className="col s12 pad0">
                    <KYCApproval />
                </div>
            </div>
        </div>
    );
}

export default Merchant;
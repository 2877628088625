import React from "react";
import M from "materialize-css";

const ReconciliationItem = ({item={}, onClick=()=>{}}) =>{

    const {email="",status="",due_when="",created_date="",tx_date="",tx_amount="", category} = item

    return(
        <tr>
        <td>{category}</td>
        <td className="gray-txt">₦{tx_amount} <br />{tx_date}</td>
        <td>{email}</td>
        <td>{created_date}</td>
        <td>{due_when}</td>
        {/* <td>
            <button onClick={onClick} className="pry-btn resolve-btn modal-trigger" data-target="resolve-dispute-modal">
                Resolve Reconciliation →
            </button>
        </td> */}
        <td>{status}</td>
    </tr>
    )
}

export default ReconciliationItem
import React from "react";
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { BULK_TRANSFER_SUCCESS_MODAl_ID } from "../../../../utility/modalIDs";

const BulkTransferSuccess = () => {
    return (
        <ModalWrapper modalID={BULK_TRANSFER_SUCCESS_MODAl_ID}>
            <div className="bps-icon">
                <img src={require("../../../../images/success-tick.svg").default} alt="success" />  
            </div>
            <h2 className="amsm-header">Successful!</h2>
            <p className="amsm-grey-text">You just made a bulk transfer to your staff list check the transaction page details </p>
        </ModalWrapper>
    );
};

export default BulkTransferSuccess;
const BillTransactionView = ({ goback }) => {
    return (
        <div id="tsv-main-wrapper" className="container">
            <div className="back-wrapper">
                <div className="manage-cards-goback" onClick={goback}>
                    <div className="manage-cards-goback-icon">
                        <img src={require("../../images/caret-down.svg").default} alt="caret"  />
                    </div>
                    <span>Back</span>
                </div>
            </div>

            <div className="tsv-grid-wrapper">
                <div className="tsv-grid-bloack-1">
                    <div className="tsv-row-header flex-div justify-content-btw">
                        <span className="tsv-title-txt">Amount</span>
                        <span className="tsv-status">Success</span>
                    </div>
                    <div className="tsv-amount success-transaction">
                        <span>₦</span>10,000.00
                    </div>

                    <div className="tsv-details-list-wrapper">
                        <ul className="tsv-details-list">
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Reference</span>
                                <span className="tsv-list-row-desc">PT08148620099</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Channnel</span>
                                <span className="tsv-list-row-desc">Card</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Type</span>
                                <span className="tsv-list-row-desc">Electricity Bill</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Merchant </span>
                                <span className="tsv-list-row-desc">+2348147973627</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Geo Location </span>
                                <span className="tsv-list-row-desc">Bida, Abuja</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Payment Time</span>
                                <span className="tsv-list-row-desc">May 19, 2021 · 01:50 PM</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Plan</span>
                                <span className="tsv-list-row-desc">Permium Membership</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Narration</span>
                                <span className="tsv-narration-txt">
                                    Please ship  from thursday, as I won't be available before then
                                </span>
                            </li>
                        </ul>
                    </div>

                   

                    <div className="tsv-user-block">
                        <div className="flex-div justify-content-btw">
                            <div className="flex-div">
                                <img src={require("../../images/jp-logo.svg").default} alt="user-avatar" className="tsv-user-avatar" />
                                <div className="tsv-user-name-block">
                                    <div className="tsv-user-name">Johnson Paulina</div>
                                    <div className="tsv-user-email">jaypaul@company.com</div>
                                </div>
                            </div>
                            <a href="/#" className="tsv-next-arr">
                                <img src={require("../../images/right-arr-circle.svg").default} alt="right-arr-circle" className="right-arr" />
                            </a>
                        </div>
                        <div className="black-list-txt-wrapper">
                            <a href="/#" className="balck-list-trigger">Blacklist Johnson</a>
                        </div>
                    </div>
                </div>
                <div className="tsv-grid-bloack-2">
                    <div className="tsv-row-header flex-div justify-content-btw">
                        <span className="tsv-title-txt">Channel Details </span>
                        <span className="tsv-status">← Refund Customer</span>
                    </div>

                    <div className="tsv-grid-blocks-wrapper">
                        <div className="tsv-grid-block">
                            <div className="tsv-grid-block-title">Card Type</div>
                            <div className="tsv-grid-block-desc">Visa Debit</div>
                        </div>
                        <div className="tsv-grid-block">
                            <div className="tsv-grid-block-title">Reference</div>
                            <div className="tsv-grid-block-desc">230*******9087</div>
                        </div>
                        <div className="tsv-grid-block">
                            <div className="tsv-grid-block-title">Authorization</div>
                            <div className="tsv-grid-block-desc">AUTH_VESTPAY</div>
                        </div>
                        <div className="tsv-grid-block">
                            <div className="tsv-grid-block-title">IP Address</div>
                            <div className="tsv-grid-block-desc">141.198.20.0.1</div>
                        </div>
                        <div className="tsv-grid-block">
                            <div className="tsv-grid-block-title">Bank and Country</div>
                            <div className="tsv-grid-block-desc">Access Bank · NG</div>
                        </div>
                    </div>

                    <div className="payment-analytics-wrapper">
                        <div className="paw-title-row">Payment Analytics</div>
                        <div className="grid-row-2">
                            <div className="analytics-time-block">
                                <div className="analytics-circle">
                                    <div className="analytics-h1">10</div>
                                    <div className="analytics-h3">Seconds</div>
                                    <div className="analytics-h5">Spent on Page</div>
                                </div>
                            </div>
                            <div className="other-analytics-details">
                                <div className="oad-block flex-div">
                                    <img src={require("../../images/device-icon.svg").default} alt="oad-icon" className="oad-icon" />
                                    <div className="oad-details">
                                        <div className="tsv-grid-block-title">Device Type</div>
                                        <div className="tsv-grid-block-desc">Desktop</div>
                                    </div>
                                </div>
                                <div className="oad-block flex-div">
                                    <img src={require("../../images/attempt-icon.svg").default} alt="oad-icon" className="oad-icon" />
                                    <div className="oad-details">
                                        <div className="tsv-grid-block-title">Attempts</div>
                                        <div className="tsv-grid-block-desc">1 attempt</div>
                                    </div>
                                </div>
                                <div className="oad-block flex-div">
                                    <img src={require("../../images/warning-icon.svg").default} alt="oad-icon" className="oad-icon" />
                                    <div className="oad-details">
                                        <div className="tsv-grid-block-title">Error</div>
                                        <div className="tsv-grid-block-desc">0 errrors</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="payment-track-history">
                            <hr className="payment-history-line" />
                            <div className="payment-block">
                                <span className="payment-history-time">00.06</span>
                                <span className="payment-history-dot"></span>
                                <span className="payment-history-desc">
                                    Attemted card payment option
                                </span>
                            </div>
                            <div className="payment-block">
                                <span className="payment-history-time">00.07</span>
                                <span className="payment-history-dot"></span>
                                <span className="payment-history-desc">
                                    Successful card payment 
                                </span>
                            </div>
                        </div>

                        <div className="tsv-details-list-wrapper">
                    <div className="tsv-row-header flex-div justify-content-btw">
                        <span className="tsv-title-txt">Service Information </span>
                        {/* <span className="tsv-status">Success</span> */}
                    </div>
                        <ul className="tsv-details-list">
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Service Type</span>
                                <span className="tsv-list-row-desc">IKEC</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Metre No</span>
                                <span className="tsv-list-row-desc">018928319</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Source </span>
                                <span className="tsv-list-row-desc">Baxipay</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Customer Phone No. </span>
                                <span className="tsv-list-row-desc">+2348147973627</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Service Name </span>
                                <span className="tsv-list-row-desc">Nil</span>
                            </li>
                            <li className="flex-div justify-content-btw">
                                <span className="tsv-list-row-title">Service Reference</span>
                                <span className="tsv-list-row-desc">00023230116065816005044700062</span>
                            </li>
                           
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillTransactionView
import { http } from '../../../../utility/http';
import { AncoreClient,  UriPath, OAuthCredential,Misc, IAMAuthenticationClient} from '@payrail/ancore'
// import  * as SDK from '../../../../dist/app.bundle';

const client = new AncoreClient( new UriPath('https://d30j1fl4d3p9wd.cloudfront.net'));

/**
 * Define function to make authentication request
 * @param {*email, password} payload 
 * @returns object that consist user payload
 */
export const loginApi = async (payload)  => {
  return new Promise( async(resolve, reject) => {
    try {

      client.handleMfaRequired = (evt) => {
        resolve(evt);
      }
      
     const session = await client.authenticate( payload.username,
      payload.secret,
        {
            uuidHash: payload.uuidHash
        }
    );
    console.log("___SESSION___", session);
    if (session) {
      resolve(session);
    }

    } catch (error) {
     const message = await  client.message();
     console.log("___MESSAGE___", message);
     console.log("___ERROR___", error);
      reject(error)
    }
   
  });
}; 

/**
 * Define function that register a user with email or phone number
 * @param {email | phone_number} payload 
 * @returns success object
 */
export const signupApi = (payload)  => {
  return new Promise( async(resolve, reject) => {
    try {
    
      client.handleOnboardingStatus = (evt) => {
        
        resolve(evt);
      }
      await client.onboarding(payload);
      
    } catch (error) {
      const message = await  client.message();
     console.log("___MESSAGE___", message);
     console.log("____ERROR__",error)
      reject(error)
    }
   
  });
}; 

/**
 * Define the function to verify OTP code sent to user email or phone number
 * @param {otp_code} payload 
 * @returns Success object 
 */
export const verifyOTPApi =  (payload) => {
  return new Promise( async(resolve, reject) => {
    try {
      client.handleOnboardingStatus = (evt) => {
        resolve(evt);
      }
      await client.onboarding(payload);
     
    } catch (error) {
      console.log("____ERROR__",error)
      reject(error)
    }
   
  });
};

/**
 * Define the function to verify OTP code sent to user email or phone number
 * @param {otp_code} payload 
 * @returns Success object 
 */
export const verifyMfaApi =  (payload) => {
  return new Promise( async(resolve, reject) => {
    try {
      const session = await client.authenticate( payload.username,
        payload.secret,
        {
            uuidHash: "96dfc68d005a867854665d29ac68c543d4844f226f8a6cde8b521e97d911c530",
            mfa: payload.mfa,
            signinResponse: payload.signinResponse
        }
    );

    if (session) {
      resolve(session);
    }
     
    } catch (error) {
      reject(error)
    }
   
  });
};

/**
 * Define function to verify user BVN data.
 * This function receive the user register BVN data.
 * @param {*} payload 
 * @returns Success object
 */
export const bvnVerificationApi = (payload)  => {
  
  return new Promise( async(resolve, reject) => {
    try {
      client.handleOnboardingStatus = (evt) => {
        
        resolve(evt);
      }
      await client.onboarding(payload);
     
    } catch (error) {
      console.log("____ERROR__",error)
      reject(error)
    }
   
  });
}; 

/**
 * Define function to create account for the user identity.
 * This method create identiy for the user on the database
 * @param {UserPayload} payload 
 * @returns 
 */
export const identitySignupApi = async (payload)  => {
  
    return new Promise( async(resolve, reject) => {
      try {
        
            const response = await client.onboarding(payload);
            resolve(response);
         
      } catch (error) {
        console.log("____ERROR__",error)
        reject(error)
      }
     
    });
  
}; 

/**
 * Define function to create account for the user identity.
 * This method create identiy for the user on the database
 * @param {UserPayload} payload 
 * @returns 
 */
export const signoutApi = async ()  => {
  
  return new Promise( async(resolve, reject) => {
    try {
      
          const response = await client.signout();
          resolve(response);
       
    } catch (error) {
      console.log("____ERROR__",error)
      reject(error)
    }
   
  });

}; 

/**
 * Define function to create account for the user identity.
 * This method create identiy for the user on the database
 * @param {UserPayload} payload 
 * @returns 
 */
export const getAccount = async ()  => {
  
  return new Promise( async(resolve, reject) => {
    try {
      
          const response = await client.accountinfo();
          resolve(response);
       
    } catch (error) {
      console.log("____ERROR__",error)
      reject(error)
    }
   
  });

};

/**
 * Define function to recover forgetpassword.
 * This method create identiy for the user on the database
 * @param {UserPayload} payload 
 * @returns 
 */
 export const forgetPasswordApi = async (payload)  => {
  
  const response = await http({
    method: 'post',
    url: '/accounts/users/',
    data: payload,
  });
 
  return response

}; 
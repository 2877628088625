import React, {useEffect} from "react";
import $ from "jquery";

import M from "materialize-css";

import "./Transactions.css";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../redux/reducers/auth";
import debounce from "debounce";
import Ongoing from "./Ongoing";
import Request from "./Request";
import { getOngoingTransaction, getRecentTransaction } from "../../redux/reducers/wallet";
// import TableWithoutContent from "./TableWithoutContent";

const Transactions = () => {

    const dispatch = useDispatch()
    const {auth = {}, wallet={}, customer} = useSelector(state=>state);
  

    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);

        $(".filter-dropdown-trigger").click(function(){
            $(this).parent().find(".filter-dropdown-content").slideToggle();
        });
    }, []);
    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);
    return (
        <div className="page-content-block-wrapper2">
            <div className="container full-height">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title">
                        <span>Transactions</span>
                    </h4>
                </div>

                <div className="row tab-wrapper">
                    <div className="col s12 pad0">
                        <ul className="tabs">
                            <li className="tab">
                                <a className="active flex-div" href="#ongoing-block">
                                    <span>Ongoing</span> 
                                </a>
                            </li>
                            <li className="tab">
                                <a className="flex-div" href="#request-block">
                                    <span>Request</span> 
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div id="ongoing-block" className="col s12 pad0 transaction-tab-blocks">
                        <Ongoing />
                    </div>

                    <div id="request-block" className="col s12 pad0 transaction-tab-blocks">
                        <Request />
                        {/* <TableWithoutContent /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Transactions;
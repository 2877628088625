import "./Invoice.css";

import ViewRequestModal from "./Modals/ViewRequest";

import { VIEW_REQUEST_MODAL_ID } from "../../../utility/modalIDs";
import InvoiceItem from "../../../components/InvoiceItem";
import { useState } from "react";
import M from "materialize-css";

const TableWithContent = ({invoices}) => {
    const [item, setItem] = useState({})
    const viewItem = (item) =>{
        setItem(item)
        const elemsModal2 = document.querySelector(`#${VIEW_REQUEST_MODAL_ID}`);
        M.Modal.getInstance(elemsModal2).open();
    }

    return(
    <div className="table-wrapper">
        <table id="subaccounts-table-with-content">
            <thead>
                <tr>
                    <th>CUSTOMER</th>
                    <th>AMOUNT</th>
                    <th >REQUESTED</th>
                    <th>INVOICE NO.</th>
                    <th>STATUS</th>
                </tr>
            </thead>
            <tbody>
            {invoices.map(((item)=>{
                                        
                        return(
                            <InvoiceItem item={item} onClick={viewItem} />
                        )
                    })
                )
            }
                {/* <tr className="ubip-cursor-p modal-trigger" data-target={VIEW_REQUEST_MODAL_ID}>
                    <td>jahido@gmail.com</td>
                    <td className="gray-txt">NGN 1,000.00</td>
                    <td>Oct 17, 2021</td>
                    <td>PL002</td>
                    <td className="ubip-success">Collected</td>
                </tr>
                <tr className="ubip-cursor-p  modal-trigger" data-target={VIEW_REQUEST_MODAL_ID}>
                    <td>jahido@gmail.com</td>
                    <td className="gray-txt">NGN 1,000.00</td>
                    <td>Oct 17, 2021</td>
                    <td>PL002</td>
                    <td className="ubip-pending ">Pending</td>
                </tr>
                <tr className="ubip-cursor-p  modal-trigger" data-target={VIEW_REQUEST_MODAL_ID}>
                    <td>folykay@gmail.com</td>
                    <td className="gray-txt">NGN 20,000.00</td>
                    <td>Oct 17, 2021</td>
                    <td>PL001</td>
                    <td className="ubip-pending ">Pending</td>
                </tr> */}
            </tbody>
        </table>
        <ViewRequestModal item={item} />
    </div>
    )
}

export default TableWithContent;
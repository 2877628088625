import React, { useState } from "react";
import { CardSelectField } from "../cardSelectField";
import { InputField } from "../InputField"

const FundCardModal = () => {
    const [error] = useState({})
    const [value, setValue] = useState({})
    const handleInput = (e) =>{
        setValue({...value, [e.target.id]: e.target.value})
    }
    return(
        <div id="fund-card-modal" className="modal dashboard-modal card-form-modal">
            <div className="modal-content">
                <img src={require("../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />  

                <h4 className="modal-block-title">Fund Card</h4>  
            
                <form action="#" className="card-modal-form">
                    <InputField 
                        type="text" 
                        label="Amount" 
                        onChange={handleInput} 
                        error={error['Amount']}
                        id={{input: "fund-amount"}} 
                        classname={{div:"input-field col s12 mag-d-0 pad0", error:"error-text", input:"inp-field", }} 
                        placeholder="NGN"
                    />

                    <div className="transaction-fee-txt">
                        <span>+0 Transaction Fee</span>
                    </div>

                    <CardSelectField 
                        label="Cards" 
                        onChange={e => setValue(e.target.id, e.target.value)}  
                        id={{select: "cards"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0 country-list-wrapper select-wrapper-2", label:"active", }} 
                    />
                    
                    <div className="submit-btn-wrapper full-width">
                        <button type="button" className="submit-btn pointer pry-btn modal-close modal-trigger full-width" data-target="fund-verification-code-modal">Done</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FundCardModal
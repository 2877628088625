import React from "react";
import M from "materialize-css";

const CustomerItem = ({email="",status="",phone_number="",first_name="",last_name=""}) =>{

    return(
        <tr>
            <td>{status}</td>
            <td className="gray-txt">{email}</td>
            <td>{first_name}</td>
            <td>{last_name}</td>
            <td>{phone_number}</td>
        </tr>
    )
}

export default CustomerItem
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import M from "materialize-css";
import { InputField } from "../../../components/InputField";
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from "react-redux";
import PhoneNumber from "../../../components/InputField/PhoneNumber";

const PersonalSetingsForm = ({toggleProfileBlock}) => {
    const {auth = {}} = useSelector(state=>state);
    const {_authorizationCredential, onboardingResponse,email, phone} = auth.user
    const {subscriberAccountName,kyc  } = _authorizationCredential._certificate
    const {firstname,lastname,} = kyc || onboardingResponse
    const [error, setError] = useState({});
    const [value, setValues] = useState("");
    
    useEffect(()=> {
        $("#user-pix-filepath").change(function(){
            var uploadedImageSrc1 = window.URL.createObjectURL(this.files[0]);
            $("#personal-profile-edit-form .upload-logo").hide();
            $("#personal-profile-edit-form .uploaded-img-div").show();
            $("#uploaded-img").attr("src", uploadedImageSrc1);
        });
        $("#business-logo-filepath").change(function(){
            var uploadedImageSrc2 = window.URL.createObjectURL(this.files[0]);
            $("#business-profile-edit-form .upload-logo").hide();
            $("#business-profile-edit-form .uploaded-img-div").show();
            $("#uploaded-img-2").attr("src", uploadedImageSrc2);
        });

        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    },[]);

    const formSchema = Yup.object().shape({
        firstname: Yup.string(),
        lastname: Yup.string(),
        email: Yup.string().email(),
        phone: Yup.number(),
        role: Yup.string(),
    });

    /**
     * Yup configuration for form validation
     */
     const { register, formState: { errors }, handleSubmit, watch, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            firstname,
            lastname,
            email,
            phone
            
        }
      })

      useEffect(()=>{
          setError(errors)
      },[errors]);

    return (
        <form action="#" id="personal-profile-edit-form" onSubmit={e => e.preventDefault()} className="edit-profile-form dashboard-form">
        <div className="edit-profile-row">
            <div className="profile-row-header">
                <h4 className="profile-row-title">Profile Photo</h4>
                <span className="profile-row-desc prd-1">
                    Choose a new avatar to be used across Payrail
                </span>
            </div>
            <div className="flex-div change-avatar-wrapper">
                <div className="file-field input-field">
                    <div className="btn">
                        <div className="upload-logo">
                            <img src={require("../../../images/default-pix.svg").default} alt="default" />
                        </div>
                        <div className="uploaded-img-div">
                            <img src="" alt="user-img" id="uploaded-img" />
                        </div>
                        <input type="file" id="user-pix-filepath" accept="image/x-png,image/gif,image/jpeg" />
                    </div>
                </div>
                <div className="username-wrapper">
                    <span className="change-photo-desc">
                        We accept files in PNG or JPG format, with a maximum size of 5 MB. Change photo
                    </span>
                </div>
            </div>
        </div>
        <div className="edit-profile-row">
            <div className="profile-row-header">
                <h4 className="profile-row-title">Name</h4>
                <span className="profile-row-desc">
                    Changes cannot be made to your name after account creation.
                </span>
            </div>
            <div className="input-field single-field grid-field">
                    <InputField 
                        type="text" 
                        label="First Name" 
                       
                        value={watch('firstname')}
                        {...register('firstname',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['firstname']? error['firstname'].message: ""} 
                        id={{input: "firstname"}} 
                        classname={{div:"input-field", error:"error-text", input:"form-inp-field", }} 
                    />
                    <InputField 
                        type="text" 
                        label="Last Name" 
                        
                        value={watch('lastname')}
                        {...register('lastname',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['lastname']? error['lastname'].message: ""} 
                        id={{input: "lastname"}} 
                        classname={{div:"input-field", error:"error-text", input:"form-inp-field", }} 
                    />
            </div>
        </div>
        <div className="edit-profile-row">
            <div className="profile-row-header">
                <h4 className="profile-row-title">Email address</h4>
                <span className="profile-row-desc">
                    All communications and activity notifications from your account will be sent to your email address.
                </span>
            </div>
            <div className="input-field single-field">
                <InputField 
                    type="email" 
                    label="Email Address" 
                    value={watch('email')}
                    {...register('email',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['email']? error['email'].message: ""} 
                    id={{input: "email"}} 
                    classname={{div:"input-field", error:"error-text", input:"form-inp-field", }} 
                />
            </div>
        </div>
        <div className="edit-profile-row">
            <div className="profile-row-header">
                <h4 className="profile-row-title">Phone number</h4>
                <span className="profile-row-desc">
                    Your phone number can be used as a security measure to validate your actions on the account.
                </span>
            </div>
            <div className="input-field single-field">
            <PhoneNumber 
                type="text" 
                label="Phone number" 
                value={watch('phone')}
                {...register('phone',{ required: true })}
                onChange={e =>{ 
                    setValue('phone', e)
                }} 
                error={error && error['phone']? error['phone'].message: ""}
                id={{input: "phone"}} 
                classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
            />
            </div>
        </div>
        <div className="edit-profile-row">
            <div className="profile-row-header">
                <h4 className="profile-row-title">Roles and privileges</h4>
                <span className="profile-row-desc">
                    Show the actions you are permitted to carry out with this acount.
                </span>
            </div>
            <div className="input-field single-field">
                <InputField 
                    type="text" 
                    label="Your Role" 
                    {...register('role',{ required: true })}
                    onChange={e => setValue(e.target.id, e.target.value)} 
                    error={error && error['role']? error['role'].message: ""}
                    id={{input: "your_role"}}
                    readOnly
                    defaultValue={"Owner"}
                    classname={{div:"input-field ", error:"error-text", input:"form-inp-field", }} 
                />
            </div>
        </div>
        <div className="view-link-wrapper">
            <NavLink to="/dashboard/administration" className="view-link">View Privileges</NavLink>
        </div>
        <div className="submit-btn-wrapper flex-div justify-content-end">
            <button className="white-submit-btn" 
            onClick={() => {toggleProfileBlock("edit-personal-profile-block", "profile-block-wrapper"); M.toast({html: 'Changes Saved Successfully'})}}
            >Save Changes</button>
        </div>
    </form>
    )
}

export default PersonalSetingsForm;
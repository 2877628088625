import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import '../css/auth.css';
import $ from 'jquery';
import M from 'materialize-css';
import * as Yup from 'yup'
import { useNavigate, NavLink  } from 'react-router-dom';
import {signup, signupFailure, signupSuccess} from "../redux/actions/auth/registeration"
import { InputField } from '../components/InputField';
import { PasswordField } from '../components/passwordField';
import { SelectField } from '../components/selectField';
import { Loader } from "../components/loader";
import { setLoading, signupAction } from "../redux/reducers/auth";
import { signupApi } from "../redux/sagas/requests/auth";
import { setToast } from "../redux/reducers/toast";

    /**
     * Constructs form validation object for phone number registraion using Yup library
     * @param phone : User phone number.
     * @param countries : Country code of the phone nuber.
     * @param referral : Refferal code.
     */
    const formSchema = Yup.object().shape({
        phone: Yup.string().required("Required"),
        countries: Yup.string(),
        referral: Yup.number(),
        
    })

    /**
     * Phone number signup componenet
     */
    const PhoneSignup = () => {

        const dispatch = useDispatch()
        const navigate = useNavigate();
        const  { user, } = useSelector(state=> state.auth)
        const [error, setError] = useState({})
        const [loading, setLoading] = useState(false)

        /**
         * Yup configuration for form validation
         */
        const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
            resolver: yupResolver(formSchema),
          })
    
          useEffect(()=>{
              setError(errors)
          },[errors])
    
    
    /**
     * onSubmit function to trigger form submission for phone number registration.
     * @param phone : User phone number.
     * @param countries : Country code of the phone nuber.
     * @param referral : Refferal code.
     */
        const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){
            createAccount(data)
            
            }
        }

        const createAccount = (data) => {
            console.log('__DATA', data)
            setLoading(true)
            signupApi(data).then(res=>{
                console.log('__RES', res)

                dispatch(signup({...data, onboardingResponse:{...res.onboardingOption.onboardingResponse}}))
                
                if (res.status === 'processing') {
                    navigate("/signup/verify-bvn") 
                }else if (res.status === 'verified'){
                    navigate("/login") 
                }else{
                    navigate("/signup/verify/email") 
                }
                  
                setLoading(false)
            }).catch(err=>{
                console.log('___EMAIL ERROR', err)
                dispatch(setToast({type:'error', message:'Error unable to create account'}))
                setLoading(false)
            
            })
          }
        
    useEffect(() => {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        })

        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $("#referral-field-trigger").click(function(){
            $(this).parent().hide();
            $("#referral-field").slideDown();
        });
    }, []);

    return(
        <div className="auth-block">
            <form id="signup-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Let’s get you started
                    </h1>
                    <p className="greet-desc">
                        Please, check your browser’s address bar to be sure you’re on <br />
                        <a href="https://app.payrail.com">https://app.payrail.com</a>
                    </p>
                </div>
                <div className="input-row row">
                    {/* <SelectField 
                        items={[
                            {key: "Nigeria", value: "Nigeria"}
                        ]}
                        label="Country" 
                        {...register('countries',{ required: true })}
                        onChange={e => setValue(e.target.id, e.target.value)} 
                        id={{select: "countries"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0 country-list-wrapper", label:"active", }} 
                    /> */}
                    <InputField 
                        type="tel"  
                        label="Phone number" 
                        {...register('phone',{ required: true })}
                        onChange={e => setValue(e.target.id, e.target.value)} 
                        error={error && error['phone']? error['phone'].message: ""} 
                        id={{input: "phone"}} 
                        classname={{div:"input-field col s12 mag-d-0 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <InputField 
                        type="text" 
                       
                        label="Invite Code" 
                        {...register('referral',{ required: false })}
                        onChange={e => setValue(e.target.id, e.target.value)} 
                        id={{div: "referral-field", input: "referral"}} 
                        error={error && error['referral']? error['referral'].message: ""}
                        classname={{div:"input-field col s12 mag-d-0 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <div className="alt-link-div">
                        Referred? <button type="button" className="txt-btn pry-color" id="referral-field-trigger">
                            <u>Click here</u></button>
                    </div>
                </div>

                {loading&&<Loader />}

                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <div className="txt-link-side">
                        <NavLink to="/signup" className="pry-color">Sign up with Email instead</NavLink>
                    </div>
                    <div className="btn-side">
                        <button type="submit" className="submit-btn pointer">Create Account </button>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default PhoneSignup;
import M from "materialize-css";
import { useEffect }  from "react";

export const CardSelectField = ({id ="", classname, label, ...otherprop}) =>{
    useEffect (()=> {
        var elemsSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemsSelect);
    }, []);

    return(
        <div className={`${classname.div}`} id={id.div}>
            <label for={id.select} className={classname.label}>{label}</label>
            <select  id={id.select}  {...otherprop}>
                <option value="" selected disabled>Select a payrail card</option>
                <option value="Physical Card">Physical Card</option>
                <option value="Virtual Card">Virtual Card</option>
            </select>
            <img src={require("../images/caret-down.svg").default} alt="caret" className="caret-icon" />
        </div>
    )

}

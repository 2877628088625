import { useEffect } from "react";
import $ from "jquery";
import M from "materialize-css"

import LocationSearchBox from "../../components/locationSearcBox";
import { useSelector } from "react-redux";

const PendingTask = () => {
    const {auth = {}} = useSelector(state=>state)
    const {user={}} = auth
    const {first_name="Olamide", last_name = "", username = "", email = ""} = user
    useEffect(()=> {
        $("#user-pix-filepath").change(function(){
            var uploadedImageSrc1 = window.URL.createObjectURL(this.files[0]);
            $("#personal-details .upload-logo").hide();
            $("#personal-details .uploaded-img-div").show();
            $("#uploaded-img").attr("src", uploadedImageSrc1);
        });
        $("#business-logo-filepath").change(function(){
            var uploadedImageSrc2 = window.URL.createObjectURL(this.files[0]);
            $("#business-details .upload-logo").hide();
            $("#business-details .uploaded-img-div").show();
            $("#uploaded-img-2").attr("src", uploadedImageSrc2);
        });

        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        $("#done-btn").click(function(){
            $("#kyc-form fieldset:not(#review-kyc-application)").hide();
            $("#review-kyc-application").show();
            $("#done-btn-wrapper").hide();
        })
        
        $(".review-field").click(function(){
            $("#done-btn-wrapper").show();
            $("#kyc-form fieldset:not(#review-kyc-application) .submit-btn").hide();
        })
        
    },[])

    $("#confirm-info-accuracy").change(function() {
        if (this.checked){
            $("#submit-review-btn").removeAttr("disabled");
        } else {
            $("#submit-review-btn").attr("disabled", true);
        }
    });

    const toggleFieldset = (hiddenSection, displayedSection) => {
        document.getElementById(hiddenSection).style.display = "none";
        document.getElementById(displayedSection).style.display = "block";
    }
    const showSocialInput = (displayedSocialField, hiddenTrigger) => {
        $("#" + displayedSocialField).slideDown();
        document.getElementById(hiddenTrigger).style.display = "none";
    }
    const hideSocialInput = (hiddenSocialField, displayedTrigger) => {
        $("#" + hiddenSocialField).slideUp();
        document.getElementById(displayedTrigger).style.display = "flex";
    }

    return (
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-header-wrapper">
                    <div className="left-align">
                        <h2 className="page-title">Complete your KYC</h2>
                        <span>
                            {first_name}, it’s important you update your profile to increase your transaction and loan purpose
                        </span>
                    </div>
                </div>

                <div className="pending-tasks-wrapper">
                    <div className="kyc-form-wrapper">
                        <form action="#" id="kyc-form" className="dashboard-form">
                            <div className="row">
                                <fieldset id="more-kyc-details">
                                    <div className="flex-div form-header justify-content-btw">
                                        <div className="flex-div change-avatar-wrapper">
                                            <div className="file-field input-field">
                                                <div className="btn">
                                                    <div className="upload-logo">
                                                        <img src={require("../../images/default-pix.svg").default} alt="default" />
                                                    </div>
                                                    <div className="uploaded-img-div">
                                                        <img src="" alt="user-img" id="uploaded-img" />
                                                    </div>
                                                    <input type="file" id="user-pix-filepath" accept="image/x-png,image/gif,image/jpeg" />
                                                </div>
                                            </div>
                                            <div className="username-wrapper">
                                                <h4 className="username">{`${first_name} ${last_name}`}</h4>
                                                <span>Choose a new avatar</span>
                                            </div>
                                        </div>
                                        <div className="page-completed-number">
                                            <span>1 of 2 complete</span>
                                        </div>
                                    </div>

                                    <div className="input-field multiple-fields">
                                        <div className="rel">
                                            <select id="id-type">
                                                <option value="" disabled>Choose form of ID</option>
                                                <option value="Driver's License">Driver's License</option>
                                                <option value="International Passport">International Passport</option>
                                                <option value="Voter's Card">Voter's Card</option>
                                                <option value="NIN">NIN</option>
                                            </select>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>
                                        <div className="rel">
                                            <input id="id-number" type="text" placeholder="ID Number" className="sub-inp-field" />
                                        </div>
                                        <div className="file-field rel">
                                             <input type="file" />
                                            <div className="btn choose-file-btn">
                                                <span>Choose file</span>
                                            </div>
                                            <div className="file-path-wrapper">
                                                <input className="file-path sub-inp-field last-inp-field" placeholder="Upload ID" type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-field multiple-fields">
                                        <input id="rc_number" type="text" placeholder="RC Number (optional)" className="form-inp-field mag-0" />
                                        <div className="file-field rel">
                                             <input type="file" />
                                            <div className="btn choose-file-btn">
                                                <span>Choose file</span>
                                            </div>
                                            <div className="file-path-wrapper">
                                                <input className="file-path sub-inp-field last-inp-field" placeholder="Upload CAC Certificate (optional)" type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="submit-btn-wrapper flex-div justify-content-end">
                                        <button type="button" className="submit-btn pry-btn" onClick={() => toggleFieldset("more-kyc-details", "review-kyc-application")}>Continue</button>
                                    </div>
                                </fieldset>

                                <fieldset id="review-kyc-application" className="hidden">
                                    <div className="flex-div form-header justify-content-end">
                                        <div className="page-completed-number">
                                            <span>2 of 2 complete</span>
                                        </div>
                                    </div>
                                    <div className="form-header-wrapper">
                                        <h4 className="form-header-title">Review your application</h4>
                                        <span className="form-header-desc">
                                            Here’s a final look at your application, make sure you have met all our registration requirements. 
                                        </span>
                                    </div>
                                    <div className="review-field flex-div" id="personal-info" onClick={() => toggleFieldset("review-kyc-application", "more-kyc-details")}>
                                        <span className="review-field-txt">KYC Information</span>
                                        {/* Change status icon to "../../images/success-review-icon.svg" if application is successful  */}
                                        <img src={require("../../images/pending-review-icon.svg").default} alt="pending-status" className="review-status-icon" />
                                    </div>

                                    <div className="checkbox-field">
                                        <label>
                                            <input type="checkbox" className="filled-in" id="confirm-info-accuracy" />
                                            <span>I confirm that the information provided are accurate and truthful.</span>
                                        </label>
                                    </div>

                                    <div className="submit-btn-wrapper flex-div justify-content-end sbw-2">
                                        <button type="submit" className="submit-btn pry-btn" id="submit-review-btn" disabled>Submit</button>
                                    </div>
                                </fieldset>
                            </div>
                        </form>

                        <div id="done-btn-wrapper" className="hidden">
                            <button type="button" className="submit-btn pry-btn" id="done-btn">Save Changes</button>
                        </div>

                        <LocationSearchBox />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PendingTask;
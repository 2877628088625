import ModalWrapper from "../../../../components/UI/ModalWrapper";
import CustomTimeAndDatePicker from "./components/CustomTimeAndDatePicker";
import { SCHEDULE_PAYMENT_MODAL_ID, RECURRING_PAYMENT_MODAL_ID } from "../../../../utility/modalIDs";

const SchedulePayment = () => {
    return (
        <ModalWrapper modalID={SCHEDULE_PAYMENT_MODAL_ID}>
            <div className="upbm-header-wrapper">
                <h4 className="upbm-header-mb">Schedule Payment</h4>
                <div className="modal-desc">You are not debited until the schedule time</div>
            </div>
            <CustomTimeAndDatePicker />
            {/* <div className="time-date-wrapper flex-div justify-content-btw">
                <InputField 
                    type="text" 
                    id={{input: "tdw-date"}} 
                    classname={{div:"input-field datepicker-field", input:"inp-field center datepicker", }} 
                    defaultValue="NOV 1,  2021"
                />
                <InputField 
                    type="text" 
                    id={{input: "tdw-time"}} 
                    classname={{div:"input-field timepicker-field", input:"inp-field center timepicker", }} 
                    defaultValue="12:000pm"
                />
            </div> */}
            <div className="vbmm-d-flex">
                <button className="pry-btn modal-close">
                    Cancel
                </button>
                <button className="pry-btn modal-trigger modal-close" data-target={RECURRING_PAYMENT_MODAL_ID}>
                    Confirm
                </button>
            </div>
        </ModalWrapper>
    );
};

export default SchedulePayment;
import { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';

import "./PinModal.css";

import $ from "jquery";

import ModalWrapper from "../UI/ModalWrapper";

import { PIN_MODAL } from "../../utility/modalIDs";
import { NavLink } from "react-router-dom";

const PinModal = ({ backTo = "",modalToOpenID = "", successModalID = "" }) => {
    const [otp, setOTP] = useState('');

    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);

    return(
        <ModalWrapper modalID={PIN_MODAL} backTo={backTo} modalToOpenID={modalToOpenID} showBackBtn>
            <div className="pm-pin">
                    <h4>Enter transaction PIN</h4>
                    <small>Enter your 4-digit Payrail PIN to approve this transfer</small>
                </div>

                <div className="pm-pin-box-container">
                    <div>
                        <div id="debit-card-codes">
                            <div className="input-row row">
                                <OtpInput
                                    value={otp}
                                    onChange={(val)=>{
                                        setOTP(val)
                                    }}
                                    numInputs={4}
                                    spmarator={<span> </span>}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pm-pin-reset">
                    <small>
                        Get trouble? Reset your PIN in <NavLink to="/dashboard/settings" className="pry-color">account security settings</NavLink>
                    </small>
                </div>

                <button className="pending-btn pry-btn modal-trigger pm-pin-okay-btn modal-close " data-target={successModalID}>
                    Okay
                </button>
        </ModalWrapper>
    )
}

export default PinModal;
// import React, {useEffect, useState} from 'react'

import { useEffect } from "react";
import M from "materialize-css";

export const SelectField = ({id ="", children, classname, label,items=[], ...otherprop}) =>{
    
    useEffect(()=> {
        var elemSelect = document.getElementById(`${id.select}`);
        M.FormSelect.init(elemSelect);
    }, []);

    return(
        <div className={`${classname.div}`} id={id.div}>
            <select  id={id.select}  {...otherprop}>
                {items.map(({key="", value=""},index)=>(
                    <option key={`${key}-${index}`} value={value}>{key}</option>
                    ))}
                
            </select>
            <label htmlFor={id.select} className={classname.label}>{label}</label>
            <img src={require("../images/caret-down.svg").default} alt="caret" className={`${classname.img ? classname.img : "caret-icon"}`} />
            {children}
        </div>
    )

}

import React, { useEffect, useState }  from "react";
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery"
import { InputField } from "../../../../../components/InputField";
import { REQUEST_PAYMENT_MODAL_ID } from "../../../../../utility/modalIDs";
import M from "materialize-css";

/**
 * Constructs form validation object for user old signup using Yup library
 * @param password : User password.
 */
 const formSchema = Yup.object().shape({
    email: Yup.string().required("Required").email(),
    
    
})
const ProfessionalInvoiceTab = ({ goToDetails }) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState({})
    

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
    resolver: yupResolver(formSchema),
    })

    useEffect(()=>{
        setError(errors)
    },[errors])

    /**
     * onSubmit function to trigger form submission for phone number registration.
     * @param email : User email address.
     * @param phone : User phone number.
     * @param password : Account password.
     */
     const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){

            goToDetails("details",data)
            const elemsModal1 = document.querySelector(`#${REQUEST_PAYMENT_MODAL_ID}`);
            M.Modal.getInstance(elemsModal1).close();
            
            }
        }

    const showAllFieldsHandler = (e) => {
        setShow(!show);
    };

    useEffect(()=> {
        $(".rpmpit-underline.rpmsit-min-depo-text").click(function(){
            $(this).hide();
            $(".input-field").removeClass("rpmsit-rel")
        })
    },[])

    return (
        <div>
            <p className="rpmsit-description">Set line items, tax etc. and invoice a customer. PDF included.</p>
            <form  className="dashboard-form modal-form" onSubmit={handleSubmit(onSubmit)}>
               
                <InputField 
                    type="email" 
                    label="Email Address" 
                    {...register('email',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['email']? error['email'].message: ""} 
                    id={{input: "email"}} 
                    classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                > 
                 <small className="rpmsit-min-depo-text rpmpit-underline rpmpit-cursor-p" onClick={() => showAllFieldsHandler()}>Update customer details?</small>
                </InputField>

                {
                    show ?
                    <>
                       
                        <InputField 
                            type="first_name" 
                            label="First Name" 
                            {...register('first_name',{ required: true })}
                            onChange={e =>{setValue(e.target.id, e.target.value)}}
                            error={error && error['first_name']? error['first_name'].message: ""} 
                            id={{input: "first_name"}} 
                            classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                        />
                        <InputField 
                            type="last_name" 
                            label="Last Name" 
                            {...register('last_name',{ required: true })}
                            onChange={e =>{setValue(e.target.id, e.target.value)}}
                            error={error && error['last_name']? error['last_name'].message: ""} 
                            id={{input: "last_name"}} 
                            classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                        />

                        <InputField 
                            type="phone_number" 
                            label="Phone Number" 
                            {...register('phone_number',{ required: true })}
                            onChange={e =>{setValue(e.target.id, e.target.value)}}
                            error={error && error['phone_number']? error['phone_number'].message: ""} 
                            id={{input: "phone_number"}} 
                            classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                        />
                        
                        
                    </>
                    : null
                }

                <button type="submit" className="pry-btn rpmsit-btn" >
                    Proceed to Details
                </button>
            </form>
        </div>
    )
};

export default ProfessionalInvoiceTab;
import { NavLink } from "react-router-dom";
// import $ from 'jquery'
import M from 'materialize-css';
import { useSelector } from "react-redux";
// import {useDispatch, useSelector} from 'react-redux'

const GettingStarted = () => {
    const {auth = {}} = useSelector(state=>state)
    const {user={}} = auth
    const {first_name="Olamide"} = user
    
    document.addEventListener('DOMContentLoaded', function() {
        var elemsDropdown = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elemsDropdown, {
            coverTrigger: false
        });
    });

    const CopyToClipboard = (selectedId) => {
        /* Get the text field */
        var copyText = document.getElementById(selectedId);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        
        /* Alert the copied text */
        M.toast({html: 'API key copied'})
    }
    
    return ( 
        <div className="page-content-wrapper">
            <div className="container">
                <div className="greeting-wrapper">
                    <h2 className="greet-user flex-div">
                        <span>Hi {first_name}</span>
                        <img src={require("../../images/waving-hand.svg").default} alt="waving hand" className="waving-hand" />
                    </h2>
                    <span>Welcome to Payrail let’s get your account up and running </span>
                </div>

                {/* <div className="change-language-wrapper">
                    <button className="btn language-menu-btn flex-div dropdown-trigger" data-target='language-dropdown'>
                        <img src={require("../../images/globe.svg").default} alt="icon" className="globe-icon" />
                        <span>Choose language</span>
                        <img src={require("../../images/caret-down-orange.svg").default} alt="icon" className="dropdown-caret" />
                    </button>
                    
                    <ul id='language-dropdown' className='dropdown-content'>
                        <li><a href="#!">English</a></li>
                        <li><a href="#!">French</a></li>
                        <li><a href="#!">Spanish</a></li>
                        <li><a href="#!">German</a></li>
                    </ul>
                </div> */}

                <div className="pending-actions-wrapper">
                    <div className="pending-action-block">
                        <div className="center">
                            <img src={require("../../images/pending-upload.svg").default} alt="pending-icon" className="pending-img" />
                            <p className="pending-description">
                                Complete your KYC verification by uploading valid government ID 
                            </p>
                            <div className="pending-btn-wrapper">
                                <NavLink to="/dashboard/pending-tasks" className="pending-btn pry-btn">Complete KYC</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="pending-action-block">
                        <div className="center">
                            <img src={require("../../images/pending-tour.svg").default} alt="pending-icon" className="pending-img" />
                            <p className="pending-description">
                                Learn what you can do with the Payrail Urban Dashboard
                            </p>
                            <div className="pending-btn-wrapper">
                                <button className="pending-btn pry-btn">Take a Tour</button>
                            </div>
                        </div>
                    </div>
                    <div className="pending-action-block">
                        <div className="center">
                            <img src={require("../../images/pending-chat.svg").default} alt="pending-icon" className="pending-img" />
                            <p className="pending-description">
                                Need some answers to questions you might have just get in touch
                            </p>
                            <div className="pending-btn-wrapper">
                                <button className="pending-btn pry-btn">Go to Support</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="docs-n-test-keys-wrapper">
                    <div className="docs-wrapper">
                        <div className="left-align">
                            <h4 className="dntk-title">API Documentation</h4>
                            <p className="dntk-description">
                                Our documentation contains the Libraries, APIs, and SDKs you need to 
                                integrate Payrail in your website or app.
                            </p>
                            <div className="pry-btn-wrapper">
                                <button className="pry-btn">Go to Doc</button>
                            </div>
                        </div>
                    </div>
                    <div className="test-keys-wrapper">
                        <div className="left-align">
                            <h4 className="dntk-title">API Documentation</h4>
                            <p className="dntk-description">
                                Also available in Settings &gt; API Keys & Webhooks
                            </p>
                            <div className="api-keys-wrapper">
                                <div className="api-key-block">
                                    <div className="api-key-field flex-div">
                                        <input className="api-key" id="test-key" readOnly value="sk_test_04fa3e8711cefe9554b6ae2e06ca7c988bc15eca" />
                                        <img src={require("../../images/red-copy-icon.svg").default} alt="copy" className="copy-icon" onClick={() => CopyToClipboard("test-key")} />
                                        <small className="api-label">Test key</small>
                                    </div>
                                </div>
                                <div className="api-key-block">
                                    <div className="api-key-field flex-div">
                                        <input className="api-key" id="secret-key" readOnly value="sk_test_04fa3e8711cefe9554b6ae2e06ca7c988bc15eca" />
                                        <img src={require("../../images/red-copy-icon.svg").default} alt="copy" className="copy-icon" onClick={() => CopyToClipboard("secret-key")} />
                                        <small className="api-label">Secret key</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default GettingStarted;
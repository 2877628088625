import { NavLink } from "react-router-dom";
import { useEffect } from "react";
// import $ from 'jquery'
// import M from 'materialize-css';
import ColoredWrapper from "../../components/UI/coloredWrapper/coloredWrapper";
import '../../css/modals.css';
import Wrapper from "../../components/UI/Wrapper";
// Link to DaterangePicker:  https://rsuitejs.com/components/date-range-picker/
import 'rsuite/dist/rsuite.min.css';
import DateRangePicker from 'rsuite/DateRangePicker';

// Link to Charts: https://github.com/hustcc/echarts-for-react
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from "react-redux";
import Transaction from "../../components/transaction";
import { getBalance, getRecentTransaction } from "../../redux/reducers/wallet";
import { setLoading } from "../../redux/reducers/auth";

const DashboardHome = () => {
    const dispatch = useDispatch();
    const {auth = {}, wallet={}} = useSelector(state=>state);
    const {recent_transaction=[], wallet_balance={}, bank_detail={}} = wallet

    useEffect(()=> {
        dispatch(setLoading(true))
        dispatch(getBalance());
        dispatch(getRecentTransaction())
        dispatch(setLoading(false))

    },[])

    const barOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              saveAsImage: { show: true }
            }
          },
        xAxis: {
            type: 'category',
            data: ['Jan','Feb','March','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov','Dec']
        },
        yAxis: {
            type: 'value',
            show: false
        },
        series: [
            {
              data: [120, 200, 150, 80, 70, 110, 130, 100],
              type: 'bar',
              roundCap: true,
              color: "#777777",
              barWidth: 4,
              barGap: '100%',
              itemStyle: {
                borderRadius: [8, 8, 0, 0],
              },
              tooltip: {
                valueFormatter: function (value) {
                  return '₦' + value;
                }
              },
              name: 'successful',
            },
            {
              data: [120, 200, 150, 80, 70, 110, 130, 70],
              type: 'bar',
              roundCap: true,
              color: "#EA6212",
              barGap: '100%',
              barWidth: 10,
              itemStyle: {
                borderRadius: [8, 8, 0, 0]
              },
              tooltip: {
                valueFormatter: function (value) {
                  return '₦' + value;
                }
              },
              name: 'failed',
            }
        ],
        grid: {
            left: '0%',
            right: '0%',
            top: '0',
            bottom: '0',
            containLabel: true
        },
    };

    const pieOption_1 = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
            show: true,
            right: 0,
            bottom: 0,
            orient: 'vertical'
          },
          grid: {
              left: '0',
              right: '0',
              top: '0',
              bottom: '0',
          },
        series: [
          {
            name: 'Success Rate',
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '10'
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 2, name: 'Active' },
              { value: 1, name: 'Inactive' }
            ],
            color: ['#EE6B25', '#777777']
          }
        ]
    };

    const pieOption_2 = {
        tooltip: {
          trigger: 'item'
        },
        
        legend: {
            show: true,
            right: 0,
            bottom: 0,
            orient: 'vertical'
          },
          grid: {
              left: '0',
              right: '0',
              top: '0',
              bottom: '0',
          },
        series: [
          {
            name: 'Transactions Overview',
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '10',
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 14967, name: 'Successful' },
              { value: 6008, name: 'Failed' },
              { value: 304, name: 'Reversed' },
              
            ],
            color: ['#EE6B25', '#F4A11D', '#146a90']
          }
        ]
    };
    
    return ( 
        <div className="page-content-wrapper">
            <div className="container">
                <div className="section-row-wrapper grid-row-1">
                    

                    <div className="section-block expense-overivew-block">
                        <div className="expense-block-header section-block-header-2 flex-div">
                            <div className="expense-income-div flex-div">
                                <div className="expense-div flex-div">
                                    <span className="disc"></span>
                                    <span className="eid-txt">Successful </span>
                                    <b className="eid-amount" title="₦100,000,000.00">₦100M</b>
                                </div>
                                <div className="income-div flex-div">
                                    <span className="disc"></span>
                                    <span className="eid-txt">Failed</span>
                                    <b className="eid-amount" title="₦120,000,000.00">₦120M</b>
                                </div>
                            </div>
                            <div className="flex-div daterange-filter">
                                <DateRangePicker onChange={(v)=>{
                                    console.log('___DATE___', v);
                                }} placeholder="1 Sept 21 - 30 Sept 21" format="dd-MMM-yy" placement="bottomEnd" />
                            </div>
                        </div>
                        <div className="balance-wrapper">
                            <span className="balance-wrapper-txt">Total Transactions</span>
                            <h4 className="wallet-balance">
                                <small >₦</small>{wallet_balance.balance || "35,000,000.09"}
                            </h4>
                            
                        </div>
                        <div className="expense-income-chart-wrapper">
                            <ReactECharts height="200" width="100%" option={barOption} />
                        </div>
                    </div>
                </div>
               
                       
                <div className="loan-overview-content">
                    <div>
                        <ColoredWrapper outlined>
                            <div className="loan-status-header">
                                <span>Merchant Overview</span>
                            </div>
                            <div className="loan-pie-chart">
                                    <ReactECharts option={pieOption_1} />
                            </div>
                            <div className="loan-chart-hr"></div>
                            
                            <div className="section-row-wrapper grid-row-3">
                                <div className="loan-status-bottom">
                                   
                                        <small>Total Merchant</small>
                                        <span>2,353,333.33</span>
                                  
                                </div>
                                <div className="loan-status-bottom">
                                   
                                        <small>Daily Active Users</small>
                                        <span>333</span>
                                   
                                </div>
                                <div className="loan-status-bottom">
                                   
                                        <small>Monthly Active Users</small>
                                        <span>353,333</span>
                                   
                                </div>
                            </div>

                        </ColoredWrapper>

                    </div>

                    <div>
                        
                        <Wrapper className="sakam" outlined grey >
                 <table id="customers-table">
                    <thead>
                        <tr>
                           
                            <th>Today</th>
                            <th>Weekly</th>
                            <th>Month</th>
                            <th>Year</th>
                           
                        </tr>
                    </thead>
                    <tbody>

                        <tr className="thp-transaction-item">
                        <td>20</td>
                        <td>1358</td>
                        <td>1389</td>
                        <td>21049</td>
                        </tr>
                   
                    </tbody>
                </table>
                        </Wrapper>
<br />
<div>
                        <ColoredWrapper outlined>
                            <div className="loan-status-header">
                                <span>Transactions Overview</span>
                               
                            </div>
                            <div className="loan-pie-chart">
                                    <ReactECharts option={pieOption_2} />
                            </div>

                           
                        </ColoredWrapper>

                        
                    </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
 
export default DashboardHome;
// import React, { useState } from "react";

// css
import "./Team.css";

// Components
import Wrapper from "../../../components/UI/Wrapper";
import InviteATeamMateModal from "./Modals/InviteATeamMate"

// Modal ID
import { INVITE_A_TEAM_MATE_MODAL_ID  } from "../../../utility/modalIDs";
import { NavLink } from "react-router-dom";

const Team = ({goToAdminCreator}) => {
    return (
        <>
            <Wrapper grey outlined className="tt-top">
                <div className="tt-top-content">
                    <div className="tt-pending">
                        <span>Pending invites </span>
                        <span className="tt-bold">. 0</span>
                    </div>
                    <button className="tt-invite-btn modal-trigger" data-target={INVITE_A_TEAM_MATE_MODAL_ID }>+ Invite a team mate</button>
                </div>
            </Wrapper>

            <Wrapper grey outlined  className="tt-table-container table-wrapper">
                <table id="customers-table">
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>EMAIL DETAILS</th>
                            <th>ROLE</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Olamide Olagunju</td>
                            <td>ambroseali@gmail.com</td>
                            <td>Business Owner</td>
                            <td>
                                <NavLink to="/dashboard/role-creator">
                                    <span className="gray-txt privil-link">Change Privileges</span>
                                </NavLink>
                            </td>
                        </tr>

                        <tr>
                            <td>Olamide Olagunju</td>
                            <td>ambroseali@gmail.com</td>
                            <td>Business Owner</td>
                            <td>
                                <NavLink to="/dashboard/role-creator">
                                    <span className="gray-txt privil-link">Change Privileges</span>
                                </NavLink>
                            </td>
                        </tr>

                        <tr>
                            <td>Olamide Olagunju</td>
                            <td>ambroseali@gmail.com</td>
                            <td>Business Owner</td>
                            <td>
                                <NavLink to="/dashboard/role-creator">
                                    <span className="gray-txt privil-link">Change Privileges</span>
                                </NavLink>
                            </td>
                        </tr>

                        <tr>
                            <td>Olamide Olagunju</td>
                            <td>ambroseali@gmail.com</td>
                            <td>Business Owner</td>
                            <td>
                                <NavLink to="/dashboard/role-creator">
                                    <span className="gray-txt privil-link">Change Privileges</span>
                                </NavLink>
                            </td>
                        </tr>

                        <tr>
                            <td>Olamide Olagunju</td>
                            <td>ambroseali@gmail.com</td>
                            <td>Business Owner</td>
                            <td>
                                <NavLink to="/dashboard/role-creator">
                                    <span className="gray-txt privil-link">Change Privileges</span>
                                </NavLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Wrapper>

            <InviteATeamMateModal />
        </>
    );
}

export default Team;
// css
import '../../../css/styles.css';
import '../../../css/fonts.css';
import 'materialize-css/dist/css/materialize.min.css';
import 'material-design-icons/iconfont/material-icons.css';
import './index.css';
import '../../../css/dashboard.css';
import "../KYCApproval/KYCApproval.css";
import M from "materialize-css";
import $ from "jquery";
import { useEffect, useState } from "react";
// Components
import Wrapper from "../../../components/UI/Wrapper";

// Modal ID
import { VIEW_ROLE_AND_PRIVILEGES_MODAL_ID  } from "../../../utility/modalIDs";
import { useNavigate } from "react-router-dom";

const ProfileDetails = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const handleInput = (e) =>{
        setSearch(e.target.value)
    }
    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);

        $(".filter-dropdown-trigger").click(function(){
            $(this).parent().find(".filter-dropdown-content").slideToggle();
        });
    }, []);
    
    const selectRole = (role) => {
        const terminalBtnModal = document.querySelector(`#${VIEW_ROLE_AND_PRIVILEGES_MODAL_ID}`);
        M.Modal.getInstance(terminalBtnModal).open();
    }

    return (
        <>
          

            <Wrapper grey outlined  className="tt-table-container table-wrapper">
                <table id="customers-table">
                    <thead>
                        <tr>
                            <th>STATUS</th>
                            <th>CREATED ON</th>
                            <th>LAST LOGIN</th>
                            <th>LIEN STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>A</td>
                            <td>27-12-2021 06:48 PM</td>
                            <td>27-12-2021 06:48 PM</td>
                            <td> No</td>
                           
                        </tr>

                       
                    </tbody>
                </table>
                <br />
                <div className="text-uppercase">ACTIONS</div>

                <div className='switch-wrapper'>
                <span>Account Status</span>
                <div className="mode-switch-wrapper">
                        <div className="switch">
                            <label>
                                <input type="checkbox" />
                                <span className="lever"></span>
                                <span className="test-mode mode-status">Active</span>
                                <span className="live-mode mode-status">Inactive</span>
                            </label>
                        </div>
                    </div>
                </div>
                
                    {/* <section id="side-bar">
            <div className="container">
            <div className="nav-list-wrapper"> */}
             <div className='switch-wrapper'>
             <span >Lien Status</span>
             <div className="mode-switch-wrapper">
                   
                        <div className="switch">
                            <label>
                                <input type="checkbox" />
                                <span className="lever"></span>
                                <span className="test-mode mode-status">Active</span>
                                <span className="live-mode mode-status">Inactive</span>
                            </label>
                        </div>
                    </div>
             </div>
                    
                    {/* </div>
                    </div>
                    </section> */}
            </Wrapper>

           
        </>
    );
}

export default ProfileDetails;
import ColoredWrapper from "../../components/UI/coloredWrapper/coloredWrapper";
import ReactECharts from 'echarts-for-react';
import { useState } from "react";
import { NavLink } from "react-router-dom";
import './index.css'
import "../Transactions/Transactions.css";
import '../../css/modals.css';
import 'rsuite/dist/rsuite.min.css';
import M from "materialize-css";
import Wrapper from "../../components/UI/Wrapper";

const Revenue = () => {

    const [revenue, setRevenue] = useState([]);

    const pieOption_1 = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          right: 0,
          bottom: 0,
          orient: 'vertical'
        },
        grid: {
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
        },
        series: [
          {
            name: 'Total Revenue',
            type: 'pie',
            radius: ['60%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '10'
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1000, name: 'Card Transactions' },
              { value: 300, name: 'Bill Payments' },
              { value: 300, name: 'POS Transactions' }
            ],
            color: ['#777777', '#DA6A2F', '#d8ea12']
          }
        ]
    };

    const repayments = [
        {
            date: "August 12, 2021",
            time: "01: 50 PM",
            amount: "₦416,666.67",
            installmentNumber: "5th"
        },
        {
            date: "July 12, 2021",
            time: "01: 50 PM",
            amount: "₦416,666.67",
            installmentNumber: "4th"
        },
        {
            date: "June 15, 2021",
            time: "04: 50 AM",
            amount: "₦416,666.67",
            installmentNumber: "3rd"
        },
        {
            date: "May 12, 2021",
            time: "10: 10 AM",
            amount: "₦416,666.67",
            installmentNumber: "2nd"
        },
        {
            date: "April 12, 2021",
            time: "12: 50 PM",
            amount: "₦416,666.67",
            installmentNumber: "1st"
        },
    ]

    return (
        <div className="page-content-wrapper">
            <div className="container">  
                <div className="page-title-wrapper loan-overview-header-container">
                    <h4 className="page-content-title flex-div">
                        <span>Revenue</span>
                    </h4>
                   
                </div>
                <div className="loan-overview-content">
                    <div>
                        <ColoredWrapper outlined>
                            <div className="loan-status-header">
                                <span>Revenue status</span>
                               
                            </div>
                            <div className="loan-pie-chart">
                                    <ReactECharts option={pieOption_1} />
                            </div>

                            <div className="loan-chart-hr"></div>

                            <div className="loan-status-bottom">
                                <div>
                                    <small>Total Revenue</small>
                                    <span>₦2,353,333.33</span>
                                </div>

                            </div>
                        </ColoredWrapper>

                        
                    </div>

                    <div>
                        
                        <Wrapper className="sakam" outlined grey >
                 <table id="customers-table">
                    <thead>
                        <tr>
                           
                            <th>Today</th>
                            <th>Weekly</th>
                            <th>Month</th>
                            <th>Year</th>
                           
                        </tr>
                    </thead>
                    <tbody>

                        <tr className="thp-transaction-item">
                        <td>20</td>
                        <td>1358</td>
                        <td>1389</td>
                        <td>21049</td>
                        </tr>
                   
                    </tbody>
                </table>
                        </Wrapper>
<br />
                        <Wrapper className="sakam" outlined grey fullHeight>
                        <table id="customers-table">
                    <thead>
                        <tr>
                           
                            <th>TRAN TYPE</th>
                            <th>AMOUNT</th>
                            <th>OLD BALANCE</th>
                            <th>NEW BALANCE</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="thp-transaction-item">
                    <td>Bill Payment</td>
                    <td>10,200.00</td>
                    <td>100,000.00</td>
                    <td>89,800.00</td>
                    </tr>
                    <tr className="thp-transaction-item">
                    <td>Card Transactions</td>
                    <td>10,200.00</td>
                    <td>100,000.00</td>
                    <td>89,800.00</td>
                    </tr>
                    <tr className="thp-transaction-item">
                    <td>POS Transactions</td>
                    <td>10,200.00</td>
                    <td>100,000.00</td>
                    <td>89,800.00</td>
                    </tr>
                    </tbody>
                </table>
                        </Wrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Revenue;
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from "../../../components/InputField";

const LoginPassword = () => {
    const [error, setError] = useState({});
    const [value, setValues] = useState("");

    const formSchema = Yup.object().shape({
        currentPassword: Yup.string(),
        newPassword: Yup.string(),
        confirmNewPassword: Yup.string(),
    });

    /**
     * Yup configuration for form validation
     */
     const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
      })

      useEffect(()=>{
          setError(errors)
      },[errors]);
    return (
        <form action="#" className="dashboard-form settings-security-form" onSubmit={e => e.preventDefault()}>
            <div className="stlpt-pin-instruction">
                Enter your Current Payrail Transaction PIN
            </div>
            <InputField 
                type="password" 
                label="Current Password" 
                {...register('currentPassword',{ required: true })}
                onChange={e =>{setValue(e.target.id, e.target.value)}}
                error={error && error['currentPassword']? error['currentPassword'].message: ""} 
                id={{input: "current_password"}} 
                classname={{div:"input-field single-field stlpt-pos", error:"error-text", input:"form-inp-field", }} 
            />

            <InputField 
                type="password" 
                label="New Password" 
                {...register('newPassword',{ required: true })}
                onChange={e =>{setValue(e.target.id, e.target.value)}}
                error={error && error['newPassword']? error['newPassword'].message: ""} 
                id={{input: "new_password"}} 
                classname={{div:"input-field single-field stlpt-pos", error:"error-text", input:"form-inp-field", }} 
            />

            <InputField 
                type="password" 
                label="Confirm New Password" 
                {...register('newPassword',{ required: true })}
                onChange={e =>{setValue(e.target.id, e.target.value)}}
                error={error && error['confirmNewPassword']? error['confirmNewPassword'].message: ""} 
                id={{input: "confirm_new_password"}} 
                classname={{div:"input-field single-field stlpt-pos", error:"error-text", input:"form-inp-field", }} 
            />

            <button className="pry-btn ipib-cancel-btn stlpt-btn">
                Save Draft
            </button>
        </form>
    );
};

export default LoginPassword;
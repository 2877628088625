/* eslint-disable no-param-reassign */
/**
 * An axios proxy to attach the base url and authentication token
 */
 import axios from 'axios';

 export const http = axios.create({
   baseURL: process.env.REACT_APP_URL
 });
 
 export const multipleRequest = (url)=>{  
   return axios.all([...url])
 };
 
 export const httpHome = axios.create({
   baseURL: process.env.REACT_APP_HOME_URL
 });
 
 export const privateHttp = axios.create({
   baseURL: process.env.REACT_APP_URL
 });
 privateHttp.interceptors.request.use((config) => {
   config.headers.Authorization = `Bearer-Jwt ${sessionStorage.getItem('token')}`;
 
   return config;
 });
 // http.interceptors.request.use((config) => {
 //   config.headers.Authorization = `Bearer-Jwt ${sessionStorage.getItem('token')}`;
 
 //   return config;
 // });
 export const blogHttp = axios.create({
   baseURL: "https://example.com"
 });
 
 privateHttp.interceptors.response.use((response) => response?.data);
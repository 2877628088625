// import React, { useEffect, useState } from "react";
import React, { useEffect } from "react";
import M from "materialize-css"
import Wrapper from "../../../components/UI/Wrapper";
import { UPLOAD_BULK_BENEFICIARIES_MODAL_ID, SCHEDULE_PAYMENT_MODAL_ID, RECURRING_PAYMENT_MODAL_ID } from "../../../utility/modalIDs";

const TransferInformationForm = ({ goToPin }) => {
    useEffect(()=> {
        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);
    return (
        <Wrapper grey outlined className="tip-flex-item ti-form-wrapper">
            <form action="#" className="dashboard-form modal-form ti-form" onSubmit={e => e.preventDefault()}>
                <div className="select-field input-field single-field rel">
                    <select id="payment_account">
                        <option value="Show all">0123456789 - Ogbus and ventures</option>
                        <option value="Show New">Show New</option>
                        <option value="Show Returning">Show Returning</option>
                    </select>
                    <label htmlFor="payment_account" className="active">Select Account </label>
                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                </div>

                <div className="select-field input-field single-field rel">
                    <select id="payment_account">
                        <option value="Show New">Select list name</option>
                        <option value="Show Returning">Show Returning</option>
                    </select>
                    <label htmlFor="payment_account" className="active">List Name </label>
                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                    <div className="tip-input-subscript">
                        <a href="/#" download="#" className="tip-icon">
                            Download template
                            <img src={require("../../../images/template.svg").default} alt="caret" className="" />
                        </a>
                        <span className="modal-trigger" data-target={UPLOAD_BULK_BENEFICIARIES_MODAL_ID}>Upload CSV</span>
                    </div>
                </div>

                <div className="select-field input-field single-field rel">
                    <select id="payment_account">
                        <option value="Show all">Rent</option>
                        <option value="Show New">Sell</option>
                    </select>
                    <label htmlFor="payment_account" className="active">Payment Tag</label>
                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                </div>

                <div className="tip-payment-type">
                    <h6>Payment Type</h6>

                    <div>
                        <label className="stlpt-d-block">
                          <input name="payment-type" type="radio" className="with-gap" defaultChecked/>
                          <span className="stlpt-radio-text ">Instant payment</span>
                        </label>
                        <label className="stlpt-d-block modal-trigger" data-target={SCHEDULE_PAYMENT_MODAL_ID}>
                          <input name="payment-type" type="radio" className="with-gap"/>
                          <span className="stlpt-radio-text ">Scheduled payment</span>
                        </label>
                        <label className="stlpt-d-block modal-trigger" data-target={RECURRING_PAYMENT_MODAL_ID}>
                          <input name="payment-type" type="radio" className="with-gap"/>
                          <span className="stlpt-radio-text ">Recurring payment</span>
                        </label>
                    </div>
                </div>

                <div className="select-field input-field single-field rel">
                    <select id="payment_account">
                        <option value="Show all">30 minutes</option>
                        <option value="Show New">Show New</option>
                        <option value="Show Returning">Show Returning</option>
                    </select>
                    <label htmlFor="payment_account" className="active">Payment Hold off</label>
                    <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                    <div className="inner-checkbox-field tip-inner-checkbox-field">
                        <label>
                            <input type="checkbox" className="filled-in" id="use-general-email" />
                            <span>Allow payments when monthly budget is exceeded</span>
                        </label>
                    </div>
                </div>

                <div className="input-field single-field tf-field">
                    <div className="input-field">
                        <textarea id="description" className="form-inp-field materialize-textarea"></textarea>
                        <label htmlFor="description">Description</label>
                    </div>
                </div>

                <div className="tip-btn-right">
                    <button className="pry-btn submit-btn" onClick={() => goToPin("enter-pin-content")}>Continue</button>
                </div>
            </form>
        </Wrapper>
    );
};

export default TransferInformationForm;
import React from "react";
import ModalWrapper from "../../../components/UI/ModalWrapper";
import { POS_REQUEST_SUCCESS } from "../../../utility/modalIDs";

const PosRequestSuccess = () => {
    return (
        <ModalWrapper modalID={POS_REQUEST_SUCCESS}>
            <div className="success-icon-wrapper">
                <img src={require("../../../images/success-tick.svg").default} alt="success" className="" />  
            </div>
            <h2 className="amsm-header">Request completed</h2>
            <p className="amsm-grey-text">A Payrail representative will contact you shortly</p>
        </ModalWrapper>
    );
};

export default PosRequestSuccess;
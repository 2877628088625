import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import '../css/auth.css';
import $ from 'jquery';
// import M from 'materialize-css';
import * as Yup from 'yup'
import { useNavigate, NavLink  } from 'react-router-dom';
import { InputField } from '../components/InputField';
import { Loader } from '../components/loader';

import { signupApi } from "../redux/sagas/requests/auth";
import { setToast } from "../redux/reducers/toast";
import { signup } from "../redux/reducers/auth";

/**
 * Constructs form validation object for phone number registraion using Yup library
 * @param email : User email address.
 * @param referral : Refferal code.
 */
const formSchema = Yup.object().shape({
    email: Yup.string().required("Required").email(),
    referral: Yup.number(),
})

/**
 * Email address signup componenet
 */
const EmailSignup = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const  { user } = useSelector(state=> state.auth)
    const [error, setError] = useState({})
    const [isEmail, setIsEmail] = useState(null)
    const [loading, setLoading] = useState(false)

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
      })

      useEffect(()=>{
          setError(errors)
      },[errors])

      /**
       * Check if email is passed as a query param.
       * If email is passed call the signupAction.
       */
      useEffect(()=>{
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
          // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
          let email = params.email; // "some_value"

          if (email && email !== null) {
            createAccount({email})
            setIsEmail(true)
           
          }else{
              setIsEmail(false)
          }
          
      },[])

      const createAccount = (data) => {
        setLoading(true)
        signupApi(data).then(res=>{
          
            setIsEmail(false)
            dispatch(signup({...data, onboardingResponse:{...res.onboardingOption.onboardingResponse}}))
            
            if (res.status === 'processing') {
                navigate("/signup/verify-bvn") 
            }else if (res.status === 'verified'){
                navigate("/login") 
            }else{
                navigate("/signup/verify/email") 
            }
              
            setLoading(false)
        }).catch(err=>{
            dispatch(setToast({type:'error', message:'Error unable to create account'}))
            console.log('___EMAIL ERROR', err)
            setLoading(false)
            
        })
      }
   
    /**
     * onSubmit function to trigger form submission for phone number registration.
     * @param email : User email address.
     * @param referral : Refferal code.
     */
      const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){
            createAccount(data)
            }
        }

    useEffect(() => {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        })

        $("#referral-field-trigger").click(function(){
            $(this).parent().hide();
            $("#referral-field").slideDown();
        });
    }, []);

    if (isEmail ==null || isEmail) {
        return  <div className="auth-block" />
    }

    return(
        <div className="auth-block">
           
            
            <form id="signup-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Let’s get you started
                    </h1>
                    <p className="greet-desc">
                        Please, check your browser’s address bar to be sure you’re on <br />
                        <a href="https://app.payrail.com">https://app.payrail.com</a>
                    </p>
                </div>
                <div className="input-row row">
                    <InputField 
                        type="text" 
                        label="Email Address" 
                        {...register('email',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['email']? error['email'].message: ""} 
                        id={{input: "email"}} 
                        classname={{div:"input-field col s12 mag-d-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <InputField 
                        type="text" 
                       
                        label="Invite Code" 
                        {...register('referral',{ required: false })}
                        onChange={e => setValue(e.target.id, e.target.value)}
                        error={error && error['referral']? error['referral'].message: ""} 
                        id={{div: "referral-field", input: "referral"}} 
                        classname={{div:"input-field col s12 mag-d-0 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <div className="alt-link-div">
                        Referred? <button type="button" className="txt-btn pry-color" id="referral-field-trigger">
                            <u>Click here</u></button>
                    </div>
                </div>
               {loading&&<Loader />}
                

                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <div className="txt-link-side">
                        <NavLink to="/signup/phone" className="pry-color"><u>Sign up with Phone number instead</u></NavLink>
                    </div>
                    <div className="btn-side">
                        <button disabled={loading} type="submit"  className="submit-btn pointer">Create Account </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EmailSignup;
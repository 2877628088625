// Global
export const PIN_MODAL = "enter-pin-modal";

//Business
// Business:: SubAccounts
export const ADD_NEW_SUB_ACCOUNT_MODAL_ID = "add-new-sub-account-modal";
// Invoice:: SubAccounts
export const VIEW_REQUEST_MODAL_ID = "view-request-modal";
export const REQUEST_PAYMENT_MODAL_ID = "request-payment-modal";

//Loan Page Modal IDs
export const CHOOSE_REPAYMENT_OPTION_MODAL = "choose-repayment-option-modal";
export const NEW_PAYMENT_DATE_MODAL = "new-payment-date-modal";
export const LOAN_APPLICATION_CONFIRMATION_MODAL = "loan-application-confirmation-modal";
export const REPAYMENT_OPTION_MODAL = "repayment-option-modal";
export const LOAN_REPAYMENT_SUCCESS_MODAL = "loan-repayment-success-modal";

// Administration
// Team Tab: Invite a Team Mate Modal
export const INVITE_A_TEAM_MATE_MODAL_ID = "invite-a-team-mate-modal";

// Team Tab: Create new role and privileges Modal
export const VIEW_ROLE_AND_PRIVILEGES_MODAL_ID = "view-role-and-privileges-modal";

// Settings
// Security Tab: Receive OTP Via Email Modal
export const RECEIVE_OTP_VIA_EMAIL_MODAL_ID = "receive-otp-via-email-modal";
// Security Tab: Receive OTP Via Authenticator App
export const RECEIVE_OTP_VIA_AUTHENTICATOR_APP_MODAL_ID = "receive-otp-via-authenticator-app-modal";

// Transfer
// Add Money Tab
export const ADD_MONEY_VIA_BANK_TRANSFER_MODAL_ID = "add-money-via-bank-transfer-modal-id";
export const ADD_MONEY_VIA_USSD_TRANSFER_MODAL_ID = "add-money-via-ussd-transfer-modal-id";
export const COPY_USSD_CODE_MODAL_ID = "copy-ussd-code-modal-id";
export const ADD_MONEY_SUCCESS_MODAl_ID = "add-money-success-modal-id";
// Send Money Tab
export const QUICK_TRANSFER_MODAL_ID = "quick-transfer-modal-id";
export const VERIFICATION_CODE_MODAL_ID = "enter-verification-code-modal-id";
export const ENTER_PIN_MODAL_ID = "enter-pin-modal-id";
export const SEND_MONEY_SUCCESS_MODAL_ID = "send-money-success-modal-id";
export const UPLOAD_BULK_BENEFICIARIES_MODAL_ID = "upload-bulk-beneficiaries-modal-id";
export const VALIDATE_BENEFICIARIES_MODAL_ID = "validate-beneficiaries-modal-id";
export const SCHEDULE_PAYMENT_MODAL_ID = "schedule-payment-modal-id";
export const RECURRING_PAYMENT_MODAL_ID = "recurring-payment-modal-id";
export const BULK_TRANSFER_SUCCESS_MODAl_ID = "bulk-transfer-success-modal-id";

// Transaction
export const REQUEST_TRANSACTION_ITEM_MODAL_ID = "request-transaction-item-modal";

// Withdrawal
export const WITHDRAWAL_TO_BANK_MODAL = "withdrawal-to-bank-modal";
export const OTP_MODAL = "otp-modal"
export const WITHDRAW_SUCCESS_MODAL = "withdrawal-success-modal"

// POS

export const POS_TERMINAL_REQUEST = "pos-terminal-request";
export const ADD_POS_TERMINAL = "add-pos-terminal";
export const EDIT_POS_TERMINAL = "edit-pos-terminal";
export const RETREIVE_POS_TERMINAL = "retreive-pos-terminal";
export const ASSIGN_POS_TERMINAL = "assign-pos-terminal";
export const POS_REQUEST_SUCCESS = "pos-request-success";
import React from "react";
import M from "materialize-css";

const AuditsItem = ({item={}, onClick=()=>{}}) =>{

    const {date="27-12-2021  06:48 PM", ipAddress="0.00000.000", operation="Login", comment="Successful", status="Successful", phone="+2348060110110",username="Moh Salah",amount="140" } = item

    return(
        <tr>
        <td>{date}</td>
        <td >{username}</td>
        <td>{ipAddress}</td>
        <td>{operation}</td>
        <td>{comment}</td>
        <td>{status}</td>
        {/* <td>
            <button onClick={onClick} className="pry-btn resolve-btn modal-trigger" data-target="resolve-dispute-modal">
               View →
            </button>
        </td> */}
      
    </tr>
    )
}

export default AuditsItem
// import React, {useState, useEffect} from "react";
// import M from "materialize-css";

import QuickTransferModal from "./Modals/QuickTransfer";
import VerificationCodeModal from "./Modals/VerificationCode";
import EnterPinModal from "./Modals/EnterPin";
import SendMoneySuccessModal from "./Modals/SuccessModal";
import SchedulePaymentModal from "../../../components/BillPaymentModals/BillPaymentSchedulePaymentModal";
import BillPaymentScheduleSuccessModal from "../../../components/BillPaymentModals/BillPaymentScheduleSuccessModal";

import { QUICK_TRANSFER_MODAL_ID } from "../../../utility/modalIDs";

const SendMoney = ({goToBulkTransfer}) => {
    // const [ quickTransferModal, setQuickTransferModal ] = useState(null);

    // useEffect(()=> {
    //     setQuickTransferModal(M.Modal.init(document.getElementById(QUICK_TRANSFER_MODAL_ID)));
    // }, []);
    return (
        <div>
            <div className="smt-option smt-mb modal-trigger" data-target={QUICK_TRANSFER_MODAL_ID}>
                <img className="smt-option-icon" src={require("../../../images/bank-circle.svg").default} alt="icon" />

                <span className="smt-option-title">Quick Transfer</span>

                <img className="smt-option-chev-icon" src={require("../../../images/chevron-right.svg").default} alt="icon" />
            </div>

            <div className="smt-option smt-mb" onClick={() => goToBulkTransfer("bulk transfer landing")}>
                <img className="smt-option-icon" src={require("../../../images/bank-circle.svg").default} alt="icon" />

                <span className="smt-option-title">Bulk Transfer</span>

                <img className="smt-option-chev-icon" src={require("../../../images/chevron-right.svg").default} alt="icon" />
            </div>

            <div className="smt-option smt-mb">
                <img className="smt-option-icon" src={require("../../../images/payrail-circle.svg").default} alt="icon" />

                <span className="smt-option-title">Payrail Users</span>

                <img className="smt-option-free-icon" src={require("../../../images/free.svg").default} alt="icon" />

                <img className="smt-option-chev-icon" src={require("../../../images/chevron-right.svg").default} alt="icon" />
            </div>

            <div className="smt-option">
                <img className="smt-option-icon" src={require("../../../images/ussd-circle.svg").default} alt="icon" />

                <span className="smt-option-title">USSD Transfer</span>

                <img className="smt-option-chev-icon" src={require("../../../images/chevron-right.svg").default} alt="icon" />
            </div>

            <QuickTransferModal />
            <VerificationCodeModal />
            <EnterPinModal />
            <SendMoneySuccessModal />
            <SchedulePaymentModal />
            <BillPaymentScheduleSuccessModal />
        </div>
    );
};

export default SendMoney;
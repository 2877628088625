import React from "react";

import { NavLink } from "react-router-dom";

import "./ATMCard.css";

const ATMCard = () => {
    return (
        <div className="new-debit-card">
            <div className="section-block wallet-card">
                <div className="wallet-card-logo-div flex-div justify-content-btw">
                    <img src={require("../../images/debit-bizness-txt.svg").default} alt="debit text" className="debit-txt-img" />
                    <img src={require("../../images/payrail-text-logo.svg").default} alt="payrail" className="wallet-card-logo" />
                </div>
                <div className="balance-wrapper">
                    <img src={require("../../images/chip.svg").default} alt="chip" className="chip-icon" />
                </div>
                <div className="wallet-card-bottom flex-div">
                    <div className="card-name">
                        <span>Ogbous and Sons </span>
                    </div>
                    <div className="card-type-logo">
                        <img src={require("../../images/biz-logo.svg").default} alt="icon" className="wallet-biz-logo" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ATMCard;
import React from "react";

import "./Wrapper.css";

const Wrapper = (props) => {
    const styles = {
        borderRadius: "16px",
        alignSelf: "flex-start"
    }
    
    if (props.outlined) {
        styles["border"] = "1px solid rgba(119, 119, 119, 0.2)";
    }

    if (props.fullHeight) {
        styles["alignSelf"] = "stretch";
        styles["height"] = "100%";
    }

    if (props.grey) {
        styles["background"] = "#F9F9F9";
    }

    if (props.white) {
        styles["background"] = "white";
    }

    
    return (
        <div className={`p-20  ${props.className|| ""}`} style={styles} >
            {props.children}
        </div>
    );
}

export default Wrapper;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  teams:[{email:"aundearisekola@gmail.com",status:"Active",phone_number:"+2348159109387",first_name:"Toyeeb",last_name:"Atunde"}],
 
}

export const administrationSlice = createSlice({
  name: 'administration',
  initialState,

  reducers: {
    inviteTeam: ()=>{},
    searchTeam: ()=>{},
    filterTeam: ()=>{},
    fetchTeam: ()=>{},
    Teams: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
      state.actionType = action.type
    },
    teamAdded: (state, action) => {
      state.administrationsAdded = true;
      state.actionType = action.type
    },
    createRole: ()=>{},
    fetchRole: ()=>{},
    roles: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
      state.actionType = action.type
    },
    roleAdded: (state, action) => {
      state.administrationsAdded = true;
      state.actionType = action.type
    },

   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Teams,
  teamAdded,
  roles,
  roleAdded,
  
  inviteTeam,
  searchTeam,
  filterTeam,
  fetchTeam,
  createRole,
  fetchRole,
   
    } = administrationSlice.actions

export default administrationSlice.reducer
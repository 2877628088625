import '../css/auth.css';
import $ from 'jquery';
import M from 'materialize-css';
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputField } from '../components/InputField';
import { PasswordField } from '../components/passwordField';
import * as Yup from 'yup'
import { useNavigate, NavLink  } from 'react-router-dom';
import { Loader } from '../components/loader';
import { SelectField } from '../components/selectField';
import { login, loginCredential, signup } from '../redux/reducers/auth';
import { loginApi } from '../redux/sagas/requests/auth';
import { setToast } from '../redux/reducers/toast';
import PhoneNumber, { CountrySelect } from '../components/InputField/PhoneNumber';
import { isValidPhoneNumber } from 'react-phone-number-input';

/**
 * Constructs form validation object for user old signup using Yup library
 * @param password : User password.
 */
const formSchema = Yup.object().shape({
    username: Yup.string().required('Required')
    .test(
      'username',
      'Please enter a valid email address or phone number',
      (value) =>
        /^((\+?\d{1,3})[- ]?)?\d{10}$/.test(value) ||
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ),
    secret: Yup.string().required("Required"),
    uuidHash: Yup.string().required("Required"),
})

/**
 * New user login componenet
 */
const LoginOld = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const  {  user, device_account, } = useSelector(state=> state.auth)
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)

    /**
     * Yup configuration for form validation
     */
    const { register,watch, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),defaultValues:{
            uuidHash: user.uuidHash
        }
      })

      useEffect(()=>{
          if (device_account && device_account.email) {
            navigate("/login/old")
          }

      },[])

      useEffect(()=>{
          setError(errors)
      },[errors])
      
    /**
     * onSubmit function to trigger form submission for phone number registration.
     * @param email : User email address.
     * @param phone : User phone number.
     * @param password : Account password.
     */
     const onSubmit = async (data) => {
        trigger()

        if (Object.keys(errors).length === 0){
            setLoading(true)
            const payload = {...data}
            if (isValidPhoneNumber(payload.username)) {
             
               payload['username'] = payload.username.replace(/\+/g, "")
            }
           
            loginApi(payload).then(res=>{
               
                if (res.type === 'mfa-required') {
                   
                    dispatch(loginCredential({...payload}))
                    navigate("/login/verify/mfa")
                    
                }else{
                   
                    dispatch(login({...res}))
                    navigate("/dashboard/home")
                }
               
                setLoading(false)
            }).catch(err=>{

                if (err.msg) {
                    dispatch(setToast({type:'error', message:err.msg}))
                }else{
                    dispatch(setToast({type:'error', message:'Error: unable to login account check if credential is correct'}))
                }
                
                setLoading(false)
               
            })
            
            }
        }

    useEffect(() => {
        

            $(".input-field input").focus(function(){
                var targetLabel = $(this).parent().find('label');
                targetLabel.addClass("active");
            })
            $(".input-field input").blur(function(){
                var targetLabel = $(this).parent().find('label');
                if($(this).val()) {
                    targetLabel.addClass("active");
                } else {
                    targetLabel.removeClass("active");
                }
            })
       

        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $("#phone-login-trigger").click(function(){
            $("#phone-login").slideDown();
            $("#email-login").slideUp();
        });
        $("#email-login-trigger").click(function(){
            $("#email-login").slideDown();
            $("#phone-login").slideUp();
        })
    }, []);

    return(
        <div className="auth-block">
           
                <div className="auth-greet-title center">
                    <h1 className="greet-title">Welcome back</h1>
                   
                    <p className="greet-desc">

                        Please, check your browser’s address bar to be sure you’re on <br />
                        <a href="https://app.payrail.com">https://business.payrail.co</a>
                       
                    </p>
                </div>
                <form id="olduser-login-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-row row" id="email-login">
                <InputField 
                        type="text" 
                        label="Account ID" 
                        value={watch('uuidHash')}
                        {...register('uuidHash',{ required: false })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['uuidHash']? error['uuidHash'].message: ""} 
                        id={{input: "uuidHash"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <InputField 
                        type="email" 
                        label="Email Address" 
                        {...register('username',{ required: false })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['username']? error['username'].message: ""} 
                        id={{input: "username"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <PasswordField 
                        type="password"
                        label="Password" 
                        {...register('secret',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['secret']? error['secret'].message: ""}  
                        id={{input: "secret"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <div className="alt-link-div flex-div justify-content-btw">
                        <button type="button" className="txt-btn pry-color" id="phone-login-trigger"><u>Log in with phone number</u></button>
                        <NavLink to="/forgot-password" className="pry-color">Forgot password?</NavLink>
                    </div>
                </div>
                </form>
                <form id="olduser-login-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-row row" id="phone-login">

                <InputField 
                        type="text" 
                        label="Account ID" 
                        value={watch('uuidHash')}
                        {...register('uuidHash',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['uuidHash']? error['uuidHash'].message: ""} 
                        id={{input: "uuidHash"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                    />

                    <PhoneNumber 
                        type="text" 
                        label="Phone number" 
                        value={watch('username')}
                        {...register('username',{ required: true })}
                        onChange={e =>{ 
                            console.log("__PHONE___",e)
                            setValue('username', e)
                        }} 
                        error={error && error['username']? error['username'].message: ""}
                        id={{input: "username"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <PasswordField 
                        type="password"
                        label="Password" 
                        value={watch('secret')}
                        {...register('secret',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['secret']? error['secret'].message: ""}
                        id={{input: "secret"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <div className="alt-link-div flex-div justify-content-btw">
                        <button type="button" className="txt-btn pry-color" id="email-login-trigger"><u>Log in with email</u></button>
                        <NavLink to="/forgot-password" className="pry-color">Forgot password?</NavLink>
                    </div>
                </div>
                {loading&&<Loader />}
                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <div className="txt-link-side">
                        New user? <NavLink to="/signup" className="pry-color"><u>Create account</u></NavLink>
                    </div>
                    <div className="btn-side">
                        <button type="submit" className="submit-btn pointer" >Sign In</button>
                    </div>
                </div>
            </form>
           
        </div>
    )
}

export default LoginOld;
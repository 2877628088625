import React, { useEffect } from "react";
import M from "materialize-css"
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { RECURRING_PAYMENT_MODAL_ID } from "../../../../utility/modalIDs";
import CustomTimeAndDatePicker from "./components/CustomTimeAndDatePicker";

const RecurringPayment = () => {
    useEffect(()=> {
        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);
    return (
        <ModalWrapper modalID={RECURRING_PAYMENT_MODAL_ID}>
            <div className="upbm-header-wrapper">
                <h4 className="upbm-header-mb">Recurring Payment</h4>
                <div className="modal-desc">When do you want this payment to reoccur?</div>
            </div>

            <form action="#" className="dashboard-form modal-form rp-form" onSubmit={e => e.preventDefault()}>
                <div className="select-field input-field single-field rel">
                    <select id="set-frequency">
                        <option value="Show all">Every Week</option>
                        <option value="Show New">Show New</option>
                        <option value="Show Returning">Show Returning</option>
                    </select>
                    <label htmlFor="set-frequency" className="active">Set Frequency </label>
                    <img src={require("../../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                </div>

                {/* TIME AND DATE IS SUPPOSED TO COME HERE */}
                <CustomTimeAndDatePicker />

                <div className="input-field single-field">
                    <div className="input-field">
                        <select id="payment_account">
                            <option value="1 hour earlier">1 hour earlier</option>
                            <option value="1 day earlier">1 day earlier</option>
                            <option value="2 day earlier">2 day e
                            arlier</option>
                        </select>
                        <label htmlFor="list_name">Remind Me</label>
                        <img src={require("../../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                    </div>
                </div>

                <div className="tip-payment-type">
                    <h6>End Payment</h6>

                    <div>
                        <label className="stlpt-d-block">
                          <input name="end-payment" type="radio" className="with-gap" defaultChecked/>
                          <span className="stlpt-radio-text ">On</span>
                        </label>
                        <label className="stlpt-d-block">
                          <input name="end-payment" type="radio" className="with-gap"/>
                          <span className="stlpt-radio-text ">After</span>
                        </label>
                    </div>
                </div>

                <div className="rpm-input-flex">
                    <div className="input-field datepicker-field single-field rel">
                        <input type="text" id="rp-end-date" defaultValue={"May 22, 2022"} className="form-inp-field datepicker" />
                        <label htmlFor="payment_account" className="active">End Date</label>
                        <img src={require("../../../../images/calendar-icon.svg").default} alt="caret" className="inp-icon" />
                    </div>

                    <div className="input-field single-field rel">
                        <input type="number" id="rp-occurence" defaultValue={"0"} className="form-inp-field" />
                        <label htmlFor="rp-occurence" className="active">Occurence </label>
                    </div>
                </div>

                <div className="input-field single-field">
                    <div className="input-field">
                        <input id="rp-occurence-num" type="text" placeholder="Select Date (Optional)" className="form-inp-field" />
                        <label htmlFor="rp-occurence-num">Number of Occurrence</label>
                    </div>
                </div>

                <div className="vbmm-d-flex">
                    <button className="pry-btn modal-close">
                        Cancel
                    </button>
                    <button className="pry-btn modal-trigger modal-close" data-target={""}>
                        Confirm
                    </button>
            </div>
            </form>
        </ModalWrapper>
    );
};

export default RecurringPayment;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message:{},
 
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,

  reducers: {
    setToast: (state, action)=>{
    
      state.message = action.payload;
    },
    Toasts: (state, action) => {
     state.message = action.payload;
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { 
  Toasts,
  setToast,
  
   
    } = toastSlice.actions

export default toastSlice.reducer
import { NavLink } from "react-router-dom";
import M from "materialize-css";

const PhysicalCard = () => {
    const CopyToClipboard = (selectedId) => {
        /* Get the text field */
        var copyText = document.getElementById(selectedId);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        
        /* Alert the copied text */
        M.toast({html: 'Card number copied'})
    }

    return (
        <div className="flip-card">
            <div className="flip-card-inner">
                <div className="section-block wallet-card flip-card-front">
                    <div className="wallet-card-logo-div flex-div justify-content-btw">
                        <span className="balance-wrapper-txt">Physcial Card</span>
                        <img src={require("../../images/payrail-text-logo.svg").default} alt="payrail" className="wallet-card-logo" />
                    </div>
                    <div className="balance-wrapper">
                        <h4 className="wallet-balance">
                            <small >₦</small>10,000.09
                        </h4>
                        <img src={require("../../images/chip.svg").default} alt="chip" className="chip-icon" />
                    </div>
                    <div className="wallet-card-bottom flex-div">
                        <div className="flex-div add-send-money">
                            <a href="#fund-card-modal" className="hover-scale modal-trigger">
                                <img src={require("../../images/fund-card.svg").default} alt="icon" className="asm-img" />
                            </a> 
                            <NavLink to="/#" className="hover-scale">
                                <img src={require("../../images/withdraw.svg").default} alt="icon" className="asm-img" />
                            </NavLink>
                        </div>
                        <div className="wallet-bizness-name">
                            <img src={require("../../images/biz-logo.svg").default} alt="icon" className="wallet-biz-logo" /> <br />
                            <span>Ogbous and Sons </span>
                        </div>
                    </div>
                </div>
                <div className="section-block wallet-card card-back flip-card-back">
                    <div className="wallet-card-logo-div flex-div justify-content-btw">
                        <span className="balance-wrapper-txt">Physical Card Details</span>
                        <img src={require("../../images/payrail-text-logo-white.svg").default} alt="payrail" className="wallet-card-logo" />
                    </div>
                    <div className="card-details-block">
                        <div className="card-name">OLAGUNJU OLAMIDE</div>
                        <div className="flex-div card-num-block">
                            <input type="text" defaultValue="9292839020202" id="card-number" hidden />
                            <div className="card-number-txt">**** 8910</div>
                            <img src={require("../../images/white-copy-icon.svg").default} alt="copy-icon" className="copy-icon" onClick={() => CopyToClipboard("card-number")} />
                        </div>
                        <div className="card-date-cvv flex-div justify-content-btw">
                            <span className="card-date">Expires June 2025</span>
                            <span className="card-cvv">CVV ‣ 890</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhysicalCard;
import { PasswordField } from "../../passwordField";
import { useState } from 'react';

const CreateCardPinModal = () => {
    const [error] = useState({})
    const [value, setValue] = useState({})
    const handleInput = (e) =>{
        setValue({...value, [e.target.id]: e.target.value})
    }
    return(
        <div id="create-card-pin-modal" className="modal dashboard-modal card-form-modal">
            <div className="modal-content">
                <img src={require("../../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />  

                <h4 className="modal-block-title center">Create Card PIN</h4>  
            
                <form action="#" className="card-modal-form">
                    <PasswordField 
                        type="password"
                        value={value.new_password} 
                        label="New Password" 
                        onChange={handleInput} 
                        error={error['new_password']}
                        id={{input: "new_password"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0" , error:"error-text", input:"inp-field", }} 
                    />
                    <PasswordField 
                        type="password"
                        value={value.confirm_password} 
                        label="Confirm New Password" 
                        onChange={handleInput} 
                        error={error['confirm_password']}
                        id={{input: "confirm_password"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0" , error:"error-text", input:"inp-field", }} 
                    />
                    <div className="submit-btn-wrapper flex-div justify-content-end">
                        <div className="btn-side">
                            <button type="button" className="submit-btn pointer pry-btn modal-close modal-trigger" data-target="create-card-pin-success">Create PIN</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateCardPinModal;
import React from "react";
import '../../css/modals.css';
import 'rsuite/dist/rsuite.min.css';

const Transaction = ({item, onClick=()=>{}}) =>{
    const {channel="",status="",reference="",title="",amount="", date="", trx_status="", merchant='Japtini'} = item
    return(
        <tr className="thp-transaction-item" onClick={onClick}>
        <td>
            <img src={require('../../images/phone-box.svg').default} alt="bill-icon" className="bill-icon" />
        </td>
        <td>
            <span className="status-pill pending-pill">{status}</span>
        </td>
        <td>{reference}</td>
        <td className="gray-txt">
            {title}
        </td>
        <td className="gray-txt">
            <span className="pending-transaction">NGN {amount}</span>
        </td>
        <td>{merchant}</td>
        <td>{date}</td>

        <td> <button className="primary-btn" onClick={()=>{
                        //  navigate('view')
                        }}>View</button></td>
    </tr>
    )
}

export default Transaction
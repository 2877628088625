import React from "react";
import { NavLink } from "react-router-dom";
import Transaction from "../../components/TransactionItem";

import Wrapper from "../../components/UI/Wrapper";

const RecentTransactions = ({recent_transaction}) => {
    return (
        <Wrapper grey outlined className="trt-history-container">
            <div className="trt-header-box">
                <div className="trt-header-title">RECENT TRANSACTION</div>
                <div className="trt-view-all">
                    <NavLink to="/dashboard/transactions">
                        <span>VIEW ALL</span>
                        <img src={require("../../images/chevron-right.svg").default} alt="caret"  />
                    </NavLink>
                </div>
            </div>
            
            <ul className="trt-history-list">
                { recent_transaction.map(((item, index)=><Transaction key={index} {...item} />))}   
            </ul>   
        </Wrapper>
    );
};

export default RecentTransactions;
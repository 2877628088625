import React, { useEffect } from "react";
import M from "materialize-css";
import $ from "jquery"

import "./CardsCarousel.css";

import VirtualCard from "../Cards/VirtualCard";
import PhysicalCard from "../Cards/PhysicalCard";
import FundToActivateCard from "../Cards/FundToActivateCard";
import AddVirtualCard from "../Cards/AddVirtualCard";

const Carousel = (props) => {
    useEffect(() => {
        const elemsCarousel = document.querySelectorAll('.carousel');
        M.Carousel.init(elemsCarousel, {
          fullWidth: true
        });

        $(".flip-card").click(function(){
          $(this).toggleClass("flipped-card")
        })
    }, [])

    function nextCard() {
      const elemsCarousel = document.querySelector('#card-carousel');
      M.Carousel.getInstance(elemsCarousel).next()
    }
    function prevCard() {
      const elemsCarousel = document.querySelector('#card-carousel');
      M.Carousel.getInstance(elemsCarousel).prev()
    }

    return (
        <div className="custom-carousel">
            <div className="custom-carousel-nav-btn custom-carousel-arrow-left" onClick={prevCard}>
                <img src={require("../../images/caret-icon.svg").default} alt="caret" className="custom-carousel-arrow-icon custom-carousel-arrow-left-icon"/>
            </div>
            <div className="custom-carousel-nav-btn custom-carousel-arrow-right" onClick={nextCard}>
                <img src={require("../../images/caret-icon.svg").default} alt="caret" className="custom-carousel-arrow-icon custom-carousel-arrow-right-icon"/>
            </div>
            <div className="carousel carousel-slider" id="card-carousel">
              <div className="carousel-item" href="#one!">
                <FundToActivateCard />
              </div>
              <div className="carousel-item" href="#two!">
                <VirtualCard />
              </div>
              <div className="carousel-item" href="#three!">
                <PhysicalCard />
              </div>
              <div className="carousel-item" href="#three!">
                <AddVirtualCard />
              </div>
            </div>
        </div>
    )
}

export default Carousel;

import React from "react";
import '../../css/modals.css';
import 'rsuite/dist/rsuite.min.css';

const Transaction = ({item, onClick=()=>{}}) =>{
    const {channel="",status="",reference="",title="",amount="", date="", trx_status=""} = item
    return(
        <tr className="thp-transaction-item modal-trigger" onClick={()=>onClick(item)}>
        <td>
            <img src={require('../../images/phone-box.svg').default} alt="bill-icon" className="bill-icon" />
        </td>
        <td>
            <span className="status-pill pending-pill">{status}</span>
        </td>
        <td>{reference}</td>
        <td className="gray-txt">
            <span className="pending-transaction">NGN {amount}</span> · {title}
        </td>
        <td>{date}</td>
        <img src={require("../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
    </tr>
    )
}

export default Transaction
import React from "react";
import '../../css/modals.css';
import 'rsuite/dist/rsuite.min.css';

const Transaction = ({title="",status="",phone_number="",type="",amount="", date="", trx_status=""}) =>{

    return(
        <tr>
            <td>
                <div className="flex-div">
                    <img src={require("../../images/airtime-tranx.svg").default} alt="transaction icon" className="tranx-activity-icon" />
                    <div className="tranx-activity-details">
                        <div className="tranx-activity">{title}</div>
                        <small className="tranx-activity-status">{status}</small>
                    </div>
                </div>
            </td>
            <td>
                <span>{phone_number}</span>
            </td>
            <td>
                <span>{type} </span>
            </td>
            <td>
                <div className="flex-div">
                    <div className="tranx-activity-details">
                        <div className={`tranx-activity tranx-amount ${trx_status === "success"?"success-transaction": trx_status === "failed"? "failed-transaction" : "pending-transaction"}`}> ₦{amount}</div>
                        <small className="tranx-activity-status">{date}</small>
                    </div>
                    <img src={require("../../images/menu-circle.svg").default} alt="icon" className="table-menu-circle" />
                </div>
            </td>
        </tr>
    )
}

export default Transaction
import React, { useState } from "react";
import OtpInput from 'react-otp-input';

import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { VERIFICATION_CODE_MODAL_ID, ENTER_PIN_MODAL_ID } from "../../../../utility/modalIDs";

const VerificationCodeModal = () => {
    const [otp, setOTP] = useState('');

    return (
        <ModalWrapper modalID={VERIFICATION_CODE_MODAL_ID}>
            <div className="qtsmm-modal-title">Enter the verification code</div>
        
            <div id="verification-codes">
                <div className="input-row row vcm-mt">
                    <OtpInput
                        value={otp}
                        onChange={(val)=>{
                            setOTP(val)
                        }}
                        numInputs={6}
                        separator={<span> </span>}
                    />
                </div>
            </div>

            <div className="vcm-flex">
                <div className="vcm-small-text">
                    Did’nt get OTP? <span>Resend</span>
                </div>

                <button className="pry-btn modal-trigger modal-close " data-target={ENTER_PIN_MODAL_ID}>
                    Verify
                </button>
            </div>
        </ModalWrapper>
    );
};

export default VerificationCodeModal;
import { useEffect } from "react";

// css
import "./Administration.css";

import M from "materialize-css";

// Components
import Team from "./Team";
import RolesAndPrivileges from "./RolesAndPrivileges";

const AdministrationMain = () => {
    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);
    }, []);

    return (
        <div className="container admin-page-cont">
            <div className="page-title-wrapper">
                <h4 className="page-content-title">
                    <span>Administration</span>
                </h4>
            </div>

            <div className="row tab-wrapper ap-fullHeight2">
                <div className="col s12 pad0">
                    <ul className="tabs">
                        <li className="tab">
                            <a className="active flex-div" href="#profile-block">
                                <span>Team</span> 
                            </a>
                        </li>
                        <li className="tab">
                            <a className="flex-div" href="#security-block">
                                <span>Roles and priviliges</span> 
                            </a>
                        </li>
                    </ul>
                </div>
                <div id="profile-block" className="col s12 pad0 ap-fullHeight">
                    <Team />
                </div>
                <div id="security-block" className="col s12 pad0">
                    <RolesAndPrivileges />
                </div>
            </div>
        </div>
    );
}

export default AdministrationMain;
import "./BillPaymentSchedulePaymentModal.css"

import { useEffect } from "react";

import $ from "jquery";

// import DateRangePicker from 'rsuite/DateRangePicker';
import M from "materialize-css";

const SchedulePaymentModal = () => {
    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);
    }, []);

    return(
        <div id="bill-payment-schedule-payment-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <img src={require("../../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />    

                <div className="sp-header">
                    <h4>
                        Schedule Payment
                    </h4>
                </div>

                <form action="#" id="personal-profile-edit-form" onSubmit={e => e.preventDefault()} className="dashboard-form">
                    <div className="select-field input-field single-field rel">
                        <select id="status-1">
                            <option value="Show all">Recurring Payment</option>
                            <option value="Show New">Show New</option>
                            <option value="Show Returning">Show Returning</option>
                        </select>
                        <label htmlFor="status-1" className="active">Select Option </label>
                        <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                    </div>
                    <div className="select-field input-field single-field rel">
                        <select id="interval">
                            <option value="Show all">Monthly</option>
                            <option value="Show New">Show New</option>
                            <option value="Show Returning">Show Returning</option>
                        </select>
                        <label htmlFor="interval" className="active">Pattern </label>
                        <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                    </div>

                    <div className="select-field input-field single-field rel">
                        <select id="interval">
                            <option value="Show all">Every 1 Month</option>
                            <option value="Show New">Show New</option>
                            <option value="Show Returning">Show Returning</option>
                        </select>
                        <label htmlFor="interval" className="active">Frequency </label>
                        <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                    </div>

                    <div className="input-field single-field datepicker-field">
                        <div className="input-field">
                            <input id="start-date" type="text" placeholder="Select Date" className="form-inp-field datepicker" />
                            <label htmlFor="start-date">Start Date</label>
                        </div>
                    </div>
                    <div className="input-field single-field datepicker-field">
                        <div className="input-field">
                            <input id="end-date" type="text" placeholder="Select Date (Optional)" className="form-inp-field datepicker" />
                            <label htmlFor="end-date">End Date</label>
                        </div>
                    </div>

                    <button className="pending-btn pry-btn modal-trigger ep-pin-okay-btn modal-close" data-target="bill-payment-schedule-success-modal">
                        Continue
                    </button>
                </form>
            </div>
        </div>
    )
}

export default SchedulePaymentModal;
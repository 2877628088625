import '../css/auth.css';
import congrats from "../images/cancel.png";

import { BrowserRouter as NavLink  } from "react-router-dom";

const CancelPage = () => {

    
    return(
        <div className="status-block-wrapper">
            <div className="auth-greet-title center">
                <img src={congrats} alt="congrats" className="congrats-img" />
                <h1 className="greet-title">
                    Transaction Cancelled
                </h1>
                <p className="greet-desc">
                    Proceed to marchant page by clicking the button below
                </p>
                <div className="submit-btn-wrapper center">
                    <div className="btn-side">
                        <NavLink to="/dashboard/home">
                            <button className="submit-btn">Back to Merchant Page</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelPage;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  invoices:[{customer:"jahido@gmail.com",amount:"1,000.00",requested:"Oct 17, 2021",invoice_number:"PL002",status:"Collected"}],
  invoiceBalance:{
    received:"10,000,000.09",
    total: "10,000,000.09",
    pending: "2,000,000.00"
  }
 
}

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,

  reducers: {
    editInvoice: ()=>{},
    sendReminder: ()=>{},
    markAsPay: ()=>{},
    addInvoice: ()=>{},
    searchInvoice: ()=>{},
    filterInvoice: ()=>{},
    fetchInvoice: ()=>{},
    Invoices: (state, action) => {
      state.invoices = action.payload;
      state.actionType = action.type
    },
    getInvoiceBalance: ()=>{},
    InvoicesBalance: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
    invoiceAdded: (state, action) => {
      state.invoiceAdded = true
      state.actionType = action.type
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { 
  Invoices,
  InvoicesBalance,
  
  addInvoice,
  searchInvoice,
  getInvoiceBalance,
  filterInvoice,
  fetchInvoice,
  editInvoice,
  sendReminder,
  markAsPay,
  invoiceAdded
   
    } = invoiceSlice.actions

export default invoiceSlice.reducer
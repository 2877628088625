
import $ from "jquery";
import M from "materialize-css"
import "../Transactions.css";
import  React,{ useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../../redux/reducers/auth";
import debounce from "debounce";

import DateRangePicker from 'rsuite/DateRangePicker';

// import Wrapper from "../../../components/UI/Wrapper";
import { REQUEST_TRANSACTION_ITEM_MODAL_ID } from "../../../utility/modalIDs";
import RequestItemModal from "./RequestItemModal";
import { getRequestTransaction, searchRequestTransaction } from "../../../redux/reducers/wallet";
import Transaction from "../../../components/RequestTransaction";

const Request = () => {
    const dispatch = useDispatch()
    const {auth = {}, wallet={}, customer} = useSelector(state=>state);
    const { request_transaction=[]} = wallet
    const [search, setSearch] = useState("");
    const [item, setItem] = useState({})
    const handleSearch = (text) =>{
       dispatch(searchRequestTransaction(text))

    }

    const fetchOngoing = () =>{
        dispatch(setLoading(true));
        dispatch(getRequestTransaction())
        dispatch(setLoading(false));
    }
    const searchHandler = useCallback(debounce((text)=>handleSearch(text), 2000), []);

    useEffect(()=>{
        if (search !== "") {
            searchHandler(search)
        }else{
            if (search !==null) {
                fetchOngoing()
            }
        }
    },[search])

    const handleInput = (e) =>{
        setSearch(e.target.value)
    }

    const viewItem = (item) =>{
        setItem(item)
        const elemsModal2 = document.querySelector(`#${REQUEST_TRANSACTION_ITEM_MODAL_ID}`);
        M.Modal.getInstance(elemsModal2).open();
    }

    useEffect(()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);
    return (
        <>
            <div className="page-bar-wrapper flex-div justify-content-btw">
                <div className="page-bar-left flex-div">
                    <div className="filter-block flex-div">
                        <div className="filter-dropdown-trigger">
                            <img src={require('../../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                            <span>Filters</span>
                        </div>


                        <ul id="filter-dropdown-2" className="filter-dropdown-content">

                            <div className="arrow-up"></div>

                            <div className="select-field input-field single-field rel">
                                <input type="text" id="plan-name" className="form-inp-field" placeholder="Search" />
                                <label htmlFor="plan-name" className="active">Plan</label>
                            </div>

                            <div className="select-field input-field single-field rel">
                                <select id="status">
                                    <option value="Show all">Show all</option>
                                    <option value="Show New">Show New</option>
                                    <option value="Show Returning">Show Returning</option>
                                </select>
                                <label htmlFor="status" className="active">Status </label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="select-field input-field single-field rel">
                                <select id="amount">
                                    <option value="Show all">Show all</option>
                                    <option value="Show New">Show New</option>
                                    <option value="Show Returning">Show Returning</option>
                                </select>
                                <label htmlFor="amount" className="active">Amount </label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="select-field input-field single-field rel">
                                <select id="expiring-cards">
                                    <option value="Show all">Show all</option>
                                    <option value="Show New">Show New</option>
                                    <option value="Show Returning">Show Returning</option>
                                </select>
                                <label htmlFor="expiring-cards" className="active">Expiring Cards </label>
                                <img src={require("../../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                            </div>

                            <div className="select-field input-field single-field rel">
                                <DateRangePicker placeholder="Select date range" id="date-range" format="dd-MMM-yy" placement="topStart" className="rs-datepicker" />
                                {/* <input type="text" id="creation-date" className="form-inp-field datepicker" placeholder="Search" /> */}
                                <label htmlFor="date-range-2" className="active">Date Range</label>
                            </div>

                            <div className="filter-btns-wrapper">
                                <button className="filter-btn pry-btn">Filter</button>
                                <button className="filter-reset-btn">
                                    <span>Reset to default</span>
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div className="vertical-divider">
                        <hr />
                    </div>
                    <div className="bar-search-block rel">
                        <img src={require('../../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                        <input onChange={handleInput} type="search" className="bar-search" placeholder="Search Customers" />
                    </div>
                </div>
                <div className="page-bar-right flex-div">
                    <div className="export-trigger-block flex-div">
                        <span>Export CSV</span>
                        <img src={require('../../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                    </div>
                </div>
            </div>

            <div className="table-wrapper">
                <table id="customers-table">
                    <thead>
                        <tr>
                            <th>CHANNEL</th>
                            <th>STATUS</th>
                            <th>REFERENCE</th>
                            <th>TRANSACTION INFORMATION</th>
                            <th>DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                    {request_transaction.map(((item)=>{
                                        
                                        return(
                                            <Transaction item={item} onClick={viewItem} />
                                        )
                                    })
                                )
                            }
                        {/* <tr className="thp-transaction-item modal-trigger" data-target={REQUEST_TRANSACTION_ITEM_MODAL_ID}>
                            <td>
                                <img src={require('../../../images/phone-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>
                                <span className="status-pill pending-pill">Pending</span>
                            </td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="pending-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/wallet-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>
                                <span className="status-pill success-pill">Successful</span>
                            </td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="success-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/building-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>
                                <span className="status-pill failed-pill">Failed</span>
                            </td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="failed-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/wallet-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>
                                <span className="status-pill pending-pill">Pending</span>
                            </td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="pending-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/wallet-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>
                                <span className="status-pill failed-pill">Failed</span>
                            </td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="failed-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr>
                        <tr className="thp-transaction-item">
                            <td>
                                <img src={require('../../../images/building-box.svg').default} alt="bill-icon" className="bill-icon" />
                            </td>
                            <td>
                                <span className="status-pill success-pill">Successful</span>
                            </td>
                            <td>PT08148620099</td>
                            <td className="gray-txt">
                                <span className="success-transaction">NGN 10,000</span> · Airtime Purchase
                            </td>
                            <td>Sun.01 Sept, 04:25AM</td>
                            <img src={require("../../../images/vertical-circles.svg").default} alt="caret" className="thp-v-icon"  />
                        </tr> */}
                    </tbody>
                </table>
            </div>

            <RequestItemModal item={item} />
        </>
    );
};

export default Request;
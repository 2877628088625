const RequestCardInProcess = () => {
    return(
        <div className="request-card-process-wrapper">
            <div className="request-card-process-block">
                <img src={require("../../images/rcpw.svg").default} alt="rcpw" className="rcpw-img" />
                <div className="rcpw-title">Physical card in Process</div>
                <p className="rcpw-desc">
                    We recieved your request and your payrail physical business debit card is been processed
                </p>
            </div>
        </div>
    )
}
export default RequestCardInProcess;
import "../css/top-nav.css";

import { NavLink } from "react-router-dom";
import M from "materialize-css";
import { useEffect } from "react";
import $ from "jquery";

// https://github.com/ambassify/react-avatar
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/reducers/auth";

const TopNav = () => {
    const dispatch = useDispatch()
    const {auth = {}} = useSelector(state=>state)
    const {user={}} = auth
    const {first_name="Olamide", last_name=""} = user
    useEffect(()=> {
        var elemsDropdown = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elemsDropdown, {
            coverTrigger: false,
            click: true,
            constrainWidth: false
        });

        $("#search-form .search-btn").click(function() {
            $("#search-form > .rel").toggleClass("inactive");
        })
    }, []);

    return(
        <nav className="top-nav">
            <div className="flex-div align-content-center justify-content-btw">
                <div id="search-form">
                    <div className="rel inactive">
                        <input id="search" type="search" className="search-input" placeholder="Search anything " />
                        <button className="search-btn">
                            <img src={require("../images/search-4.svg").default} alt="icon" className="search-btn-img" />
                        </button>
                    </div>
                </div>

                {/* <div className="topnav-btn-wrapper flex-div">
                    <NavLink to="/#" className="topnav-btn hover-scale">
                        <img src={require("../images/settings.svg").default} alt="icon" className="topnav-btn-img" />
                    </NavLink>
                    <NavLink to="/#" className="topnav-btn hover-scale">
                        <img src={require("../images/referral.svg").default} alt="icon" className="topnav-btn-img" />
                    </NavLink>
                    <NavLink to="/#" className="topnav-btn hover-scale">
                        <img src={require("../images/support.svg").default} alt="icon" className="topnav-btn-img" />
                    </NavLink>
                </div> */}

                <div className="user-session-wrapper flex-div">
                    <NavLink to="/dashboard/settings" className="topnav-btn hover-scale">
                        <img src={require("../images/settings.svg").default} alt="icon" className="topnav-btn-img tbi-default" title="Settings" />
                        <img src={require("../images/settings-black.svg").default} alt="icon" className="topnav-btn-img tbi-active" title="Settings" />
                    </NavLink>
                    {/* <NavLink to="/dashboard/referrals" className="topnav-btn hover-scale">
                        <img src={require("../images/referral.svg").default} alt="icon" className="topnav-btn-img tbi-default" title="Referral" />
                        <img src={require("../images/referral-black.svg").default} alt="icon" className="topnav-btn-img tbi-active" title="Referral" />
                    </NavLink> */}
                    {/* <NavLink to="/#" className="topnav-btn hover-scale">
                        <img src={require("../images/support.svg").default} alt="icon" className="topnav-btn-img" title="Support" />
                    </NavLink> */}

                    <NavLink to="/#" className="topnav-btn hover-scale">
                        <img src={require("../images/notification.svg").default} alt="icon" className="topnav-btn-img" title="Notifications" />
                    </NavLink>
                    <div className="dropdown-trigger flex-div" data-target="dropdown1">
                        <div className="user-details flex-div">
                            <img src={require("../images/user-pix.png").default} alt="user avatar" className="user-avatar" hidden /> {/* To be used if user avatar exists --- Take out the "hidden" attribute */}
                            {/* <div className="user-name">Olamide Olagunju</div> */}
                            <Avatar name={`${first_name} ${last_name}`} className="no-user-avatar" />
                        </div>
                        {/* <div className="caret-div">
                            <img src={require("../images/caret-down-orange.svg").default} alt="caret" className="topnav-caret" />
                        </div> */}
                    </div>
                    <ul id='dropdown1' className='dropdown-content'>
                        <li>
                            <NavLink to="/dashboard/settings">Go to profile</NavLink>
                        </li>
                        <li className="divider" tabIndex="-1"></li>
                        <li>
                            <NavLink to="#!" onClick={()=>dispatch(logout())}>Log out</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default TopNav;
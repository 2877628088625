import "./CardDefreezeSuccessModal.css"

const CardDefreezeSuccessModal = () => {
    return(
        <div id="card-defreeze-success-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <div className="defreeze-card-success">
                    <div>
                        <img src={require("../../../../images/Unock.svg").default} alt="unlock-img" /> 
                    </div>
                </div>
                
            
                <div className="ep-pin">
                    <h4>Card Unfrozen successfully</h4>
                </div>

                <div className="defreeze-card-modal-text">
                    <span>Your Payrail business debit card has been defrost.</span> <br></br>
                    <span>You can freeze it anytime you want</span>
                </div>

                <div className="defreeze-card-modal-btn">
                    <button className="pry-btn modal-trigger modal-close ">
                        Okay
                    </button>
                </div>
            </div>

        </div>
    )
}

export default CardDefreezeSuccessModal;
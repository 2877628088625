import React, { useEffect } from "react";
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { VALIDATE_BENEFICIARIES_MODAL_ID, SCHEDULE_PAYMENT_MODAL_ID } from "../../../../utility/modalIDs";

import $ from "jquery";

const ValidateBeneficiaries = () => {
    useEffect(()=> {
        $(".delete-icon").click(function(){
            $(this).parent().hide()
        });
    }, []);

    return (
        <ModalWrapper modalID={VALIDATE_BENEFICIARIES_MODAL_ID} className="vbm-modal-width">
            <div className="upbm-header-wrapper">
                <h4 className="upbm-header-mb">Validating beneficiaries</h4>
                <div className="modal-desc">Hold and drag left to remove beneficiaries</div>
            </div>
            <form action="#" className="dashboard-form modal-form vbm-form" onSubmit={e => e.preventDefault()}>
                <div className="vbm-d-flex">
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="first_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="first_name">First Name</label>
                        </div>
                    </div>
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="list_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="list_name">Amount</label>
                        </div>
                    </div>
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="list_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="list_name">Account Number</label>
                        </div>
                    </div>
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="list_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="list_name">Bank Name</label>
                        </div>
                    </div>
                    <img src={require("../../../../images/check-circle-orange-fill.svg").default} alt="status-circle"/>
                    <img src={require("../../../../images/delete-icon.svg").default} alt="delete" className="delete-icon"/>
                </div>

                <div className="vbm-d-flex">
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="first_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="first_name">First Name</label>
                        </div>
                    </div>
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="list_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="list_name">Amount</label>
                        </div>
                    </div>
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="list_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="list_name">Account Number</label>
                        </div>
                    </div>
                    <div className="input-field single-field">
                        <div className="input-field">
                            <input id="list_name" type="text" defaultValue={""} className="form-inp-field" />
                            <label htmlFor="list_name">Bank Name</label>
                        </div>
                    </div>
                    <img src={require("../../../../images/cancel-circle-grey-fill.svg").default} alt="status-circle"/>
                    <img src={require("../../../../images/delete-icon.svg").default} alt="delete" className="delete-icon"/>
                </div>

                <button className="pry-btn modal-trigger modal-close amvutm-btn-fullwidth" data-target={SCHEDULE_PAYMENT_MODAL_ID}>
                    Add Beneficiaries
                </button>
            </form>
        </ModalWrapper>
    );
};

export default ValidateBeneficiaries;
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { NavLink, useNavigate} from 'react-router-dom';
import '../css/auth.css';
import $ from 'jquery';
import OtpInput from 'react-otp-input';
import { signup} from "../redux/reducers/auth";
import { verifyOTPApi } from "../redux/sagas/requests/auth";
import { setToast } from "../redux/reducers/toast";
import { Loader } from "../components/loader";

/**
 * Constructs form validation object for verification with phone number page using Yup library
 * @param otp : OTP code set to user number.
 */
const formSchema = Yup.object().shape({
    otp: Yup.number().required("Required"),
})

/**
 * OTP verification with email componenet
 */
const VerifyEmailSignup = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    
    const  {  user, } = useSelector(state=> state.auth)
    const [loading, setLoading] = useState(false)

     /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, watch, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
      })

    /**
     * onSubmit function to trigger form submission for email verification.
     * @param otp : OTP code sent to user for verification.
     */
     const onSubmit = (data) => {
        trigger()
    
        if (Object.keys(errors).length === 0){
            setLoading(true)
            const newData = {email: user.email, mfa: data.otp.toString(), onboardingResponse: user.onboardingResponse}
            verifyOTPApi(newData).then(res=>{
                console.log('___OTP RES_', res)
                if (res.status === 'verify') {
                    dispatch(setToast({type:'error', message:'Error: Invalid OTP code'}))
                }else{
                    dispatch(signup({otp: data.otp.toString(), onboardingResponse:{...res.onboardingOption.onboardingResponse}}))
                    navigate("/signup/email-verified")
                }
                setLoading(false)
               
            }).catch(err=>{
                console.log('___OTP ERROR__', err)
                setLoading(false)
                dispatch(setToast({type:'error', message:'Error: Invalid OTP code'}))
            })
            
            }
        }
   
    useEffect(() => {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        })

        $("#referral-field-trigger").click(function(){
            $(this).parent().hide();
            $("#referral-field").slideDown();
        });

    }, []);
    
    return(
        <div className="auth-block">
            <form id="verification-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Enter the verification code
                    </h1>
                    <p className="greet-email-desc">
                       {user.email}. <NavLink to="/signup"><u>Wrong email?</u></NavLink>
                    </p>
                </div>
                <div className="input-row row">
                    <div id="verification-codes">
                        <OtpInput
                            value={watch("otp")}
                            {...register('otp',{ required: true })}
                            onChange={e =>{setValue("otp", e)}}
                            numInputs={6}
                            separator={<span> </span>}
                        />
                    </div>
                </div>

                {loading&&<Loader />}

                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <div className="txt-link-side">
                        Did’nt get OTP?
                        <button type="button" className="txt-btn pry-color"><u>Resend</u></button>
                    </div>
                    <div className="btn-side">
                        <button type="submit" disabled={loading} className="submit-btn pointer">Verify</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default VerifyEmailSignup;
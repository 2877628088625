import '../css/auth.css';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { PasswordField } from '../components/passwordField';

const ResetPassword = () => {
    const [error, setError] = useState({})
    const [value, setValue] = useState({})

    useEffect(() => {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        })
    }, []);

    const handleInput = (e) =>{
        setValue({...value, [e.target.id]: e.target.value})
    }

    
    return(
        <div className="auth-block">
            <form id="new-password-form" action="/signup/success">
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Create new password, <span>Olamide</span>
                    </h1>
                    <p className="greet-desc">
                        Please, check your browser’s address bar to be sure you’re on <br />
                        <a href="https://app.payrail.com">https://app.payrail.com</a>
                    </p>
                </div>
                <div className="row">
                    <PasswordField 
                        type="password"
                        value={value.old_password} 
                        label="Old Password" 
                        onChange={handleInput} 
                        error={error['old_password']}
                        id={{input: "old_password", div: "old-password-row"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0" , error:"error-text", input:"inp-field", }} 
                    />
                    <PasswordField 
                        type="password"
                        value={value.new_password} 
                        label="New Password" 
                        onChange={handleInput} 
                        error={error['new_password']}
                        id={{input: "new_password"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0" , error:"error-text", input:"inp-field", }} 
                    />
                    <PasswordField 
                        type="password"
                        value={value.confirm_password} 
                        label="Confirm New Password" 
                        onChange={handleInput} 
                        error={error['confirm_password']}
                        id={{input: "confirm_password"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0" , error:"error-text", input:"inp-field", }} 
                    />
                </div>

                <div className="submit-btn-wrapper flex-div justify-content-end">
                    <div className="btn-side">
                        <button className="submit-btn pointer">Create new password</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ResetPassword;
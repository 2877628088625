import '../css/auth.css';
import $ from 'jquery';
import M from 'materialize-css';
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
// import { isObjEmpty } from '@utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputField } from '../components/InputField';
import { PasswordField } from '../components/passwordField';
import * as Yup from 'yup'
import { useNavigate, NavLink  } from 'react-router-dom';
import { Loader } from '../components/loader';
import { identitySignupApi } from '../redux/sagas/requests/auth';
import { SerializeFormats,  Misc, OAuthCredential} from '@payrail/ancore'

/**
 * Constructs form validation object for identity with phone numbe using Yup library
 * @param email : User email address.
 * @param username : Account username.
 * @param first_name : User first name.
 * @param last_name : User last name.
 * @param country : User country.
 * @param password : User password.
 */
const formSchema = Yup.object().shape({
    email: Yup.string().required("Required").email(),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
})

/**
 * Account identity componenet
 */
const EmailIdentity = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const  {user} = useSelector(state=> state.auth)
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            email: user.email
        }
        
      })

      useEffect(()=>{
          setError(errors)
      },[errors])

    /**
     * onSubmit function to trigger form submission for identity with phone numbe using Yup library
     * @param email : Account user email address.
     * @param username : Account username.
     * @param first_name : User first name.
     * @param last_name : User last name.
     * @param country : User country.
     * @param password : User password.
     */
     const onSubmit = (data) => {
        trigger()

        if (Object.keys(errors).length === 0){
            setLoading(true)
            const newData = {email: data.email, onboardingResponse: user.onboardingResponse, 
                accountProvisionSettings: {
                subscriber_account_name: 'AubMarket',
                subscriber_data_security_mode: 'system',
                subscriber_dataprotection_algo: 'none',
                administratorCredentials: new OAuthCredential(
                    Misc.GenerateId(Misc.Constants.MAX_ID_LENGTH),
                    Misc.GenerateId(Misc.Constants.MAX_ID_LENGTH),
                    data.email,
                    data.password,
                    false,
                ).serialize(SerializeFormats.HIBERNATESTATE_FORMAT)
            }}

            console.log('___OTP DATA_2', newData)
            identitySignupApi(newData).then(res=>{
                console.log('___OTP RES_', res)
                setLoading(false)
                navigate("/signup/success")
            }).catch(err=>{
                console.log('___OTP ERROR__', err)
                setLoading(false)
            })
            
            }
        }

    useEffect(() => {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        })

        $("section.auth-wrapper.container").addClass("identity-page-wrapper");

        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);
 
    return(
        <div className="auth-block">
            <form id="identity-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Your Idenitity
                    </h1>
                    <p className="greet-desc">
                        Tell us more about you, please use your name as it appears on your ID  
                    </p>
                </div>
                <div className="input-row row">
                    <InputField 
                        type="text" 
                        label="First Name" 
                        {...register('first_name',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['first_name']? error['first_name'].message: ""} 
                        id={{input: "first_name"}} 
                        classname={{div:"input-field col s12 m6 mag-d-0 mag-t-0 pad0 f-name-field", error:"error-text", input:"inp-field", }} 
                    />
                    <InputField 
                        type="text" 
                        label="Last Name" 
                        {...register('last_name',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['last_name']? error['last_name'].message: ""} 
                        id={{input: "last_name"}} 
                        classname={{div:"input-field col s12 m6 mag-d-0 mag-t-0 pad0 l-name-field", error:"error-text", input:"inp-field", }} 
                    />
                    <InputField 
                        type="email" 
                        value={user.email}
                        disabled={true}
                        {...register('email',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['email']? error['email'].message: ""} 
                        id={{input: "email"}} 
                        classname={{div:"input-field col s12 mag-d-0 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <InputField 
                        type="text" 
                        label="Username" 
                        {...register('username',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['username']? error['username'].message: ""}  
                        id={{input: "username"}} 
                        classname={{div:"input-field col s12 mag-d-0 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <PasswordField 
                        type="password"
                        label="Password" 
                        {...register('password',{ required: true })}
                        onChange={e =>{setValue(e.target.id, e.target.value)}}
                        error={error && error['password']? error['password'].message: ""}  
                        id={{input: "password"}} 
                        classname={{div:"input-field col s12 mag-d-0 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                </div>

                {loading&&<Loader />}

                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <div className="txt-link-side">
                        Have an account? <NavLink to="/login/new" className="pry-color"><u>Login</u></NavLink>
                    </div>
                    <div className="btn-side">
                        <button type="submit" className="submit-btn pointer">Continue </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EmailIdentity;
import M from "materialize-css";
const { default: Wrapper } = require("../../components/UI/Wrapper")
const { Loader } = require("../../components/loader")

const terminalStatus = {
    'active': "green-text",
    'suspended': "amber-text",
    'damaged': "red-text",
};
const POSInventory= ({POSInventories, fetchingPOSInventory}) => {

    const editTerminal = () => {
        const terminalBtnModal = document.querySelector('#edit-pos-terminal');
        M.Modal.getInstance(terminalBtnModal).open();
    }

    const retreiveTerminal = () => {
        const terminalBtnModal = document.querySelector('#retreive-pos-terminal');
        M.Modal.getInstance(terminalBtnModal).open();
    }

    const assignTerminal = () => {
        const terminalBtnModal = document.querySelector('#assign-pos-terminal');
        M.Modal.getInstance(terminalBtnModal).open();
    }

    return(
        <Wrapper grey outlined  className="tt-table-container table-wrapper">
                                    <table id="customers-table">
                                        <thead>
                                            <tr>
                                                <th>TERMINAL ID</th>
                                                <th>SERIAL NO.</th>
                                                <th>TYPE</th>
                                                <th>ASSIGNEE</th>
                                                <th>STATUS</th>
                                                <th>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
    
    
                                            {
                                                fetchingPOSInventory? <Loader/> :
                                                POSInventories.length? POSInventories.map(item=>{
                                                    const {terminalId, terminalAlias, bankAccount, status = ''} = item
                                                    return(
                                                        <tr>
                                                            <td>{terminalId}</td>
                                                            <td>{terminalAlias}</td>
                                                            <td>{bankAccount}</td>
                                                            <td>{terminalAlias}</td>
                                                            <td>
                                                                {status == ''}
                                                                <span className={terminalStatus[status]}>{status}</span>
                                                            </td>
                                                            <td><button className="primary-btn" onClick={()=>{
                                                                editTerminal()
                                                            }}>Edit</button></td>
                                                            <td><button className="primary-btn" onClick={()=>{
                                                                assignTerminal()
                                                            }}>Assign</button></td>
                                                            <td><button className="primary-btn" onClick={()=>{
                                                               retreiveTerminal()
                                                            }}>Retrieve</button></td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                           
                                        </tbody>
                                    </table>
                                </Wrapper>
    )

}

export default POSInventory
import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../redux/reducers/auth";
import debounce from "debounce";
import $ from "jquery";
import M from "materialize-css";

// Link to DaterangePicker:  https://rsuitejs.com/components/date-range-picker/
import DateRangePicker from 'rsuite/DateRangePicker';
import { fetchPlan, fetchSubscribers, searchPlan, searchSubscribers } from "../../redux/reducers/subscription";
import PlanItem from "../../components/PlanItem";
import SubscriberItem from "../../components/SubscriberItem";

const Subscriptions = () => {

    const dispatch = useDispatch()
    const {auth = {}, subscription={}} = useSelector(state=>state);
    const { plans=[], subscribers=[]} = subscription
    const [search_plan, setSearchPlan] = useState("");
    const [search_subscriber, setSearchSubscriber] = useState("");

    const handleSearchPlan = (text) =>{
       dispatch(searchPlan(text))

    }

    const getPlan = () =>{
        dispatch(setLoading(true));
        dispatch(fetchPlan())
        dispatch(setLoading(false));
    }
    const searchHandlerPlan = useCallback(debounce((text)=>handleSearchPlan(text), 2000), []);

    useEffect(()=>{
        if (search_plan !== "") {
            searchHandlerPlan(search_plan)
        }else{
            if (search_plan !==null) {
                getPlan()
            }
        }
    },[search_plan])

    const handlePlanInput = (e) =>{
        setSearchPlan(e.target.value)
    }

    const handleSubSearch = (text) =>{
        dispatch(searchSubscribers(text))
 
     }
 
     const getSubscriber = () =>{
         dispatch(setLoading(true));
         dispatch(fetchSubscribers())
         dispatch(setLoading(false));
     }

     const searchSubHandler = useCallback(debounce((text)=>handleSubSearch(text), 2000), []);
 
     useEffect(()=>{
         if (search_subscriber !== "") {
            searchSubHandler(search_subscriber)
         }else{
             if (search_subscriber !==null) {
                getSubscriber()
             }
         }
     },[search_subscriber])
 
     const handleSubInput = (e) =>{
         setSearchSubscriber(e.target.value)
     }


    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        var elemsDatepicker = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elemsDatepicker);

        $(".filter-dropdown-trigger").click(function(){
            $(this).parent().find(".filter-dropdown-content").slideToggle();
        });
    }, []);
    return(
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Subscription </span>
                        {/* <small className="page-label-tag">3</small> */}
                    </h4>
                </div>

                <div className="row tab-wrapper">
                    <div className="col s12 pad0">
                        <ul className="tabs">
                            <li className="tab">
                                <a className="active flex-div" href="#plans-block">
                                    <span>Plan</span> 
                                    <small className="page-label-tag">3</small>
                                </a>
                            </li>
                            <li className="tab">
                                <a className="flex-div" href="#subscribers-block">
                                    <span>Subscribers</span> 
                                    <small className="page-label-tag">3</small>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div id="plans-block" className="col s12 pad0">
                        <div className="page-bar-wrapper flex-div justify-content-btw">
                            <div className="page-bar-left flex-div">
                                <div className="filter-block flex-div">
                                    <div className="filter-dropdown-trigger">
                                        <img src={require('../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                                        <span>Filters</span>
                                    </div>


                                    <ul id="filter-dropdown" className="filter-dropdown-content">

                                        <div className="arrow-up"></div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="status-1">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="status-1" className="active">Status </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="interval">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="interval" className="active">Interval </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="subscription-num">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="subscription-num" className="active">No. of Subscription </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="amount-1">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="amount-1" className="active">Amount </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="total-revenue">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="total-revenue" className="active">Total Revenue </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>


                                        <div className="select-field input-field single-field rel">
                                            <DateRangePicker placeholder="Select date range" id="date-range" format="dd-MMM-yy" placement="topStart" className="rs-datepicker" />
                                            {/* <input type="text" id="creation-date" className="form-inp-field datepicker" placeholder="Search" /> */}
                                            <label htmlFor="date-range-1" className="active">Date Range</label>
                                        </div>

                                        <div className="filter-btns-wrapper">
                                            <button className="filter-btn pry-btn">Filter</button>
                                            <button className="filter-reset-btn">
                                                <span>Reset to default</span>
                                            </button>
                                        </div>
                                    </ul>
                                </div>
                                <div className="vertical-divider">
                                    <hr />
                                </div>
                                <div className="bar-search-block rel">
                                    <img src={require('../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                                    <input onChange={handlePlanInput} type="search" className="bar-search" placeholder="Search Plan" />
                                </div>
                            </div>
                            <div className="page-bar-right flex-div">
                                <div className="add-btn-wrapper">
                                    <button className="add-trigger-btn modal-trigger" data-target="add-new-customer-modal">
                                        &#43; Add Plan
                                    </button>
                                </div>
                                <div className="export-trigger-block flex-div">
                                    <span>Export CSV</span>
                                    <img src={require('../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="table-wrapper">
                            <table id="customers-table">
                                <thead>
                                    <tr>
                                        <th>INFORMATION</th>
                                        <th>SUBSCRIPTION</th>
                                        <th>AMOUNT</th>
                                        <th>INTERVAL</th>
                                        <th>CREATE ON</th>
                                        <th>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {plans.map(((item)=>{
                                        
                                        return(
                                            <PlanItem item={item} />
                                            )
                                        })
                                    )
                                }
                                    {/* <tr>
                                        <td>F1 Streaming</td>
                                        <td>0/1 Active</td>
                                        <td className="gray-txt">NGN 10,000.00</td>
                                        <td>Monthly</td>
                                        <td>Aug 22, 2021</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <td>Newsletter</td>
                                        <td>1/1 Active</td>
                                        <td className="gray-txt">NGN 3,000.00</td>
                                        <td>Monthly</td>
                                        <td>Feb 23, 2021</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <td>Premium Membership</td>
                                        <td>1/1 Active</td>
                                        <td className="gray-txt">NGN 52,000.00</td>
                                        <td>Annually</td>
                                        <td>Jan 02, 2021</td>
                                        <td>Cancelled</td>
                                    </tr>
                                    <tr>
                                        <td>Platinum</td>
                                        <td>0/0 Active</td>
                                        <td className="gray-txt">NGN 1,000,000.00</td>
                                        <td>Monthly</td>
                                        <td>Dec 3, 2020</td>
                                        <td>Active</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div id="subscribers-block" className="col s12 pad0">
                        <div className="page-bar-wrapper flex-div justify-content-btw">
                            <div className="page-bar-left flex-div">
                                <div className="filter-block flex-div">
                                    <div className="filter-dropdown-trigger">
                                        <img src={require('../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                                        <span>Filters</span>
                                    </div>


                                    <ul id="filter-dropdown-2" className="filter-dropdown-content">

                                        <div className="arrow-up"></div>

                                        <div className="select-field input-field single-field rel">
                                            <input type="text" id="plan-name" className="form-inp-field" placeholder="Search" />
                                            <label htmlFor="plan-name" className="active">Plan</label>
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="status">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="status" className="active">Status </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="amount">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="amount" className="active">Amount </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <select id="expiring-cards">
                                                <option value="Show all">Show all</option>
                                                <option value="Show New">Show New</option>
                                                <option value="Show Returning">Show Returning</option>
                                            </select>
                                            <label htmlFor="expiring-cards" className="active">Expiring Cards </label>
                                            <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                        </div>

                                        <div className="select-field input-field single-field rel">
                                            <DateRangePicker placeholder="Select date range" id="date-range" format="dd-MMM-yy" placement="topStart" className="rs-datepicker" />
                                            {/* <input type="text" id="creation-date" className="form-inp-field datepicker" placeholder="Search" /> */}
                                            <label htmlFor="date-range-2" className="active">Date Range</label>
                                        </div>

                                        <div className="filter-btns-wrapper">
                                            <button className="filter-btn pry-btn">Filter</button>
                                            <button className="filter-reset-btn">
                                                <span>Reset to default</span>
                                            </button>
                                        </div>
                                    </ul>
                                </div>
                                <div className="vertical-divider">
                                    <hr />
                                </div>
                                <div className="bar-search-block rel">
                                    <img src={require('../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                                    <input onChange={handleSubInput} type="search" className="bar-search" placeholder="Search Subscriber" />
                                </div>
                            </div>
                            <div className="page-bar-right flex-div">
                                <div className="export-trigger-block flex-div">
                                    <span>Export CSV</span>
                                    <img src={require('../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="table-wrapper">
                            <table id="customers-table">
                                <thead>
                                    <tr>
                                        <th>CUSTOMER</th>
                                        <th>PLAN</th>
                                        <th>AMOUNT</th>
                                        <th>SUBSCRIBED ON</th>
                                        <th>UNIT</th>
                                        <th>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {subscribers.map(((item)=>{
                                        
                                        return(
                                            <SubscriberItem item={item} />
                                            )
                                        })
                                    )
                                }
                                    {/* <tr>
                                        <td>Jide Apete</td>
                                        <td>johnbu@gmail.com</td>
                                        <td className="gray-txt">NGN 100.00</td>
                                        <td>Jun 3, 2021</td>
                                        <td>2</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <td>Seun Makinde</td>
                                        <td>seunma@gmail.com</td>
                                        <td className="gray-txt">NGN 3000.00</td>
                                        <td>Aug 11, 2020</td>
                                        <td>3</td>
                                        <td>Completed</td>
                                    </tr>
                                    <tr>
                                        <td>Bisi Alagbede</td>
                                        <td>alabisi@gmail.com</td>
                                        <td className="gray-txt">NGN 2,000.00</td>
                                        <td>Mar 20, 2020</td>
                                        <td>4</td>
                                        <td>Cancelled</td>
                                    </tr>
                                    <tr>
                                        <td>Ade Tiger</td>
                                        <td>tigade@gmail.com</td>
                                        <td className="gray-txt">NGN 1,000.00</td>
                                        <td>Feb 2, 2020</td>
                                        <td>1</td>
                                        <td>Attentio</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscriptions;
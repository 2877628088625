import M from "materialize-css";
import $ from "jquery";

import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMessage, setLoading } from "../../redux/reducers/auth";
import debounce from "debounce";
import ResolveDisputeModal from "../../components/resolveDisputeModal";
import DisputeItem from "../../components/DisputeItem";
import { fetchDispute, searchDispute } from "../../redux/reducers/dispute";
import AuditsItem from "../../components/AuditsItem";

const Audits = () => {
    const dispatch = useDispatch()
    const {auth = {}, reports={}, customer} = useSelector(state=>state);
    const { disputes=[{},{}]} = reports
    const [search, setSearch] = useState("");

    const handleSearch = (text) =>{
       dispatch(searchDispute(text))

    }

    const getCustomer = () =>{
        dispatch(setLoading(true));
        dispatch(fetchDispute())
        dispatch(setLoading(false));
    }
    const searchHandler = useCallback(debounce((text)=>handleSearch(text), 2000), []);

    useEffect(()=>{
        if (search !== "") {
            searchHandler(search)
        }else{
            if (search !==null) {
                getCustomer()
            }
        }
    },[search])

    const handleInput = (e) =>{
        setSearch(e.target.value)
    }
    useEffect (()=> {
        var elemsModal = document.querySelectorAll('.modal');
        M.Modal.init(elemsModal);

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        $(".filter-dropdown-trigger").click(function(){
            $(".filter-dropdown-content").slideToggle();
        });
    }, []);

    return(
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title flex-div">
                        <span>Insights and Auditss </span>
                        <small className="page-label-tag">4</small>
                    </h4>
                </div>

                <div className="page-bar-wrapper flex-div justify-content-btw">
                    <div className="page-bar-left flex-div">
                        <div className="filter-block flex-div">
                            <div className="filter-dropdown-trigger">
                                <img src={require('../../images/filter.svg').default} alt="filter-icon" className="filter-icon" />
                                <span>Filters</span>
                            </div>


                            <ul id="filter-dropdown" className="filter-dropdown-content">

                                <div className="arrow-up"></div>

                                <div className="select-field input-field single-field rel">
                                    <select id="status">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="status" className="active">Status</label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="category">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="category" className="active">Category </label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="resolution">
                                        <option value="Show all">Show all</option>
                                        <option value="Show New">Show New</option>
                                        <option value="Show Returning">Show Returning</option>
                                    </select>
                                    <label htmlFor="resolution" className="active">Resolution </label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="select-field input-field single-field rel">
                                    <select id="date-period">
                                        <option value="All Time">All Time</option>
                                        <option value="This Week">This Week</option>
                                        <option value="This Month">This Month</option>
                                    </select>
                                    <label htmlFor="date-period" className="active">Date Period</label>
                                    <img src={require("../../images/caret-icon.svg").default} alt="caret" className="inp-caret-icon" />
                                </div>

                                <div className="filter-btns-wrapper">
                                    <button className="filter-btn pry-btn">Filter</button>
                                    <button className="filter-reset-btn">
                                        <span>Reset to default</span>
                                    </button>
                                </div>
                            </ul>
                        </div>
                        <div className="vertical-divider">
                            <hr />
                        </div>
                        <div className="bar-search-block rel">
                            <img src={require('../../images/search-3.svg').default} alt="search-icon" className="search-icon-2" />
                            <input onChange={handleInput} type="search" className="bar-search" placeholder="Search Customers" />
                        </div>
                    </div>
                    <div className="page-bar-right flex-div">
                        <div className="export-trigger-block flex-div">
                            <span>Export CSV</span>
                            <img src={require('../../images/yellow-bill-icon.svg').default} alt="bill-icon" className="bill-icon" />
                        </div>
                    </div>
                </div>

                <div className="table-wrapper">
                    <table id="customers-table">
                        <thead>
                            <tr>
                                <th>DATE</th>
                                <th>USERNAME</th>
                                <th>IP ADDRESS</th>
                                <th>OPERATION</th>
                                <th>COMMENT</th>
                                <th>STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                        {disputes.map(((item)=>{
                                        
                                        return(
                                            <AuditsItem item={item} onClick={()=>{}} />
                                        )
                                    })
                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
            </div>

            <ResolveDisputeModal />
        </div>
    )
}

export default Audits;
import '../css/auth.css';
import $ from 'jquery';
import M from 'materialize-css';

import {useDispatch, useSelector} from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState, useRef} from 'react';
import * as Yup from 'yup'
import { useNavigate, NavLink  } from 'react-router-dom';
import { InputField } from '../components/InputField';
import { SelectField } from '../components/selectField';
import { Loader } from "../components/loader";

/**
 * Constructs form validation object for bvn verification using Yup library
 * @param bvn_phone : Phone number used to register for user bvn.
 * @param countries : Country associated with the bvn.
 * @param bvn : Bank verification number.
 * @param dob : Date of birth associated with the bvn
 */

const formSchema = Yup.object().shape({
    bvn_phone: Yup.string().required("Required"),
    // countries: Yup.string().required("Required"),
    bvn: Yup.string().required("Required"),
    dob: Yup.string().required("Required"),
   
})

/**
 * Bank verification number componenet
 */

const BvnVerification = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const  { loading, user, server_error, bvnVerificationSuccessful, actionType } = useSelector(state=> state.auth)
    const [error, setError] = useState({})
    
    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors },watch, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues:{ ...user, bvn_phone: user.phone}
      })

      useEffect(()=>{
          setError(errors)
      },[errors])

    useEffect(() => {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        })

        var elemsDatepicker = document.querySelectorAll('.datepicker');
    
        // Initialize the datepicker
        const datepicker = M.Datepicker.init(elemsDatepicker, {
            format: 'yyyy-mm-dd',
            onClose: function() {
                // Get the selected date
                if (this.date) {
                    const dateObject = new Date(this.date);
                    const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth()+1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`;
                    setValue('dob', formattedDate)
                }
                
            }
        });

        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
        

        $("#referral-field-trigger").click(function(){
            $(this).parent().hide();
            $("#referral-field").slideDown();
        });
    }, []);


    /**
     * onSubmit function to trigger form submission for bank verification.
     * @param bvn_phone : Phone number used to register for user bvn.
     * @param countries : Country associated with the bvn.
     * @param bvn : Bank verification number.
     * @param dob : Date of birth associated with the bvn
     */
     const onSubmit = (data) => {
        console.log("__DATA__e", data)
        trigger()

        if (Object.keys(errors).length === 0){
            navigate("/signup/identity/email")
            // dispatch(bvnAction(data))
            // dispatch(setLoading(true))
            
            }
        }

       
    
    return(
        <div className="auth-block">
            <form id="bvn-verification-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-greet-title center">
                    <h1 className="greet-title">
                        Bank Verification Number 
                    </h1>
                    <p className="greet-desc">
                        Your Bank Verification Number is requested for KYC verification purpose to combat fraud in the system
                    </p>
                </div>
                <div className="input-row row">
                    <InputField 
                        type="tel" 
                       value={watch('bvn')}
                        label="BVN" 
                        {...register('bvn',{ required: true })}
                        onChange={e => setValue(e.target.id, e.target.value)} 
                        error={error && error['bvn']? error['bvn'].message: ""} 
                        id={{input: "bvn"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <SelectField 
                       items={[
                           {key: "Nigeria", value: "Nigeria"}
                       ]}
                        label="Country" 
                        {...register('countries',{ required: true })}
                        error={error && error['countries']? error['countries'].message: ""} 
                        onChange={e => setValue(e.target.id, e.target.value)}  
                        id={{select: "countries"}} 
                        classname={{div:"input-field col s12 mag-d-10 pad0 country-list-wrapper",  error:"error-text", label:"active", }} 
                    />
                    <InputField 
                        type="tel" 
                        value={watch('bvn_phone')}
                        label="BVN Phone Number" 
                        {...register('bvn_phone',{ required: true })}
                        onChange={e => setValue(e.target.id, e.target.value)} 
                        error={error && error['bvn_phone']? error['bvn_phone'].message: ""} 
                        id={{input: "bvn_phone"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0", error:"error-text", input:"inp-field", }} 
                    />
                    <InputField 
                        type="text" 
                        value={watch("dob")}
                        label="Date of Birth" 
                        {...register('dob',{ required: true })}
                        onChange={e =>setValue('dob', e.target.value)} 
                        error={error && error['dob']? error['dob'].message: ""} 
                        id={{input: "dob"}} 
                        classname={{div:"input-field col s12 mag-d-10 mag-t-0 pad0", error:"error-text", input:"inp-field datepicker", }} 
                    />
                </div>
                {loading&&<Loader />}
                <div className="submit-btn-wrapper flex-div justify-content-btw">
                    <div className="txt-link-side">
                        <NavLink to="/signup" className="pry-color">Sign up with Email instead</NavLink>
                    </div>
                    <div className="btn-side">
                        <button type="submit" className="submit-btn pointer">Verify </button>
                    </div>
                </div>

                <div className="agreement-div">
                    <p>By clicking “Verify”  your agree to Payrail 
                        <a href="http://" target="_blank" rel="noopener noreferrer"> Policy</a> and 
                        <a href="http://" target="_blank" rel="noopener noreferrer"> Terms</a> 
                    </p>
                </div>
            </form>

        </div>
    )
}

export default BvnVerification;
import "./Withdrawal.css";
import { useEffect } from "react";
import M from "materialize-css";
import { useSelector } from "react-redux";

import RecentTranstions from "./RecentTransactions";
import WithdrawToBankModal from "./Modals/WithdrawToBankModal";
import { WITHDRAWAL_TO_BANK_MODAL } from "../../utility/modalIDs";
import OtpModal from "./Modals/OtpModal";
import WithdrawSuccessModal from "./Modals/WithdrawSuccessModal";

const WithdrawalPage = () => {
    const {wallet={}} = useSelector(state=>state);
    const {recent_transaction = []} = wallet
    useEffect(()=> {
        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);
    return (
        <div className="page-content-wrapper">
            <div className="container">
                <div className="page-title-wrapper">
                    <h4 className="page-content-title">
                        <span>Withdrawal</span>
                    </h4>
                </div>

                <div className="dashboard-form mtp-account-select">
                    <div className="select-field input-field single-field">
                        <select id="role">
                            <option value="Main Account">Main Account</option>
                            <option value="Sub Account 1">Sub Account 1</option>
                            <option value="Sub Account 2">Sub Account 2</option>
                        </select>
                        <img src={require("../../images/caret-icon.svg").default} alt="caret" className="mtp-inp-caret-icon" />
                    </div>

                    <div className="mtp-account-select-balance">
                        <small>₦</small>12,000,000.09
                    </div>
                </div>

                <div className="mtp-section">
                    <div className="row tab-wrapper mtp-tab-container">
                        <div className="col s12 pad0">
                            <ul className="tabs">
                                <li className="tab">
                                    <div className="flex-div mtb-tab-item">
                                        <span>Bank Withdrawal</span> 
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div id="profile-block" className="col s12 pad0">
                            <div className="smt-option smt-mb modal-trigger" data-target={WITHDRAWAL_TO_BANK_MODAL}>
                                <img className="smt-option-icon" src={require("../../images/bank-circle.svg").default} alt="icon" />
                                <span className="smt-option-title">Withdraw to Bank</span>
                                <img className="smt-option-chev-icon" src={require("../../images/chevron-right.svg").default} alt="icon" />
                            </div>
                        </div>
                    </div>

                    <RecentTranstions recent_transaction={recent_transaction} />
                </div>
            </div>
            <WithdrawToBankModal />
            <OtpModal />
            <WithdrawSuccessModal />
        </div>
    );
};

export default WithdrawalPage;
import React, {useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../css/status-toast.css"
const Toast = ({type="success", message=""}) => {
 
    return (
        <>
        <div className={`status-wrapper ${type}`}>
            {
                type==="success"?
                <img src={require("../../images/okay.svg").default} alt="okay" className="status-icon" />:
                <img src={require("../../images/cancel.svg").default} alt="cancel" className="status-icon" />
            }
           
            <span className="status-txt">
                {message}
            </span>
        </div>

    </>
    )
}

export default Toast;
import { useState } from "react";

import "./Invoice.css";

// components
import PaymentRequestDetails from "./PaymentRequestDetails";
import Main from "./main";
import AddSuccessModal from "../../../components/sucessfulModal";

const Invoice = () => {
    const [isMain, setIsMain] = useState("main");
    const [data, setData] = useState({});

    const pageChangeHandler = (val, data) => {
        setIsMain(val);
        setData(data)
    };

    return(
        <div className="page-content-wrapper">
            <div className="container">
                {
                    isMain === "main" ? <Main goToDetails={pageChangeHandler} /> : <PaymentRequestDetails data={data} goback={pageChangeHandler} />
                }
            </div>
            <AddSuccessModal title="Invoice added successfully" desc="A new invoice has been added. You can add more." />
        </div>
    )
}

export default Invoice;
import "../Settings.css";

import { useEffect, useState} from "react";
import M from "materialize-css";

import LoginPassword from "./LoginPassword";
import TransactionPin from "./TransactionPin";

import OTPViaEmailModal from "./Modals/OTPViaEmail";
import OTPViaAuthenticatorApp from "./Modals/OTPViaAuthenticatorApp";

import { RECEIVE_OTP_VIA_EMAIL_MODAL_ID, RECEIVE_OTP_VIA_AUTHENTICATOR_APP_MODAL_ID } from "../../../utility/modalIDs";

const SecurityTab = () => {
    const [ modal1, setModal1 ] = useState(null);
    const [ modal2, setModal2 ] = useState(null);

    useEffect(()=> {
        const elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        const elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);

        const emailOtpModal = document.getElementById(RECEIVE_OTP_VIA_EMAIL_MODAL_ID);
        setModal1(M.Modal.init(emailOtpModal));

        const authAppOtpModal = document.getElementById(RECEIVE_OTP_VIA_AUTHENTICATOR_APP_MODAL_ID);
        setModal2(M.Modal.init(authAppOtpModal));
    }, []);

    console.log("modal1", modal1);

    return(
    <>
        <div className="settings-block-wrapper stlpt-block-wrapper-height">
            <div className="profile-settings-wrapper">
                <div id="security-block">
                    <div className="row tab-wrapper">
                        <div className="col s12 pad0">
                            <ul className="tabs stlpt-tab-bg tabs-fixed-width">
                                <li className="tab">
                                    <a className="active flex-div stlpt-centered" href="#login-password-block">
                                        <span>Login Password</span> 
                                    </a>
                                </li>
                                {/* <li className="tab">
                                    <a className="flex-div stlpt-centered" href="#transaction-pin-block">
                                        <span>Transaction PIN</span> 
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                        <div id="login-password-block" className="col s12 pad0">
                            <LoginPassword />
                        </div>      
                        {/* <div id="transaction-pin-block" className="col s12 pad0">
                            <TransactionPin />
                        </div>     */}
                    </div>
                </div>
            </div>
        </div>

        <div className="settings-block-wrapper stlpt-block-wrapper-height stlpt-mt">
            <div className="stlpt-2fa-title">Two-Factor authentication (2FA)</div>
            <div className="stlpt-2fa-description">Decide your prefered method of receiving one time passwords (OTP).</div>
            <label className="stlpt-d-block" onClick={() => modal1.open()}>
              <input name="group1" type="radio" className="with-gap" />
              <span className="stlpt-radio-text ">Receive OTP via email and SMS</span>
            </label>
            <label className="stlpt-d-block" onClick={() => modal2.open()}>
              <input name="group1" type="radio" className="with-gap"  />
              <span className="stlpt-radio-text">Receive OTP via authenticator app</span>
            </label>
        </div>
        <OTPViaEmailModal />
        <OTPViaAuthenticatorApp />
    </>
    )
}

export default SecurityTab;
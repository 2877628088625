import "./BillPaymentScheduleSuccessModal.css"

import { useEffect } from "react";

import $ from "jquery";

const BillPaymentScheduleSuccessModal = () => {
    useEffect(()=> {
        $(".input-field input").focus(function(){
            var targetLabel = $(this).parent().find('label');
            targetLabel.addClass("active");
        })
        $(".input-field input").blur(function(){
            var targetLabel = $(this).parent().find('label');
            if($(this).val()) {
                targetLabel.addClass("active");
            } else {
                targetLabel.removeClass("active");
            }
        });
    }, []);

    return(
        <div id="bill-payment-schedule-success-modal" className="modal dashboard-modal">
            <div className="modal-content">
                <img src={require("../../../images/close-icon.svg").default} alt="close modal" className="modal-close close-modal-icon" />    

                <div className="bps-icon">
                    <img src={require("../../../images/success-tick.svg").default} alt="success" className="" />  
                    <h2>Awesome</h2>  
                </div>

                <div className="bps-schedule-success-message">
                    <p>Your next bill payment has been scheduled to<br></br> <span>Monthly. Start: 01/09/2022. End: 05/01/2022</span> <br></br>
                    successfully</p>
                </div>

            </div>
        </div>
    )
}

export default BillPaymentScheduleSuccessModal;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  disputes:[{email:"atunde@gmail.com",status:"Awaiting Your Feedback",due_when:"Tomorrow",created_date:"Jun 3, 2021",tx_date:"Apr 16, 2021",tx_amount:"100.00", category:"Chargeback"}],
 
}

export const disputeSlice = createSlice({
  name: 'dispute',
  initialState,

  reducers: {
    addDispute: ()=>{},
    searchDispute: ()=>{},
    filterDispute: ()=>{},
    fetchDispute: ()=>{},
    Disputes: (state, action) => {
      state.token = action.payload;
      state.user = action.payload;
      state.server_error = null;
      state.loginSuccessful = true
    },
   
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  Disputes,
  
  addDispute,
  searchDispute,
  filterDispute,
  fetchDispute,
   
    } = disputeSlice.actions

export default disputeSlice.reducer
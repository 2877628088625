import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { RECEIVE_OTP_VIA_EMAIL_MODAL_ID } from "../../../../utility/modalIDs";

const OTPViaEmailModal = () => {
    return (
        <ModalWrapper modalID={RECEIVE_OTP_VIA_EMAIL_MODAL_ID} >
            <h4 className="ivvrm-header">Receive OTP via email</h4>

            <div className="otpvem-text">Your one-time passwords will be sent to your email address</div>

            <hr />

            <div className="ipib-draft-btns">
                <button className="pry-btn ipib-cancel-btn modal-close">
                    Cancel
                </button>
                <button className="pry-btn modal-close">
                    Enable email OTP
                </button>
            </div>
        </ModalWrapper>
    )
};

export default OTPViaEmailModal;
import "./CreateNewRole.css";

// components
import ModalWrapper from "../../../../../components/UI/ModalWrapper";

//modal ID
import { VIEW_ROLE_AND_PRIVILEGES_MODAL_ID } from "../../../../../utility/modalIDs";
import { NavLink } from "react-router-dom";

const CreateNewRoleModal = () => {
    return(
        <ModalWrapper modalID={VIEW_ROLE_AND_PRIVILEGES_MODAL_ID }>
            <div>
                <h5 className="iatmm-header">Admin role privileges</h5>
                <p className="iatmm-sub-header">Admins have the following privileges:</p>
            </div>

            <div className="cnrapm-privileges-box">
                <h6>Payments and invoices</h6>
                <ul>
                    <li className="cnrapm-privileges-list-item">
                        <span>Prepare and manage payments and invoices</span>
                        <img src={require("../../../../../images/mark-circle-green.svg").default} alt="check-circle" /> 
                    </li>
                </ul>
            </div>

            <div className="cnrapm-privileges-box">
                <h6>Account privileges</h6>
                <ul>
                    <li className="cnrapm-privileges-list-item">
                        <span>Can view accounts</span>
                        <img src={require("../../../../../images/mark-circle-green.svg").default} alt="check-circle" /> 
                    </li>
                    <li className="cnrapm-privileges-list-item">
                        <span>Can download statements</span>
                        <img src={require("../../../../../images/mark-circle-green.svg").default} alt="check-circle" /> 
                    </li>
                    <li className="cnrapm-privileges-list-item">
                        <span>Can create accounts</span>
                        <img src={require("../../../../../images/cancel-circle-red.svg").default} alt="check-circle" /> 
                    </li>
                </ul>
            </div>

            <div className="cnrapm-privileges-box">
                <h6>Administration privileges</h6>
                <ul>
                    <li className="cnrapm-privileges-list-item">
                        <span>Team mates</span>
                        <NavLink to="/dashboard/role-creator">
                            <img src={require("../../../../../images/edit-pill-green.svg").default} alt="edit" /> 
                        </NavLink>
                    </li>
                    <li className="cnrapm-privileges-list-item">
                        <span>Roles and privileges</span>
                        <NavLink to="/dashboard/role-creator">
                            <img src={require("../../../../../images/hide-pill-red.svg").default} alt="hide" /> 
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className="cnrapm-privileges-box">
                <h6>Settings privileges</h6>
                <ul>
                    <li className="cnrapm-privileges-list-item">
                        <span>Company settings</span>
                        <NavLink to="/dashboard/role-creator">
                            <img src={require("../../../../../images/edit-pill-green.svg").default} alt="edit" /> 
                        </NavLink>
                    </li>
                    <li className="cnrapm-privileges-list-item">
                        <span>Compliance check</span>
                        <NavLink to="/dashboard/role-creator">
                            <img src={require("../../../../../images/edit-pill-green.svg").default} alt="edit" /> 
                        </NavLink> 
                    </li>
                    <li className="cnrapm-privileges-list-item">
                        <span>Approval rules</span>
                        <NavLink to="/dashboard/role-creator">
                            <img src={require("../../../../../images/hide-pill-red.svg").default} alt="hide" /> 
                        </NavLink> 
                    </li>
                </ul>
            </div>
        </ModalWrapper>
    )
};

export default CreateNewRoleModal;
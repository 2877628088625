import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  terminals:[],
  fetchingTerminal: true,
 
}

export const terminalSlice = createSlice({
  name: 'pos',
  initialState,

  reducers: {
    addTerminal: ()=>{},
    requestTerminal: ()=>{},
    fetchTerminals: ()=>{},
    Terminals: (state, action) => {
     state.terminals = action.payload;
        state.fetchingTerminal = false;
        state.server_error = null;
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { 
  Terminals,
  
  addTerminal,
  requestTerminal,
  fetchTerminals,
   
    } = terminalSlice.actions

export default terminalSlice.reducer
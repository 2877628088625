// import "../css/modals.css"
import "../../css/dispute-modal.css"

import React,{ useEffect, useState } from "react";
import $ from "jquery";
import M from "materialize-css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // add the Quill stylesheet

const ViewTicketModal = () => {
    const [content, setContent] = useState('');
  
    function handleContentChange(value) {
      setContent(value);
    }
    const styles = {
      fontSize: '16px',
      color: '#333',
      
      width: '100%',
      height: '200px',
      resize: 'none',
      marginBottom: '50px',
      marginTop: '20px'
    }

    useEffect(()=> {
        var elemsTab = document.querySelectorAll('.tabs');
        M.Tabs.init(elemsTab);

        // const commentBox = document.querySelector("#comment-box");
        // commentBox.addEventListener("keyup", function(event) {
        //     var commentBoxText = document.querySelector("#comment-box").value;
        //     // Number 13 is the "Enter" key on the keyboard
        //     if(event.keyCode === 13) {
        //         if(commentBoxText !== "") {
        //             // Cancel the default action, if needed
        //             event.preventDefault();
        //             // Trigger the button element with a click
        //             //   document.getElementById("myBtn").click();
        //             $(".chat-box").prepend('<li class="chat-box-row myself"> <div> <div class="chat-row-title"> <b class="chat-sender">Me </b> <span class="chat-time"> · a second ago</span> </div> <div class="chat-row-message">' + commentBoxText + '</div> </li> </div>');
        //         }
        //     }
        // });
    }, []);

    return(
        <div id="tsv-main-wrapper" className="container">
            <div className="back-wrapper">
                <div className="manage-cards-goback" onClick={()=>{}}>
                    <div className="manage-cards-goback-icon">
                        <img src={require("../../images/caret-down.svg").default} alt="caret"  />
                    </div>
                    <span>Back</span>
                </div>
            </div>
                <div className="dispute-grid">
                    <div className="resolve-dispute-block">
                       
                        <div className="resolve-dispute-chat-block">
                        <div className="flex-div chat-block-header">
                            <img src={require("../../images/chat.svg").default} alt="chat" className="chat-icon" />
                            <span>Conversation</span>
                        </div>
                        <ReactQuill
                            style={styles}
                            value={content}
                            onChange={handleContentChange}
                            />
                    {/* <textarea id="chargeback-reason" className="materialize-textarea form-inp-field"></textarea> */}
               
               
                    <div className="input-title">
                        <b>
                        File type: .jpg, .gif, .jpeg, .png, .pdf, zip, .doc, .docx (Max file size: 128MB) <span className="red-text">*</span>
                        </b>
                        <p>
                            Upload all relevant document for this ticket. Make sure they are legible and 
                            contain specific details about the issue. 
                        </p>
                    </div>
                    <div className="file-field input-field">
                        <input type="file" id="accept-chargeback-files" multiple />
                        <div className="file-path-wrapper">
                            <input className="file-path form-inp-field" type="text" placeholder="+ Choose File" />
                        </div>
                    </div>
               

                <div className="iatmm-d-flex btn-wrapper">
                   
                    <button className="iatmm-btn iatmm-invite-btn modal-close">
                    Reply
                    </button>
                </div>
                        
                    </div>
                    </div>
                    <div className="disputes-details-wrapper">
                            <div className="disputes-details-block flex-div justify-content-btw">
                                <span>Requestor - Merchant </span>
                                <b>Olamide Olagunju • +2348060110110</b>
                            </div>
                            <div className="disputes-details-block flex-div justify-content-btw">
                                <span>Department</span>
                                <b>Customer Service Desk</b>
                            </div>
                            <div className="disputes-details-block flex-div justify-content-btw">
                                <span>Submitted</span>
                                <b>28/03/2022 • 06:35 </b>
                            </div>
                            <div className="disputes-details-block flex-div justify-content-btw">
                                <span>Status/Pirority</span>
                                <b>Open  </b>
                            </div>
                        </div>
                    
                </div>
                <div className="resolve-dispute-block">
                <ul className="chat-box">
                            <li className="chat-box-row myself">
                                <div>
                                    <div className="chat-row-title">
                                        <b className="chat-sender">John Jeff • Customer Support </b>
                                        <span className="chat-time"> · 31 mins ago</span>
                                    </div>
                                    <div className="chat-row-message">
                                    Hello Olamide, Thank you for holding on. Your Payrail Merchant account has been successfully reactivated.
Do let me know if I can assist you further. Regards,
-- Abisola A Customer Support
Do you know you can pay with bank transfer via Paystack or Rave and have your service activated automatically? Please click here to learn more. Looking for a bulk transaction? Visit payrail.co to make an inquiry or send us a mail at support@payrail.co  For promotional offers visit promos page  Need some extra cash: Make some money by becoming an affiliate click here

                                    </div>
                                </div>
                            </li>
                            <li className="chat-box-row admin-guys">
                                <div>
                                    <div className="chat-row-title">
                                        <b className="chat-sender">Olamide Olagunju  • +2348060110110 </b>
                                        <span className="chat-time"> · an hour ago</span>
                                    </div>
                                    <div className="chat-row-message">
                                        Please kindly respond to the dispute
                                    </div>
                                </div>
                            </li>
                            <li className="chat-box-row customer-guys">
                                <div>
                                    <div className="chat-row-title">
                                        <b className="chat-sender">Olamide Olagunju  • +2348060110110 </b>
                                        <span className="chat-time"> · 1 day ago</span>
                                    </div>
                                    <div className="chat-row-message">
                                    Hello Support, I have issues with my account... Loging in has been a big challenge, Please I need this solved as soon as possible   
Do let me know if I can assist you further. Regards,
-

                                    </div>
                                </div>
                            </li>
                            <li className="chat-box-row myself">
                                <div>
                                    <div className="chat-row-title">
                                        <b className="chat-sender">John Jeff • Customer Support </b>
                                        <span className="chat-time"> · 31 mins ago</span>
                                    </div>
                                    <div className="chat-row-message">
                                    Hello Olamide, Thank you for holding on. Your Payrail Merchant account has been successfully reactivated.
Do let me know if I can assist you further. Regards,
-- Abisola A Customer Support
Do you know you can pay with bank transfer via Paystack or Rave and have your service activated automatically? Please click here to learn more. Looking for a bulk transaction? Visit payrail.co to make an inquiry or send us a mail at support@payrail.co  For promotional offers visit promos page  Need some extra cash: Make some money by becoming an affiliate click here

                                    </div>
                                </div>
                            </li>
                        </ul>
                        </div>
                </div>
         
       
    )
};

export default ViewTicketModal;
import React, { useState, useEffect} from "react";
import M from "materialize-css";
import ModalWrapper from "../../../../components/UI/ModalWrapper";
import { ADD_MONEY_VIA_USSD_TRANSFER_MODAL_ID, COPY_USSD_CODE_MODAL_ID } from "../../../../utility/modalIDs";
import { SelectField } from "../../../../components/selectField";
import { InputField } from "../../../../components/InputField";

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';

/**
 * Constructs form validation object for user old signup using Yup library
 * @param password : User password.
 */
const formSchema = Yup.object().shape({
    amount: Yup.string().required("Required"),
    bank_name: Yup.string().required("Required"),
})

const AddMoneyViaUSSDTransferModal = () => {
    useEffect (()=> {
        var elemSelect = document.querySelectorAll('select');
        M.FormSelect.init(elemSelect);
    }, []);


    const [error, setError] = useState({});

    /**
     * Yup configuration for form validation
     */
    const { register, formState: { errors }, handleSubmit, control, setValue, trigger } = useForm({
        resolver: yupResolver(formSchema),
    })
    
    useEffect(()=>{
        setError(errors)
    },[errors]);

    const onSubmit = (data) => {
        trigger()
        if (Object.keys(errors).length === 0){
           
        }
    }
    return (
        <ModalWrapper modalID={ADD_MONEY_VIA_USSD_TRANSFER_MODAL_ID}>
            <h4 className="ivvrm-header">USSD Transfer</h4>

            <img src={require("../../../../images/ussd-circle-big.svg").default} alt="icon" className="amvbtm-icon" />

            <div className="amvbtm-description">
            Add money to your Payrail wallet using USSD transfer from your other bank
            </div>

            <form className="dashboard-form modal-form amvutm-mt" onSubmit={handleSubmit(onSubmit)}>
                <InputField 
                    type="text" 
                    label="Amount" 
                    {...register('amount',{ required: true })}
                    onChange={e =>{setValue(e.target.id, e.target.value)}}
                    error={error && error['amount']? error['amount'].message: ""} 
                    id={{input: "amount"}} 
                    classname={{div:"input-field single-field", error:"error-text", input:"form-inp-field"}} 
                />

                <SelectField 
                    items={[
                        {key: "GTbank", value: "GTbank"},
                    ]}
                    type="text" 
                    label="Your Bank Name" 
                    {...register('bank_name',{ required: true })}
                    onChange={e => setValue(e.target.id, e.target.value)} 
                    id={{select: "bank_name"}} 
                    classname={{div:"select-field input-field single-field rel", label:"active", img: "inp-caret-icon" }} 
                />
                <button type="submit" className="pry-btn modal-trigger modal-close amvutm-btn-fullwidth" data-target={COPY_USSD_CODE_MODAL_ID}>
                    Continue
                </button>
            </form>
        </ModalWrapper>
    );
};

export default AddMoneyViaUSSDTransferModal;